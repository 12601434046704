@import 'src/mixins.scss'; //ok
@import 'src/standard-mixins.scss';

/* ADDICTION LIST */
addiction-list {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	position: relative;

	&.show-bulk-actions {
		height: calc(100% - 70px);
	}

	.header-grid-container {
		/* uguale ad addiction-grid.scss */
		width: 100%;
		min-height: auto;
		@include flex-center;
		flex-flow: row wrap;
		position: relative;

		&.expanded {
			margin-bottom: 70px;
		}

		&.dam {
			.header-grid {
				.left-container {
					.title {
						h1 {
							@include font-style(40px, $font-light, $white, 0, 100%);
						}
					}
				}
			}
		}

		.header-grid {
			width: 100%;
			height: 70px;
			padding: 10px 20px;
			display: flex;

			.left-container {
				flex: 1;
				height: 100%;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				margin-right: auto;

				.checkbox-container {
					width: 30px;

					mat-checkbox {
						@include mat-checkbox-style(transparentize($white, 0.9), $primary, none);
						margin: 0 10px 0 0;
					}

				}

				.title {
					width: calc(100% - 30px);

					h1 {
						@include font-style(20px, $font-light, $white, 0, 150%);
						text-overflow: ellipsis;
						overflow: hidden;
						margin: 0;
					}

				}

			}

			.right-container {
				display: flex;
				align-items: center;
				justify-content: flex-end;

				.view-mode-container {
					@include view-mode-buttons;
				}

				addiction-search {
					/* stile dentro addiction-components.scss */
					margin-left: 20px;
				}

				button.filters-button {
					@include button-style('y', $white, $white, $buttonIcon: true, $buttonBackground: false);
					margin-left: 20px;

					&::after {
						content: '';
						width: calc(100% + 4px);
						height: 10px;
						background: $brand-color-03;
						position: absolute;
						bottom: -10px;
						left: -2px;
						opacity: 0;
					}

					&[aria-expanded='false'] {
						box-shadow: none;
					}

					&[aria-expanded='true'] {
						background: $brand-color-03;
						border: 2px solid $brand-color-03;

						&::after {
							opacity: 1;
						}

						&.mat-menu-below {
							border-bottom-left-radius: 0;
							border-bottom-right-radius: 0;

							&::after {
								bottom: -10px;
							}

						}

						&.mat-menu-above {
							border-top-left-radius: 0;
							border-top-right-radius: 0;

							&::after {
								top: -10px;
							}

						}

					}

				}

				button.import-export {
					@include button-style('l', $white, $white, $buttonIcon: true, $buttonBackground: false);
					margin-left: 20px;
				}

				button.new {
					@include button-style(36px, $brand-color-04, $white, $buttonIcon: false, $buttonBackground: true);
					margin-left: 20px;
				}

				.elements {
					display: flex;
					justify-content: flex-end;

					.number {
						@include font-style(14px, $font-regular, $white, 1px, 100%);
						margin-right: 5px;
					}

					.text {
						@include font-style(14px, $font-regular, $white, 1px, 100%);
					}

				}

			}

		}

		addiction-bulk-action-bar {
			/* stile dentro addiction-components.scss */
		}

	}

	.single-row-header {
		height: 56px;
    min-height: 56px;
		width: calc(100% - 40px);
		display: flex;
		align-items: center;
		column-gap: 10px;
		margin: 0 20px;
		border-top: 1px solid transparentize($white, 0.9);
		border-bottom: 1px solid transparentize($white, 0.9);

		&.hidden {
			display: none;
		}

		&.mat-sort {
			.single-column {
				span {
					cursor: pointer;
				}
			}
		}

		mat-checkbox {
			width: 20px;
			@include mat-checkbox-style(transparentize($white, 0.9), $primary, none);
		}

		.single-column {
			display: flex;
			overflow: hidden;

			&.checkbox {
				width: 20px;
			}

			&.image {
				width: 60px;
			}

			&.single {
				flex: 1;
			}

			&.double {
				flex: 2;
			}

			&.triple {
				flex: 3;
			}

			&.arrow {
				width: 30px;
			}

			&.options {
				width: 30px;
				justify-content: center;
			}

			&.icon {
				width: 60px;
			}

			&.position {
				width: 30px;
			}

			&.delete {
				width: 30px;
			}

			&.reverse {
				justify-content: flex-end;

				span {
					text-align: right;
				}

			}

      &.center {
        justify-content: center;

        span {
          text-align: center;
        }

      }

			span {
				width: 100%;
				@include font-style(10px, $font-medium, transparentize($white, 0.5), 0.5px, 100%);
				text-transform: uppercase;
				text-align: left;
				word-break: break-all;
			}

		}

	}

	.list-viewport {
		width: 100%;
		height: 100%;
		overflow-y: scroll;
		overflow-x: hidden;
		@include scroll-bar;

		&.with-header {
			//solo header-grid-container
			height: calc(100% - 70px);

			&.with-header-template {
				//sia header-grid-container che single-row-header
				height: calc(100% - 120px);
			}

		}

		&.with-header-template {
			//solo single-row-header
			height: calc(100% - 50px);
		}

		&.hidden {
      height: 0;
		}

		* {
			transition: none;
		}

		.cdk-virtual-scroll-content-wrapper {
			display: flex;
			flex-flow: column;
			width: 100%;
			max-width: 100%;
			padding: 0 20px;

			.list-row {
				display: flex;
				justify-content: center;
				flex-flow: column;
				width: 100%;
				height: auto;
				column-gap: 20px;

				.single-row {
					min-height: 56px;
					height: 100%;
					display: flex;
					align-items: center;
					column-gap: 10px;
					cursor: pointer;
					padding: 10px 0;
					position: relative;
					border-bottom: 1px solid transparentize($white, 0.9);

					@media (hover: hover) and (pointer: fine) {
						&:hover {
							.single-column {
								&.image {
									img {
										opacity: 0.5;
									}
								}
							}
						}
					}

					mat-checkbox {
						width: 20px;
						@include mat-checkbox-style(transparentize($white, 0.9), $primary, none);
					}

					.single-column {
						height: 100%;
						display: flex;
						align-items: center;
						justify-content: flex-start;
						@include font-style(12px, $font-regular, transparentize($white, 0.4), 0, 150%);
						text-align: left;
						cursor: pointer;
						overflow: hidden;

						&.checkbox {
							width: 20px;
						}

						&.image {
							width: 60px;
							height: 60px;
							border: 1px solid $brand-color-08;

							img {
								width: 100%;
								height: 100%;
								object-fit: cover;
								object-position: center;
								transition: $standard-transition;
							}

						}

						&.icon {
							width: 60px;
							@include flex-center;
							cursor: pointer;
							transition: $standard-transition;

							@media (hover: hover) and (pointer: fine) {

								&:hover {
									opacity: 0.5;
								}

							}

							span {
								width: 20px;
								height: 20px;
								@include flex-center;
								position: relative;

								&::before {
									width: 20px;
									height: 20px;
									font-size: 12px;
									font-family: $font-icon;
									color: $white;
									@include flex-center;
									position: absolute;
								}

								&.user-detail {
									&::before {
										content: 'M';
									}
								}

								&.challenge-detail {
									&::before {
										content: 'N';
									}
								}

								//LMS CORSI ESITO RISPOSTA
								&.wrong {
									&::before {
										content: 'd';
										color: $red-01;
										box-shadow: 0 0 10px $red-01;
									}
								}

								&.right {
									&::before {
										content: 'c';
										color: $on-color;
										box-shadow: 0 0 10px $on-color;
									}
								}

								//INTEGRAZIONI
								&.liferay,
								&.wordpress,
								&.catalean,
								&.smartCo {
									width: 34px;
									height: 34px;

									&::before {
										width: 34px;
										height: 34px;
										background-size: contain;
										background-repeat: no-repeat;
										background-position: center;
									}

								}

								&.liferay {
									&::before {
										content: '';
										background-image: url('../../../assets/images/integrations/integration-liferay.png');
									}
								}

								&.wordpress {
									&::before {
										content: '';
										background-image: url('../../../assets/images/integrations/integration-wordpress.png');
									}
								}

								&.catalean {
									&::before {
										content: '';
										background-image: url('../../../assets/images/integrations/integration-catalean.png');
									}
								}

								&.smartCo {
									&::before {
										content: '';
										background-image: url('../../../assets/images/integrations/integration-smartco.png');
									}
								}

							}

						}

						&.buttons-actions {
							width: 60px;
							@include flex-center;

							button {
								width: 20px;
								height: 20px;
								border-radius: 5px;
								border: none;
								background: $transparent;
								@include flex-center;
								cursor: pointer;
								transition: $standard-transition;

								@media (hover: hover) and (pointer: fine) {

									&:hover {
										opacity: 0.5;
									}

								}

								&::before {
									width: 20px;
									height: 20px;
									font-family: $font-icon;
									@include flex-center;
									position: absolute;
								}

								&.invalid {
									&::before {
										content: 'd';
										font-size: 12px;
										color: $ko-color;
									}
								}

								&.valid {
									&::before {
										content: 'c';
										font-size: 15px;
										color: $ok-color;
									}
								}

							}

						}

            &.features {

              > span {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                box-orient: vertical;
                -webkit-line-clamp: 3;
                line-clamp: 3;
                overflow: hidden;
                text-overflow: ellipsis;
                max-height: calc(18px * 3);

                //&:hover {
                //  -webkit-line-clamp: unset;
                //  -webkit-box-orient: unset;
                //  max-height: none;
                //  overflow: visible;
                //  transition-delay: 2s;
                //}

              }

            }

						&.single {
							flex: 1;
						}

						&.double {
							flex: 2;
						}

						&.triple {
							flex: 3;
						}

						&.position {
							width: 30px;

							span {
								@include font-style(14px, $font-medium, $white, 0, 100%);
							}

						}

						&.answer-status {}

						&.reverse {
							justify-content: flex-end;

							span {
								text-align: right;
							}

						}

            &.center {
              justify-content: center;

              span {
                text-align: center;
              }

            }

						span {
							width: 100%;
							@include font-style(12px, $font-regular, transparentize($white, 0.4), 0, 150%); //line-height 18px, se cambiato, modificare calc(18px * 3) sopra
							text-align: left;
							cursor: pointer;
							text-overflow: ellipsis;
							overflow: hidden;

							&.highlight {
								@include font-style(14px, $font-medium, $white, 0, 150%);
								cursor: pointer;
							}

							&.format {
								width: auto;
								@include font-style(10px, $font-semibold, $white, 0, 100%);
								cursor: pointer;
								text-transform: uppercase;
								background: $brand-color-08;
								border-radius: 3px;
								padding: 4px 5px;
							}

							//&.elements { //DA CONTROLLARE
							//  width: 100%;
							//  @include font-style(10px, $font-semibold, $white, 0, 100%);
							//  cursor: pointer;
							//  text-transform: uppercase;
							//  text-align: right;
							//}

							&.status {
								padding-left: 16px;
								position: relative;
								display: flex;
								align-items: center;

								&::before {
									content: '';
									width: 6px;
									height: 6px;
									border-radius: 100%;
									background: $pending-color;
									position: absolute;
									left: 1px;
								}

								&.CONFIRMED,
								&.PUBLISHED,
								&.ENABLED,
								&.ACTIVE,
								&.VALID {
									&::before {
										background: $on-color;
									}
								}

								&.DECLINED,
								&.ARCHIVED,
								&.DISABLED,
								&.INACTIVE,
								&.NOT_VALID {
									&::before {
										background: $red-01;
									}
								}

								&.correct,
                &.right,
								&.partial,
								&.wrong {
									width: 30px;
									height: 30px;
									padding: 0;
									@include flex-center;

									&::before {
										content: 'f';
										width: 18px;
										height: 18px;
										font-size: 17px;
										font-family: $font-icon;
										color: $pending-color;
										text-shadow: 0 0 5px $pending-color;
										border-radius: 0;
										background: var(--transparent);
									}

								}

								&.correct,
                &.right {
									&::before {
										content: 'c';
										font-size: 18px;
										color: $on-color;
										text-shadow: 0 0 5px $on-color;
									}
								}

								&.wrong {
									&::before {
										content: 'd';
										font-size: 16px;
										color: $red-01;
										text-shadow: 0 0 5px $red-01;
									}
								}

							}

						}

						&.arrow {
							width: 30px;
							height: 10px;
							justify-content: center;

							&::before {
								content: 'b';
								width: 10px;
								height: 10px;
								font-family: $font-icon;
								color: $white;
								font-size: 6px;
								background: $transparent;
								pointer-events: none;
								display: flex;
								align-items: center;
								justify-content: flex-end;
								transform: rotate(-90deg);
							}

						}

						&.options {
							width: 30px;
							justify-content: center;
						}

						&.delete {
							width: 30px;

							button.delete {
								@include button-style('i', $red-01, $red-01, $buttonIcon: true, $buttonBackground: false);
								border: none;
							}

						}

					}

					&.disabled {
						opacity: 0.5;
					}

				}

				.single-row-skeleton {
					min-height: 56px;
					display: flex;
					align-items: center;
					column-gap: 20px;
					cursor: pointer;
					padding: 10px 0;
					position: relative;
					border-bottom: 1px solid transparentize($white, 0.9);

					.single-column {
						height: 100%;
						display: flex;
						align-items: center;
						justify-content: flex-start;
						overflow: hidden;

						&.checkbox {
							width: 18px;
							height: 18px;
							@include skeleton-loading;

							span {
								width: 100%;
							}

						}

						&.image {
							width: 60px;
							height: 60px;
							border: none;
							@include skeleton-loading;
						}

						&.single {
							flex: 1;
						}

						&.double {
							flex: 2;
						}

						&.triple {
							flex: 3;
						}

						&.icon {
							width: 60px;
						}

						&.position {
							width: 30px;
						}

						&.reverse {
							justify-content: flex-end;

							span {
								text-align: right;
							}

						}

            &.center {
              justify-content: center;

              span {
                text-align: center;
              }

            }

						span {
							width: 80%;
							height: 20px;
							border-radius: 3px;
							@include skeleton-loading;
						}

						&.arrow {
							width: 30px;
							height: 10px;
							justify-content: center;

							&::before {
								content: 'b';
								width: 10px;
								height: 10px;
								font-family: $font-icon;
								color: $white;
								font-size: 6px;
								background: $transparent;
								pointer-events: none;
								display: flex;
								align-items: center;
								justify-content: flex-end;
								transform: rotate(-90deg);
							}

						}

						&.options {
							width: 30px;
							justify-content: center;
						}

					}

				}

				cdk-accordion {
					height: 100%;

					cdk-accordion-item {
						height: 100%;

						&.accordion-disabled {
							.single-row.accordion-header {
								.single-column {
									&.arrow {
										&::before {
											display: none;
										}
									}
								}
							}
						}

						&[aria-expanded='true'] {
							.single-row.accordion-header {
								//background: linear-gradient(to bottom, transparentize($white, 0.95), $transparent);

								.single-column {
									&.arrow {
										&::before {
											transform: rotate(0);
										}
									}
								}
							}
						}

						.action-list {
							.single-row.accordion-details {
								border-bottom: 0;

								&:first-of-type {
									padding-top: 60px;
								}

								&:last-of-type {
									padding-bottom: 60px;
								}

								.single-column {
									&:first-of-type {
										span {
											padding-left: 50px;
										}
									}

									&.arrow {
										&::before {
											display: none;
										}
									}
								}
							}
						}
					}
				}
			}
		}

		.cdk-virtual-scroll-spacer {
			display: none;
		}

	}

	.no-results-container {
		@include no-results;
	}

}
