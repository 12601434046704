@charset "UTF-8";
/* Custom Theming for Angular Material */
@import '~jsoneditor/dist/jsoneditor.min.css';
.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
  background-color: var(--mat-ripple-color, rgba(0, 0, 0, 0.1));
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-app-background {
  background-color: var(--mat-app-background-color, transparent);
  color: var(--mat-app-text-color, inherit);
}

html {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
}

html {
  --mat-option-selected-state-label-text-color: #3f51b5;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-accent {
  --mat-option-selected-state-label-text-color: #ff4081;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-warn {
  --mat-option-selected-state-label-text-color: #f44336;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

html {
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
}

.mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #3f51b5;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #3f51b5;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-full-pseudo-checkbox-selected-icon-color: #ff4081;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #ff4081;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #ff4081;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #ff4081;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #f44336;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #f44336;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-app-background-color: #fafafa;
  --mat-app-text-color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

html {
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
}

html {
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
}

html {
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #3f51b5;
  --mdc-linear-progress-track-color: rgba(63, 81, 181, 0.25);
}
.mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #ff4081;
  --mdc-linear-progress-track-color: rgba(255, 64, 129, 0.25);
}
.mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #f44336;
  --mdc-linear-progress-track-color: rgba(244, 67, 54, 0.25);
}

html {
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
}

html {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}

html {
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
}

html {
  --mdc-filled-text-field-caret-color: #3f51b5;
  --mdc-filled-text-field-focus-active-indicator-color: #3f51b5;
  --mdc-filled-text-field-focus-label-text-color: rgba(63, 81, 181, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #f44336;
  --mdc-filled-text-field-error-focus-label-text-color: #f44336;
  --mdc-filled-text-field-error-label-text-color: #f44336;
  --mdc-filled-text-field-error-caret-color: #f44336;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-hover-active-indicator-color: #f44336;
  --mdc-outlined-text-field-caret-color: #3f51b5;
  --mdc-outlined-text-field-focus-outline-color: #3f51b5;
  --mdc-outlined-text-field-focus-label-text-color: rgba(63, 81, 181, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #f44336;
  --mdc-outlined-text-field-error-focus-label-text-color: #f44336;
  --mdc-outlined-text-field-error-label-text-color: #f44336;
  --mdc-outlined-text-field-error-hover-label-text-color: #f44336;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #f44336;
  --mdc-outlined-text-field-error-hover-outline-color: #f44336;
  --mdc-outlined-text-field-error-outline-color: #f44336;
  --mat-form-field-focus-select-arrow-color: rgba(63, 81, 181, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-state-layer-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-error-text-color: #f44336;
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-select-disabled-option-text-color: GrayText;
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.04;
  --mat-form-field-focus-state-layer-opacity: 0.08;
}

.mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #ff4081;
  --mdc-filled-text-field-focus-active-indicator-color: #ff4081;
  --mdc-filled-text-field-focus-label-text-color: rgba(255, 64, 129, 0.87);
  --mdc-outlined-text-field-caret-color: #ff4081;
  --mdc-outlined-text-field-focus-outline-color: #ff4081;
  --mdc-outlined-text-field-focus-label-text-color: rgba(255, 64, 129, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(255, 64, 129, 0.87);
}

.mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #f44336;
  --mdc-filled-text-field-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
  --mdc-outlined-text-field-caret-color: #f44336;
  --mdc-outlined-text-field-focus-outline-color: #f44336;
  --mdc-outlined-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(244, 67, 54, 0.87);
}

html {
  --mat-form-field-container-height: 56px;
  --mat-form-field-filled-label-display: block;
  --mat-form-field-container-vertical-padding: 16px;
  --mat-form-field-filled-with-label-container-padding-top: 24px;
  --mat-form-field-filled-with-label-container-padding-bottom: 8px;
}

html {
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(63, 81, 181, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
html .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(255, 64, 129, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
html .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(244, 67, 54, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}

html {
  --mat-select-arrow-transform: translateY(-8px);
}

html {
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-autocomplete-background-color: white;
}

html {
  --mdc-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-dialog-container-shadow-color: #000;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
}

html {
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}

.mat-mdc-standard-chip {
  --mdc-chip-container-shape-family: rounded;
  --mdc-chip-container-shape-radius: 16px 16px 16px 16px;
  --mdc-chip-with-avatar-avatar-shape-family: rounded;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px 14px 14px 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}

.mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-selected-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-flat-disabled-selected-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-selected-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mat-chip-selected-disabled-trailing-icon-color: #212121;
  --mat-chip-selected-trailing-icon-color: #212121;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #3f51b5;
  --mdc-chip-elevated-selected-container-color: #3f51b5;
  --mdc-chip-elevated-disabled-container-color: #3f51b5;
  --mdc-chip-flat-disabled-selected-container-color: #3f51b5;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #ff4081;
  --mdc-chip-elevated-selected-container-color: #ff4081;
  --mdc-chip-elevated-disabled-container-color: #ff4081;
  --mdc-chip-flat-disabled-selected-container-color: #ff4081;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #f44336;
  --mdc-chip-elevated-selected-container-color: #f44336;
  --mdc-chip-elevated-disabled-container-color: #f44336;
  --mdc-chip-flat-disabled-selected-container-color: #f44336;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 32px;
}

html {
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
}

html {
  --mdc-switch-selected-focus-state-layer-color: #3949ab;
  --mdc-switch-selected-handle-color: #3949ab;
  --mdc-switch-selected-hover-state-layer-color: #3949ab;
  --mdc-switch-selected-pressed-state-layer-color: #3949ab;
  --mdc-switch-selected-focus-handle-color: #1a237e;
  --mdc-switch-selected-hover-handle-color: #1a237e;
  --mdc-switch-selected-pressed-handle-color: #1a237e;
  --mdc-switch-selected-focus-track-color: #7986cb;
  --mdc-switch-selected-hover-track-color: #7986cb;
  --mdc-switch-selected-pressed-track-color: #7986cb;
  --mdc-switch-selected-track-color: #7986cb;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
html .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-color: rgba(0, 0, 0, 0.87);
}
html .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #d81b60;
  --mdc-switch-selected-handle-color: #d81b60;
  --mdc-switch-selected-hover-state-layer-color: #d81b60;
  --mdc-switch-selected-pressed-state-layer-color: #d81b60;
  --mdc-switch-selected-focus-handle-color: #880e4f;
  --mdc-switch-selected-hover-handle-color: #880e4f;
  --mdc-switch-selected-pressed-handle-color: #880e4f;
  --mdc-switch-selected-focus-track-color: #f06292;
  --mdc-switch-selected-hover-track-color: #f06292;
  --mdc-switch-selected-pressed-track-color: #f06292;
  --mdc-switch-selected-track-color: #f06292;
}
html .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #e53935;
  --mdc-switch-selected-handle-color: #e53935;
  --mdc-switch-selected-hover-state-layer-color: #e53935;
  --mdc-switch-selected-pressed-state-layer-color: #e53935;
  --mdc-switch-selected-focus-handle-color: #b71c1c;
  --mdc-switch-selected-hover-handle-color: #b71c1c;
  --mdc-switch-selected-pressed-handle-color: #b71c1c;
  --mdc-switch-selected-focus-track-color: #e57373;
  --mdc-switch-selected-hover-track-color: #e57373;
  --mdc-switch-selected-pressed-track-color: #e57373;
  --mdc-switch-selected-track-color: #e57373;
}

html {
  --mdc-switch-state-layer-size: 40px;
}

html {
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-radio-button {
  --mdc-form-field-label-text-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #3f51b5;
  --mdc-radio-selected-hover-icon-color: #3f51b5;
  --mdc-radio-selected-icon-color: #3f51b5;
  --mdc-radio-selected-pressed-icon-color: #3f51b5;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #3f51b5;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ff4081;
  --mdc-radio-selected-hover-icon-color: #ff4081;
  --mdc-radio-selected-icon-color: #ff4081;
  --mdc-radio-selected-pressed-icon-color: #ff4081;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #ff4081;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #f44336;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}

html {
  --mdc-radio-state-layer-size: 40px;
  --mat-radio-touch-target-display: block;
}

html {
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
}

html {
  --mdc-slider-handle-color: #3f51b5;
  --mdc-slider-focus-handle-color: #3f51b5;
  --mdc-slider-hover-handle-color: #3f51b5;
  --mdc-slider-active-track-color: #3f51b5;
  --mdc-slider-inactive-track-color: #3f51b5;
  --mdc-slider-with-tick-marks-inactive-container-color: #3f51b5;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-label-container-color: #000;
  --mdc-slider-label-label-text-color: #fff;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-ripple-color: #3f51b5;
  --mat-slider-hover-state-layer-color: rgba(63, 81, 181, 0.05);
  --mat-slider-focus-state-layer-color: rgba(63, 81, 181, 0.2);
  --mat-slider-value-indicator-opacity: 0.6;
}
html .mat-accent {
  --mat-slider-ripple-color: #ff4081;
  --mat-slider-hover-state-layer-color: rgba(255, 64, 129, 0.05);
  --mat-slider-focus-state-layer-color: rgba(255, 64, 129, 0.2);
  --mdc-slider-handle-color: #ff4081;
  --mdc-slider-focus-handle-color: #ff4081;
  --mdc-slider-hover-handle-color: #ff4081;
  --mdc-slider-active-track-color: #ff4081;
  --mdc-slider-inactive-track-color: #ff4081;
  --mdc-slider-with-tick-marks-inactive-container-color: #ff4081;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
html .mat-warn {
  --mat-slider-ripple-color: #f44336;
  --mat-slider-hover-state-layer-color: rgba(244, 67, 54, 0.05);
  --mat-slider-focus-state-layer-color: rgba(244, 67, 54, 0.2);
  --mdc-slider-handle-color: #f44336;
  --mdc-slider-focus-handle-color: #f44336;
  --mdc-slider-hover-handle-color: #f44336;
  --mdc-slider-active-track-color: #f44336;
  --mdc-slider-inactive-track-color: #f44336;
  --mdc-slider-with-tick-marks-inactive-container-color: #f44336;
  --mdc-slider-with-tick-marks-active-container-color: white;
}

html {
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
}

html {
  --mat-menu-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-icon-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
  --mat-menu-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 4px;
}

html {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #3f51b5;
  --mdc-radio-selected-hover-icon-color: #3f51b5;
  --mdc-radio-selected-icon-color: #3f51b5;
  --mdc-radio-selected-pressed-icon-color: #3f51b5;
}

.mat-accent .mdc-list-item__start,
.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ff4081;
  --mdc-radio-selected-hover-icon-color: #ff4081;
  --mdc-radio-selected-icon-color: #ff4081;
  --mdc-radio-selected-pressed-icon-color: #ff4081;
}

.mat-warn .mdc-list-item__start,
.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
}

.mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #3f51b5;
  --mdc-checkbox-selected-hover-icon-color: #3f51b5;
  --mdc-checkbox-selected-icon-color: #3f51b5;
  --mdc-checkbox-selected-pressed-icon-color: #3f51b5;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #3f51b5;
  --mdc-checkbox-selected-hover-state-layer-color: #3f51b5;
  --mdc-checkbox-selected-pressed-state-layer-color: #3f51b5;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #ff4081;
  --mdc-checkbox-selected-hover-icon-color: #ff4081;
  --mdc-checkbox-selected-icon-color: #ff4081;
  --mdc-checkbox-selected-pressed-icon-color: #ff4081;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ff4081;
  --mdc-checkbox-selected-hover-state-layer-color: #ff4081;
  --mdc-checkbox-selected-pressed-state-layer-color: #ff4081;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #3f51b5;
}
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #3f51b5;
}

.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}

html {
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
  --mat-list-list-item-leading-icon-start-space: 16px;
  --mat-list-list-item-leading-icon-end-space: 32px;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 56px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 72px;
}

html {
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-paginator-container-size: 56px;
  --mat-paginator-form-field-container-height: 40px;
  --mat-paginator-form-field-container-vertical-padding: 8px;
}

html {
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
}

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #3f51b5;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #3f51b5;
  --mat-tab-header-active-ripple-color: #3f51b5;
  --mat-tab-header-inactive-ripple-color: #3f51b5;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #3f51b5;
  --mat-tab-header-active-hover-label-text-color: #3f51b5;
  --mat-tab-header-active-focus-indicator-color: #3f51b5;
  --mat-tab-header-active-hover-indicator-color: #3f51b5;
}
.mat-mdc-tab-group.mat-accent, .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #ff4081;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #ff4081;
  --mat-tab-header-active-ripple-color: #ff4081;
  --mat-tab-header-inactive-ripple-color: #ff4081;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #ff4081;
  --mat-tab-header-active-hover-label-text-color: #ff4081;
  --mat-tab-header-active-focus-indicator-color: #ff4081;
  --mat-tab-header-active-hover-indicator-color: #ff4081;
}
.mat-mdc-tab-group.mat-warn, .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #f44336;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #f44336;
  --mat-tab-header-active-ripple-color: #f44336;
  --mat-tab-header-inactive-ripple-color: #f44336;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #f44336;
  --mat-tab-header-active-hover-label-text-color: #f44336;
  --mat-tab-header-active-focus-indicator-color: #f44336;
  --mat-tab-header-active-hover-indicator-color: #f44336;
}
.mat-mdc-tab-group.mat-background-primary, .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #3f51b5;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-accent, .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #ff4081;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-warn, .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #f44336;
  --mat-tab-header-with-background-foreground-color: white;
}

.mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 48px;
}

html {
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
}

html {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #ff4081;
  --mdc-checkbox-selected-hover-icon-color: #ff4081;
  --mdc-checkbox-selected-icon-color: #ff4081;
  --mdc-checkbox-selected-pressed-icon-color: #ff4081;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ff4081;
  --mdc-checkbox-selected-hover-state-layer-color: #ff4081;
  --mdc-checkbox-selected-pressed-state-layer-color: #ff4081;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
  --mat-checkbox-disabled-label-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-checkbox {
  --mdc-form-field-label-text-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #3f51b5;
  --mdc-checkbox-selected-hover-icon-color: #3f51b5;
  --mdc-checkbox-selected-icon-color: #3f51b5;
  --mdc-checkbox-selected-pressed-icon-color: #3f51b5;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #3f51b5;
  --mdc-checkbox-selected-hover-state-layer-color: #3f51b5;
  --mdc-checkbox-selected-pressed-state-layer-color: #3f51b5;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

html {
  --mdc-checkbox-state-layer-size: 40px;
  --mat-checkbox-touch-target-display: block;
}

html {
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-keep-touch-target: false;
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
}

html {
  --mdc-text-button-label-text-color: black;
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-text-button-state-layer-color: black;
  --mat-text-button-disabled-state-layer-color: black;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: black;
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-disabled-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: black;
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-container-shadow-color: #000;
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-disabled-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-label-text-color: black;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: black;
  --mat-outlined-button-disabled-state-layer-color: black;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
}

.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #3f51b5;
  --mat-text-button-state-layer-color: #3f51b5;
  --mat-text-button-ripple-color: rgba(63, 81, 181, 0.1);
}
.mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #ff4081;
  --mat-text-button-state-layer-color: #ff4081;
  --mat-text-button-ripple-color: rgba(255, 64, 129, 0.1);
}
.mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
  --mat-text-button-state-layer-color: #f44336;
  --mat-text-button-ripple-color: rgba(244, 67, 54, 0.1);
}

.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #3f51b5;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #ff4081;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #3f51b5;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #ff4081;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #3f51b5;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #3f51b5;
  --mat-outlined-button-ripple-color: rgba(63, 81, 181, 0.1);
}
.mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #ff4081;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #ff4081;
  --mat-outlined-button-ripple-color: rgba(255, 64, 129, 0.1);
}
.mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #f44336;
  --mat-outlined-button-ripple-color: rgba(244, 67, 54, 0.1);
}

html {
  --mdc-text-button-container-height: 36px;
  --mdc-filled-button-container-height: 36px;
  --mdc-outlined-button-container-height: 36px;
  --mdc-protected-button-container-height: 36px;
  --mat-text-button-touch-target-display: block;
  --mat-filled-button-touch-target-display: block;
  --mat-protected-button-touch-target-display: block;
  --mat-outlined-button-touch-target-display: block;
}

html {
  --mdc-icon-button-icon-size: 24px;
}

html {
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
  --mat-icon-button-state-layer-color: black;
  --mat-icon-button-disabled-state-layer-color: black;
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.04;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
}
html .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #3f51b5;
  --mat-icon-button-state-layer-color: #3f51b5;
  --mat-icon-button-ripple-color: rgba(63, 81, 181, 0.1);
}
html .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #ff4081;
  --mat-icon-button-state-layer-color: #ff4081;
  --mat-icon-button-ripple-color: rgba(255, 64, 129, 0.1);
}
html .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #f44336;
  --mat-icon-button-state-layer-color: #f44336;
  --mat-icon-button-ripple-color: rgba(244, 67, 54, 0.1);
}

html {
  --mat-icon-button-touch-target-display: block;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 48px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 12px;
}

html {
  --mdc-fab-container-shape: 50%;
  --mdc-fab-icon-size: 24px;
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-icon-size: 24px;
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
}

html {
  --mdc-fab-container-color: white;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-shadow-color: #000;
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: black;
  --mat-fab-disabled-state-layer-color: black;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.04;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-fab-small-container-color: white;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shadow-color: #000;
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: black;
  --mat-fab-small-disabled-state-layer-color: black;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.04;
  --mat-fab-small-focus-state-layer-opacity: 0.12;
  --mat-fab-small-pressed-state-layer-opacity: 0.12;
  --mat-fab-small-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-shadow-color: #000;
}
html .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: #3f51b5;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #ff4081;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #f44336;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: #3f51b5;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #ff4081;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #f44336;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}

html {
  --mat-fab-touch-target-display: block;
  --mat-fab-small-touch-target-display: block;
}

html {
  --mdc-snackbar-container-shape: 4px;
}

html {
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #ff4081;
}

html {
  --mat-table-row-item-outline-width: 1px;
}

html {
  --mat-table-background-color: white;
  --mat-table-header-headline-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-table-header-container-height: 56px;
  --mat-table-footer-container-height: 52px;
  --mat-table-row-item-container-height: 52px;
}

html {
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
}

html {
  --mdc-circular-progress-active-indicator-color: #3f51b5;
}
html .mat-accent {
  --mdc-circular-progress-active-indicator-color: #ff4081;
}
html .mat-warn {
  --mdc-circular-progress-active-indicator-color: #f44336;
}

html {
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
}

html {
  --mat-badge-background-color: #3f51b5;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #b9b9b9;
  --mat-badge-disabled-state-text-color: rgba(0, 0, 0, 0.38);
}

.mat-badge-accent {
  --mat-badge-background-color: #ff4081;
  --mat-badge-text-color: white;
}

.mat-badge-warn {
  --mat-badge-background-color: #f44336;
  --mat-badge-text-color: white;
}

html {
  --mat-bottom-sheet-container-shape: 4px;
}

html {
  --mat-bottom-sheet-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-bottom-sheet-container-background-color: white;
}

html {
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
}

html {
  --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-divider-color: #e0e0e0;
}

html {
  --mat-standard-button-toggle-height: 48px;
}

html {
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

html {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #3f51b5;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(63, 81, 181, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(63, 81, 181, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(63, 81, 181, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #3f51b5;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(63, 81, 181, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-text-color: black;
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #ff4081;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(255, 64, 129, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(255, 64, 129, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(255, 64, 129, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(255, 64, 129, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #f44336;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(244, 67, 54, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(244, 67, 54, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}

.mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #ff4081;
}
.mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #f44336;
}

.mat-calendar-controls {
  --mat-icon-button-touch-target-display: none;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}

html {
  --mat-divider-width: 1px;
}

html {
  --mat-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
}

html {
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-expansion-header-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-header-description-color: rgba(0, 0, 0, 0.54);
  --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54);
}

html {
  --mat-expansion-header-collapsed-state-height: 48px;
  --mat-expansion-header-expanded-state-height: 64px;
}

html {
  --mat-icon-color: inherit;
}

.mat-icon.mat-primary {
  --mat-icon-color: #3f51b5;
}
.mat-icon.mat-accent {
  --mat-icon-color: #ff4081;
}
.mat-icon.mat-warn {
  --mat-icon-color: #f44336;
}

html {
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
}

html {
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-content-background-color: #fafafa;
  --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
}

html {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #3f51b5;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #3f51b5;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #3f51b5;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: #f44336;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #f44336;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
html .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #ff4081;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #ff4081;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #ff4081;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
html .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #f44336;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #f44336;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #f44336;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}

html {
  --mat-stepper-header-height: 72px;
}

html {
  --mat-sort-arrow-color: #757575;
}

html {
  --mat-toolbar-container-background-color: whitesmoke;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #3f51b5;
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #ff4081;
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #f44336;
  --mat-toolbar-container-text-color: white;
}

html {
  --mat-toolbar-standard-height: 64px;
  --mat-toolbar-mobile-height: 56px;
}

html {
  --mat-tree-container-background-color: white;
  --mat-tree-node-text-color: rgba(0, 0, 0, 0.87);
}

html {
  --mat-tree-node-min-height: 48px;
}

/* You can add global styles to this file, and also import other style files */
/* --- TYPOGRAPHY --- */
/* --- COLORS --- */
/* --- ANIMATIONS --- */
/* --- SPECIFIC MIXINS --- */
/* Stile per buttons che al click aprono un sottomenù */
/* --- TYPOGRAPHY --- */
/* --- COLORS --- */
/* --- ANIMATIONS --- */
/* --- STANDARD MIXINS --- */
/* --- FLEX --- */
/* --- FONTS --- */
/* Usato ad es. per scroll dentro select o popup con sfondo $brand-color-03 */
@keyframes loading {
  to {
    background-position-x: -20%;
  }
}
/*
NOTE BUTTONS FE 3.0 - DAM
I buttons con lo sfondo colorato, senza bordo e icona in mezzo (es. +) si fanno così:
@include button-style("e", $brand-color-04, $white, $buttonIcon: true, $buttonBackground: true);

I buttons senza sfondo, con bordo e icona in mezzo (es. permessi, back, navigazione, filtri) si fanno così:
@include button-style("e", $brand-color-04, $brand-color-04, $buttonIcon: true, $buttonBackground: false);

I buttons con lo sfondo colorato, senza bordo e testo in mezzo (es. salva, nuovo) si fanno così:
@include button-style(36px, $brand-color-04, $white, $buttonIcon: false, $buttonBackground: true);

I buttons senza sfondo, con bordo e testo in mezzo (es. aggiungi a collezione, collezioni) si fanno così:
@include button-style(36px, $brand-color-04, $brand-color-04, $buttonIcon: false, $buttonBackground: false);
*/
/* --- TYPOGRAPHY --- */
/* --- COLORS --- */
/* --- ANIMATIONS --- */
/* --- TYPOGRAPHY --- */
/* --- COLORS --- */
/* --- ANIMATIONS --- */
/* --- STANDARD MIXINS --- */
/* --- FLEX --- */
/* --- FONTS --- */
/* Usato ad es. per scroll dentro select o popup con sfondo $brand-color-03 */
@keyframes loading {
  to {
    background-position-x: -20%;
  }
}
/*
NOTE BUTTONS FE 3.0 - DAM
I buttons con lo sfondo colorato, senza bordo e icona in mezzo (es. +) si fanno così:
@include button-style("e", $brand-color-04, $white, $buttonIcon: true, $buttonBackground: true);

I buttons senza sfondo, con bordo e icona in mezzo (es. permessi, back, navigazione, filtri) si fanno così:
@include button-style("e", $brand-color-04, $brand-color-04, $buttonIcon: true, $buttonBackground: false);

I buttons con lo sfondo colorato, senza bordo e testo in mezzo (es. salva, nuovo) si fanno così:
@include button-style(36px, $brand-color-04, $white, $buttonIcon: false, $buttonBackground: true);

I buttons senza sfondo, con bordo e testo in mezzo (es. aggiungi a collezione, collezioni) si fanno così:
@include button-style(36px, $brand-color-04, $brand-color-04, $buttonIcon: false, $buttonBackground: false);
*/
/* SEGMA FONT */
@font-face {
  font-family: "Segma";
  src: url("assets/fonts/Segma/Segma-Thin.eot");
  src: url("assets/fonts/Segma/Segma-Thin.eot?#iefix") format("embedded-opentype"), url("assets/fonts/Segma/Segma-Thin.woff2") format("woff2"), url("assets/fonts/Segma/Segma-Thin.woff") format("woff"), url("assets/fonts/Segma/Segma-Thin.ttf") format("truetype"), url("assets/fonts/Segma/Segma-Thin.svg#Segma-Thin") format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Segma";
  src: url("assets/fonts/Segma/Segma-Light.eot");
  src: url("assets/fonts/Segma/Segma-Light.eot?#iefix") format("embedded-opentype"), url("assets/fonts/Segma/Segma-Light.woff2") format("woff2"), url("assets/fonts/Segma/Segma-Light.woff") format("woff"), url("assets/fonts/Segma/Segma-Light.ttf") format("truetype"), url("assets/fonts/Segma/Segma-Light.svg#Segma-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Segma";
  src: url("assets/fonts/Segma/Segma-Regular.eot");
  src: url("assets/fonts/Segma/Segma-Regular.eot?#iefix") format("embedded-opentype"), url("assets/fonts/Segma/Segma-Regular.woff2") format("woff2"), url("assets/fonts/Segma/Segma-Regular.woff") format("woff"), url("assets/fonts/Segma/Segma-Regular.ttf") format("truetype"), url("assets/fonts/Segma/Segma-Regular.svg#Segma-Regular") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Segma";
  src: url("assets/fonts/Segma/Segma-Medium.eot");
  src: url("assets/fonts/Segma/Segma-Medium.eot?#iefix") format("embedded-opentype"), url("assets/fonts/Segma/Segma-Medium.woff2") format("woff2"), url("assets/fonts/Segma/Segma-Medium.woff") format("woff"), url("assets/fonts/Segma/Segma-Medium.ttf") format("truetype"), url("assets/fonts/Segma/Segma-Medium.svg#Segma-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Segma";
  src: url("assets/fonts/Segma/Segma-SemiBold.eot");
  src: url("assets/fonts/Segma/Segma-SemiBold.eot?#iefix") format("embedded-opentype"), url("assets/fonts/Segma/Segma-SemiBold.woff2") format("woff2"), url("assets/fonts/Segma/Segma-SemiBold.woff") format("woff"), url("assets/fonts/Segma/Segma-SemiBold.ttf") format("truetype"), url("assets/fonts/Segma/Segma-SemiBold.svg#Segma-SemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Segma";
  src: url("assets/fonts/Segma/Segma-Bold.eot");
  src: url("assets/fonts/Segma/Segma-Bold.eot?#iefix") format("embedded-opentype"), url("assets/fonts/Segma/Segma-Bold.woff2") format("woff2"), url("assets/fonts/Segma/Segma-Bold.woff") format("woff"), url("assets/fonts/Segma/Segma-Bold.ttf") format("truetype"), url("assets/fonts/Segma/Segma-Bold.svg#Segma-Bold") format("svg");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Segma";
  src: url("assets/fonts/Segma/Segma-Black.eot");
  src: url("assets/fonts/Segma/Segma-Black.eot?#iefix") format("embedded-opentype"), url("assets/fonts/Segma/Segma-Black.woff2") format("woff2"), url("assets/fonts/Segma/Segma-Black.woff") format("woff"), url("assets/fonts/Segma/Segma-Black.ttf") format("truetype"), url("assets/fonts/Segma/Segma-Black.svg#Segma-Black") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
/* MEDIA LIBRARY - DATALEAN 3.0 FONT ICON */
@font-face {
  font-family: "DataLean Font Icon";
  src: url("assets/fonts/FontIcon/icomoon.eot?kpw6fb");
  src: url("assets/fonts/FontIcon/icomoon.eot?kpw6fb#iefix") format("embedded-opentype"), url("assets/fonts/FontIcon/icomoon.woff2?kpw6fb") format("woff2"), url("assets/fonts/FontIcon/icomoon.ttf?kpw6fb") format("truetype"), url("assets/fonts/FontIcon/icomoon.woff?kpw6fb") format("woff"), url("assets/fonts/FontIcon/icomoon.svg?kpw6fb#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon-],
[class*=" icon-"] {
  font-family: "DataLean Font Icon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-back::before {
  content: "a";
}

.icon-arrow::before {
  content: "b";
}

.icon-checked::before {
  content: "c";
}

.icon-close::before {
  content: "d";
}

.icon-plus::before {
  content: "e";
}

.icon-minus::before {
  content: "f";
}

.icon-move::before {
  content: "g";
}

.icon-search::before {
  content: "h";
}

.icon-delete::before {
  content: "i";
}

.icon-video::before {
  content: "j";
}

.icon-copy::before {
  content: "k";
}

.icon-order::before {
  content: "l";
}

.icon-zoom::before {
  content: "m";
}

.icon-edit::before {
  content: "n";
}

.icon-date::before {
  content: "o";
}

.icon-image::before {
  content: "p";
}

.icon-doc::before {
  content: "q";
}

.icon-bell::before {
  content: "r";
}

.icon-folder-empty::before {
  content: "s";
}

.icon-folder-filled::before {
  content: "t";
}

.icon-upload::before {
  content: "u";
}

.icon-collections::before {
  content: "v";
}

.icon-configuration::before {
  content: "w";
}

.icon-app-switch::before {
  content: "x";
}

.icon-filters::before {
  content: "y";
}

.icon-grid::before {
  content: "z";
}

.icon-list::before {
  content: "A";
}

.icon-image1::before {
  content: "B";
}

.icon-video1::before {
  content: "C";
}

.icon-doc1::before {
  content: "D";
}

.icon-archive::before {
  content: "E";
}

.icon-unknown::before {
  content: "F";
}

.icon-ban::before {
  content: "G";
}

.icon-contextual::before {
  content: "H";
}

.icon-permissions::before {
  content: "I";
}

.icon-replace::before {
  content: "J";
}

.icon-download::before {
  content: "K";
}

.icon-qrcode::before {
  content: "L";
}

.icon-user::before {
  content: "M";
}

.icon-challenge::before {
  content: "N";
}

.icon-eye-opened::before {
  content: "O";
}

.icon-eye-closed::before {
  content: "P";
}

.icon-catalog::before {
  content: "Q";
}

.icon-datasheet::before {
  content: "R";
}

@font-face {
  font-family: "DataLean Structure Font Icon";
  src: url("assets/fonts/FontIconStrutture/icomoon.eot?or13gt");
  src: url("assets/fonts/FontIconStrutture/icomoon.eot?or13gt#iefix") format("embedded-opentype"), url("assets/fonts/FontIconStrutture/icomoon.woff2?or13gt") format("woff2"), url("assets/fonts/FontIconStrutture/icomoon.ttf?or13gt") format("truetype"), url("assets/fonts/FontIconStrutture/icomoon.woff?or13gt") format("woff"), url("assets/fonts/FontIconStrutture/icomoon.svg?or13gt#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon-],
[class*=" icon-"] {
  font-family: "DataLean Structure Font Icon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-select::before {
  content: "a";
}

.icon-container::before {
  content: "b";
}

.icon-file::before {
  content: "c";
}

.icon-textarea::before {
  content: "d";
}

.icon-html::before {
  content: "e";
}

.icon-email::before {
  content: "f";
}

.icon-checkbox::before {
  content: "g";
}

.icon-qr-code::before {
  content: "h";
}

.icon-filter::before {
  content: "i";
}

.icon-privacy:before {
  content: "j";
}

.icon-media::before {
  content: "k";
}

.icon-text::before {
  content: "l";
}

.icon-hotspotter::before {
  content: "m";
}

.icon-radio::before {
  content: "n";
}

.icon-related::before {
  content: "o";
}

.icon-phone::before {
  content: "p";
}

.icon-attachment::before {
  content: "q";
}

.icon-url-title::before {
  content: "r";
}

.icon-toggle::before {
  content: "s";
}

.icon-image-map::before {
  content: "t";
}

.icon-categories::before {
  content: "u";
}

.icon-date::before {
  content: "v";
}

.icon-number::before {
  content: "w";
}

.icon-hidden::before {
  content: "x";
}

.icon-pictograms::before {
  content: "y";
}

.icon-survery-domain-for-answers::before {
  content: "z";
}

.icon-substructure::before {
  content: "A";
}

.icon-availability-option::before {
  content: "B";
}

.icon-availabiity-rule::before {
  content: "C";
}

@font-face {
  font-family: "DataLean HTML Font Icon";
  src: url("assets/fonts/FontIconHtml/icomoon.eot?1oa1r1");
  src: url("assets/fonts/FontIconHtml/icomoon.eot?1oa1r1#iefix") format("embedded-opentype"), url("assets/fonts/FontIconHtml/icomoon.woff2?1oa1r1") format("woff2"), url("assets/fonts/FontIconHtml/icomoon.ttf?1oa1r1") format("truetype"), url("assets/fonts/FontIconHtml/icomoon.woff?1oa1r1") format("woff"), url("assets/fonts/FontIconHtml/icomoon.svg?1oa1r1#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon-],
[class*=" icon-"] {
  font-family: "DataLean HTML Font Icon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-html-bold::before {
  content: "a";
}

.icon-html-italic::before {
  content: "b";
}

.icon-html-underline::before {
  content: "c";
}

.icon-html-strike::before {
  content: "d";
}

.icon-html-ordered-list::before {
  content: "e";
}

.icon-html-bullet-list::before {
  content: "f";
}

.icon-html-link::before {
  content: "g";
}

.icon-html-text-color::before {
  content: "h";
}

.icon-html-bg-color::before {
  content: "i";
}

.icon-html-text-left::before {
  content: "j";
}

.icon-html-text-center::before {
  content: "k";
}

.icon-html-text-right::before {
  content: "l";
}

.icon-html-text-justify::before {
  content: "m";
}

.icon-html-superscript::before {
  content: "n";
}

.icon-html-subscript::before {
  content: "o";
}

.icon-html-table::before {
  content: "p";
}

.icon-html-add-row::before {
  content: "q";
}

.icon-html-add-column::before {
  content: "r";
}

.icon-html-delete-row::before {
  content: "s";
}

.icon-html-delete-column::before {
  content: "t";
}

.icon-html-delete-table::before {
  content: "u";
}

/* --- TYPOGRAPHY --- */
/* --- COLORS --- */
/* --- ANIMATIONS --- */
/* --- STANDARD MIXINS --- */
/* --- FLEX --- */
/* --- FONTS --- */
/* Usato ad es. per scroll dentro select o popup con sfondo $brand-color-03 */
@keyframes loading {
  to {
    background-position-x: -20%;
  }
}
/*
NOTE BUTTONS FE 3.0 - DAM
I buttons con lo sfondo colorato, senza bordo e icona in mezzo (es. +) si fanno così:
@include button-style("e", $brand-color-04, $white, $buttonIcon: true, $buttonBackground: true);

I buttons senza sfondo, con bordo e icona in mezzo (es. permessi, back, navigazione, filtri) si fanno così:
@include button-style("e", $brand-color-04, $brand-color-04, $buttonIcon: true, $buttonBackground: false);

I buttons con lo sfondo colorato, senza bordo e testo in mezzo (es. salva, nuovo) si fanno così:
@include button-style(36px, $brand-color-04, $white, $buttonIcon: false, $buttonBackground: true);

I buttons senza sfondo, con bordo e testo in mezzo (es. aggiungi a collezione, collezioni) si fanno così:
@include button-style(36px, $brand-color-04, $brand-color-04, $buttonIcon: false, $buttonBackground: false);
*/
/* ADDICTION AUTOCOMPLETE */
addiction-autocomplete {
  width: 100%;
  display: flex;
}
addiction-autocomplete .form-field {
  width: 100%;
  display: flex;
}
addiction-autocomplete .form-field .form-input-container {
  width: 100%;
  display: flex;
}
addiction-autocomplete .form-field .form-input-container input {
  width: 100%;
  height: 36px;
  font-family: "Segma", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 100%;
  letter-spacing: 0;
  cursor: default;
  cursor: text;
  padding: 5px 10px;
  background: rgba(255, 255, 255, 0.05);
  border: none;
  border-radius: 5px;
  caret-color: #FFFFFF;
}
/* POPUP */
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane {
  /* POPUP AUTOCOMPLETE */
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-autocomplete-panel {
  border: none;
  border-radius: 0;
  margin-top: -4px;
  box-shadow: none;
  padding: 0;
  background: #01286C;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-autocomplete-panel::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-autocomplete-panel::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: none;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-autocomplete-panel::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0);
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-autocomplete-panel mat-optgroup {
  background: #01286C;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-autocomplete-panel mat-optgroup .mat-mdc-optgroup-label {
  background: #01286C;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-autocomplete-panel mat-optgroup .mat-mdc-optgroup-label .mdc-list-item__primary-text {
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.75);
  line-height: 130%;
  letter-spacing: 1.2px;
  cursor: default;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-autocomplete-panel mat-option {
  padding: 10px 16px;
  background: #01286C;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
@media (hover: hover) and (pointer: fine) {
  .cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-autocomplete-panel mat-option:hover {
    opacity: 0.5;
  }
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-autocomplete-panel mat-option .mdc-list-item__primary-text {
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.75);
  line-height: 130%;
  letter-spacing: 1.2px;
  cursor: default;
  cursor: pointer;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-autocomplete-panel mat-option mat-pseudo-checkbox {
  display: none;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-autocomplete-panel mat-option .mat-ripple {
  display: none;
}

/* --- TYPOGRAPHY --- */
/* --- COLORS --- */
/* --- ANIMATIONS --- */
/* --- SPECIFIC MIXINS --- */
/* Stile per buttons che al click aprono un sottomenù */
/* --- TYPOGRAPHY --- */
/* --- COLORS --- */
/* --- ANIMATIONS --- */
/* --- STANDARD MIXINS --- */
/* --- FLEX --- */
/* --- FONTS --- */
/* Usato ad es. per scroll dentro select o popup con sfondo $brand-color-03 */
@keyframes loading {
  to {
    background-position-x: -20%;
  }
}
/*
NOTE BUTTONS FE 3.0 - DAM
I buttons con lo sfondo colorato, senza bordo e icona in mezzo (es. +) si fanno così:
@include button-style("e", $brand-color-04, $white, $buttonIcon: true, $buttonBackground: true);

I buttons senza sfondo, con bordo e icona in mezzo (es. permessi, back, navigazione, filtri) si fanno così:
@include button-style("e", $brand-color-04, $brand-color-04, $buttonIcon: true, $buttonBackground: false);

I buttons con lo sfondo colorato, senza bordo e testo in mezzo (es. salva, nuovo) si fanno così:
@include button-style(36px, $brand-color-04, $white, $buttonIcon: false, $buttonBackground: true);

I buttons senza sfondo, con bordo e testo in mezzo (es. aggiungi a collezione, collezioni) si fanno così:
@include button-style(36px, $brand-color-04, $brand-color-04, $buttonIcon: false, $buttonBackground: false);
*/
addiction-checkbox .form-field.disabled .checkbox-default label {
  opacity: 0.5;
}
addiction-checkbox .form-field .checkbox-default {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
addiction-checkbox .form-field .checkbox-default label {
  font-family: "Segma", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 130%;
  letter-spacing: 0;
  cursor: default;
  margin-left: 10px;
  display: flex;
  align-items: center;
}
addiction-checkbox .form-field .checkbox-default label .tooltip {
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  position: relative;
  cursor: pointer;
}
addiction-checkbox .form-field .checkbox-default label .tooltip::before {
  content: "F";
  font-family: "DataLean Font Icon";
  font-size: 13px;
  color: #FFFFFF;
  position: absolute;
  pointer-events: none;
}
addiction-checkbox .form-field .checkbox-default input {
  width: 18px;
  height: 18px;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  appearance: none;
  cursor: pointer;
  position: relative;
  background: rgba(255, 255, 255, 0);
}
addiction-checkbox .form-field .checkbox-default input:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
addiction-checkbox .form-field .checkbox-default input:disabled::before {
  cursor: not-allowed;
}
addiction-checkbox .form-field .checkbox-default input:disabled::after {
  cursor: not-allowed;
}
addiction-checkbox .form-field .checkbox-default input:indeterminate::after {
  content: "";
  width: 1px;
  height: 4px;
  background: #FFFFFF;
  position: absolute;
  margin-bottom: 2px;
  transform: rotate(0);
  opacity: 1;
  z-index: 1;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-checkbox .form-field .checkbox-default input::before {
  content: "";
  width: 18px;
  height: 18px;
  position: absolute;
  background: rgb(44, 53, 70);
  border-radius: 5px;
  z-index: 0;
  cursor: pointer;
}
addiction-checkbox .form-field .checkbox-default input::after {
  content: "";
  width: 7px;
  height: 4px;
  position: absolute;
  border-bottom: 2px solid #FFFFFF;
  border-left: 2px solid #FFFFFF;
  margin-bottom: 2px;
  transform: rotate(-45deg);
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-checkbox .form-field .checkbox-default input:checked::before {
  background: #004BC4;
}
addiction-checkbox .form-field .checkbox-default input:checked::after {
  opacity: 1;
  background: rgba(255, 255, 255, 0);
}

/* --- TYPOGRAPHY --- */
/* --- COLORS --- */
/* --- ANIMATIONS --- */
/* --- STANDARD MIXINS --- */
/* --- FLEX --- */
/* --- FONTS --- */
/* Usato ad es. per scroll dentro select o popup con sfondo $brand-color-03 */
@keyframes loading {
  to {
    background-position-x: -20%;
  }
}
/*
NOTE BUTTONS FE 3.0 - DAM
I buttons con lo sfondo colorato, senza bordo e icona in mezzo (es. +) si fanno così:
@include button-style("e", $brand-color-04, $white, $buttonIcon: true, $buttonBackground: true);

I buttons senza sfondo, con bordo e icona in mezzo (es. permessi, back, navigazione, filtri) si fanno così:
@include button-style("e", $brand-color-04, $brand-color-04, $buttonIcon: true, $buttonBackground: false);

I buttons con lo sfondo colorato, senza bordo e testo in mezzo (es. salva, nuovo) si fanno così:
@include button-style(36px, $brand-color-04, $white, $buttonIcon: false, $buttonBackground: true);

I buttons senza sfondo, con bordo e testo in mezzo (es. aggiungi a collezione, collezioni) si fanno così:
@include button-style(36px, $brand-color-04, $brand-color-04, $buttonIcon: false, $buttonBackground: false);
*/
/* ADDICTION BULK ACTION BAR */
addiction-bulk-action-bar {
  height: 70px;
  width: calc(100% - 40px);
  padding: 0;
  margin: 0 20px;
  background: #132039;
  border-top: 1px solid rgba(61, 93, 150, 0.3);
  position: absolute;
  bottom: -70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* Se si uniforma addiction-bulk-action-bar con datalean-bulk-actions-selector,
  ci sarà da togliere da qua il border top, position absolute e bottom -70px (che vanno solo nel DAM) */
}
addiction-bulk-action-bar .left-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
addiction-bulk-action-bar .left-container label {
  font-family: "Segma", sans-serif;
  font-size: 10px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
  line-height: 130%;
  letter-spacing: 2.5px;
  cursor: default;
  text-transform: uppercase;
  margin-right: 10px;
  display: flex;
  align-items: center;
}
addiction-bulk-action-bar .left-container label .tooltip {
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  position: relative;
  cursor: pointer;
}
addiction-bulk-action-bar .left-container label .tooltip::before {
  content: "F";
  font-family: "DataLean Font Icon";
  font-size: 13px;
  color: #FFFFFF;
  position: absolute;
  pointer-events: none;
}
addiction-bulk-action-bar .left-container addiction-select { /* stile dentro addiction-components.scss */
  min-width: 200px;
}
addiction-bulk-action-bar .left-container .counter {
  margin-left: 20px;
}
addiction-bulk-action-bar .left-container .counter span {
  font-family: "Segma", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 100%;
  letter-spacing: 1px;
  cursor: default;
}
addiction-bulk-action-bar .right-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
addiction-bulk-action-bar .right-container .buttons-container {
  display: flex;
}
addiction-bulk-action-bar .right-container .buttons-container button {
  width: 100%;
  height: 36px;
  padding: 5px 20px;
  background: rgba(255, 255, 255, 0);
  border: 2px solid #FFFFFF;
  border-radius: 10px;
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 100%;
  letter-spacing: 1.2px;
  cursor: default;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  margin-left: 20px;
}
@media (hover: hover) and (pointer: fine) {
  addiction-bulk-action-bar .right-container .buttons-container button:hover {
    opacity: 0.5;
  }
}
addiction-bulk-action-bar .right-container .buttons-container button.disabled, addiction-bulk-action-bar .right-container .buttons-container button[disabled], addiction-bulk-action-bar .right-container .buttons-container button[disabled=true] {
  opacity: 0.25;
  cursor: not-allowed;
}
addiction-bulk-action-bar .right-container .buttons-container button.execute {
  border: 2px solid #FFFFFF;
  color: #FFFFFF;
}
addiction-bulk-action-bar .right-container .buttons-container button.cancel {
  border: 2px solid #FF4848;
  color: #FF4848;
}

/* --- TYPOGRAPHY --- */
/* --- COLORS --- */
/* --- ANIMATIONS --- */
/* --- STANDARD MIXINS --- */
/* --- FLEX --- */
/* --- FONTS --- */
/* Usato ad es. per scroll dentro select o popup con sfondo $brand-color-03 */
@keyframes loading {
  to {
    background-position-x: -20%;
  }
}
/*
NOTE BUTTONS FE 3.0 - DAM
I buttons con lo sfondo colorato, senza bordo e icona in mezzo (es. +) si fanno così:
@include button-style("e", $brand-color-04, $white, $buttonIcon: true, $buttonBackground: true);

I buttons senza sfondo, con bordo e icona in mezzo (es. permessi, back, navigazione, filtri) si fanno così:
@include button-style("e", $brand-color-04, $brand-color-04, $buttonIcon: true, $buttonBackground: false);

I buttons con lo sfondo colorato, senza bordo e testo in mezzo (es. salva, nuovo) si fanno così:
@include button-style(36px, $brand-color-04, $white, $buttonIcon: false, $buttonBackground: true);

I buttons senza sfondo, con bordo e testo in mezzo (es. aggiungi a collezione, collezioni) si fanno così:
@include button-style(36px, $brand-color-04, $brand-color-04, $buttonIcon: false, $buttonBackground: false);
*/
addiction-chip {
  display: inline-block;
}
addiction-chip .chip {
  display: flex;
  align-items: center;
  background: #132039;
  padding: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
@media (hover: hover) and (pointer: fine) {
  addiction-chip .chip:hover {
    opacity: 0.5;
  }
}
addiction-chip .chip .label {
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 130%;
  letter-spacing: 0.85px;
  cursor: default;
  cursor: pointer;
  display: flex;
  align-items: center;
}
addiction-chip .chip .label .tooltip {
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  position: relative;
  cursor: pointer;
}
addiction-chip .chip .label .tooltip::before {
  content: "F";
  font-family: "DataLean Font Icon";
  font-size: 15px;
  color: #FFFFFF;
  position: absolute;
  pointer-events: none;
}
addiction-chip .chip button.remove {
  width: 10px;
  height: 100%;
  padding: 0;
  background-color: rgba(255, 255, 255, 0);
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0 0 0 10px;
  cursor: pointer;
}
addiction-chip .chip button.remove::before {
  content: "d";
  width: 7px;
  height: 7px;
  color: #FFFFFF;
  font-size: 7px;
  font-family: "DataLean Font Icon";
  position: absolute;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* --- TYPOGRAPHY --- */
/* --- COLORS --- */
/* --- ANIMATIONS --- */
/* --- STANDARD MIXINS --- */
/* --- FLEX --- */
/* --- FONTS --- */
/* Usato ad es. per scroll dentro select o popup con sfondo $brand-color-03 */
@keyframes loading {
  to {
    background-position-x: -20%;
  }
}
/*
NOTE BUTTONS FE 3.0 - DAM
I buttons con lo sfondo colorato, senza bordo e icona in mezzo (es. +) si fanno così:
@include button-style("e", $brand-color-04, $white, $buttonIcon: true, $buttonBackground: true);

I buttons senza sfondo, con bordo e icona in mezzo (es. permessi, back, navigazione, filtri) si fanno così:
@include button-style("e", $brand-color-04, $brand-color-04, $buttonIcon: true, $buttonBackground: false);

I buttons con lo sfondo colorato, senza bordo e testo in mezzo (es. salva, nuovo) si fanno così:
@include button-style(36px, $brand-color-04, $white, $buttonIcon: false, $buttonBackground: true);

I buttons senza sfondo, con bordo e testo in mezzo (es. aggiungi a collezione, collezioni) si fanno così:
@include button-style(36px, $brand-color-04, $brand-color-04, $buttonIcon: false, $buttonBackground: false);
*/
/* POPUP CONFIRMATION MESSAGE */
addiction-confirmation-message .modal-input-container,
datalean-message .modal-input-container {
  min-width: 400px;
  max-width: 600px;
  height: auto;
  max-height: 800px;
  padding: 25px 30px;
  border: none;
  border-radius: 0;
  background: #132039;
}
addiction-confirmation-message .modal-input-container .header-container,
datalean-message .modal-input-container .header-container {
  height: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
addiction-confirmation-message .modal-input-container .header-container .title,
datalean-message .modal-input-container .header-container .title {
  width: calc(100% - 56px);
  font-family: "Segma", sans-serif;
  font-size: 20px;
  font-weight: 300;
  color: #FFFFFF;
  line-height: 100%;
  letter-spacing: 0;
  cursor: default;
  display: flex;
  align-items: center;
}
addiction-confirmation-message .modal-input-container .header-container .title .tooltip,
datalean-message .modal-input-container .header-container .title .tooltip {
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  position: relative;
  cursor: pointer;
}
addiction-confirmation-message .modal-input-container .header-container .title .tooltip::before,
datalean-message .modal-input-container .header-container .title .tooltip::before {
  content: "F";
  font-family: "DataLean Font Icon";
  font-size: 15px;
  color: #FFFFFF;
  position: absolute;
  pointer-events: none;
}
addiction-confirmation-message .modal-input-container .header-container .close-container,
datalean-message .modal-input-container .header-container .close-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
addiction-confirmation-message .modal-input-container .header-container .close-container button.close,
datalean-message .modal-input-container .header-container .close-container button.close {
  box-shadow: none;
  border-radius: 3px;
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: initial;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  width: 34px;
  height: 34px;
  background: rgba(255, 255, 255, 0);
  border: 2px solid #FFFFFF;
}
addiction-confirmation-message .modal-input-container .header-container .close-container button.close[aria-expanded=true],
datalean-message .modal-input-container .header-container .close-container button.close[aria-expanded=true] {
  border-radius: 0;
}
@media (hover: hover) and (pointer: fine) {
  addiction-confirmation-message .modal-input-container .header-container .close-container button.close:hover,
  datalean-message .modal-input-container .header-container .close-container button.close:hover {
    opacity: 0.5;
  }
}
addiction-confirmation-message .modal-input-container .header-container .close-container button.close::before,
datalean-message .modal-input-container .header-container .close-container button.close::before {
  content: "d";
  width: 12px;
  height: 12px;
  font-size: 12px;
  color: #FFFFFF;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 100%;
  font-family: "DataLean Font Icon";
  position: absolute;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-confirmation-message .modal-input-container .header-container .close-container button.close:disabled, addiction-confirmation-message .modal-input-container .header-container .close-container button.close.disabled, addiction-confirmation-message .modal-input-container .header-container .close-container button.close[disabled], addiction-confirmation-message .modal-input-container .header-container .close-container button.close[disabled=true],
datalean-message .modal-input-container .header-container .close-container button.close:disabled,
datalean-message .modal-input-container .header-container .close-container button.close.disabled,
datalean-message .modal-input-container .header-container .close-container button.close[disabled],
datalean-message .modal-input-container .header-container .close-container button.close[disabled=true] {
  opacity: 0.25;
  cursor: not-allowed;
  pointer-events: none;
}
addiction-confirmation-message .modal-input-container .header-container .close-container button.close:disabled span, addiction-confirmation-message .modal-input-container .header-container .close-container button.close.disabled span, addiction-confirmation-message .modal-input-container .header-container .close-container button.close[disabled] span, addiction-confirmation-message .modal-input-container .header-container .close-container button.close[disabled=true] span,
datalean-message .modal-input-container .header-container .close-container button.close:disabled span,
datalean-message .modal-input-container .header-container .close-container button.close.disabled span,
datalean-message .modal-input-container .header-container .close-container button.close[disabled] span,
datalean-message .modal-input-container .header-container .close-container button.close[disabled=true] span {
  cursor: not-allowed;
  pointer-events: none;
}
addiction-confirmation-message .modal-input-container .body-container,
datalean-message .modal-input-container .body-container {
  height: calc(100% - 56px);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: column;
  overflow-y: auto;
}
addiction-confirmation-message .modal-input-container .body-container::-webkit-scrollbar,
datalean-message .modal-input-container .body-container::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
addiction-confirmation-message .modal-input-container .body-container::-webkit-scrollbar-thumb,
datalean-message .modal-input-container .body-container::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: none;
}
addiction-confirmation-message .modal-input-container .body-container::-webkit-scrollbar-track,
datalean-message .modal-input-container .body-container::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0);
}
addiction-confirmation-message .modal-input-container .body-container .text,
datalean-message .modal-input-container .body-container .text {
  width: 100%;
  font-family: "Segma", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 150%;
  letter-spacing: 0;
  cursor: default;
  text-align: center;
}
addiction-confirmation-message .modal-input-container .body-container .text span,
datalean-message .modal-input-container .body-container .text span {
  font-size: 18px;
  font-weight: 500;
}
addiction-confirmation-message .modal-input-container .body-container .button-container,
datalean-message .modal-input-container .body-container .button-container {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
addiction-confirmation-message .modal-input-container .body-container .button-container button,
datalean-message .modal-input-container .body-container .button-container button {
  width: auto;
  padding: 5px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 130%;
  letter-spacing: 2.5px;
  cursor: default;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  min-height: 36px;
  background: #004BC4;
  border: none;
  border-radius: 10px;
  margin: 0 10px;
}
addiction-confirmation-message .modal-input-container .body-container .button-container button span,
datalean-message .modal-input-container .body-container .button-container button span {
  width: 100%;
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 130%;
  letter-spacing: 2.5px;
  cursor: default;
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  margin-top: 3px;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-confirmation-message .modal-input-container .body-container .button-container button[aria-expanded=true],
datalean-message .modal-input-container .body-container .button-container button[aria-expanded=true] {
  border-radius: 0;
}
@media (hover: hover) and (pointer: fine) {
  addiction-confirmation-message .modal-input-container .body-container .button-container button:hover,
  datalean-message .modal-input-container .body-container .button-container button:hover {
    background: #FFFFFF;
    color: #004BC4;
  }
  addiction-confirmation-message .modal-input-container .body-container .button-container button:hover span,
  datalean-message .modal-input-container .body-container .button-container button:hover span {
    color: #004BC4;
  }
}
addiction-confirmation-message .modal-input-container .body-container .button-container button:disabled, addiction-confirmation-message .modal-input-container .body-container .button-container button.disabled, addiction-confirmation-message .modal-input-container .body-container .button-container button[disabled], addiction-confirmation-message .modal-input-container .body-container .button-container button[disabled=true],
datalean-message .modal-input-container .body-container .button-container button:disabled,
datalean-message .modal-input-container .body-container .button-container button.disabled,
datalean-message .modal-input-container .body-container .button-container button[disabled],
datalean-message .modal-input-container .body-container .button-container button[disabled=true] {
  opacity: 0.25;
  cursor: not-allowed;
  pointer-events: none;
}
addiction-confirmation-message .modal-input-container .body-container .button-container button:disabled span, addiction-confirmation-message .modal-input-container .body-container .button-container button.disabled span, addiction-confirmation-message .modal-input-container .body-container .button-container button[disabled] span, addiction-confirmation-message .modal-input-container .body-container .button-container button[disabled=true] span,
datalean-message .modal-input-container .body-container .button-container button:disabled span,
datalean-message .modal-input-container .body-container .button-container button.disabled span,
datalean-message .modal-input-container .body-container .button-container button[disabled] span,
datalean-message .modal-input-container .body-container .button-container button[disabled=true] span {
  cursor: not-allowed;
  pointer-events: none;
}

/* --- TYPOGRAPHY --- */
/* --- COLORS --- */
/* --- ANIMATIONS --- */
/* --- SPECIFIC MIXINS --- */
/* Stile per buttons che al click aprono un sottomenù */
/* --- TYPOGRAPHY --- */
/* --- COLORS --- */
/* --- ANIMATIONS --- */
/* --- STANDARD MIXINS --- */
/* --- FLEX --- */
/* --- FONTS --- */
/* Usato ad es. per scroll dentro select o popup con sfondo $brand-color-03 */
@keyframes loading {
  to {
    background-position-x: -20%;
  }
}
/*
NOTE BUTTONS FE 3.0 - DAM
I buttons con lo sfondo colorato, senza bordo e icona in mezzo (es. +) si fanno così:
@include button-style("e", $brand-color-04, $white, $buttonIcon: true, $buttonBackground: true);

I buttons senza sfondo, con bordo e icona in mezzo (es. permessi, back, navigazione, filtri) si fanno così:
@include button-style("e", $brand-color-04, $brand-color-04, $buttonIcon: true, $buttonBackground: false);

I buttons con lo sfondo colorato, senza bordo e testo in mezzo (es. salva, nuovo) si fanno così:
@include button-style(36px, $brand-color-04, $white, $buttonIcon: false, $buttonBackground: true);

I buttons senza sfondo, con bordo e testo in mezzo (es. aggiungi a collezione, collezioni) si fanno così:
@include button-style(36px, $brand-color-04, $brand-color-04, $buttonIcon: false, $buttonBackground: false);
*/
addiction-dam-header {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  background: #0E1B34;
  border-bottom: 1px solid rgba(61, 93, 150, 0.3);
  width: 100%;
  height: auto;
  overflow: hidden;
}
addiction-dam-header .header-container {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
addiction-dam-header .header-container .left-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex: 1;
}
addiction-dam-header .header-container .left-container button.back {
  box-shadow: none;
  border-radius: 3px;
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: initial;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  width: 34px;
  height: 34px;
  background: rgba(255, 255, 255, 0);
  border: 2px solid #FFFFFF;
  margin-right: 20px;
}
addiction-dam-header .header-container .left-container button.back[aria-expanded=true] {
  border-radius: 0;
}
@media (hover: hover) and (pointer: fine) {
  addiction-dam-header .header-container .left-container button.back:hover {
    opacity: 0.5;
  }
}
addiction-dam-header .header-container .left-container button.back::before {
  content: "a";
  width: 16px;
  height: 16px;
  font-size: 16px;
  color: #FFFFFF;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 100%;
  font-family: "DataLean Font Icon";
  position: absolute;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-dam-header .header-container .left-container button.back:disabled, addiction-dam-header .header-container .left-container button.back.disabled, addiction-dam-header .header-container .left-container button.back[disabled], addiction-dam-header .header-container .left-container button.back[disabled=true] {
  opacity: 0.25;
  cursor: not-allowed;
  pointer-events: none;
}
addiction-dam-header .header-container .left-container button.back:disabled span, addiction-dam-header .header-container .left-container button.back.disabled span, addiction-dam-header .header-container .left-container button.back[disabled] span, addiction-dam-header .header-container .left-container button.back[disabled=true] span {
  cursor: not-allowed;
  pointer-events: none;
}
addiction-dam-header .header-container .left-container button.menu-button {
  box-shadow: none;
  border-radius: 3px;
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: initial;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  width: 34px;
  height: 34px;
  background: rgba(255, 255, 255, 0);
  border: 2px solid #FFFFFF;
  margin-right: 20px;
}
addiction-dam-header .header-container .left-container button.menu-button[aria-expanded=true] {
  border-radius: 0;
}
@media (hover: hover) and (pointer: fine) {
  addiction-dam-header .header-container .left-container button.menu-button:hover {
    opacity: 0.5;
  }
}
addiction-dam-header .header-container .left-container button.menu-button::before {
  content: "w";
  width: 16px;
  height: 16px;
  font-size: 16px;
  color: #FFFFFF;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 100%;
  font-family: "DataLean Font Icon";
  position: absolute;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-dam-header .header-container .left-container button.menu-button:disabled, addiction-dam-header .header-container .left-container button.menu-button.disabled, addiction-dam-header .header-container .left-container button.menu-button[disabled], addiction-dam-header .header-container .left-container button.menu-button[disabled=true] {
  opacity: 0.25;
  cursor: not-allowed;
  pointer-events: none;
}
addiction-dam-header .header-container .left-container button.menu-button:disabled span, addiction-dam-header .header-container .left-container button.menu-button.disabled span, addiction-dam-header .header-container .left-container button.menu-button[disabled] span, addiction-dam-header .header-container .left-container button.menu-button[disabled=true] span {
  cursor: not-allowed;
  pointer-events: none;
}
addiction-dam-header .header-container .left-container button.navigation-button {
  box-shadow: none;
  border-radius: 3px;
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: initial;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  width: 34px;
  height: 34px;
  background: rgba(255, 255, 255, 0);
  border: 2px solid #FFFFFF;
  margin-right: 20px;
}
addiction-dam-header .header-container .left-container button.navigation-button[aria-expanded=true] {
  border-radius: 0;
}
@media (hover: hover) and (pointer: fine) {
  addiction-dam-header .header-container .left-container button.navigation-button:hover {
    opacity: 0.5;
  }
}
addiction-dam-header .header-container .left-container button.navigation-button::before {
  content: "s";
  width: 16px;
  height: 16px;
  font-size: 16px;
  color: #FFFFFF;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 100%;
  font-family: "DataLean Font Icon";
  position: absolute;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-dam-header .header-container .left-container button.navigation-button:disabled, addiction-dam-header .header-container .left-container button.navigation-button.disabled, addiction-dam-header .header-container .left-container button.navigation-button[disabled], addiction-dam-header .header-container .left-container button.navigation-button[disabled=true] {
  opacity: 0.25;
  cursor: not-allowed;
  pointer-events: none;
}
addiction-dam-header .header-container .left-container button.navigation-button:disabled span, addiction-dam-header .header-container .left-container button.navigation-button.disabled span, addiction-dam-header .header-container .left-container button.navigation-button[disabled] span, addiction-dam-header .header-container .left-container button.navigation-button[disabled=true] span {
  cursor: not-allowed;
  pointer-events: none;
}
addiction-dam-header .header-container .left-container button.filters-button {
  box-shadow: none;
  border-radius: 3px;
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: initial;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  width: 34px;
  height: 34px;
  background: rgba(255, 255, 255, 0);
  border: 2px solid #FFFFFF;
  margin-right: 20px;
}
addiction-dam-header .header-container .left-container button.filters-button[aria-expanded=true] {
  border-radius: 0;
}
@media (hover: hover) and (pointer: fine) {
  addiction-dam-header .header-container .left-container button.filters-button:hover {
    opacity: 0.5;
  }
}
addiction-dam-header .header-container .left-container button.filters-button::before {
  content: "y";
  width: 16px;
  height: 16px;
  font-size: 16px;
  color: #FFFFFF;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 100%;
  font-family: "DataLean Font Icon";
  position: absolute;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-dam-header .header-container .left-container button.filters-button:disabled, addiction-dam-header .header-container .left-container button.filters-button.disabled, addiction-dam-header .header-container .left-container button.filters-button[disabled], addiction-dam-header .header-container .left-container button.filters-button[disabled=true] {
  opacity: 0.25;
  cursor: not-allowed;
  pointer-events: none;
}
addiction-dam-header .header-container .left-container button.filters-button:disabled span, addiction-dam-header .header-container .left-container button.filters-button.disabled span, addiction-dam-header .header-container .left-container button.filters-button[disabled] span, addiction-dam-header .header-container .left-container button.filters-button[disabled=true] span {
  cursor: not-allowed;
  pointer-events: none;
}
addiction-dam-header .header-container .left-container button.active {
  border: 2px solid #0061FF;
}
@media (hover: hover) and (pointer: fine) {
  addiction-dam-header .header-container .left-container button.active:hover {
    opacity: 1;
  }
}
addiction-dam-header .header-container .left-container button.active::before {
  color: #0061FF;
}
addiction-dam-header .header-container .left-container form.search-form {
  display: inline-block;
  width: 200px;
  padding: 0 5px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
addiction-dam-header .header-container .left-container form.search-form .reset-search-button {
  width: 10px;
  height: 10px;
  position: absolute;
  right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
addiction-dam-header .header-container .left-container form.search-form .reset-search-button::before {
  content: "d";
  width: 10px;
  height: 10px;
  font-size: 10px;
  color: #FFFFFF;
  font-family: "DataLean Font Icon";
  position: absolute;
  cursor: pointer;
}
addiction-dam-header .header-container .right-container {
  width: 100%;
  flex: 3;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  background: rgba(255, 255, 255, 0);
  /* DIMENSIONE - uguale dentro collection-detail.component.scss */
  /* VIEW GRID/LIST - uguale dentro collection-detail.component.scss */
  /* ORDER - uguale dentro collection-detail.component.scss */
  /* COLLEZIONI */
  /* BULK ACTIONS */
  /* ELIMINA */
  /* NUOVO */
}
addiction-dam-header .header-container .right-container .size-container {
  display: flex;
  align-items: center;
  font-family: "Segma", sans-serif;
  font-size: 10px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 100%;
  letter-spacing: 2.5px;
  cursor: default;
  text-transform: uppercase;
  margin-left: 20px;
}
addiction-dam-header .header-container .right-container .size-container label {
  font-family: "Segma", sans-serif;
  font-size: 10px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
  line-height: 100%;
  letter-spacing: 2.5px;
  cursor: default;
  text-transform: uppercase;
  margin-right: 10px;
  display: flex;
  align-items: center;
}
addiction-dam-header .header-container .right-container .size-container label .tooltip {
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  position: relative;
  cursor: pointer;
}
addiction-dam-header .header-container .right-container .size-container label .tooltip::before {
  content: "F";
  font-family: "DataLean Font Icon";
  font-size: 13px;
  color: #FFFFFF;
  position: absolute;
  pointer-events: none;
}
addiction-dam-header .header-container .right-container .size-container .mat-mdc-slider {
  min-width: 200px;
  --mat-mdc-slider-focus-ripple-color: none;
  /* PRIMA, DA CAPIRE SE SERVE ANCORA */
}
addiction-dam-header .header-container .right-container .size-container .mat-mdc-slider .mdc-slider__track {
  height: 1px;
  background: #3d5d96;
}
addiction-dam-header .header-container .right-container .size-container .mat-mdc-slider .mdc-slider__track .mdc-slider__track--inactive {
  height: 1px;
  background: #3d5d96;
}
addiction-dam-header .header-container .right-container .size-container .mat-mdc-slider .mdc-slider__track .mdc-slider__track--active {
  display: none;
}
addiction-dam-header .header-container .right-container .size-container .mat-mdc-slider mat-slider-visual-thumb .mdc-slider__thumb-knob {
  border-radius: 3px;
  border: 1px solid #3d5d96;
  background: #132039;
  box-shadow: none;
}
addiction-dam-header .header-container .right-container .size-container .mat-mdc-slider .mat-mdc-slider-wrapper {
  height: 1px;
  background: #3d5d96;
}
addiction-dam-header .header-container .right-container .size-container .mat-mdc-slider .mat-mdc-slider-wrapper .mat-mdc-slider-track-wrapper {
  height: 1px;
  background: #3d5d96;
}
addiction-dam-header .header-container .right-container .size-container .mat-mdc-slider .mat-mdc-slider-wrapper .mat-mdc-slider-ticks-container {
  height: 1px;
  background: #3d5d96;
}
addiction-dam-header .header-container .right-container .size-container .mat-mdc-slider .mat-mdc-slider-wrapper .mat-mdc-slider-thumb-container .mat-mdc-slider-focus-ring,
addiction-dam-header .header-container .right-container .size-container .mat-mdc-slider .mat-mdc-slider-wrapper .mat-mdc-slider-thumb-container .mat-mdc-slider-thumb-label {
  display: none;
}
addiction-dam-header .header-container .right-container .size-container .mat-mdc-slider .mat-mdc-slider-wrapper .mat-mdc-slider-thumb-container .mat-mdc-slider-thumb {
  transform: none;
  width: 19px !important;
  height: 19px !important;
  border: 1px solid #3d5d96;
  background: #09152C;
  border-radius: 3px;
}
addiction-dam-header .header-container .right-container .size-container .mat-mdc-slider ::ng-deep {
  /* PRIMA, DA CAPIRE SE SERVE ANCORA */
}
addiction-dam-header .header-container .right-container .size-container .mat-mdc-slider ::ng-deep .mdc-slider__track {
  height: 1px;
  background: #3d5d96;
}
addiction-dam-header .header-container .right-container .size-container .mat-mdc-slider ::ng-deep .mdc-slider__track .mdc-slider__track--inactive {
  height: 1px;
  background: #3d5d96;
}
addiction-dam-header .header-container .right-container .size-container .mat-mdc-slider ::ng-deep .mdc-slider__track .mdc-slider__track--active {
  display: none;
}
addiction-dam-header .header-container .right-container .size-container .mat-mdc-slider ::ng-deep mat-slider-visual-thumb .mdc-slider__thumb-knob {
  border-radius: 3px;
  border: 1px solid #3d5d96;
  background: #132039;
  box-shadow: none;
}
addiction-dam-header .header-container .right-container .size-container .mat-mdc-slider ::ng-deep .mat-mdc-slider-wrapper {
  height: 1px;
  background: #3d5d96;
}
addiction-dam-header .header-container .right-container .size-container .mat-mdc-slider ::ng-deep .mat-mdc-slider-wrapper .mat-mdc-slider-track-wrapper {
  height: 1px;
  background: #3d5d96;
}
addiction-dam-header .header-container .right-container .size-container .mat-mdc-slider ::ng-deep .mat-mdc-slider-wrapper .mat-mdc-slider-ticks-container {
  height: 1px;
  background: #3d5d96;
}
addiction-dam-header .header-container .right-container .size-container .mat-mdc-slider ::ng-deep .mat-mdc-slider-wrapper .mat-mdc-slider-thumb-container .mat-mdc-slider-focus-ring,
addiction-dam-header .header-container .right-container .size-container .mat-mdc-slider ::ng-deep .mat-mdc-slider-wrapper .mat-mdc-slider-thumb-container .mat-mdc-slider-thumb-label {
  display: none;
}
addiction-dam-header .header-container .right-container .size-container .mat-mdc-slider ::ng-deep .mat-mdc-slider-wrapper .mat-mdc-slider-thumb-container .mat-mdc-slider-thumb {
  transform: none;
  width: 19px !important;
  height: 19px !important;
  border: 1px solid #3d5d96;
  background: #09152C;
  border-radius: 3px;
}
addiction-dam-header .header-container .right-container .view-mode-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
addiction-dam-header .header-container .right-container .view-mode-container .view-button {
  width: 20px;
  height: 36px;
  background: rgba(255, 255, 255, 0);
  padding: 0;
  border: none;
  border-radius: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  margin-left: 20px;
}
@media (hover: hover) and (pointer: fine) {
  addiction-dam-header .header-container .right-container .view-mode-container .view-button:hover {
    opacity: 0.5;
  }
}
addiction-dam-header .header-container .right-container .view-mode-container .view-button::before {
  content: "v";
  width: 16px;
  height: 16px;
  color: #FFFFFF;
  font-size: 16px;
  font-family: "DataLean Font Icon";
  position: absolute;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}
addiction-dam-header .header-container .right-container .view-mode-container .view-button.active::before {
  color: #0061FF;
}
@media (hover: hover) and (pointer: fine) {
  addiction-dam-header .header-container .right-container .view-mode-container .view-button.active:hover {
    opacity: 1;
  }
}
addiction-dam-header .header-container .right-container .view-mode-container .view-button.list-view::before {
  content: "A";
}
addiction-dam-header .header-container .right-container .view-mode-container .view-button.grid-view::before {
  content: "z";
}
addiction-dam-header .header-container .right-container .order-container {
  display: flex;
  align-items: center;
}
addiction-dam-header .header-container .right-container .order-container .select-container {
  display: flex;
  align-items: center;
  margin-left: 20px;
}
addiction-dam-header .header-container .right-container .order-container .select-container label {
  font-family: "Segma", sans-serif;
  font-size: 10px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
  line-height: 100%;
  letter-spacing: 2.5px;
  cursor: default;
  text-transform: uppercase;
  margin-right: 10px;
  display: flex;
  align-items: center;
}
addiction-dam-header .header-container .right-container .order-container .select-container label .tooltip {
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  position: relative;
  cursor: pointer;
}
addiction-dam-header .header-container .right-container .order-container .select-container label .tooltip::before {
  content: "F";
  font-family: "DataLean Font Icon";
  font-size: 13px;
  color: #FFFFFF;
  position: absolute;
  pointer-events: none;
}
addiction-dam-header .header-container .right-container .order-container .select-container addiction-select {
  min-width: 200px;
}
addiction-dam-header .header-container .right-container .collections-container {
  margin-left: 20px;
}
addiction-dam-header .header-container .right-container .collections-container .collections-button {
  width: auto;
  height: 36px;
  background: rgba(255, 255, 255, 0);
  padding: 5px 10px 5px 35px;
  border: 2px solid #FFFFFF;
  border-radius: 3px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
@media (hover: hover) and (pointer: fine) {
  addiction-dam-header .header-container .right-container .collections-container .collections-button:hover {
    opacity: 0.5;
  }
}
addiction-dam-header .header-container .right-container .collections-container .collections-button::before {
  content: "v";
  width: 14px;
  height: 14px;
  color: #FFFFFF;
  font-size: 14px;
  font-family: "DataLean Font Icon";
  position: absolute;
  left: 10px;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}
addiction-dam-header .header-container .right-container .collections-container .collections-button .label {
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 100%;
  letter-spacing: 2.5px;
  cursor: default;
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  align-items: center;
}
addiction-dam-header .header-container .right-container .collections-container .collections-button .label .tooltip {
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  position: relative;
  cursor: pointer;
}
addiction-dam-header .header-container .right-container .collections-container .collections-button .label .tooltip::before {
  content: "F";
  font-family: "DataLean Font Icon";
  font-size: 15px;
  color: #FFFFFF;
  position: absolute;
  pointer-events: none;
}
addiction-dam-header .header-container .right-container .delete-container {
  display: flex;
  margin-left: 20px;
}
addiction-dam-header .header-container .right-container .delete-container button.delete {
  box-shadow: none;
  border-radius: 3px;
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: initial;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  width: 34px;
  height: 34px;
  background: rgba(255, 255, 255, 0);
  border: 2px solid #FF4848;
}
addiction-dam-header .header-container .right-container .delete-container button.delete[aria-expanded=true] {
  border-radius: 0;
}
@media (hover: hover) and (pointer: fine) {
  addiction-dam-header .header-container .right-container .delete-container button.delete:hover {
    opacity: 0.5;
  }
}
addiction-dam-header .header-container .right-container .delete-container button.delete::before {
  content: "i";
  width: 16px;
  height: 16px;
  font-size: 16px;
  color: #FF4848;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 100%;
  font-family: "DataLean Font Icon";
  position: absolute;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-dam-header .header-container .right-container .delete-container button.delete:disabled, addiction-dam-header .header-container .right-container .delete-container button.delete.disabled, addiction-dam-header .header-container .right-container .delete-container button.delete[disabled], addiction-dam-header .header-container .right-container .delete-container button.delete[disabled=true] {
  opacity: 0.25;
  cursor: not-allowed;
  pointer-events: none;
}
addiction-dam-header .header-container .right-container .delete-container button.delete:disabled span, addiction-dam-header .header-container .right-container .delete-container button.delete.disabled span, addiction-dam-header .header-container .right-container .delete-container button.delete[disabled] span, addiction-dam-header .header-container .right-container .delete-container button.delete[disabled=true] span {
  cursor: not-allowed;
  pointer-events: none;
}
addiction-dam-header .header-container .right-container .create-new-container {
  display: flex;
  margin-left: 20px;
}
addiction-dam-header .header-container .right-container .create-new-container button.add-new {
  box-shadow: none;
  border-radius: 3px;
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: initial;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  width: 36px;
  height: 36px;
  background: #004BC4;
  border: none;
}
addiction-dam-header .header-container .right-container .create-new-container button.add-new[aria-expanded=true] {
  border-radius: 0;
}
@media (hover: hover) and (pointer: fine) {
  addiction-dam-header .header-container .right-container .create-new-container button.add-new:hover {
    background: #FFFFFF;
  }
  addiction-dam-header .header-container .right-container .create-new-container button.add-new:hover::before {
    color: #004BC4;
  }
}
addiction-dam-header .header-container .right-container .create-new-container button.add-new::before {
  content: "e";
  width: 16px;
  height: 16px;
  font-size: 16px;
  color: #FFFFFF;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 100%;
  font-family: "DataLean Font Icon";
  position: absolute;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-dam-header .header-container .right-container .create-new-container button.add-new:disabled, addiction-dam-header .header-container .right-container .create-new-container button.add-new.disabled, addiction-dam-header .header-container .right-container .create-new-container button.add-new[disabled], addiction-dam-header .header-container .right-container .create-new-container button.add-new[disabled=true] {
  opacity: 0.25;
  cursor: not-allowed;
  pointer-events: none;
}
addiction-dam-header .header-container .right-container .create-new-container button.add-new:disabled span, addiction-dam-header .header-container .right-container .create-new-container button.add-new.disabled span, addiction-dam-header .header-container .right-container .create-new-container button.add-new[disabled] span, addiction-dam-header .header-container .right-container .create-new-container button.add-new[disabled=true] span {
  cursor: not-allowed;
  pointer-events: none;
}

/* --- TYPOGRAPHY --- */
/* --- COLORS --- */
/* --- ANIMATIONS --- */
/* --- STANDARD MIXINS --- */
/* --- FLEX --- */
/* --- FONTS --- */
/* Usato ad es. per scroll dentro select o popup con sfondo $brand-color-03 */
@keyframes loading {
  to {
    background-position-x: -20%;
  }
}
/*
NOTE BUTTONS FE 3.0 - DAM
I buttons con lo sfondo colorato, senza bordo e icona in mezzo (es. +) si fanno così:
@include button-style("e", $brand-color-04, $white, $buttonIcon: true, $buttonBackground: true);

I buttons senza sfondo, con bordo e icona in mezzo (es. permessi, back, navigazione, filtri) si fanno così:
@include button-style("e", $brand-color-04, $brand-color-04, $buttonIcon: true, $buttonBackground: false);

I buttons con lo sfondo colorato, senza bordo e testo in mezzo (es. salva, nuovo) si fanno così:
@include button-style(36px, $brand-color-04, $white, $buttonIcon: false, $buttonBackground: true);

I buttons senza sfondo, con bordo e testo in mezzo (es. aggiungi a collezione, collezioni) si fanno così:
@include button-style(36px, $brand-color-04, $brand-color-04, $buttonIcon: false, $buttonBackground: false);
*/
/* ADDICTION DATE PICKER */
addiction-date-picker {
  width: 100%;
}
addiction-date-picker .date-picker-container {
  width: 100%;
  display: flex;
  column-gap: 20px;
}
addiction-date-picker .date-picker-container mat-form-field {
  flex: 1;
  height: 36px;
  background: rgba(255, 255, 255, 0);
  border: none;
  border-radius: 0;
  padding: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-date-picker .date-picker-container mat-form-field.mat-form-field-disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
addiction-date-picker .date-picker-container mat-form-field.mat-form-field-disabled .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix mat-datepicker-toggle button {
  pointer-events: none;
}
addiction-date-picker .date-picker-container mat-form-field .mat-mdc-text-field-wrapper {
  width: 100%;
  height: 100%;
  padding: 0;
  background: rgba(255, 255, 255, 0);
}
addiction-date-picker .date-picker-container mat-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-focus-overlay {
  display: none;
}
addiction-date-picker .date-picker-container mat-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex {
  width: 100%;
  height: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0);
}
addiction-date-picker .date-picker-container mat-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
  width: 100%;
  height: 100%;
  min-height: 100%;
  padding: 0;
  margin: 0;
  border: 0;
  display: flex;
  flex-flow: column;
  border: none;
}
addiction-date-picker .date-picker-container mat-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix input {
  width: 100%;
  height: 100%;
  min-height: 100%;
  padding: 5px 36px 5px 10px;
  margin: 0;
  caret-color: #FFFFFF;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 5px;
  font-family: "Segma", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 100%;
  letter-spacing: 1px;
  cursor: default;
  cursor: inherit;
}
addiction-date-picker .date-picker-container mat-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix mat-select {
  width: 100%;
  height: 100%;
  min-height: 100%;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 5px;
  padding: 5px 10px;
}
addiction-date-picker .date-picker-container mat-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix mat-select[aria-expanded=true] {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
addiction-date-picker .date-picker-container mat-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix mat-select .mat-mdc-select-trigger {
  min-height: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
addiction-date-picker .date-picker-container mat-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix mat-select .mat-mdc-select-trigger .mat-mdc-select-value {
  padding: 3px 20px 0 0;
}
addiction-date-picker .date-picker-container mat-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix mat-select .mat-mdc-select-trigger .mat-mdc-select-value .mat-mdc-select-min-line {
  font-family: "Segma", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 170%;
  letter-spacing: 1px;
  cursor: default;
  cursor: pointer;
}
addiction-date-picker .date-picker-container mat-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix mat-select .mat-mdc-select-trigger .mat-mdc-select-arrow-wrapper {
  transform: unset;
  width: 10px;
  position: absolute;
  right: 0;
}
addiction-date-picker .date-picker-container mat-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix mat-select .mat-mdc-select-trigger .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow {
  width: 100%;
  height: 100%;
  margin: 0;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
addiction-date-picker .date-picker-container mat-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix mat-select .mat-mdc-select-trigger .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow::before {
  content: "b";
  width: 10px;
  height: 5px;
  color: #FFFFFF;
  font-size: 7px;
  font-family: "DataLean Font Icon";
  position: absolute;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  display: flex;
  justify-content: center;
  align-items: center;
}
addiction-date-picker .date-picker-container mat-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix mat-select .mat-mdc-select-trigger .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow svg {
  display: none;
}
addiction-date-picker .date-picker-container mat-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-form-field-label-wrapper {
  padding: 0;
}
addiction-date-picker .date-picker-container mat-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix {
  padding: 0;
  position: absolute;
  right: 10px;
}
addiction-date-picker .date-picker-container mat-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix mat-datepicker-toggle button {
  width: 16px;
  height: 16px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  appearance: none;
  outline: none;
  border: none;
  background: rgba(255, 255, 255, 0);
  position: relative;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985) !important;
}
@media (hover: hover) and (pointer: fine) {
  addiction-date-picker .date-picker-container mat-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix mat-datepicker-toggle button:hover {
    opacity: 0.5;
  }
}
addiction-date-picker .date-picker-container mat-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix mat-datepicker-toggle button::before {
  content: "o";
  width: 100%;
  height: 100%;
  color: #FFFFFF;
  font-size: 16px;
  font-family: "DataLean Font Icon";
  position: absolute;
  pointer-events: none;
  z-index: 1;
}
addiction-date-picker .date-picker-container mat-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix mat-datepicker-toggle button * {
  display: none;
}
addiction-date-picker .date-picker-container mat-form-field .mat-mdc-text-field-wrapper .mdc-line-ripple {
  display: none;
}
addiction-date-picker .date-picker-container mat-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-underline {
  display: none;
}
addiction-date-picker .date-picker-container mat-form-field .mat-mdc-form-field-subscript-wrapper {
  display: none;
}

/* POPUP */
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane {
  /* POPUP DATE PICKER */
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane.mat-datepicker-popup {
  background: #01286C;
  transform: translateX(0) !important;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane.mat-datepicker-popup * {
  color: #FFFFFF;
  font-family: "Segma", sans-serif;
  fill: #FFFFFF;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane.mat-datepicker-popup .mat-datepicker-content {
  background: #01286C;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane.mat-datepicker-popup .mat-datepicker-content .mat-datepicker-content-container mat-calendar mat-calendar-header .mat-calendar-header .mat-calendar-controls {
  margin: 0;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane.mat-datepicker-popup .mat-datepicker-content .mat-datepicker-content-container mat-calendar .mat-calendar-content mat-month-view .mat-calendar-table .mat-calendar-table-header,
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane.mat-datepicker-popup .mat-datepicker-content .mat-datepicker-content-container mat-calendar .mat-calendar-content mat-year-view .mat-calendar-table .mat-calendar-table-header,
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane.mat-datepicker-popup .mat-datepicker-content .mat-datepicker-content-container mat-calendar .mat-calendar-content mat-multi-year-view .mat-calendar-table .mat-calendar-table-header {
  display: none;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane.mat-datepicker-popup .mat-datepicker-content .mat-datepicker-content-container mat-calendar .mat-calendar-content mat-month-view .mat-calendar-table .mat-calendar-body .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane.mat-datepicker-popup .mat-datepicker-content .mat-datepicker-content-container mat-calendar .mat-calendar-content mat-year-view .mat-calendar-table .mat-calendar-body .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane.mat-datepicker-popup .mat-datepicker-content .mat-datepicker-content-container mat-calendar .mat-calendar-content mat-multi-year-view .mat-calendar-table .mat-calendar-body .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.1);
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane.mat-datepicker-popup .mat-datepicker-content .mat-datepicker-content-container mat-calendar .mat-calendar-content mat-month-view .mat-calendar-table .mat-calendar-body .mat-calendar-body-selected,
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane.mat-datepicker-popup .mat-datepicker-content .mat-datepicker-content-container mat-calendar .mat-calendar-content mat-year-view .mat-calendar-table .mat-calendar-body .mat-calendar-body-selected,
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane.mat-datepicker-popup .mat-datepicker-content .mat-datepicker-content-container mat-calendar .mat-calendar-content mat-multi-year-view .mat-calendar-table .mat-calendar-body .mat-calendar-body-selected {
  background-color: rgba(255, 255, 255, 0.1);
  color: #FFFFFF;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane.mat-datepicker-popup .mat-datepicker-content .mat-datepicker-content-container mat-calendar .mat-calendar-content mat-month-view .mat-calendar-table .mat-calendar-body .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane.mat-datepicker-popup .mat-datepicker-content .mat-datepicker-content-container mat-calendar .mat-calendar-content mat-month-view .mat-calendar-table .mat-calendar-body .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane.mat-datepicker-popup .mat-datepicker-content .mat-datepicker-content-container mat-calendar .mat-calendar-content mat-year-view .mat-calendar-table .mat-calendar-body .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane.mat-datepicker-popup .mat-datepicker-content .mat-datepicker-content-container mat-calendar .mat-calendar-content mat-year-view .mat-calendar-table .mat-calendar-body .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane.mat-datepicker-popup .mat-datepicker-content .mat-datepicker-content-container mat-calendar .mat-calendar-content mat-multi-year-view .mat-calendar-table .mat-calendar-body .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane.mat-datepicker-popup .mat-datepicker-content .mat-datepicker-content-container mat-calendar .mat-calendar-content mat-multi-year-view .mat-calendar-table .mat-calendar-body .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 255, 255, 0.5) !important;
}
/* --- TYPOGRAPHY --- */
/* --- COLORS --- */
/* --- ANIMATIONS --- */
/* --- TYPOGRAPHY --- */
/* --- COLORS --- */
/* --- ANIMATIONS --- */
/* --- STANDARD MIXINS --- */
/* --- FLEX --- */
/* --- FONTS --- */
/* Usato ad es. per scroll dentro select o popup con sfondo $brand-color-03 */
@keyframes loading {
  to {
    background-position-x: -20%;
  }
}
/*
NOTE BUTTONS FE 3.0 - DAM
I buttons con lo sfondo colorato, senza bordo e icona in mezzo (es. +) si fanno così:
@include button-style("e", $brand-color-04, $white, $buttonIcon: true, $buttonBackground: true);

I buttons senza sfondo, con bordo e icona in mezzo (es. permessi, back, navigazione, filtri) si fanno così:
@include button-style("e", $brand-color-04, $brand-color-04, $buttonIcon: true, $buttonBackground: false);

I buttons con lo sfondo colorato, senza bordo e testo in mezzo (es. salva, nuovo) si fanno così:
@include button-style(36px, $brand-color-04, $white, $buttonIcon: false, $buttonBackground: true);

I buttons senza sfondo, con bordo e testo in mezzo (es. aggiungi a collezione, collezioni) si fanno così:
@include button-style(36px, $brand-color-04, $brand-color-04, $buttonIcon: false, $buttonBackground: false);
*/
/* ADDICTION EMAIL */
addiction-email {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
addiction-email .form-group-container {
  width: calc(100% + 20px);
  margin-left: -10px;
  margin-right: -10px;
  display: flex;
  align-items: flex-end;
  flex-flow: row wrap;
  row-gap: 20px;
}
addiction-email .form-group-container .divider {
  width: calc(100% - 20px);
  height: 1px;
  margin: 20px 10px 0 10px;
  background: rgba(61, 93, 150, 0.2);
}
addiction-email .form-group-container .title-fields-container {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding: 0 10px;
  margin-top: 30px;
}
addiction-email .form-group-container .title-fields-container.no-space {
  padding: 0;
  margin: 0;
}
addiction-email .form-group-container .title-fields-container h2 {
  width: 100%;
  font-family: "Segma", sans-serif;
  font-size: 20px;
  font-weight: 300;
  color: #FFFFFF;
  line-height: 100%;
  letter-spacing: 0;
  cursor: default;
  margin: 0 !important;
}
addiction-email .form-group-container .datalean-container { /* Per ora solo feature-editor.component.html */
  width: 100%;
}
addiction-email .form-group-container .fields-container {
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-flow: row wrap;
  row-gap: 20px;
}
addiction-email .form-group-container .fields-container.radio-dates-container {
  width: 50%;
}
@media screen and (max-width: 1900px) {
  addiction-email .form-group-container .fields-container.radio-dates-container {
    width: 100%;
  }
}
addiction-email .form-group-container .fields-container .skeleton {
  display: flex;
  align-items: center;
  column-gap: 20px;
  width: 100%;
  height: 36px;
  cursor: pointer;
  background: linear-gradient(110deg, rgba(1, 40, 108, 0) 40%, rgba(1, 40, 108, 0.5) 60%, rgba(1, 40, 108, 0) 70%) rgba(0, 75, 196, 0.2);
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-in-out infinite;
}
addiction-email .form-group-container .non-repeatable-container {
  width: 100%;
}
addiction-email .form-group-container .repeatable-container {
  width: calc(100% - 20px);
  margin: 0 10px;
  display: flex;
  flex-flow: column;
}
addiction-email .form-group-container .repeatable-container > label {
  width: 100%;
  font-family: "Segma", sans-serif;
  font-size: 20px;
  font-weight: 300;
  color: #FFFFFF;
  line-height: 100%;
  letter-spacing: 0;
  cursor: default;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
addiction-email .form-group-container .repeatable-container > label .tooltip {
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  position: relative;
  cursor: pointer;
}
addiction-email .form-group-container .repeatable-container > label .tooltip::before {
  content: "F";
  font-family: "DataLean Font Icon";
  font-size: 15px;
  color: #FFFFFF;
  position: absolute;
  pointer-events: none;
}
addiction-email .form-group-container .repeatable-container .drag-placeholder {
  width: 100%;
  height: 20px;
  background: #004BC4;
}
addiction-email .form-group-container .repeatable-container .single-element {
  width: 100%;
  height: auto;
  border: 1px solid #1F2B43;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  transition: all 0s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-email .form-group-container .repeatable-container .single-element.collapsed {
  background: #1F2B43;
}
addiction-email .form-group-container .repeatable-container .single-element.closed {
  background: #1F2B43;
}
addiction-email .form-group-container .repeatable-container .single-element.closed .repeatable-fields-container {
  height: 0;
  opacity: 0;
  padding: 0 10px;
  overflow: hidden;
  background: #1F2B43;
}
addiction-email .form-group-container .repeatable-container .single-element.closed .buttons-container {
  padding: 0;
}
addiction-email .form-group-container .repeatable-container .single-element .drag-container {
  width: 40px;
  border-radius: 5px;
  background: #1F2B43;
  display: flex;
  flex-flow: column;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
@media (hover: hover) and (pointer: fine) {
  addiction-email .form-group-container .repeatable-container .single-element .drag-container:hover {
    background: rgba(0, 75, 196, 0.5);
  }
}
addiction-email .form-group-container .repeatable-container .single-element .drag-container.disabled {
  pointer-events: none;
  cursor: default;
}
addiction-email .form-group-container .repeatable-container .single-element .drag-container.disabled .number {
  display: none;
}
addiction-email .form-group-container .repeatable-container .single-element .drag-container .number {
  font-family: "Segma", sans-serif;
  font-size: 10px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 100%;
  letter-spacing: 1.5px;
  cursor: default;
  text-align: center;
  cursor: grab;
}
addiction-email .form-group-container .repeatable-container .single-element .repeatable-fields-container {
  width: calc(100% - 80px);
  display: flex;
  align-items: center;
  padding: 20px 10px;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-email .form-group-container .repeatable-container .single-element .repeatable-fields-container.collapsed {
  height: 0;
  overflow: hidden;
}
addiction-email .form-group-container .repeatable-container .single-element .repeatable-fields-container.collapsed > * {
  display: none;
}
addiction-email .form-group-container .repeatable-container .single-element .repeatable-fields-container.expanded {
  height: auto;
  overflow: visible;
}
addiction-email .form-group-container .repeatable-container .single-element .repeatable-fields-container.no-drag, addiction-email .form-group-container .repeatable-container .single-element .repeatable-fields-container.no-buttons {
  width: calc(100% - 40px);
}
addiction-email .form-group-container .repeatable-container .single-element .repeatable-fields-container.no-drag.no-buttons {
  width: 100%;
}
addiction-email .form-group-container .repeatable-container .single-element .buttons-container {
  width: 40px;
  padding: 10px 0;
  border-radius: 5px;
  background: #1F2B43;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-email .form-group-container .repeatable-container .single-element .buttons-container button.clone {
  box-shadow: none;
  border-radius: 3px;
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: initial;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  width: 34px;
  height: 34px;
  background: rgba(255, 255, 255, 0);
  border: 2px solid #FFFFFF;
  border: none;
}
addiction-email .form-group-container .repeatable-container .single-element .buttons-container button.clone[aria-expanded=true] {
  border-radius: 0;
}
@media (hover: hover) and (pointer: fine) {
  addiction-email .form-group-container .repeatable-container .single-element .buttons-container button.clone:hover {
    opacity: 0.5;
  }
}
addiction-email .form-group-container .repeatable-container .single-element .buttons-container button.clone::before {
  content: "k";
  width: 16px;
  height: 16px;
  font-size: 16px;
  color: #FFFFFF;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 100%;
  font-family: "DataLean Font Icon";
  position: absolute;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-email .form-group-container .repeatable-container .single-element .buttons-container button.clone:disabled, addiction-email .form-group-container .repeatable-container .single-element .buttons-container button.clone.disabled, addiction-email .form-group-container .repeatable-container .single-element .buttons-container button.clone[disabled], addiction-email .form-group-container .repeatable-container .single-element .buttons-container button.clone[disabled=true] {
  opacity: 0.25;
  cursor: not-allowed;
  pointer-events: none;
}
addiction-email .form-group-container .repeatable-container .single-element .buttons-container button.clone:disabled span, addiction-email .form-group-container .repeatable-container .single-element .buttons-container button.clone.disabled span, addiction-email .form-group-container .repeatable-container .single-element .buttons-container button.clone[disabled] span, addiction-email .form-group-container .repeatable-container .single-element .buttons-container button.clone[disabled=true] span {
  cursor: not-allowed;
  pointer-events: none;
}
addiction-email .form-group-container .repeatable-container .single-element .buttons-container button.accordion {
  box-shadow: none;
  border-radius: 3px;
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: initial;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  width: 34px;
  height: 34px;
  background: rgba(255, 255, 255, 0);
  border: 2px solid #FFFFFF;
  border: none;
}
addiction-email .form-group-container .repeatable-container .single-element .buttons-container button.accordion[aria-expanded=true] {
  border-radius: 0;
}
@media (hover: hover) and (pointer: fine) {
  addiction-email .form-group-container .repeatable-container .single-element .buttons-container button.accordion:hover {
    opacity: 0.5;
  }
}
addiction-email .form-group-container .repeatable-container .single-element .buttons-container button.accordion::before {
  content: "a";
  width: 16px;
  height: 16px;
  font-size: 16px;
  color: #FFFFFF;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 100%;
  font-family: "DataLean Font Icon";
  position: absolute;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-email .form-group-container .repeatable-container .single-element .buttons-container button.accordion:disabled, addiction-email .form-group-container .repeatable-container .single-element .buttons-container button.accordion.disabled, addiction-email .form-group-container .repeatable-container .single-element .buttons-container button.accordion[disabled], addiction-email .form-group-container .repeatable-container .single-element .buttons-container button.accordion[disabled=true] {
  opacity: 0.25;
  cursor: not-allowed;
  pointer-events: none;
}
addiction-email .form-group-container .repeatable-container .single-element .buttons-container button.accordion:disabled span, addiction-email .form-group-container .repeatable-container .single-element .buttons-container button.accordion.disabled span, addiction-email .form-group-container .repeatable-container .single-element .buttons-container button.accordion[disabled] span, addiction-email .form-group-container .repeatable-container .single-element .buttons-container button.accordion[disabled=true] span {
  cursor: not-allowed;
  pointer-events: none;
}
addiction-email .form-group-container .repeatable-container .single-element .buttons-container button.accordion::before {
  font-size: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2px;
  transform: rotate(90deg);
}
addiction-email .form-group-container .repeatable-container .single-element .buttons-container button.accordion::after {
  content: "";
  width: 16px;
  height: 16px;
  border: 1px solid #FFFFFF;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}
addiction-email .form-group-container .repeatable-container .single-element .buttons-container button.accordion.down::before {
  margin-left: -2px;
  transform: rotate(270deg);
}
addiction-email .form-group-container .repeatable-container .single-element .buttons-container button.delete {
  box-shadow: none;
  border-radius: 3px;
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: initial;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  width: 34px;
  height: 34px;
  background: rgba(255, 255, 255, 0);
  border: 2px solid #FF4848;
  border: none;
}
addiction-email .form-group-container .repeatable-container .single-element .buttons-container button.delete[aria-expanded=true] {
  border-radius: 0;
}
@media (hover: hover) and (pointer: fine) {
  addiction-email .form-group-container .repeatable-container .single-element .buttons-container button.delete:hover {
    opacity: 0.5;
  }
}
addiction-email .form-group-container .repeatable-container .single-element .buttons-container button.delete::before {
  content: "i";
  width: 16px;
  height: 16px;
  font-size: 16px;
  color: #FF4848;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 100%;
  font-family: "DataLean Font Icon";
  position: absolute;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-email .form-group-container .repeatable-container .single-element .buttons-container button.delete:disabled, addiction-email .form-group-container .repeatable-container .single-element .buttons-container button.delete.disabled, addiction-email .form-group-container .repeatable-container .single-element .buttons-container button.delete[disabled], addiction-email .form-group-container .repeatable-container .single-element .buttons-container button.delete[disabled=true] {
  opacity: 0.25;
  cursor: not-allowed;
  pointer-events: none;
}
addiction-email .form-group-container .repeatable-container .single-element .buttons-container button.delete:disabled span, addiction-email .form-group-container .repeatable-container .single-element .buttons-container button.delete.disabled span, addiction-email .form-group-container .repeatable-container .single-element .buttons-container button.delete[disabled] span, addiction-email .form-group-container .repeatable-container .single-element .buttons-container button.delete[disabled=true] span {
  cursor: not-allowed;
  pointer-events: none;
}
addiction-email .form-group-container .repeatable-container button.add-element {
  width: auto;
  padding: 5px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 130%;
  letter-spacing: 2.5px;
  cursor: default;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  min-height: 36px;
  background: #004BC4;
  border: none;
  border-radius: 10px;
  max-width: max-content;
}
addiction-email .form-group-container .repeatable-container button.add-element span {
  width: 100%;
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 130%;
  letter-spacing: 2.5px;
  cursor: default;
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  margin-top: 3px;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-email .form-group-container .repeatable-container button.add-element[aria-expanded=true] {
  border-radius: 0;
}
@media (hover: hover) and (pointer: fine) {
  addiction-email .form-group-container .repeatable-container button.add-element:hover {
    background: #FFFFFF;
    color: #004BC4;
  }
  addiction-email .form-group-container .repeatable-container button.add-element:hover span {
    color: #004BC4;
  }
}
addiction-email .form-group-container .repeatable-container button.add-element:disabled, addiction-email .form-group-container .repeatable-container button.add-element.disabled, addiction-email .form-group-container .repeatable-container button.add-element[disabled], addiction-email .form-group-container .repeatable-container button.add-element[disabled=true] {
  opacity: 0.25;
  cursor: not-allowed;
  pointer-events: none;
}
addiction-email .form-group-container .repeatable-container button.add-element:disabled span, addiction-email .form-group-container .repeatable-container button.add-element.disabled span, addiction-email .form-group-container .repeatable-container button.add-element[disabled] span, addiction-email .form-group-container .repeatable-container button.add-element[disabled=true] span {
  cursor: not-allowed;
  pointer-events: none;
}
addiction-email .form-group-container .accordion-container {
  width: 100%;
}
addiction-email .form-group-container .accordion-container .accordion-item {
  width: 100%;
  display: flex;
  flex-flow: column;
  row-gap: 20px;
}
addiction-email .form-group-container .accordion-container .accordion-item .accordion-header .title-fields-container {
  cursor: pointer;
}
addiction-email .form-group-container .accordion-container .accordion-item .accordion-header .title-fields-container h2 {
  width: calc(100% - 40px);
  cursor: pointer;
}
addiction-email .form-group-container .accordion-container .accordion-item .accordion-header .title-fields-container .accordion-arrow {
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0);
  border: none;
  padding: 0;
  position: relative;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}
addiction-email .form-group-container .accordion-container .accordion-item .accordion-header .title-fields-container .accordion-arrow::before {
  content: "b";
  width: 7px;
  height: 7px;
  color: #FFFFFF;
  font-size: 7px;
  font-family: "DataLean Font Icon";
  position: absolute;
  pointer-events: none;
}
addiction-email .form-group-container .accordion-container .accordion-item .accordion-header .title-fields-container .accordion-arrow.closed {
  transform: rotate(-90deg);
}
addiction-email .form-group-container .accordion-container .accordion-item .accordion-content-container {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  row-gap: 20px;
}
addiction-email .form-group-container .field-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: column;
  padding: 0 10px;
  overflow: visible;
  /* Crea in automatico le larghezze da 0 a 100 ogni 1 */
}
addiction-email .form-group-container .field-container.ratio-0 {
  width: 0%;
}
addiction-email .form-group-container .field-container.ratio-1 {
  width: 1%;
}
addiction-email .form-group-container .field-container.ratio-2 {
  width: 2%;
}
addiction-email .form-group-container .field-container.ratio-3 {
  width: 3%;
}
addiction-email .form-group-container .field-container.ratio-4 {
  width: 4%;
}
addiction-email .form-group-container .field-container.ratio-5 {
  width: 5%;
}
addiction-email .form-group-container .field-container.ratio-6 {
  width: 6%;
}
addiction-email .form-group-container .field-container.ratio-7 {
  width: 7%;
}
addiction-email .form-group-container .field-container.ratio-8 {
  width: 8%;
}
addiction-email .form-group-container .field-container.ratio-9 {
  width: 9%;
}
addiction-email .form-group-container .field-container.ratio-10 {
  width: 10%;
}
addiction-email .form-group-container .field-container.ratio-11 {
  width: 11%;
}
addiction-email .form-group-container .field-container.ratio-12 {
  width: 12%;
}
addiction-email .form-group-container .field-container.ratio-13 {
  width: 13%;
}
addiction-email .form-group-container .field-container.ratio-14 {
  width: 14%;
}
addiction-email .form-group-container .field-container.ratio-15 {
  width: 15%;
}
addiction-email .form-group-container .field-container.ratio-16 {
  width: 16%;
}
addiction-email .form-group-container .field-container.ratio-17 {
  width: 17%;
}
addiction-email .form-group-container .field-container.ratio-18 {
  width: 18%;
}
addiction-email .form-group-container .field-container.ratio-19 {
  width: 19%;
}
addiction-email .form-group-container .field-container.ratio-20 {
  width: 20%;
}
addiction-email .form-group-container .field-container.ratio-21 {
  width: 21%;
}
addiction-email .form-group-container .field-container.ratio-22 {
  width: 22%;
}
addiction-email .form-group-container .field-container.ratio-23 {
  width: 23%;
}
addiction-email .form-group-container .field-container.ratio-24 {
  width: 24%;
}
addiction-email .form-group-container .field-container.ratio-25 {
  width: 25%;
}
addiction-email .form-group-container .field-container.ratio-26 {
  width: 26%;
}
addiction-email .form-group-container .field-container.ratio-27 {
  width: 27%;
}
addiction-email .form-group-container .field-container.ratio-28 {
  width: 28%;
}
addiction-email .form-group-container .field-container.ratio-29 {
  width: 29%;
}
addiction-email .form-group-container .field-container.ratio-30 {
  width: 30%;
}
addiction-email .form-group-container .field-container.ratio-31 {
  width: 31%;
}
addiction-email .form-group-container .field-container.ratio-32 {
  width: 32%;
}
addiction-email .form-group-container .field-container.ratio-33 {
  width: 33%;
}
addiction-email .form-group-container .field-container.ratio-34 {
  width: 34%;
}
addiction-email .form-group-container .field-container.ratio-35 {
  width: 35%;
}
addiction-email .form-group-container .field-container.ratio-36 {
  width: 36%;
}
addiction-email .form-group-container .field-container.ratio-37 {
  width: 37%;
}
addiction-email .form-group-container .field-container.ratio-38 {
  width: 38%;
}
addiction-email .form-group-container .field-container.ratio-39 {
  width: 39%;
}
addiction-email .form-group-container .field-container.ratio-40 {
  width: 40%;
}
addiction-email .form-group-container .field-container.ratio-41 {
  width: 41%;
}
addiction-email .form-group-container .field-container.ratio-42 {
  width: 42%;
}
addiction-email .form-group-container .field-container.ratio-43 {
  width: 43%;
}
addiction-email .form-group-container .field-container.ratio-44 {
  width: 44%;
}
addiction-email .form-group-container .field-container.ratio-45 {
  width: 45%;
}
addiction-email .form-group-container .field-container.ratio-46 {
  width: 46%;
}
addiction-email .form-group-container .field-container.ratio-47 {
  width: 47%;
}
addiction-email .form-group-container .field-container.ratio-48 {
  width: 48%;
}
addiction-email .form-group-container .field-container.ratio-49 {
  width: 49%;
}
addiction-email .form-group-container .field-container.ratio-50 {
  width: 50%;
}
addiction-email .form-group-container .field-container.ratio-51 {
  width: 51%;
}
addiction-email .form-group-container .field-container.ratio-52 {
  width: 52%;
}
addiction-email .form-group-container .field-container.ratio-53 {
  width: 53%;
}
addiction-email .form-group-container .field-container.ratio-54 {
  width: 54%;
}
addiction-email .form-group-container .field-container.ratio-55 {
  width: 55%;
}
addiction-email .form-group-container .field-container.ratio-56 {
  width: 56%;
}
addiction-email .form-group-container .field-container.ratio-57 {
  width: 57%;
}
addiction-email .form-group-container .field-container.ratio-58 {
  width: 58%;
}
addiction-email .form-group-container .field-container.ratio-59 {
  width: 59%;
}
addiction-email .form-group-container .field-container.ratio-60 {
  width: 60%;
}
addiction-email .form-group-container .field-container.ratio-61 {
  width: 61%;
}
addiction-email .form-group-container .field-container.ratio-62 {
  width: 62%;
}
addiction-email .form-group-container .field-container.ratio-63 {
  width: 63%;
}
addiction-email .form-group-container .field-container.ratio-64 {
  width: 64%;
}
addiction-email .form-group-container .field-container.ratio-65 {
  width: 65%;
}
addiction-email .form-group-container .field-container.ratio-66 {
  width: 66%;
}
addiction-email .form-group-container .field-container.ratio-67 {
  width: 67%;
}
addiction-email .form-group-container .field-container.ratio-68 {
  width: 68%;
}
addiction-email .form-group-container .field-container.ratio-69 {
  width: 69%;
}
addiction-email .form-group-container .field-container.ratio-70 {
  width: 70%;
}
addiction-email .form-group-container .field-container.ratio-71 {
  width: 71%;
}
addiction-email .form-group-container .field-container.ratio-72 {
  width: 72%;
}
addiction-email .form-group-container .field-container.ratio-73 {
  width: 73%;
}
addiction-email .form-group-container .field-container.ratio-74 {
  width: 74%;
}
addiction-email .form-group-container .field-container.ratio-75 {
  width: 75%;
}
addiction-email .form-group-container .field-container.ratio-76 {
  width: 76%;
}
addiction-email .form-group-container .field-container.ratio-77 {
  width: 77%;
}
addiction-email .form-group-container .field-container.ratio-78 {
  width: 78%;
}
addiction-email .form-group-container .field-container.ratio-79 {
  width: 79%;
}
addiction-email .form-group-container .field-container.ratio-80 {
  width: 80%;
}
addiction-email .form-group-container .field-container.ratio-81 {
  width: 81%;
}
addiction-email .form-group-container .field-container.ratio-82 {
  width: 82%;
}
addiction-email .form-group-container .field-container.ratio-83 {
  width: 83%;
}
addiction-email .form-group-container .field-container.ratio-84 {
  width: 84%;
}
addiction-email .form-group-container .field-container.ratio-85 {
  width: 85%;
}
addiction-email .form-group-container .field-container.ratio-86 {
  width: 86%;
}
addiction-email .form-group-container .field-container.ratio-87 {
  width: 87%;
}
addiction-email .form-group-container .field-container.ratio-88 {
  width: 88%;
}
addiction-email .form-group-container .field-container.ratio-89 {
  width: 89%;
}
addiction-email .form-group-container .field-container.ratio-90 {
  width: 90%;
}
addiction-email .form-group-container .field-container.ratio-91 {
  width: 91%;
}
addiction-email .form-group-container .field-container.ratio-92 {
  width: 92%;
}
addiction-email .form-group-container .field-container.ratio-93 {
  width: 93%;
}
addiction-email .form-group-container .field-container.ratio-94 {
  width: 94%;
}
addiction-email .form-group-container .field-container.ratio-95 {
  width: 95%;
}
addiction-email .form-group-container .field-container.ratio-96 {
  width: 96%;
}
addiction-email .form-group-container .field-container.ratio-97 {
  width: 97%;
}
addiction-email .form-group-container .field-container.ratio-98 {
  width: 98%;
}
addiction-email .form-group-container .field-container.ratio-99 {
  width: 99%;
}
addiction-email .form-group-container .field-container.ratio-100 {
  width: 100%;
}
addiction-email .form-group-container .field-container.ratio-66 {
  width: 66.666%;
}
addiction-email .form-group-container .field-container.ratio-33 {
  width: 33.333%;
}
addiction-email .form-group-container .field-container.ratio-0, addiction-email .form-group-container .field-container.radio-null, addiction-email .form-group-container .field-container.radio-undefined {
  width: 0;
  display: none;
}
addiction-email .form-group-container .field-container.ratio-auto {
  width: auto;
}
addiction-email .form-group-container .field-container.ratio-auto addiction-date-picker {
  min-width: 420px;
}
addiction-email .form-group-container .field-container.bigger > .text {
  font-size: 20px;
}
addiction-email .form-group-container .field-container.bigger addiction-input::ng-deep .form-field .form-input-container.number::before {
  right: 15px;
  top: 15px;
}
addiction-email .form-group-container .field-container.bigger addiction-input::ng-deep .form-field .form-input-container.number::after {
  right: 15px;
  bottom: 15px;
}
addiction-email .form-group-container .field-container.bigger addiction-input::ng-deep .form-field .form-input-container input {
  height: 60px;
  padding: 5px 15px;
  font-family: "Segma", sans-serif;
  font-size: 20px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 100%;
  letter-spacing: 1px;
  cursor: default;
  cursor: text;
}
addiction-email .form-group-container .field-container.bigger addiction-select::ng-deep .select-container select {
  height: 60px;
  padding: 5px 15px;
  font-family: "Segma", sans-serif;
  font-size: 20px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 100%;
  letter-spacing: 1px;
  cursor: default;
  cursor: text;
}
addiction-email .form-group-container .field-container.bigger addiction-select::ng-deep mat-form-field {
  height: 60px;
}
addiction-email .form-group-container .field-container.bigger addiction-select::ng-deep mat-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-select {
  padding: 5px 15px;
}
addiction-email .form-group-container .field-container.bigger addiction-select::ng-deep mat-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-select .mat-mdc-select-trigger .mat-mdc-select-value .mat-mdc-select-min-line {
  font-family: "Segma", sans-serif;
  font-size: 20px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 100%;
  letter-spacing: 1px;
  cursor: default;
  cursor: pointer;
}
addiction-email .form-group-container .field-container.bigger .info-container .value {
  font-size: 20px;
}
addiction-email .form-group-container .field-container.entity-version {
  display: flex;
  flex-flow: row;
  align-items: center;
}
addiction-email .form-group-container .field-container.entity-version label {
  width: 50%;
  margin: 0;
}
addiction-email .form-group-container .field-container.entity-version .entity-version-container {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
}
addiction-email .form-group-container .field-container.entity-version .entity-version-container .version {
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 100%;
  letter-spacing: 0;
  cursor: default;
  text-transform: uppercase;
}
addiction-email .form-group-container .field-container.entity-version .entity-version-container button.versioning {
  box-shadow: none;
  border-radius: 3px;
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: initial;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  width: 34px;
  height: 34px;
  background: rgba(255, 255, 255, 0);
  border: 2px solid #004BC4;
  height: 20px;
  border: none;
}
addiction-email .form-group-container .field-container.entity-version .entity-version-container button.versioning[aria-expanded=true] {
  border-radius: 0;
}
@media (hover: hover) and (pointer: fine) {
  addiction-email .form-group-container .field-container.entity-version .entity-version-container button.versioning:hover {
    opacity: 0.5;
  }
}
addiction-email .form-group-container .field-container.entity-version .entity-version-container button.versioning::before {
  content: "w";
  width: 16px;
  height: 16px;
  font-size: 16px;
  color: #FFFFFF;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 100%;
  font-family: "DataLean Font Icon";
  position: absolute;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-email .form-group-container .field-container.entity-version .entity-version-container button.versioning:disabled, addiction-email .form-group-container .field-container.entity-version .entity-version-container button.versioning.disabled, addiction-email .form-group-container .field-container.entity-version .entity-version-container button.versioning[disabled], addiction-email .form-group-container .field-container.entity-version .entity-version-container button.versioning[disabled=true] {
  opacity: 0.25;
  cursor: not-allowed;
  pointer-events: none;
}
addiction-email .form-group-container .field-container.entity-version .entity-version-container button.versioning:disabled span, addiction-email .form-group-container .field-container.entity-version .entity-version-container button.versioning.disabled span, addiction-email .form-group-container .field-container.entity-version .entity-version-container button.versioning[disabled] span, addiction-email .form-group-container .field-container.entity-version .entity-version-container button.versioning[disabled=true] span {
  cursor: not-allowed;
  pointer-events: none;
}
addiction-email .form-group-container .field-container.entity-version .entity-version-container button.checked {
  box-shadow: none;
  border-radius: 3px;
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: initial;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  width: 34px;
  height: 34px;
  background: rgba(255, 255, 255, 0);
  border: 2px solid #09152C;
  height: 20px;
  border: none;
}
addiction-email .form-group-container .field-container.entity-version .entity-version-container button.checked[aria-expanded=true] {
  border-radius: 0;
}
@media (hover: hover) and (pointer: fine) {
  addiction-email .form-group-container .field-container.entity-version .entity-version-container button.checked:hover {
    opacity: 0.5;
  }
}
addiction-email .form-group-container .field-container.entity-version .entity-version-container button.checked::before {
  content: "c";
  width: 16px;
  height: 16px;
  font-size: 16px;
  color: #4F8A10;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 100%;
  font-family: "DataLean Font Icon";
  position: absolute;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-email .form-group-container .field-container.entity-version .entity-version-container button.checked:disabled, addiction-email .form-group-container .field-container.entity-version .entity-version-container button.checked.disabled, addiction-email .form-group-container .field-container.entity-version .entity-version-container button.checked[disabled], addiction-email .form-group-container .field-container.entity-version .entity-version-container button.checked[disabled=true] {
  opacity: 0.25;
  cursor: not-allowed;
  pointer-events: none;
}
addiction-email .form-group-container .field-container.entity-version .entity-version-container button.checked:disabled span, addiction-email .form-group-container .field-container.entity-version .entity-version-container button.checked.disabled span, addiction-email .form-group-container .field-container.entity-version .entity-version-container button.checked[disabled] span, addiction-email .form-group-container .field-container.entity-version .entity-version-container button.checked[disabled=true] span {
  cursor: not-allowed;
  pointer-events: none;
}
addiction-email .form-group-container .field-container.entity-version .entity-version-container button.refused {
  box-shadow: none;
  border-radius: 3px;
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: initial;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  width: 34px;
  height: 34px;
  background: rgba(255, 255, 255, 0);
  border: 2px solid #09152C;
  height: 20px;
  border: none;
}
addiction-email .form-group-container .field-container.entity-version .entity-version-container button.refused[aria-expanded=true] {
  border-radius: 0;
}
@media (hover: hover) and (pointer: fine) {
  addiction-email .form-group-container .field-container.entity-version .entity-version-container button.refused:hover {
    opacity: 0.5;
  }
}
addiction-email .form-group-container .field-container.entity-version .entity-version-container button.refused::before {
  content: "d";
  width: 12px;
  height: 12px;
  font-size: 12px;
  color: #A20000;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 100%;
  font-family: "DataLean Font Icon";
  position: absolute;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-email .form-group-container .field-container.entity-version .entity-version-container button.refused:disabled, addiction-email .form-group-container .field-container.entity-version .entity-version-container button.refused.disabled, addiction-email .form-group-container .field-container.entity-version .entity-version-container button.refused[disabled], addiction-email .form-group-container .field-container.entity-version .entity-version-container button.refused[disabled=true] {
  opacity: 0.25;
  cursor: not-allowed;
  pointer-events: none;
}
addiction-email .form-group-container .field-container.entity-version .entity-version-container button.refused:disabled span, addiction-email .form-group-container .field-container.entity-version .entity-version-container button.refused.disabled span, addiction-email .form-group-container .field-container.entity-version .entity-version-container button.refused[disabled] span, addiction-email .form-group-container .field-container.entity-version .entity-version-container button.refused[disabled=true] span {
  cursor: not-allowed;
  pointer-events: none;
}
addiction-email .form-group-container .field-container label,
addiction-email .form-group-container .field-container .label {
  width: 100%;
  font-family: "Segma", sans-serif;
  font-size: 10px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
  line-height: 100%;
  letter-spacing: 2.5px;
  cursor: default;
  text-transform: uppercase;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  display: flex;
  align-items: center;
}
addiction-email .form-group-container .field-container label .tooltip,
addiction-email .form-group-container .field-container .label .tooltip {
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  position: relative;
  cursor: pointer;
}
addiction-email .form-group-container .field-container label .tooltip::before,
addiction-email .form-group-container .field-container .label .tooltip::before {
  content: "F";
  font-family: "DataLean Font Icon";
  font-size: 13px;
  color: #FFFFFF;
  position: absolute;
  pointer-events: none;
}
addiction-email .form-group-container .field-container .required {
  width: auto;
  font-family: "Segma", sans-serif;
  font-size: 10px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
  line-height: 100%;
  letter-spacing: 2.5px;
  cursor: default;
  text-transform: uppercase;
}
addiction-email .form-group-container .field-container .title {
  display: flex;
  align-items: center;
  display: flex;
  align-items: center;
}
addiction-email .form-group-container .field-container .title .tooltip {
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  position: relative;
  cursor: pointer;
}
addiction-email .form-group-container .field-container .title .tooltip::before {
  content: "F";
  font-family: "DataLean Font Icon";
  font-size: 15px;
  color: #FFFFFF;
  position: absolute;
  pointer-events: none;
}
addiction-email .form-group-container .field-container > .text {
  width: 100%;
  font-family: "Segma", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 180%;
  letter-spacing: 1px;
  cursor: default;
}
addiction-email .form-group-container .field-container .skeleton {
  background: linear-gradient(110deg, rgba(1, 40, 108, 0) 40%, rgba(1, 40, 108, 0.5) 60%, rgba(1, 40, 108, 0) 70%) rgba(0, 75, 196, 0.2);
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-in-out infinite;
}
addiction-email .form-group-container .field-container .skeleton.label {
  width: 30%;
  height: 10px;
  margin-bottom: 10px;
  border-radius: 3px;
}
addiction-email .form-group-container .field-container .skeleton.input {
  width: 100%;
  height: 36px;
  border-radius: 5px;
}
addiction-email .form-group-container .field-container addiction-input { /* stile dentro addiction-components.scss */ }
addiction-email .form-group-container .field-container addiction-select { /* stile dentro addiction-components.scss */ }
addiction-email .form-group-container .field-container addiction-email { /* stile dentro addiction-components.scss */ }
addiction-email .form-group-container .field-container mat-slide-toggle {
  appearance: none;
}
addiction-email .form-group-container .field-container mat-slide-toggle[aria-readonly=true], addiction-email .form-group-container .field-container mat-slide-toggle[aria-disabled=true] {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}
addiction-email .form-group-container .field-container mat-slide-toggle::ng-deep .mdc-form-field {
  appearance: none;
}
addiction-email .form-group-container .field-container mat-slide-toggle::ng-deep .mdc-form-field button.mdc-switch {
  --mdc-switch-handle-height: 26px;
  --mdc-switch-handle-shape: 13px;
  --mdc-switch-handle-width: 26px;
  --mdc-switch-track-height: 36px;
  --mdc-switch-track-shape: 18px;
  --mdc-switch-track-width: 70px;
  --mdc-switch-disabled-handle-opacity: 1;
  --mdc-switch-disabled-selected-icon-opacity: 1;
  --mdc-switch-disabled-track-opacity: 1;
  --mdc-switch-disabled-unselected-icon-opacity: 1;
  --mdc-switch-selected-focus-state-layer-opacity: 1;
  --mdc-switch-selected-hover-state-layer-opacity: 1;
  --mdc-switch-selected-pressed-state-layer-opacity: 1;
  --mdc-switch-unselected-focus-state-layer-opacity: 1;
  --mdc-switch-unselected-hover-state-layer-opacity: 1;
  --mdc-switch-unselected-pressed-state-layer-opacity: 1;
}
addiction-email .form-group-container .field-container mat-slide-toggle::ng-deep .mdc-form-field button.mdc-switch.mdc-switch--checked .mdc-switch__handle-track {
  margin: 0 -5px;
}
addiction-email .form-group-container .field-container mat-slide-toggle::ng-deep .mdc-form-field button.mdc-switch.mdc-switch--checked .mdc-switch__handle-track .mdc-switch__handle::after {
  background: #FFFFFF !important;
}
addiction-email .form-group-container .field-container mat-slide-toggle::ng-deep .mdc-form-field button.mdc-switch.mdc-switch--checked .mdc-switch__handle-track .mdc-switch__handle .mdc-switch__icons {
  display: flex;
  justify-content: center;
  align-items: center;
}
addiction-email .form-group-container .field-container mat-slide-toggle::ng-deep .mdc-form-field button.mdc-switch.mdc-switch--checked .mdc-switch__handle-track .mdc-switch__handle .mdc-switch__icons::before {
  content: "c";
  font-size: 9px;
  margin-top: 2px;
  color: #004BC4;
}
addiction-email .form-group-container .field-container mat-slide-toggle::ng-deep .mdc-form-field button.mdc-switch .mdc-switch__track::before {
  background: rgba(255, 255, 255, 0.1) !important;
}
addiction-email .form-group-container .field-container mat-slide-toggle::ng-deep .mdc-form-field button.mdc-switch .mdc-switch__track::after {
  background: #004BC4 !important;
}
addiction-email .form-group-container .field-container mat-slide-toggle::ng-deep .mdc-form-field button.mdc-switch .mdc-switch__handle-track {
  margin: 0 5px;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-email .form-group-container .field-container mat-slide-toggle::ng-deep .mdc-form-field button.mdc-switch .mdc-switch__handle-track .mdc-switch__handle {
  --mat-switch-with-icon-handle-size: 26px;
}
addiction-email .form-group-container .field-container mat-slide-toggle::ng-deep .mdc-form-field button.mdc-switch .mdc-switch__handle-track .mdc-switch__handle::before {
  display: none;
}
addiction-email .form-group-container .field-container mat-slide-toggle::ng-deep .mdc-form-field button.mdc-switch .mdc-switch__handle-track .mdc-switch__handle::after {
  background: #004BC4 !important;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-email .form-group-container .field-container mat-slide-toggle::ng-deep .mdc-form-field button.mdc-switch .mdc-switch__handle-track .mdc-switch__handle .mdc-switch__shadow,
addiction-email .form-group-container .field-container mat-slide-toggle::ng-deep .mdc-form-field button.mdc-switch .mdc-switch__handle-track .mdc-switch__handle .mdc-switch__ripple {
  display: none;
}
addiction-email .form-group-container .field-container mat-slide-toggle::ng-deep .mdc-form-field button.mdc-switch .mdc-switch__handle-track .mdc-switch__handle .mdc-switch__icons {
  display: flex;
  justify-content: center;
  align-items: center;
}
addiction-email .form-group-container .field-container mat-slide-toggle::ng-deep .mdc-form-field button.mdc-switch .mdc-switch__handle-track .mdc-switch__handle .mdc-switch__icons::before {
  content: "d";
  width: 10px;
  height: 10px;
  font-size: 7px;
  color: #FFFFFF;
  font-family: "DataLean Font Icon";
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  pointer-events: none;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-email .form-group-container .field-container mat-slide-toggle::ng-deep .mdc-form-field button.mdc-switch .mdc-switch__handle-track .mdc-switch__handle .mdc-switch__icons svg {
  display: none;
}
addiction-email .form-group-container .field-container mat-slide-toggle::ng-deep .mdc-form-field label {
  font-family: "Segma", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 130%;
  letter-spacing: 0;
  cursor: default;
  margin-left: 10px;
  display: flex;
  align-items: center;
}
addiction-email .form-group-container .field-container mat-slide-toggle::ng-deep .mdc-form-field label .tooltip {
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  position: relative;
  cursor: pointer;
}
addiction-email .form-group-container .field-container mat-slide-toggle::ng-deep .mdc-form-field label .tooltip::before {
  content: "F";
  font-family: "DataLean Font Icon";
  font-size: 13px;
  color: #FFFFFF;
  position: absolute;
  pointer-events: none;
}
addiction-email .form-group-container .field-container .info-container { /* campo con solo informazioni es. "tipo" "formato" "data" */
  width: 100%;
}
addiction-email .form-group-container .field-container .info-container .label {
  font-family: "Segma", sans-serif;
  font-size: 10px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
  line-height: 100%;
  letter-spacing: 2.5px;
  cursor: default;
  text-transform: uppercase;
  margin-bottom: 10px;
}
addiction-email .form-group-container .field-container .info-container .value {
  font-family: "Segma", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 160%;
  letter-spacing: 0.5px;
  cursor: default;
  word-break: break-all;
}
addiction-email .form-group-container .field-container .answers-container {
  width: 100%;
  display: flex;
  flex-flow: column;
}
addiction-email .form-group-container .field-container .answers-container label {
  margin: 20px 0;
}
addiction-email .form-group-container .field-container .answers-container .single-answer {
  width: 100%;
  display: flex;
  flex-flow: column;
  row-gap: 20px;
}
addiction-email .form-group-container .field-container .answers-container .single-answer .accordion-header {
  min-height: 56px;
  border-bottom: 1px solid rgba(61, 93, 150, 0.15);
  display: flex;
  align-items: center;
  cursor: pointer;
}
addiction-email .form-group-container .field-container .answers-container .single-answer .accordion-header .left-container {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
addiction-email .form-group-container .field-container .answers-container .single-answer .accordion-header .left-container .number {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  background: #1F2B43;
  margin-right: 20px;
  display: flex;
  flex-flow: column;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  font-family: "Segma", sans-serif;
  font-size: 10px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 100%;
  letter-spacing: 1.5px;
  cursor: default;
  cursor: pointer;
  text-align: center;
}
addiction-email .form-group-container .field-container .answers-container .single-answer .accordion-header .left-container .title {
  font-family: "Segma", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 130%;
  letter-spacing: 0;
  cursor: default;
  cursor: pointer;
}
addiction-email .form-group-container .field-container .answers-container .single-answer .accordion-header .right-container {
  width: 200px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
addiction-email .form-group-container .field-container .answers-container .single-answer .accordion-header .right-container .count {
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 130%;
  letter-spacing: 0;
  cursor: default;
  cursor: pointer;
}
addiction-email .form-group-container .field-container .answers-container .single-answer .accordion-header .right-container .accordion-arrow {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  background: #004BC4;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  position: relative;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-email .form-group-container .field-container .answers-container .single-answer .accordion-header .right-container .accordion-arrow::before {
  content: "b";
  width: 7px;
  height: 7px;
  color: #FFFFFF;
  font-size: 7px;
  font-family: "DataLean Font Icon";
  position: absolute;
  pointer-events: none;
  transform: rotate(0deg);
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-email .form-group-container .field-container .answers-container .single-answer .accordion-header .right-container .accordion-arrow.closed {
  background: #1F2B43;
}
addiction-email .form-group-container .field-container .answers-container .single-answer .accordion-header .right-container .accordion-arrow.closed::before {
  transform: rotate(90deg);
}
addiction-email .form-group-container .field-container .answers-container .single-answer .accordion-content-container {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  row-gap: 20px;
  margin: 20px 0;
}
addiction-email .form-group-container .field-container .feature-chips-selector-container {
  width: 100%;
}
addiction-email .form-group-container .field-container .feature-chips-selector-container datalean-feature-chips-selector { /* stile dentro chip-selector.component.scss */ }
addiction-email .form-group-container .field-container .asset-selector-container { /* stile dentro asset-selector.component.scss */ }
addiction-email .form-group-container .field-container .single-thumbnail-container {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
}
addiction-email .form-group-container .field-container .single-thumbnail-container .dimensions,
addiction-email .form-group-container .field-container .single-thumbnail-container .bytes {
  flex: 1;
  font-family: "Segma", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 150%;
  letter-spacing: 1px;
  cursor: default;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
addiction-email .form-group-container .field-container .single-thumbnail-container .bytes {
  padding: 0 10px;
}
addiction-email .form-group-container .field-container .single-thumbnail-container button.download {
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0);
  box-shadow: none;
  border: none;
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-email .form-group-container .field-container .single-thumbnail-container button.download::before {
  content: "K";
  width: 16px;
  height: 16px;
  font-size: 16px;
  color: #FFFFFF;
  font-family: "DataLean Font Icon";
  position: absolute;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
@media (hover: hover) and (pointer: fine) {
  addiction-email .form-group-container .field-container .single-thumbnail-container button.download:hover {
    opacity: 0.5;
  }
}
addiction-email .form-group-container .field-container .single-thumbnail-container button.download:disabled, addiction-email .form-group-container .field-container .single-thumbnail-container button.download.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
addiction-email .form-group-container .field-container .buttons-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-flow: row;
}
addiction-email .form-group-container .field-container .buttons-container button {
  width: auto;
  padding: 5px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 130%;
  letter-spacing: 2.5px;
  cursor: default;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  min-height: 36px;
  background: #004BC4;
  border: none;
  border-radius: 10px;
  margin-left: 20px;
}
addiction-email .form-group-container .field-container .buttons-container button span {
  width: 100%;
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 130%;
  letter-spacing: 2.5px;
  cursor: default;
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  margin-top: 3px;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-email .form-group-container .field-container .buttons-container button[aria-expanded=true] {
  border-radius: 0;
}
@media (hover: hover) and (pointer: fine) {
  addiction-email .form-group-container .field-container .buttons-container button:hover {
    background: #FFFFFF;
    color: #004BC4;
  }
  addiction-email .form-group-container .field-container .buttons-container button:hover span {
    color: #004BC4;
  }
}
addiction-email .form-group-container .field-container .buttons-container button:disabled, addiction-email .form-group-container .field-container .buttons-container button.disabled, addiction-email .form-group-container .field-container .buttons-container button[disabled], addiction-email .form-group-container .field-container .buttons-container button[disabled=true] {
  opacity: 0.25;
  cursor: not-allowed;
  pointer-events: none;
}
addiction-email .form-group-container .field-container .buttons-container button:disabled span, addiction-email .form-group-container .field-container .buttons-container button.disabled span, addiction-email .form-group-container .field-container .buttons-container button[disabled] span, addiction-email .form-group-container .field-container .buttons-container button[disabled=true] span {
  cursor: not-allowed;
  pointer-events: none;
}
addiction-email .form-group-container .field-container .simple-container-container {
  width: 100%;
  display: flex;
  flex-flow: column;
  margin: 10px 0;
  padding-left: 17px;
  border-left: 3px solid rgba(0, 75, 196, 0.8);
}
addiction-email .form-group-container .field-container .simple-container-container.no-border {
  padding-left: 0;
  border-left: 0;
}
addiction-email .form-group-container .field-container .simple-container-container .container-title {
  width: 100%;
  font-family: "Segma", sans-serif;
  font-size: 20px;
  font-weight: 300;
  color: #FFFFFF;
  line-height: 100%;
  letter-spacing: 0;
  cursor: default;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
addiction-email .form-group-container .field-container .simple-container-container .container-title .title {
  font-family: "Segma", sans-serif;
  font-size: 20px;
  font-weight: 300;
  color: #FFFFFF;
  line-height: 100%;
  letter-spacing: 0;
  cursor: default;
  cursor: pointer;
}
addiction-email .form-group-container .field-container .simple-container-container .container-title.accordion {
  justify-content: space-between;
}
addiction-email .form-group-container .field-container .simple-container-container .container-title.accordion button.accordion {
  box-shadow: none;
  border-radius: 3px;
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: initial;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  width: 34px;
  height: 34px;
  background: rgba(255, 255, 255, 0);
  border: 2px solid #FFFFFF;
  border: none;
}
addiction-email .form-group-container .field-container .simple-container-container .container-title.accordion button.accordion[aria-expanded=true] {
  border-radius: 0;
}
@media (hover: hover) and (pointer: fine) {
  addiction-email .form-group-container .field-container .simple-container-container .container-title.accordion button.accordion:hover {
    opacity: 0.5;
  }
}
addiction-email .form-group-container .field-container .simple-container-container .container-title.accordion button.accordion::before {
  content: "a";
  width: 16px;
  height: 16px;
  font-size: 16px;
  color: #FFFFFF;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 100%;
  font-family: "DataLean Font Icon";
  position: absolute;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-email .form-group-container .field-container .simple-container-container .container-title.accordion button.accordion:disabled, addiction-email .form-group-container .field-container .simple-container-container .container-title.accordion button.accordion.disabled, addiction-email .form-group-container .field-container .simple-container-container .container-title.accordion button.accordion[disabled], addiction-email .form-group-container .field-container .simple-container-container .container-title.accordion button.accordion[disabled=true] {
  opacity: 0.25;
  cursor: not-allowed;
  pointer-events: none;
}
addiction-email .form-group-container .field-container .simple-container-container .container-title.accordion button.accordion:disabled span, addiction-email .form-group-container .field-container .simple-container-container .container-title.accordion button.accordion.disabled span, addiction-email .form-group-container .field-container .simple-container-container .container-title.accordion button.accordion[disabled] span, addiction-email .form-group-container .field-container .simple-container-container .container-title.accordion button.accordion[disabled=true] span {
  cursor: not-allowed;
  pointer-events: none;
}
addiction-email .form-group-container .field-container .simple-container-container .container-title.accordion button.accordion::before {
  font-size: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2px;
  transform: rotate(90deg);
}
addiction-email .form-group-container .field-container .simple-container-container .container-title.accordion button.accordion::after {
  content: "";
  width: 16px;
  height: 16px;
  border: 1px solid #FFFFFF;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}
addiction-email .form-group-container .field-container .simple-container-container .container-title.accordion button.accordion.down::before {
  margin-left: -2px;
  transform: rotate(270deg);
}
addiction-email .form-group-container .field-container .simple-container-container .accordion-content.collapsed {
  height: 0;
  overflow: hidden;
}
addiction-email .form-group-container .field-container .simple-container-container .accordion-content.collapsed > * {
  display: none;
}
addiction-email .form-group-container .field-container .simple-container-container .accordion-content.expanded {
  height: auto;
  overflow: visible;
}
addiction-email .form-group-container .field-container .simple-container-container datalean-container,
addiction-email .form-group-container .field-container .simple-container-container .datalean-container {
  /* stile dentro container.component.scss */
  width: calc(100% + 20px);
  margin-left: -10px;
}
addiction-email .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container,
addiction-email .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container,
addiction-email .form-group-container .field-container .simple-container-container .datalean-container .container form .field-container .simple-container-container,
addiction-email .form-group-container .field-container .simple-container-container .datalean-container .container .form-group-container .field-container .simple-container-container {
  border-left: 3px solid rgba(0, 75, 196, 0.6);
}
addiction-email .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container,
addiction-email .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container,
addiction-email .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container,
addiction-email .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container,
addiction-email .form-group-container .field-container .simple-container-container .datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container,
addiction-email .form-group-container .field-container .simple-container-container .datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container,
addiction-email .form-group-container .field-container .simple-container-container .datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container,
addiction-email .form-group-container .field-container .simple-container-container .datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container {
  border-left: 3px solid rgba(0, 75, 196, 0.4);
}
addiction-email .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container,
addiction-email .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container,
addiction-email .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container,
addiction-email .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container,
addiction-email .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container,
addiction-email .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container,
addiction-email .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container,
addiction-email .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container,
addiction-email .form-group-container .field-container .simple-container-container .datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container,
addiction-email .form-group-container .field-container .simple-container-container .datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container,
addiction-email .form-group-container .field-container .simple-container-container .datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container,
addiction-email .form-group-container .field-container .simple-container-container .datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container,
addiction-email .form-group-container .field-container .simple-container-container .datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container,
addiction-email .form-group-container .field-container .simple-container-container .datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container,
addiction-email .form-group-container .field-container .simple-container-container .datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container,
addiction-email .form-group-container .field-container .simple-container-container .datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container {
  border-left: 3px solid rgba(0, 75, 196, 0.2);
}
addiction-email .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container,
addiction-email .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container,
addiction-email .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container,
addiction-email .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container,
addiction-email .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container,
addiction-email .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container,
addiction-email .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container,
addiction-email .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container,
addiction-email .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container,
addiction-email .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container,
addiction-email .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container,
addiction-email .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container,
addiction-email .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container,
addiction-email .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container,
addiction-email .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container,
addiction-email .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container,
addiction-email .form-group-container .field-container .simple-container-container .datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container,
addiction-email .form-group-container .field-container .simple-container-container .datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container,
addiction-email .form-group-container .field-container .simple-container-container .datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container,
addiction-email .form-group-container .field-container .simple-container-container .datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container,
addiction-email .form-group-container .field-container .simple-container-container .datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container,
addiction-email .form-group-container .field-container .simple-container-container .datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container,
addiction-email .form-group-container .field-container .simple-container-container .datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container,
addiction-email .form-group-container .field-container .simple-container-container .datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container,
addiction-email .form-group-container .field-container .simple-container-container .datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container,
addiction-email .form-group-container .field-container .simple-container-container .datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container,
addiction-email .form-group-container .field-container .simple-container-container .datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container,
addiction-email .form-group-container .field-container .simple-container-container .datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container,
addiction-email .form-group-container .field-container .simple-container-container .datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container,
addiction-email .form-group-container .field-container .simple-container-container .datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container,
addiction-email .form-group-container .field-container .simple-container-container .datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container,
addiction-email .form-group-container .field-container .simple-container-container .datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container {
  border-left: 3px solid rgba(0, 75, 196, 0.1);
}
addiction-email .form-group-container .field-container .external-file-selector {
  width: 100%;
}
addiction-email .form-group-container .field-container .external-file-selector input[type=file] {
  width: 100%;
  height: 36px;
  font-family: "Segma", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 100%;
  letter-spacing: 0;
  cursor: default;
  cursor: pointer;
  padding: 0;
  background: rgba(255, 255, 255, 0.05);
  border: none;
  margin: 0;
  border-radius: 10px 5px 5px 10px;
  position: relative;
}
addiction-email .form-group-container .field-container .external-file-selector input[type=file]::placeholder {
  font-family: "Segma", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
  line-height: 100%;
  letter-spacing: 0;
  cursor: default;
}
addiction-email .form-group-container .field-container .external-file-selector input[type=file]::file-selector-button {
  width: auto;
  padding: 5px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 130%;
  letter-spacing: 2.5px;
  cursor: default;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  min-height: 36px;
  background: #004BC4;
  border: none;
  border-radius: 10px;
  width: 150px;
  padding: 0;
  margin-right: 10px;
  display: inline-block;
}
addiction-email .form-group-container .field-container .external-file-selector input[type=file]::file-selector-button span {
  width: 100%;
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 130%;
  letter-spacing: 2.5px;
  cursor: default;
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  margin-top: 3px;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-email .form-group-container .field-container .external-file-selector input[type=file]::file-selector-button[aria-expanded=true] {
  border-radius: 0;
}
@media (hover: hover) and (pointer: fine) {
  addiction-email .form-group-container .field-container .external-file-selector input[type=file]::file-selector-button:hover {
    background: #FFFFFF;
    color: #004BC4;
  }
  addiction-email .form-group-container .field-container .external-file-selector input[type=file]::file-selector-button:hover span {
    color: #004BC4;
  }
}
addiction-email .form-group-container .field-container .external-file-selector input[type=file]::file-selector-button:disabled, addiction-email .form-group-container .field-container .external-file-selector input[type=file]::file-selector-button.disabled, addiction-email .form-group-container .field-container .external-file-selector input[type=file]::file-selector-button[disabled], addiction-email .form-group-container .field-container .external-file-selector input[type=file]::file-selector-button[disabled=true] {
  opacity: 0.25;
  cursor: not-allowed;
  pointer-events: none;
}
addiction-email .form-group-container .field-container .external-file-selector input[type=file]::file-selector-button:disabled span, addiction-email .form-group-container .field-container .external-file-selector input[type=file]::file-selector-button.disabled span, addiction-email .form-group-container .field-container .external-file-selector input[type=file]::file-selector-button[disabled] span, addiction-email .form-group-container .field-container .external-file-selector input[type=file]::file-selector-button[disabled=true] span {
  cursor: not-allowed;
  pointer-events: none;
}
addiction-email .form-group-container .field-container .search-list-field {
  width: 100%;
  display: flex;
  flex-flow: column;
}
addiction-email .form-group-container .field-container .search-list-field .search-container {
  display: flex;
  gap: 20px;
}
addiction-email .form-group-container .field-container .search-list-field .search-container addiction-search {
  flex: 1;
}
addiction-email .form-group-container .field-container .search-list-field .search-container button.add {
  box-shadow: none;
  border-radius: 3px;
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: initial;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  width: 36px;
  height: 36px;
  background: #004BC4;
  border: none;
}
addiction-email .form-group-container .field-container .search-list-field .search-container button.add[aria-expanded=true] {
  border-radius: 0;
}
@media (hover: hover) and (pointer: fine) {
  addiction-email .form-group-container .field-container .search-list-field .search-container button.add:hover {
    background: #FFFFFF;
  }
  addiction-email .form-group-container .field-container .search-list-field .search-container button.add:hover::before {
    color: #004BC4;
  }
}
addiction-email .form-group-container .field-container .search-list-field .search-container button.add::before {
  content: "e";
  width: 16px;
  height: 16px;
  font-size: 16px;
  color: #FFFFFF;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 100%;
  font-family: "DataLean Font Icon";
  position: absolute;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-email .form-group-container .field-container .search-list-field .search-container button.add:disabled, addiction-email .form-group-container .field-container .search-list-field .search-container button.add.disabled, addiction-email .form-group-container .field-container .search-list-field .search-container button.add[disabled], addiction-email .form-group-container .field-container .search-list-field .search-container button.add[disabled=true] {
  opacity: 0.25;
  cursor: not-allowed;
  pointer-events: none;
}
addiction-email .form-group-container .field-container .search-list-field .search-container button.add:disabled span, addiction-email .form-group-container .field-container .search-list-field .search-container button.add.disabled span, addiction-email .form-group-container .field-container .search-list-field .search-container button.add[disabled] span, addiction-email .form-group-container .field-container .search-list-field .search-container button.add[disabled=true] span {
  cursor: not-allowed;
  pointer-events: none;
}
addiction-email .form-group-container .field-container .search-list-field .list-container {
  width: calc(100% + 40px);
  margin-left: -20px;
}
addiction-email .form-group-container .field-container .search-list-field .list-container addiction-list {
  height: 250px !important;
}
addiction-email .form-group-container .field-container .images-radio-container {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  row-gap: 20px;
  column-gap: 20px;
}
addiction-email .form-group-container .field-container .images-radio-container.columns-3 .single-image-radio {
  width: calc(33.333% - 13.333px);
}
addiction-email .form-group-container .field-container .images-radio-container.columns-4 .single-image-radio {
  width: calc(25% - 15px);
}
addiction-email .form-group-container .field-container .images-radio-container .single-image-radio {
  width: calc(14.285% - 17.142px);
  max-height: 250px;
  height: auto;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  align-items: center;
}
addiction-email .form-group-container .field-container .images-radio-container .single-image-radio label {
  width: 100%;
  height: calc(100% - 28px);
  margin: 0;
}
addiction-email .form-group-container .field-container .images-radio-container .single-image-radio label img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: bottom;
  cursor: pointer;
}
addiction-email .form-group-container .field-container .images-radio-container .single-image-radio input {
  width: 18px;
  height: 18px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 100%;
  cursor: pointer;
  border: 5px solid rgba(255, 255, 255, 0);
  margin: 10px 0 0 0;
  position: relative;
  appearance: none;
}
addiction-email .form-group-container .field-container .images-radio-container .single-image-radio input:checked {
  background: #FFFFFF;
  border: 5px solid #004BC4;
}
addiction-email .form-group-container .field-container .images-radio-container .single-image-radio .delete {
  box-shadow: none;
  border-radius: 3px;
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: initial;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  width: 34px;
  height: 34px;
  background: rgba(255, 255, 255, 0);
  border: 2px solid #FF4848;
  border: none;
}
addiction-email .form-group-container .field-container .images-radio-container .single-image-radio .delete[aria-expanded=true] {
  border-radius: 0;
}
@media (hover: hover) and (pointer: fine) {
  addiction-email .form-group-container .field-container .images-radio-container .single-image-radio .delete:hover {
    opacity: 0.5;
  }
}
addiction-email .form-group-container .field-container .images-radio-container .single-image-radio .delete::before {
  content: "i";
  width: 16px;
  height: 16px;
  font-size: 16px;
  color: #FF4848;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 100%;
  font-family: "DataLean Font Icon";
  position: absolute;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-email .form-group-container .field-container .images-radio-container .single-image-radio .delete:disabled, addiction-email .form-group-container .field-container .images-radio-container .single-image-radio .delete.disabled, addiction-email .form-group-container .field-container .images-radio-container .single-image-radio .delete[disabled], addiction-email .form-group-container .field-container .images-radio-container .single-image-radio .delete[disabled=true] {
  opacity: 0.25;
  cursor: not-allowed;
  pointer-events: none;
}
addiction-email .form-group-container .field-container .images-radio-container .single-image-radio .delete:disabled span, addiction-email .form-group-container .field-container .images-radio-container .single-image-radio .delete.disabled span, addiction-email .form-group-container .field-container .images-radio-container .single-image-radio .delete[disabled] span, addiction-email .form-group-container .field-container .images-radio-container .single-image-radio .delete[disabled=true] span {
  cursor: not-allowed;
  pointer-events: none;
}
addiction-email .form-group-container .field-container .hotspot-selected-image {
  width: 200px;
  height: 200px;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: rgba(255, 255, 255, 0.1);
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-email .form-group-container .field-container .hotspot-selected-image.full {
  background: rgba(255, 255, 255, 0);
  border: 1px solid rgba(61, 93, 150, 0.3);
}
addiction-email .form-group-container .field-container .hotspot-selected-image .buttons-container {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
  column-gap: 20px;
  position: absolute;
}
addiction-email .form-group-container .field-container .hotspot-selected-image .buttons-container button.add {
  box-shadow: none;
  border-radius: 3px;
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: initial;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  width: 36px;
  height: 36px;
  background: #004BC4;
  border: none;
}
addiction-email .form-group-container .field-container .hotspot-selected-image .buttons-container button.add[aria-expanded=true] {
  border-radius: 0;
}
@media (hover: hover) and (pointer: fine) {
  addiction-email .form-group-container .field-container .hotspot-selected-image .buttons-container button.add:hover {
    background: #FFFFFF;
  }
  addiction-email .form-group-container .field-container .hotspot-selected-image .buttons-container button.add:hover::before {
    color: #004BC4;
  }
}
addiction-email .form-group-container .field-container .hotspot-selected-image .buttons-container button.add::before {
  content: "e";
  width: 16px;
  height: 16px;
  font-size: 16px;
  color: #FFFFFF;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 100%;
  font-family: "DataLean Font Icon";
  position: absolute;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-email .form-group-container .field-container .hotspot-selected-image .buttons-container button.add:disabled, addiction-email .form-group-container .field-container .hotspot-selected-image .buttons-container button.add.disabled, addiction-email .form-group-container .field-container .hotspot-selected-image .buttons-container button.add[disabled], addiction-email .form-group-container .field-container .hotspot-selected-image .buttons-container button.add[disabled=true] {
  opacity: 0.25;
  cursor: not-allowed;
  pointer-events: none;
}
addiction-email .form-group-container .field-container .hotspot-selected-image .buttons-container button.add:disabled span, addiction-email .form-group-container .field-container .hotspot-selected-image .buttons-container button.add.disabled span, addiction-email .form-group-container .field-container .hotspot-selected-image .buttons-container button.add[disabled] span, addiction-email .form-group-container .field-container .hotspot-selected-image .buttons-container button.add[disabled=true] span {
  cursor: not-allowed;
  pointer-events: none;
}
addiction-email .form-group-container .field-container .hotspot-selected-image .buttons-container button.edit {
  box-shadow: none;
  border-radius: 3px;
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: initial;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  width: 36px;
  height: 36px;
  background: #004BC4;
  border: none;
}
addiction-email .form-group-container .field-container .hotspot-selected-image .buttons-container button.edit[aria-expanded=true] {
  border-radius: 0;
}
@media (hover: hover) and (pointer: fine) {
  addiction-email .form-group-container .field-container .hotspot-selected-image .buttons-container button.edit:hover {
    background: #FFFFFF;
  }
  addiction-email .form-group-container .field-container .hotspot-selected-image .buttons-container button.edit:hover::before {
    color: #004BC4;
  }
}
addiction-email .form-group-container .field-container .hotspot-selected-image .buttons-container button.edit::before {
  content: "n";
  width: 16px;
  height: 16px;
  font-size: 16px;
  color: #FFFFFF;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 100%;
  font-family: "DataLean Font Icon";
  position: absolute;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-email .form-group-container .field-container .hotspot-selected-image .buttons-container button.edit:disabled, addiction-email .form-group-container .field-container .hotspot-selected-image .buttons-container button.edit.disabled, addiction-email .form-group-container .field-container .hotspot-selected-image .buttons-container button.edit[disabled], addiction-email .form-group-container .field-container .hotspot-selected-image .buttons-container button.edit[disabled=true] {
  opacity: 0.25;
  cursor: not-allowed;
  pointer-events: none;
}
addiction-email .form-group-container .field-container .hotspot-selected-image .buttons-container button.edit:disabled span, addiction-email .form-group-container .field-container .hotspot-selected-image .buttons-container button.edit.disabled span, addiction-email .form-group-container .field-container .hotspot-selected-image .buttons-container button.edit[disabled] span, addiction-email .form-group-container .field-container .hotspot-selected-image .buttons-container button.edit[disabled=true] span {
  cursor: not-allowed;
  pointer-events: none;
}
addiction-email .form-group-container .field-container .hotspot-selected-image .buttons-container button.delete {
  box-shadow: none;
  border-radius: 3px;
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: initial;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  width: 36px;
  height: 36px;
  background: #FF4848;
  border: none;
}
addiction-email .form-group-container .field-container .hotspot-selected-image .buttons-container button.delete[aria-expanded=true] {
  border-radius: 0;
}
@media (hover: hover) and (pointer: fine) {
  addiction-email .form-group-container .field-container .hotspot-selected-image .buttons-container button.delete:hover {
    background: #FFFFFF;
  }
  addiction-email .form-group-container .field-container .hotspot-selected-image .buttons-container button.delete:hover::before {
    color: #FF4848;
  }
}
addiction-email .form-group-container .field-container .hotspot-selected-image .buttons-container button.delete::before {
  content: "i";
  width: 16px;
  height: 16px;
  font-size: 16px;
  color: #FFFFFF;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 100%;
  font-family: "DataLean Font Icon";
  position: absolute;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-email .form-group-container .field-container .hotspot-selected-image .buttons-container button.delete:disabled, addiction-email .form-group-container .field-container .hotspot-selected-image .buttons-container button.delete.disabled, addiction-email .form-group-container .field-container .hotspot-selected-image .buttons-container button.delete[disabled], addiction-email .form-group-container .field-container .hotspot-selected-image .buttons-container button.delete[disabled=true] {
  opacity: 0.25;
  cursor: not-allowed;
  pointer-events: none;
}
addiction-email .form-group-container .field-container .hotspot-selected-image .buttons-container button.delete:disabled span, addiction-email .form-group-container .field-container .hotspot-selected-image .buttons-container button.delete.disabled span, addiction-email .form-group-container .field-container .hotspot-selected-image .buttons-container button.delete[disabled] span, addiction-email .form-group-container .field-container .hotspot-selected-image .buttons-container button.delete[disabled=true] span {
  cursor: not-allowed;
  pointer-events: none;
}
addiction-email .form-group-container .field-container .hotspot-selected-image img {
  width: 100%;
  height: 100%;
}
addiction-email .form-group-container .title-fields-container {
  margin-top: 0;
}
addiction-email .form-group-container .title-fields-container h2 {
  display: flex;
  align-items: center;
}
addiction-email .form-group-container .title-fields-container h2 .tooltip {
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  position: relative;
  cursor: pointer;
}
addiction-email .form-group-container .title-fields-container h2 .tooltip::before {
  content: "F";
  font-family: "DataLean Font Icon";
  font-size: 15px;
  color: #FFFFFF;
  position: absolute;
  pointer-events: none;
}

/* --- TYPOGRAPHY --- */
/* --- COLORS --- */
/* --- ANIMATIONS --- */
/* --- SPECIFIC MIXINS --- */
/* Stile per buttons che al click aprono un sottomenù */
/* --- TYPOGRAPHY --- */
/* --- COLORS --- */
/* --- ANIMATIONS --- */
/* --- STANDARD MIXINS --- */
/* --- FLEX --- */
/* --- FONTS --- */
/* Usato ad es. per scroll dentro select o popup con sfondo $brand-color-03 */
@keyframes loading {
  to {
    background-position-x: -20%;
  }
}
/*
NOTE BUTTONS FE 3.0 - DAM
I buttons con lo sfondo colorato, senza bordo e icona in mezzo (es. +) si fanno così:
@include button-style("e", $brand-color-04, $white, $buttonIcon: true, $buttonBackground: true);

I buttons senza sfondo, con bordo e icona in mezzo (es. permessi, back, navigazione, filtri) si fanno così:
@include button-style("e", $brand-color-04, $brand-color-04, $buttonIcon: true, $buttonBackground: false);

I buttons con lo sfondo colorato, senza bordo e testo in mezzo (es. salva, nuovo) si fanno così:
@include button-style(36px, $brand-color-04, $white, $buttonIcon: false, $buttonBackground: true);

I buttons senza sfondo, con bordo e testo in mezzo (es. aggiungi a collezione, collezioni) si fanno così:
@include button-style(36px, $brand-color-04, $brand-color-04, $buttonIcon: false, $buttonBackground: false);
*/
/* ADDICTION FILTER MENU */
addiction-filter-menu {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  padding: 20px;
  overflow-y: auto;
}
addiction-filter-menu::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
addiction-filter-menu::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: none;
}
addiction-filter-menu::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0);
}
addiction-filter-menu > .buttons-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
addiction-filter-menu > .buttons-container button {
  width: auto;
  padding: 5px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 130%;
  letter-spacing: 2.5px;
  cursor: default;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  min-height: 34px;
  background: rgba(255, 255, 255, 0);
  border: 2px solid #FFFFFF;
  border-radius: 3px;
  width: 100%;
  padding: 5px;
  margin-bottom: 20px;
  letter-spacing: 1.5px;
}
addiction-filter-menu > .buttons-container button span {
  width: 100%;
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 130%;
  letter-spacing: 2.5px;
  cursor: default;
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  margin-top: 3px;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
@media (hover: hover) and (pointer: fine) {
  addiction-filter-menu > .buttons-container button:hover {
    opacity: 0.5;
  }
}
addiction-filter-menu > .buttons-container button:disabled, addiction-filter-menu > .buttons-container button.disabled, addiction-filter-menu > .buttons-container button[disabled], addiction-filter-menu > .buttons-container button[disabled=true] {
  opacity: 0.25;
  cursor: not-allowed;
  pointer-events: none;
}
addiction-filter-menu > .buttons-container button:disabled span, addiction-filter-menu > .buttons-container button.disabled span, addiction-filter-menu > .buttons-container button[disabled] span, addiction-filter-menu > .buttons-container button[disabled=true] span {
  cursor: not-allowed;
  pointer-events: none;
}
addiction-filter-menu .checkbox-filters-container {
  display: flex;
  flex-flow: column;
}
addiction-filter-menu .checkbox-filters-container .single-filter {
  display: flex;
  flex-flow: column;
  margin-bottom: 20px;
}
addiction-filter-menu .checkbox-filters-container .single-filter .filter-title {
  font-family: "Segma", sans-serif;
  font-size: 10px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
  line-height: 150%;
  letter-spacing: 0.5px;
  cursor: default;
  text-transform: uppercase;
  margin-bottom: 10px;
}
addiction-filter-menu .checkbox-filters-container .single-filter .filter-options {
  display: flex;
  flex-flow: column;
  margin-bottom: 20px;
}
addiction-filter-menu .checkbox-filters-container .single-filter .filter-options:last-of-type {
  margin-bottom: 0;
}
addiction-filter-menu .checkbox-filters-container .single-filter .filter-options .single-filter-option {
  margin-bottom: 10px;
}
addiction-filter-menu .checkbox-filters-container .single-filter .filter-options .single-filter-option:last-of-type {
  margin-bottom: 0;
}
addiction-filter-menu .checkbox-filters-container .single-filter .filter-options .single-filter-option mat-checkbox {
  width: 100%;
}
addiction-filter-menu .checkbox-filters-container .single-filter .filter-options .single-filter-option mat-checkbox .mdc-form-field .mdc-checkbox {
  padding: 0;
}
addiction-filter-menu .checkbox-filters-container .single-filter .filter-options .single-filter-option mat-checkbox .mdc-form-field .mdc-checkbox .mat-mdc-checkbox-touch-target {
  display: none;
}
addiction-filter-menu .checkbox-filters-container .single-filter .filter-options .single-filter-option mat-checkbox .mdc-form-field .mdc-checkbox input {
  width: 18px;
  height: 18px;
}
addiction-filter-menu .checkbox-filters-container .single-filter .filter-options .single-filter-option mat-checkbox .mdc-form-field .mdc-checkbox input:enabled:checked ~ .mdc-checkbox__background {
  border-color: unset;
  background-color: #004BC4 !important;
}
addiction-filter-menu .checkbox-filters-container .single-filter .filter-options .single-filter-option mat-checkbox .mdc-form-field .mdc-checkbox input:enabled:indeterminate ~ .mdc-checkbox__background, addiction-filter-menu .checkbox-filters-container .single-filter .filter-options .single-filter-option mat-checkbox .mdc-form-field .mdc-checkbox input.mdc-checkbox--selected ~ .mdc-checkbox__background {
  border-color: unset;
  background-color: #004BC4 !important;
}
addiction-filter-menu .checkbox-filters-container .single-filter .filter-options .single-filter-option mat-checkbox .mdc-form-field .mdc-checkbox input:enabled:indeterminate ~ .mdc-checkbox__background .mdc-checkbox__mixedmark, addiction-filter-menu .checkbox-filters-container .single-filter .filter-options .single-filter-option mat-checkbox .mdc-form-field .mdc-checkbox input.mdc-checkbox--selected ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
  transform: scale(0.5);
  border-color: #FFFFFF !important;
}
addiction-filter-menu .checkbox-filters-container .single-filter .filter-options .single-filter-option mat-checkbox .mdc-form-field .mdc-checkbox .mdc-checkbox__ripple {
  display: none;
}
addiction-filter-menu .checkbox-filters-container .single-filter .filter-options .single-filter-option mat-checkbox .mdc-form-field .mdc-checkbox .mdc-checkbox__background {
  width: 18px;
  height: 18px;
  top: unset;
  left: unset;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.1) !important;
  border: none !important;
}
addiction-filter-menu .checkbox-filters-container .single-filter .filter-options .single-filter-option mat-checkbox .mdc-form-field .mdc-checkbox .mdc-checkbox__background svg {
  transform: scale(0.6);
}
addiction-filter-menu .checkbox-filters-container .single-filter .filter-options .single-filter-option mat-checkbox .mdc-form-field .mdc-checkbox .mdc-checkbox__background .mdc-checkbox__mixedmark {
  transform: scale(0);
}
addiction-filter-menu .checkbox-filters-container .single-filter .filter-options .single-filter-option mat-checkbox .mdc-form-field .mdc-checkbox .mat-mdc-checkbox-ripple {
  display: none;
}
addiction-filter-menu .checkbox-filters-container .single-filter .filter-options .single-filter-option mat-checkbox .mdc-form-field .mdc-label {
  padding-left: 10px;
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 150%;
  letter-spacing: 0;
  cursor: default;
}
addiction-filter-menu .checkbox-filters-container .single-filter .filter-options .single-filter-option mat-checkbox .mdc-form-field {
  width: 100%;
}
addiction-filter-menu .checkbox-filters-container .single-filter .filter-options .single-filter-option mat-checkbox .mdc-form-field .mdc-label {
  text-overflow: ellipsis;
  overflow: hidden;
}
addiction-filter-menu .checkbox-filters-container .single-filter .filter-options .buttons-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
addiction-filter-menu .checkbox-filters-container .single-filter .filter-options .buttons-container button {
  appearance: none;
  border: none;
  outline: none;
  padding: 0;
  background-color: rgba(255, 255, 255, 0);
  font-family: "Segma", sans-serif;
  font-size: 10px;
  font-weight: 500;
  color: #0061FF;
  line-height: 150%;
  letter-spacing: 1px;
  cursor: default;
  cursor: pointer;
  text-transform: uppercase;
}
@media (hover: hover) and (pointer: fine) {
  addiction-filter-menu .checkbox-filters-container .single-filter .filter-options .buttons-container button:hover {
    opacity: 0.5;
  }
}
addiction-filter-menu .checkbox-filters-container .single-filter .single-filter-option.tag form {
  display: flex;
  flex-flow: column;
}
addiction-filter-menu .checkbox-filters-container .single-filter .single-filter-option.tag form .chip-container {
  display: flex;
  flex-flow: column;
}
addiction-filter-menu .checkbox-filters-container .single-filter .single-filter-option.tag form .chip-container mat-chip-grid {
  margin-top: 5px;
}
addiction-filter-menu .checkbox-filters-container .single-filter .single-filter-option.tag form .chip-container mat-chip-grid .mdc-evolution-chip-set__chips .single-chip mat-chip-row {
  min-height: 32px;
  max-height: unset;
  height: auto;
  padding: 10px 0;
  border-radius: 0;
  background: #004BC4;
}
@media (hover: hover) and (pointer: fine) {
  addiction-filter-menu .checkbox-filters-container .single-filter .single-filter-option.tag form .chip-container mat-chip-grid .mdc-evolution-chip-set__chips .single-chip mat-chip-row:hover .mdc-evolution-chip__cell .remove {
    opacity: 0.5;
  }
}
addiction-filter-menu .checkbox-filters-container .single-filter .single-filter-option.tag form .chip-container mat-chip-grid .mdc-evolution-chip-set__chips .single-chip mat-chip-row .mat-ripple {
  display: none;
}
addiction-filter-menu .checkbox-filters-container .single-filter .single-filter-option.tag form .chip-container mat-chip-grid .mdc-evolution-chip-set__chips .single-chip mat-chip-row .mat-mdc-chip-focus-overlay {
  display: none;
}
addiction-filter-menu .checkbox-filters-container .single-filter .single-filter-option.tag form .chip-container mat-chip-grid .mdc-evolution-chip-set__chips .single-chip mat-chip-row .mdc-evolution-chip__action .mdc-evolution-chip__text-label .text {
  font-family: "Segma", sans-serif;
  font-size: 10px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 150%;
  letter-spacing: 0;
  cursor: default;
  white-space: break-spaces;
}
addiction-filter-menu .checkbox-filters-container .single-filter .single-filter-option.tag form .chip-container mat-chip-grid .mdc-evolution-chip-set__chips .single-chip mat-chip-row .mdc-evolution-chip__cell .remove {
  position: relative;
  display: flex;
  opacity: 1;
}
addiction-filter-menu .checkbox-filters-container .single-filter .single-filter-option.tag form .chip-container mat-chip-grid .mdc-evolution-chip-set__chips .single-chip mat-chip-row .mdc-evolution-chip__cell .remove::before {
  content: "d";
  width: 10px;
  height: 10px;
  font-size: 10px;
  color: #FFFFFF;
  font-family: "DataLean Font Icon";
  position: absolute;
  top: unset;
  right: unset;
  bottom: unset;
  left: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  border: none;
  pointer-events: none;
}
addiction-filter-menu .checkbox-filters-container .single-filter .single-filter-option.tag form .chip-container .mat-mdc-chip-input {
  width: 100%;
  height: 36px;
  flex: unset;
  padding: 5px 10px;
  margin: 0;
  caret-color: #FFFFFF;
  border: none;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 5px;
  font-family: "Segma", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 100%;
  letter-spacing: 0;
  cursor: default;
  cursor: inherit;
}
addiction-filter-menu .checkbox-filters-container .single-filter .single-filter-option.category form {
  display: flex;
  flex-flow: column;
}
addiction-filter-menu .dates-filters-container {
  display: flex;
  flex-flow: column;
}
addiction-filter-menu .dates-filters-container .single-filter {
  display: flex;
  flex-flow: column;
  margin-bottom: 20px;
}
addiction-filter-menu .dates-filters-container .single-filter .filter-title {
  font-family: "Segma", sans-serif;
  font-size: 10px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
  line-height: 150%;
  letter-spacing: 0.5px;
  cursor: default;
  text-transform: uppercase;
  margin-bottom: 10px;
}
/* --- TYPOGRAPHY --- */
/* --- COLORS --- */
/* --- ANIMATIONS --- */
/* --- SPECIFIC MIXINS --- */
/* Stile per buttons che al click aprono un sottomenù */
/* --- TYPOGRAPHY --- */
/* --- COLORS --- */
/* --- ANIMATIONS --- */
/* --- STANDARD MIXINS --- */
/* --- FLEX --- */
/* --- FONTS --- */
/* Usato ad es. per scroll dentro select o popup con sfondo $brand-color-03 */
@keyframes loading {
  to {
    background-position-x: -20%;
  }
}
/*
NOTE BUTTONS FE 3.0 - DAM
I buttons con lo sfondo colorato, senza bordo e icona in mezzo (es. +) si fanno così:
@include button-style("e", $brand-color-04, $white, $buttonIcon: true, $buttonBackground: true);

I buttons senza sfondo, con bordo e icona in mezzo (es. permessi, back, navigazione, filtri) si fanno così:
@include button-style("e", $brand-color-04, $brand-color-04, $buttonIcon: true, $buttonBackground: false);

I buttons con lo sfondo colorato, senza bordo e testo in mezzo (es. salva, nuovo) si fanno così:
@include button-style(36px, $brand-color-04, $white, $buttonIcon: false, $buttonBackground: true);

I buttons senza sfondo, con bordo e testo in mezzo (es. aggiungi a collezione, collezioni) si fanno così:
@include button-style(36px, $brand-color-04, $brand-color-04, $buttonIcon: false, $buttonBackground: false);
*/
/* ADDICTION GRID */
addiction-grid {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
}
addiction-grid.show-bulk-actions {
  height: calc(100% - 70px);
}
addiction-grid .header-grid-container { /* uguale ad addiction-list.scss */
  width: 100%;
  min-height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
  position: relative;
}
addiction-grid .header-grid-container.expanded {
  margin-bottom: 70px;
}
addiction-grid .header-grid-container.dam .header-grid .left-container .title h1 {
  font-family: "Segma", sans-serif;
  font-size: 40px;
  font-weight: 300;
  color: #FFFFFF;
  line-height: 100%;
  letter-spacing: 0;
  cursor: default;
}
addiction-grid .header-grid-container .header-grid {
  width: 100%;
  height: 70px;
  padding: 10px 20px;
  display: flex;
}
addiction-grid .header-grid-container .header-grid .left-container {
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: auto;
}
addiction-grid .header-grid-container .header-grid .left-container .checkbox-container {
  width: 30px;
}
addiction-grid .header-grid-container .header-grid .left-container .checkbox-container mat-checkbox {
  margin: 0 10px 0 0;
}
addiction-grid .header-grid-container .header-grid .left-container .checkbox-container mat-checkbox .mdc-form-field .mdc-checkbox {
  padding: 0;
}
addiction-grid .header-grid-container .header-grid .left-container .checkbox-container mat-checkbox .mdc-form-field .mdc-checkbox .mat-mdc-checkbox-touch-target {
  display: none;
}
addiction-grid .header-grid-container .header-grid .left-container .checkbox-container mat-checkbox .mdc-form-field .mdc-checkbox input {
  width: 18px;
  height: 18px;
}
addiction-grid .header-grid-container .header-grid .left-container .checkbox-container mat-checkbox .mdc-form-field .mdc-checkbox input:enabled:checked ~ .mdc-checkbox__background {
  border-color: unset;
  background-color: #004BC4 !important;
}
addiction-grid .header-grid-container .header-grid .left-container .checkbox-container mat-checkbox .mdc-form-field .mdc-checkbox input:enabled:indeterminate ~ .mdc-checkbox__background, addiction-grid .header-grid-container .header-grid .left-container .checkbox-container mat-checkbox .mdc-form-field .mdc-checkbox input.mdc-checkbox--selected ~ .mdc-checkbox__background {
  border-color: unset;
  background-color: #004BC4 !important;
}
addiction-grid .header-grid-container .header-grid .left-container .checkbox-container mat-checkbox .mdc-form-field .mdc-checkbox input:enabled:indeterminate ~ .mdc-checkbox__background .mdc-checkbox__mixedmark, addiction-grid .header-grid-container .header-grid .left-container .checkbox-container mat-checkbox .mdc-form-field .mdc-checkbox input.mdc-checkbox--selected ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
  transform: scale(0.5);
  border-color: #FFFFFF !important;
}
addiction-grid .header-grid-container .header-grid .left-container .checkbox-container mat-checkbox .mdc-form-field .mdc-checkbox .mdc-checkbox__ripple {
  display: none;
}
addiction-grid .header-grid-container .header-grid .left-container .checkbox-container mat-checkbox .mdc-form-field .mdc-checkbox .mdc-checkbox__background {
  width: 18px;
  height: 18px;
  top: unset;
  left: unset;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.1) !important;
  border: none !important;
}
addiction-grid .header-grid-container .header-grid .left-container .checkbox-container mat-checkbox .mdc-form-field .mdc-checkbox .mdc-checkbox__background svg {
  transform: scale(0.6);
}
addiction-grid .header-grid-container .header-grid .left-container .checkbox-container mat-checkbox .mdc-form-field .mdc-checkbox .mdc-checkbox__background .mdc-checkbox__mixedmark {
  transform: scale(0);
}
addiction-grid .header-grid-container .header-grid .left-container .checkbox-container mat-checkbox .mdc-form-field .mdc-checkbox .mat-mdc-checkbox-ripple {
  display: none;
}
addiction-grid .header-grid-container .header-grid .left-container .checkbox-container mat-checkbox .mdc-form-field .mdc-label {
  padding-left: 10px;
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 150%;
  letter-spacing: 0;
  cursor: default;
}
addiction-grid .header-grid-container .header-grid .left-container .title {
  width: calc(100% - 30px);
}
addiction-grid .header-grid-container .header-grid .left-container .title h1 {
  font-family: "Segma", sans-serif;
  font-size: 20px;
  font-weight: 300;
  color: #FFFFFF;
  line-height: 150%;
  letter-spacing: 0;
  cursor: default;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
}
addiction-grid .header-grid-container .header-grid .right-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
addiction-grid .header-grid-container .header-grid .right-container addiction-search { /* stile dentro addiction-components.scss */ }
addiction-grid .header-grid-container .header-grid .right-container button.new {
  width: auto;
  padding: 5px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 130%;
  letter-spacing: 2.5px;
  cursor: default;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  min-height: 36px;
  background: #004BC4;
  border: none;
  border-radius: 10px;
  margin-left: 20px;
}
addiction-grid .header-grid-container .header-grid .right-container button.new span {
  width: 100%;
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 130%;
  letter-spacing: 2.5px;
  cursor: default;
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  margin-top: 3px;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-grid .header-grid-container .header-grid .right-container button.new[aria-expanded=true] {
  border-radius: 0;
}
@media (hover: hover) and (pointer: fine) {
  addiction-grid .header-grid-container .header-grid .right-container button.new:hover {
    background: #FFFFFF;
    color: #004BC4;
  }
  addiction-grid .header-grid-container .header-grid .right-container button.new:hover span {
    color: #004BC4;
  }
}
addiction-grid .header-grid-container .header-grid .right-container button.new:disabled, addiction-grid .header-grid-container .header-grid .right-container button.new.disabled, addiction-grid .header-grid-container .header-grid .right-container button.new[disabled], addiction-grid .header-grid-container .header-grid .right-container button.new[disabled=true] {
  opacity: 0.25;
  cursor: not-allowed;
  pointer-events: none;
}
addiction-grid .header-grid-container .header-grid .right-container button.new:disabled span, addiction-grid .header-grid-container .header-grid .right-container button.new.disabled span, addiction-grid .header-grid-container .header-grid .right-container button.new[disabled] span, addiction-grid .header-grid-container .header-grid .right-container button.new[disabled=true] span {
  cursor: not-allowed;
  pointer-events: none;
}
addiction-grid .header-grid-container .header-grid .right-container .elements {
  display: flex;
  justify-content: flex-end;
}
addiction-grid .header-grid-container .header-grid .right-container .elements .number {
  font-family: "Segma", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 100%;
  letter-spacing: 1px;
  cursor: default;
  margin-right: 5px;
}
addiction-grid .header-grid-container .header-grid .right-container .elements .text {
  font-family: "Segma", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 100%;
  letter-spacing: 1px;
  cursor: default;
}
addiction-grid .header-grid-container addiction-bulk-action-bar { /* stile dentro addiction-components.scss */ }
addiction-grid .grid-viewport {
  width: 100%;
  height: calc(100% - 20px);
  margin-top: 20px;
  padding: 0 10px;
  overflow-y: auto;
  overflow-x: hidden;
}
addiction-grid .grid-viewport::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
addiction-grid .grid-viewport::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: none;
}
addiction-grid .grid-viewport::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0);
}
addiction-grid .grid-viewport.with-header {
  height: calc(100% - 70px);
  margin-top: 0;
}
addiction-grid .grid-viewport.hidden {
  display: none;
}
addiction-grid .grid-viewport .grid-row {
  display: flex;
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}
addiction-grid .grid-viewport .grid-row .grid-card {
  height: auto;
  flex: 1;
  padding: 0 10px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
addiction-grid .grid-viewport .grid-row .grid-card .card {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}
addiction-grid .grid-viewport .grid-row .grid-card .card.folders, addiction-grid .grid-viewport .grid-row .grid-card .card.smart-widget, addiction-grid .grid-viewport .grid-row .grid-card .card.collections {
  width: 100%;
  aspect-ratio: 5/4;
  background-image: url("../../../assets/images/folder-border.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  padding: 10px;
  display: flex;
  justify-content: flex-end;
  position: relative;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
@media (hover: hover) and (pointer: fine) {
  addiction-grid .grid-viewport .grid-row .grid-card .card.folders:hover, addiction-grid .grid-viewport .grid-row .grid-card .card.smart-widget:hover, addiction-grid .grid-viewport .grid-row .grid-card .card.collections:hover {
    opacity: 0.5;
  }
}
addiction-grid .grid-viewport .grid-row .grid-card .card.folders .text-container, addiction-grid .grid-viewport .grid-row .grid-card .card.smart-widget .text-container, addiction-grid .grid-viewport .grid-row .grid-card .card.collections .text-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-flow: column;
  padding-right: 20px;
  position: relative;
}
addiction-grid .grid-viewport .grid-row .grid-card .card.folders .text-container .title, addiction-grid .grid-viewport .grid-row .grid-card .card.smart-widget .text-container .title, addiction-grid .grid-viewport .grid-row .grid-card .card.collections .text-container .title {
  width: 100%;
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 100%;
  letter-spacing: 0;
  cursor: default;
  word-break: break-all;
  cursor: pointer;
  margin-bottom: 5px;
}
addiction-grid .grid-viewport .grid-row .grid-card .card.folders .text-container .date, addiction-grid .grid-viewport .grid-row .grid-card .card.smart-widget .text-container .date, addiction-grid .grid-viewport .grid-row .grid-card .card.collections .text-container .date {
  width: 100%;
  font-family: "Segma", sans-serif;
  font-size: 10px;
  font-weight: 500;
  color: #71A7FF;
  line-height: 100%;
  letter-spacing: 0;
  cursor: default;
  cursor: pointer;
}
addiction-grid .grid-viewport .grid-row .grid-card .card.folders .text-container .count, addiction-grid .grid-viewport .grid-row .grid-card .card.smart-widget .text-container .count, addiction-grid .grid-viewport .grid-row .grid-card .card.collections .text-container .count {
  font-family: "Segma", sans-serif;
  font-size: 10px;
  font-weight: 500;
  color: #71A7FF;
  line-height: 100%;
  letter-spacing: 0;
  cursor: default;
  cursor: pointer;
  position: absolute;
  right: 0;
  bottom: 0;
}
addiction-grid .grid-viewport .grid-row .grid-card .card.loading .image-container {
  border: none;
  border-radius: 5px;
  background: linear-gradient(110deg, rgba(1, 40, 108, 0) 40%, rgba(1, 40, 108, 0.5) 60%, rgba(1, 40, 108, 0) 70%) rgba(0, 75, 196, 0.2);
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-in-out infinite;
}
addiction-grid .grid-viewport .grid-row .grid-card .card.loading .description-container .name {
  width: 60%;
  height: 20px;
  border-radius: 3px;
  background: linear-gradient(110deg, rgba(1, 40, 108, 0) 40%, rgba(1, 40, 108, 0.5) 60%, rgba(1, 40, 108, 0) 70%) rgba(0, 75, 196, 0.2);
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-in-out infinite;
}
@media (hover: hover) and (pointer: fine) {
  addiction-grid .grid-viewport .grid-row .grid-card .card:hover .image-container img {
    opacity: 0.5;
  }
}
addiction-grid .grid-viewport .grid-row .grid-card .card .image-container {
  width: 100%;
  height: auto;
  aspect-ratio: 1;
  border: 1px solid #3d5d96;
  position: relative;
}
addiction-grid .grid-viewport .grid-row .grid-card .card .image-container img {
  width: 100%;
  height: 100%;
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-grid .grid-viewport .grid-row .grid-card .card .image-container .format {
  max-width: calc(100% - 40px);
  word-break: break-all;
  position: absolute;
  right: 5px;
  bottom: 5px;
  padding: 4px 5px;
  border-radius: 3px;
  background: #3d5d96;
  font-family: "Segma", sans-serif;
  font-size: 10px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 100%;
  letter-spacing: 0;
  cursor: default;
  cursor: pointer;
  text-transform: uppercase;
}
addiction-grid .grid-viewport .grid-row .grid-card .card .image-container mat-checkbox {
  position: absolute;
  left: 5px;
  bottom: 5px;
}
addiction-grid .grid-viewport .grid-row .grid-card .card .image-container mat-checkbox .mdc-form-field .mdc-checkbox {
  padding: 0;
}
addiction-grid .grid-viewport .grid-row .grid-card .card .image-container mat-checkbox .mdc-form-field .mdc-checkbox .mat-mdc-checkbox-touch-target {
  display: none;
}
addiction-grid .grid-viewport .grid-row .grid-card .card .image-container mat-checkbox .mdc-form-field .mdc-checkbox input {
  width: 18px;
  height: 18px;
}
addiction-grid .grid-viewport .grid-row .grid-card .card .image-container mat-checkbox .mdc-form-field .mdc-checkbox input:enabled:checked ~ .mdc-checkbox__background {
  border-color: unset;
  background-color: #004BC4 !important;
}
addiction-grid .grid-viewport .grid-row .grid-card .card .image-container mat-checkbox .mdc-form-field .mdc-checkbox input:enabled:indeterminate ~ .mdc-checkbox__background, addiction-grid .grid-viewport .grid-row .grid-card .card .image-container mat-checkbox .mdc-form-field .mdc-checkbox input.mdc-checkbox--selected ~ .mdc-checkbox__background {
  border-color: unset;
  background-color: #004BC4 !important;
}
addiction-grid .grid-viewport .grid-row .grid-card .card .image-container mat-checkbox .mdc-form-field .mdc-checkbox input:enabled:indeterminate ~ .mdc-checkbox__background .mdc-checkbox__mixedmark, addiction-grid .grid-viewport .grid-row .grid-card .card .image-container mat-checkbox .mdc-form-field .mdc-checkbox input.mdc-checkbox--selected ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
  transform: scale(0.5);
  border-color: #FFFFFF !important;
}
addiction-grid .grid-viewport .grid-row .grid-card .card .image-container mat-checkbox .mdc-form-field .mdc-checkbox .mdc-checkbox__ripple {
  display: none;
}
addiction-grid .grid-viewport .grid-row .grid-card .card .image-container mat-checkbox .mdc-form-field .mdc-checkbox .mdc-checkbox__background {
  width: 18px;
  height: 18px;
  top: unset;
  left: unset;
  border-radius: 5px;
  background-color: rgba(61, 93, 150, 0.5) !important;
  border: none !important;
}
addiction-grid .grid-viewport .grid-row .grid-card .card .image-container mat-checkbox .mdc-form-field .mdc-checkbox .mdc-checkbox__background svg {
  transform: scale(0.6);
}
addiction-grid .grid-viewport .grid-row .grid-card .card .image-container mat-checkbox .mdc-form-field .mdc-checkbox .mdc-checkbox__background .mdc-checkbox__mixedmark {
  transform: scale(0);
}
addiction-grid .grid-viewport .grid-row .grid-card .card .image-container mat-checkbox .mdc-form-field .mdc-checkbox .mat-mdc-checkbox-ripple {
  display: none;
}
addiction-grid .grid-viewport .grid-row .grid-card .card .image-container mat-checkbox .mdc-form-field .mdc-label {
  padding-left: 10px;
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 150%;
  letter-spacing: 0;
  cursor: default;
}
addiction-grid .grid-viewport .grid-row .grid-card .card .description-container {
  width: 100%;
  margin-top: 10px;
}
addiction-grid .grid-viewport .grid-row .grid-card .card .description-container .name {
  width: 100%;
  display: flex;
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 150%;
  letter-spacing: 0;
  cursor: default;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
addiction-grid .grid-viewport .buttons-container {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
addiction-grid .grid-viewport .buttons-container button.more {
  width: auto;
  padding: 5px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 130%;
  letter-spacing: 2.5px;
  cursor: default;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  min-height: 36px;
  background: #004BC4;
  border: none;
  border-radius: 10px;
}
addiction-grid .grid-viewport .buttons-container button.more span {
  width: 100%;
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 130%;
  letter-spacing: 2.5px;
  cursor: default;
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  margin-top: 3px;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-grid .grid-viewport .buttons-container button.more[aria-expanded=true] {
  border-radius: 0;
}
@media (hover: hover) and (pointer: fine) {
  addiction-grid .grid-viewport .buttons-container button.more:hover {
    background: #FFFFFF;
    color: #004BC4;
  }
  addiction-grid .grid-viewport .buttons-container button.more:hover span {
    color: #004BC4;
  }
}
addiction-grid .grid-viewport .buttons-container button.more:disabled, addiction-grid .grid-viewport .buttons-container button.more.disabled, addiction-grid .grid-viewport .buttons-container button.more[disabled], addiction-grid .grid-viewport .buttons-container button.more[disabled=true] {
  opacity: 0.25;
  cursor: not-allowed;
  pointer-events: none;
}
addiction-grid .grid-viewport .buttons-container button.more:disabled span, addiction-grid .grid-viewport .buttons-container button.more.disabled span, addiction-grid .grid-viewport .buttons-container button.more[disabled] span, addiction-grid .grid-viewport .buttons-container button.more[disabled=true] span {
  cursor: not-allowed;
  pointer-events: none;
}
addiction-grid .no-results-container {
  width: 100%;
  height: auto;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column;
}
addiction-grid .no-results-container.bigger ng-lottie {
  width: 400px;
  height: 400px;
  margin-bottom: 0;
}
addiction-grid .no-results-container ng-lottie {
  display: flex;
  width: 250px;
  height: 250px;
  object-fit: contain;
}
addiction-grid .no-results-container ng-lottie.arrow-top {
  transform: rotate(230deg);
}
addiction-grid .no-results-container ng-lottie.arrow-bottom {
  transform: rotate(50deg);
}
addiction-grid .no-results-container .title {
  width: 100%;
  margin-bottom: 10px;
  font-family: "Segma", sans-serif;
  font-size: 30px;
  font-weight: 300;
  color: #FFFFFF;
  line-height: 130%;
  letter-spacing: 0;
  cursor: default;
  text-align: center;
}
addiction-grid .no-results-container .text {
  width: 100%;
  font-family: "Segma", sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.65);
  line-height: 150%;
  letter-spacing: 0;
  cursor: default;
  text-align: center;
}
addiction-grid .no-results-container .text span {
  color: #09152C;
}

/* --- TYPOGRAPHY --- */
/* --- COLORS --- */
/* --- ANIMATIONS --- */
/* --- SPECIFIC MIXINS --- */
/* Stile per buttons che al click aprono un sottomenù */
/* --- TYPOGRAPHY --- */
/* --- COLORS --- */
/* --- ANIMATIONS --- */
/* --- STANDARD MIXINS --- */
/* --- FLEX --- */
/* --- FONTS --- */
/* Usato ad es. per scroll dentro select o popup con sfondo $brand-color-03 */
@keyframes loading {
  to {
    background-position-x: -20%;
  }
}
/*
NOTE BUTTONS FE 3.0 - DAM
I buttons con lo sfondo colorato, senza bordo e icona in mezzo (es. +) si fanno così:
@include button-style("e", $brand-color-04, $white, $buttonIcon: true, $buttonBackground: true);

I buttons senza sfondo, con bordo e icona in mezzo (es. permessi, back, navigazione, filtri) si fanno così:
@include button-style("e", $brand-color-04, $brand-color-04, $buttonIcon: true, $buttonBackground: false);

I buttons con lo sfondo colorato, senza bordo e testo in mezzo (es. salva, nuovo) si fanno così:
@include button-style(36px, $brand-color-04, $white, $buttonIcon: false, $buttonBackground: true);

I buttons senza sfondo, con bordo e testo in mezzo (es. aggiungi a collezione, collezioni) si fanno così:
@include button-style(36px, $brand-color-04, $brand-color-04, $buttonIcon: false, $buttonBackground: false);
*/
/* ADDICTION GRID OPTIONS */
addiction-grid-options {
  width: 20px;
}
addiction-grid-options .option-container {
  width: 25px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: none;
  padding: 0;
  outline: 0;
  border: none;
  position: relative;
}
addiction-grid-options .option-container::before {
  content: "";
  width: 100%;
  height: 10px;
  background: #01286C;
  position: absolute;
  bottom: -10px;
  opacity: 0;
}
addiction-grid-options .option-container::after {
  content: "H";
  width: 100%;
  height: 100%;
  font-family: "DataLean Font Icon";
  font-size: 14px;
  position: absolute;
  color: #004BC4;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
addiction-grid-options .option-container.mat-menu-below {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
addiction-grid-options .option-container.mat-menu-below::before {
  bottom: -10px;
}
addiction-grid-options .option-container.mat-menu-above {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
addiction-grid-options .option-container.mat-menu-above::before {
  top: -10px;
}
addiction-grid-options .option-container[aria-expanded=false] {
  box-shadow: none;
}
addiction-grid-options .option-container[aria-expanded=true] {
  background: #01286C;
}
addiction-grid-options .option-container[aria-expanded=true]::before {
  opacity: 1;
}
addiction-grid-options .option-container[aria-expanded=true]::after {
  color: #FFFFFF;
}
@media (hover: hover) and (pointer: fine) {
  addiction-grid-options .option-container:hover {
    opacity: 0.5;
  }
}
addiction-grid-options .option-container mat-icon {
  display: none;
}

/* --- TYPOGRAPHY --- */
/* --- COLORS --- */
/* --- ANIMATIONS --- */
/* --- SPECIFIC MIXINS --- */
/* Stile per buttons che al click aprono un sottomenù */
/* --- TYPOGRAPHY --- */
/* --- COLORS --- */
/* --- ANIMATIONS --- */
/* --- STANDARD MIXINS --- */
/* --- FLEX --- */
/* --- FONTS --- */
/* Usato ad es. per scroll dentro select o popup con sfondo $brand-color-03 */
@keyframes loading {
  to {
    background-position-x: -20%;
  }
}
/*
NOTE BUTTONS FE 3.0 - DAM
I buttons con lo sfondo colorato, senza bordo e icona in mezzo (es. +) si fanno così:
@include button-style("e", $brand-color-04, $white, $buttonIcon: true, $buttonBackground: true);

I buttons senza sfondo, con bordo e icona in mezzo (es. permessi, back, navigazione, filtri) si fanno così:
@include button-style("e", $brand-color-04, $brand-color-04, $buttonIcon: true, $buttonBackground: false);

I buttons con lo sfondo colorato, senza bordo e testo in mezzo (es. salva, nuovo) si fanno così:
@include button-style(36px, $brand-color-04, $white, $buttonIcon: false, $buttonBackground: true);

I buttons senza sfondo, con bordo e testo in mezzo (es. aggiungi a collezione, collezioni) si fanno così:
@include button-style(36px, $brand-color-04, $brand-color-04, $buttonIcon: false, $buttonBackground: false);
*/
addiction-html-editor {
  width: 100%;
  max-width: 100%;
}
addiction-html-editor form {
  width: 100%;
  display: flex;
  flex-flow: column;
}
addiction-html-editor form ngx-editor-menu {
  z-index: 10;
}
addiction-html-editor form ngx-editor-menu[ng-reflect-disabled=true] {
  cursor: not-allowed;
}
addiction-html-editor form ngx-editor-menu[ng-reflect-disabled=true] .NgxEditor__MenuBar {
  pointer-events: none;
}
addiction-html-editor form ngx-editor-menu[ng-reflect-disabled=true] + ngx-editor {
  opacity: 0.5;
  pointer-events: none;
}
addiction-html-editor form ngx-editor-menu .NgxEditor__MenuBar {
  height: auto;
  min-height: 50px;
  padding: 12px 10px;
  margin-top: 5px;
  background: #2C3446;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  column-gap: 5px;
  row-gap: 10px;
  border-radius: 5px;
}
addiction-html-editor form ngx-editor-menu .NgxEditor__MenuBar .NgxEditor__Seperator {
  display: none;
}
addiction-html-editor form ngx-editor-menu .NgxEditor__MenuBar ngx-toggle-command {
  cursor: pointer;
}
@media (hover: hover) and (pointer: fine) {
  addiction-html-editor form ngx-editor-menu .NgxEditor__MenuBar ngx-toggle-command:hover {
    background: rgba(255, 255, 255, 0);
  }
}
addiction-html-editor form ngx-editor-menu .NgxEditor__MenuBar ngx-toggle-command .NgxEditor__MenuItem--Active {
  background: rgba(255, 255, 255, 0.15);
  border-radius: 5px;
}
addiction-html-editor form ngx-editor-menu .NgxEditor__MenuBar ngx-dropdown {
  cursor: pointer;
}
@media (hover: hover) and (pointer: fine) {
  addiction-html-editor form ngx-editor-menu .NgxEditor__MenuBar ngx-dropdown:hover {
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.15);
  }
}
addiction-html-editor form ngx-editor-menu .NgxEditor__MenuBar ngx-dropdown.NgxEditor__Dropdown {
  height: 30px;
  cursor: pointer;
}
addiction-html-editor form ngx-editor-menu .NgxEditor__MenuBar ngx-dropdown.NgxEditor__Dropdown .NgxEditor__Dropdown--Text {
  font-size: 14px;
  padding: 0 10px;
  cursor: pointer;
}
addiction-html-editor form ngx-editor-menu .NgxEditor__MenuBar ngx-dropdown.NgxEditor__Dropdown .NgxEditor__Dropdown--Text.NgxEditor__Dropdown--Selected {
  background: rgba(255, 255, 255, 0);
  color: #FFFFFF;
}
addiction-html-editor form ngx-editor-menu .NgxEditor__MenuBar ngx-dropdown.NgxEditor__Dropdown .NgxEditor__Dropdown--Text::after {
  margin-left: 10px;
}
addiction-html-editor form ngx-editor-menu .NgxEditor__MenuBar ngx-dropdown .NgxEditor__Dropdown--DropdownMenu {
  background: #212d44;
  top: 34px;
}
addiction-html-editor form ngx-editor-menu .NgxEditor__MenuBar ngx-dropdown .NgxEditor__Dropdown--DropdownMenu .NgxEditor__Dropdown--Item {
  font-size: 14px;
  padding: 10px;
}
addiction-html-editor form ngx-editor-menu .NgxEditor__MenuBar ngx-dropdown .NgxEditor__Dropdown--DropdownMenu .NgxEditor__Dropdown--Item.NgxEditor__Dropdown--Active {
  background: rgba(255, 255, 255, 0.5);
  color: #212d44;
}
@media (hover: hover) and (pointer: fine) {
  addiction-html-editor form ngx-editor-menu .NgxEditor__MenuBar ngx-dropdown .NgxEditor__Dropdown--DropdownMenu .NgxEditor__Dropdown--Item:hover {
    color: #212d44;
  }
}
addiction-html-editor form ngx-editor-menu .NgxEditor__MenuBar ngx-link {
  cursor: pointer;
}
@media (hover: hover) and (pointer: fine) {
  addiction-html-editor form ngx-editor-menu .NgxEditor__MenuBar ngx-link:hover {
    background-color: rgba(255, 255, 255, 0);
  }
}
addiction-html-editor form ngx-editor-menu .NgxEditor__MenuBar ngx-link .NgxEditor__MenuItem--Active {
  background: rgba(255, 255, 255, 0.15);
  border-radius: 5px;
}
addiction-html-editor form ngx-editor-menu .NgxEditor__MenuBar ngx-link .NgxEditor__Popup {
  top: 34px;
  background: #212d44;
}
addiction-html-editor form ngx-editor-menu .NgxEditor__MenuBar ngx-link .NgxEditor__Popup .NgxEditor__Popup--Form .NgxEditor__Popup--FormGroup .NgxEditor__Popup--Col label {
  color: #FFFFFF;
  font-size: 12px;
  display: flex;
  align-items: center;
}
addiction-html-editor form ngx-editor-menu .NgxEditor__MenuBar ngx-link .NgxEditor__Popup .NgxEditor__Popup--Form .NgxEditor__Popup--FormGroup .NgxEditor__Popup--Col label > input {
  width: 18px;
  height: 18px;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  appearance: none;
  cursor: pointer;
  position: relative;
  background: rgba(255, 255, 255, 0);
  margin-right: 5px;
}
addiction-html-editor form ngx-editor-menu .NgxEditor__MenuBar ngx-link .NgxEditor__Popup .NgxEditor__Popup--Form .NgxEditor__Popup--FormGroup .NgxEditor__Popup--Col label > input:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
addiction-html-editor form ngx-editor-menu .NgxEditor__MenuBar ngx-link .NgxEditor__Popup .NgxEditor__Popup--Form .NgxEditor__Popup--FormGroup .NgxEditor__Popup--Col label > input:disabled::before {
  cursor: not-allowed;
}
addiction-html-editor form ngx-editor-menu .NgxEditor__MenuBar ngx-link .NgxEditor__Popup .NgxEditor__Popup--Form .NgxEditor__Popup--FormGroup .NgxEditor__Popup--Col label > input:disabled::after {
  cursor: not-allowed;
}
addiction-html-editor form ngx-editor-menu .NgxEditor__MenuBar ngx-link .NgxEditor__Popup .NgxEditor__Popup--Form .NgxEditor__Popup--FormGroup .NgxEditor__Popup--Col label > input:indeterminate::after {
  content: "";
  width: 1px;
  height: 4px;
  background: #FFFFFF;
  position: absolute;
  margin-bottom: 2px;
  transform: rotate(0);
  opacity: 1;
  z-index: 1;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-html-editor form ngx-editor-menu .NgxEditor__MenuBar ngx-link .NgxEditor__Popup .NgxEditor__Popup--Form .NgxEditor__Popup--FormGroup .NgxEditor__Popup--Col label > input::before {
  content: "";
  width: 18px;
  height: 18px;
  position: absolute;
  background: rgb(44, 53, 70);
  border-radius: 5px;
  z-index: 0;
  cursor: pointer;
}
addiction-html-editor form ngx-editor-menu .NgxEditor__MenuBar ngx-link .NgxEditor__Popup .NgxEditor__Popup--Form .NgxEditor__Popup--FormGroup .NgxEditor__Popup--Col label > input::after {
  content: "";
  width: 7px;
  height: 4px;
  position: absolute;
  border-bottom: 2px solid #FFFFFF;
  border-left: 2px solid #FFFFFF;
  margin-bottom: 2px;
  transform: rotate(-45deg);
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-html-editor form ngx-editor-menu .NgxEditor__MenuBar ngx-link .NgxEditor__Popup .NgxEditor__Popup--Form .NgxEditor__Popup--FormGroup .NgxEditor__Popup--Col label > input:checked::before {
  background: #004BC4;
}
addiction-html-editor form ngx-editor-menu .NgxEditor__MenuBar ngx-link .NgxEditor__Popup .NgxEditor__Popup--Form .NgxEditor__Popup--FormGroup .NgxEditor__Popup--Col label > input:checked::after {
  opacity: 1;
  background: rgba(255, 255, 255, 0);
}
addiction-html-editor form ngx-editor-menu .NgxEditor__MenuBar ngx-link .NgxEditor__Popup .NgxEditor__Popup--Form .NgxEditor__Popup--FormGroup .NgxEditor__Popup--Col > input {
  width: 100%;
  height: 30px;
  border: none;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.15);
  font-size: 14px;
  padding: 0 5px;
  margin-bottom: 5px;
}
addiction-html-editor form ngx-editor-menu .NgxEditor__MenuBar ngx-link .NgxEditor__Popup .NgxEditor__Popup--Form .NgxEditor__Popup--FormGroup .NgxEditor__Popup--Col .NgxEditor__HelpText {
  font-size: 12px;
}
addiction-html-editor form ngx-editor-menu .NgxEditor__MenuBar ngx-link .NgxEditor__Popup .NgxEditor__Popup--Form > button {
  color: #FFFFFF;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 5px;
  padding: 5px 10px;
  border: none;
  box-shadow: none;
  margin-top: 10px;
  appearance: none;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
@media (hover: hover) and (pointer: fine) {
  addiction-html-editor form ngx-editor-menu .NgxEditor__MenuBar ngx-link .NgxEditor__Popup .NgxEditor__Popup--Form > button:hover {
    opacity: 0.5;
  }
}
addiction-html-editor form ngx-editor-menu .NgxEditor__MenuBar ngx-color-picker {
  cursor: pointer;
}
@media (hover: hover) and (pointer: fine) {
  addiction-html-editor form ngx-editor-menu .NgxEditor__MenuBar ngx-color-picker:hover {
    background-color: rgba(255, 255, 255, 0);
  }
}
addiction-html-editor form ngx-editor-menu .NgxEditor__MenuBar ngx-color-picker .NgxEditor__MenuItem--Active {
  background: rgba(255, 255, 255, 0.15);
  border-radius: 5px;
}
addiction-html-editor form ngx-editor-menu .NgxEditor__MenuBar ngx-color-picker .NgxEditor__Popup {
  top: 34px;
  background: #212d44;
}
addiction-html-editor form ngx-editor-menu .NgxEditor__MenuBar ngx-color-picker .NgxEditor__Popup .NgxEditor__ColorContainer button {
  border: 1px solid #FFFFFF;
  margin: 0;
  padding: 0;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
@media (hover: hover) and (pointer: fine) {
  addiction-html-editor form ngx-editor-menu .NgxEditor__MenuBar ngx-color-picker .NgxEditor__Popup .NgxEditor__ColorContainer button:hover {
    border: 1px solid rgba(255, 255, 255, 0.15);
  }
}
addiction-html-editor form ngx-editor-menu .NgxEditor__MenuBar ngx-color-picker .NgxEditor__Popup .NgxEditor__MenuItem--Button {
  color: #FFFFFF;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 5px;
  padding: 5px 10px;
  border: none;
  box-shadow: none;
  margin-top: 10px;
  appearance: none;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
@media (hover: hover) and (pointer: fine) {
  addiction-html-editor form ngx-editor-menu .NgxEditor__MenuBar ngx-color-picker .NgxEditor__Popup .NgxEditor__MenuItem--Button:hover {
    opacity: 0.5;
  }
}
addiction-html-editor form ngx-editor-menu .NgxEditor__MenuBar .NgxEditor__MenuItem--Icon {
  cursor: pointer;
}
@media (hover: hover) and (pointer: fine) {
  addiction-html-editor form ngx-editor-menu .NgxEditor__MenuBar .NgxEditor__MenuItem--Icon:hover {
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.15);
  }
}
addiction-html-editor form ngx-editor {
  z-index: 0;
}
addiction-html-editor form ngx-editor[aria-disabled=true], addiction-html-editor form ngx-editor[aria-readonly=true] {
  cursor: not-allowed;
  opacity: 0.5;
}
addiction-html-editor form ngx-editor .NgxEditor {
  padding: 0;
  margin: -5px -1px 0 -1px;
  background: #1F2B43;
  border-radius: 0 0 5px 5px;
}
addiction-html-editor form ngx-editor .NgxEditor .NgxEditor__Content {
  max-height: 180px;
  margin-top: 20px;
  padding: 0 10px 10px 10px;
  font-family: "Segma", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 160%;
  letter-spacing: 0;
  cursor: default;
  cursor: text;
  overflow-y: auto;
}
addiction-html-editor form ngx-editor .NgxEditor .NgxEditor__Content::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
addiction-html-editor form ngx-editor .NgxEditor .NgxEditor__Content::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: none;
}
addiction-html-editor form ngx-editor .NgxEditor .NgxEditor__Content::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0);
}
addiction-html-editor form ngx-editor .NgxEditor .NgxEditor__Content a {
  color: #004BC4;
}
addiction-html-editor form ngx-editor .NgxEditor .NgxEditor__Content b,
addiction-html-editor form ngx-editor .NgxEditor .NgxEditor__Content strong {
  font-weight: 700;
}
addiction-html-editor form ngx-editor .NgxEditor .NgxEditor__Content em {
  font-style: italic;
}
addiction-html-editor form ngx-editor .NgxEditor .NgxEditor__Content u {
  text-decoration: underline;
}
addiction-html-editor form ngx-editor .NgxEditor .NgxEditor__Content s {
  text-decoration: line-through;
}
addiction-html-editor form ngx-editor .NgxEditor .NgxEditor__Content h1 {
  font-family: "Segma", sans-serif;
  font-size: 33px;
  font-weight: 700;
  color: #FFFFFF;
  line-height: 100%;
  letter-spacing: 1px;
  cursor: default;
  cursor: text;
  margin: 20px 0;
}
addiction-html-editor form ngx-editor .NgxEditor .NgxEditor__Content h2 {
  font-family: "Segma", sans-serif;
  font-size: 30px;
  font-weight: 700;
  color: #FFFFFF;
  line-height: 110%;
  letter-spacing: 1px;
  cursor: default;
  cursor: text;
  margin: 20px 0;
}
addiction-html-editor form ngx-editor .NgxEditor .NgxEditor__Content h3 {
  font-family: "Segma", sans-serif;
  font-size: 27px;
  font-weight: 700;
  color: #FFFFFF;
  line-height: 120%;
  letter-spacing: 1px;
  cursor: default;
  cursor: text;
  margin: 20px 0;
}
addiction-html-editor form ngx-editor .NgxEditor .NgxEditor__Content h4 {
  font-family: "Segma", sans-serif;
  font-size: 24px;
  font-weight: 700;
  color: #FFFFFF;
  line-height: 130%;
  letter-spacing: 1px;
  cursor: default;
  cursor: text;
  margin: 20px 0;
}
addiction-html-editor form ngx-editor .NgxEditor .NgxEditor__Content h5 {
  font-family: "Segma", sans-serif;
  font-size: 21px;
  font-weight: 700;
  color: #FFFFFF;
  line-height: 140%;
  letter-spacing: 1px;
  cursor: default;
  cursor: text;
  margin: 20px 0;
}
addiction-html-editor form ngx-editor .NgxEditor .NgxEditor__Content h6 {
  font-family: "Segma", sans-serif;
  font-size: 18px;
  font-weight: 700;
  color: #FFFFFF;
  line-height: 150%;
  letter-spacing: 1px;
  cursor: default;
  cursor: text;
  margin: 20px 0;
}

/* --- TYPOGRAPHY --- */
/* --- COLORS --- */
/* --- ANIMATIONS --- */
/* --- STANDARD MIXINS --- */
/* --- FLEX --- */
/* --- FONTS --- */
/* Usato ad es. per scroll dentro select o popup con sfondo $brand-color-03 */
@keyframes loading {
  to {
    background-position-x: -20%;
  }
}
/*
NOTE BUTTONS FE 3.0 - DAM
I buttons con lo sfondo colorato, senza bordo e icona in mezzo (es. +) si fanno così:
@include button-style("e", $brand-color-04, $white, $buttonIcon: true, $buttonBackground: true);

I buttons senza sfondo, con bordo e icona in mezzo (es. permessi, back, navigazione, filtri) si fanno così:
@include button-style("e", $brand-color-04, $brand-color-04, $buttonIcon: true, $buttonBackground: false);

I buttons con lo sfondo colorato, senza bordo e testo in mezzo (es. salva, nuovo) si fanno così:
@include button-style(36px, $brand-color-04, $white, $buttonIcon: false, $buttonBackground: true);

I buttons senza sfondo, con bordo e testo in mezzo (es. aggiungi a collezione, collezioni) si fanno così:
@include button-style(36px, $brand-color-04, $brand-color-04, $buttonIcon: false, $buttonBackground: false);
*/
/* ADDICTION INPUT */
addiction-input {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
addiction-input .invalid-input {
  border: 1px solid red !important;
}
addiction-input .invalid-input-message {
  color: red;
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: red;
  line-height: 100%;
  letter-spacing: 2.5px;
  cursor: default;
}
addiction-input .form-field {
  width: 100%;
}
addiction-input .form-field.disabled .form-input-container.number::before, addiction-input .form-field.disabled .form-input-container.number::after, addiction-input .form-field.readonly .form-input-container.number::before, addiction-input .form-field.readonly .form-input-container.number::after {
  opacity: 0.5;
}
addiction-input .form-field label {
  width: 100%;
  display: flex;
  font-family: "Segma", sans-serif;
  font-size: 10px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
  line-height: 100%;
  letter-spacing: 2.5px;
  cursor: default;
  text-transform: uppercase;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
addiction-input .form-field label .tooltip {
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  position: relative;
  cursor: pointer;
}
addiction-input .form-field label .tooltip::before {
  content: "F";
  font-family: "DataLean Font Icon";
  font-size: 13px;
  color: #FFFFFF;
  position: absolute;
  pointer-events: none;
}
addiction-input .form-field .form-input-container {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}
addiction-input .form-field .form-input-container.number::before {
  content: "b";
  width: 16px;
  height: 15px;
  color: #FFFFFF;
  font-size: 7px;
  font-family: "DataLean Font Icon";
  background: #1F2B43;
  position: absolute;
  right: 10px;
  top: 5px;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scaleY(-1);
  pointer-events: none;
  z-index: 1;
}
addiction-input .form-field .form-input-container.number::after {
  content: "b";
  width: 16px;
  height: 15px;
  color: #FFFFFF;
  font-size: 7px;
  font-family: "DataLean Font Icon";
  background: #1F2B43;
  position: absolute;
  right: 10px;
  bottom: 5px;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  z-index: 1;
}
addiction-input .form-field .form-input-container input {
  width: 100%;
  height: 36px;
  font-family: "Segma", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 100%;
  letter-spacing: 0;
  cursor: default;
  cursor: text;
  padding: 5px 10px;
  background: rgba(255, 255, 255, 0.05);
  border: none;
  margin: 0;
  border-radius: 5px;
}
addiction-input .form-field .form-input-container input::placeholder {
  font-family: "Segma", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
  line-height: 100%;
  letter-spacing: 0;
  cursor: default;
}
addiction-input .form-field .form-input-container input:disabled, addiction-input .form-field .form-input-container input:read-only {
  cursor: not-allowed;
  opacity: 0.5;
}
addiction-input .form-field .form-input-container input[type=number]::-webkit-textfield-decoration-container, addiction-input .form-field .form-input-container input[type=number]::-webkit-inner-spin-button {
  cursor: pointer;
}
addiction-input .form-field .form-input-container mat-icon.append-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  position: absolute;
  right: 5px;
  cursor: pointer;
}
@media (hover: hover) and (pointer: fine) {
  addiction-input .form-field .form-input-container mat-icon.append-icon:hover {
    opacity: 0.5;
  }
}

/* --- TYPOGRAPHY --- */
/* --- COLORS --- */
/* --- ANIMATIONS --- */
/* --- SPECIFIC MIXINS --- */
/* Stile per buttons che al click aprono un sottomenù */
/* --- TYPOGRAPHY --- */
/* --- COLORS --- */
/* --- ANIMATIONS --- */
/* --- STANDARD MIXINS --- */
/* --- FLEX --- */
/* --- FONTS --- */
/* Usato ad es. per scroll dentro select o popup con sfondo $brand-color-03 */
@keyframes loading {
  to {
    background-position-x: -20%;
  }
}
/*
NOTE BUTTONS FE 3.0 - DAM
I buttons con lo sfondo colorato, senza bordo e icona in mezzo (es. +) si fanno così:
@include button-style("e", $brand-color-04, $white, $buttonIcon: true, $buttonBackground: true);

I buttons senza sfondo, con bordo e icona in mezzo (es. permessi, back, navigazione, filtri) si fanno così:
@include button-style("e", $brand-color-04, $brand-color-04, $buttonIcon: true, $buttonBackground: false);

I buttons con lo sfondo colorato, senza bordo e testo in mezzo (es. salva, nuovo) si fanno così:
@include button-style(36px, $brand-color-04, $white, $buttonIcon: false, $buttonBackground: true);

I buttons senza sfondo, con bordo e testo in mezzo (es. aggiungi a collezione, collezioni) si fanno così:
@include button-style(36px, $brand-color-04, $brand-color-04, $buttonIcon: false, $buttonBackground: false);
*/
/* ADDICTION LIST */
addiction-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
}
addiction-list.show-bulk-actions {
  height: calc(100% - 70px);
}
addiction-list .header-grid-container {
  /* uguale ad addiction-grid.scss */
  width: 100%;
  min-height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
  position: relative;
}
addiction-list .header-grid-container.expanded {
  margin-bottom: 70px;
}
addiction-list .header-grid-container.dam .header-grid .left-container .title h1 {
  font-family: "Segma", sans-serif;
  font-size: 40px;
  font-weight: 300;
  color: #FFFFFF;
  line-height: 100%;
  letter-spacing: 0;
  cursor: default;
}
addiction-list .header-grid-container .header-grid {
  width: 100%;
  height: 70px;
  padding: 10px 20px;
  display: flex;
}
addiction-list .header-grid-container .header-grid .left-container {
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: auto;
}
addiction-list .header-grid-container .header-grid .left-container .checkbox-container {
  width: 30px;
}
addiction-list .header-grid-container .header-grid .left-container .checkbox-container mat-checkbox {
  margin: 0 10px 0 0;
}
addiction-list .header-grid-container .header-grid .left-container .checkbox-container mat-checkbox .mdc-form-field .mdc-checkbox {
  padding: 0;
}
addiction-list .header-grid-container .header-grid .left-container .checkbox-container mat-checkbox .mdc-form-field .mdc-checkbox .mat-mdc-checkbox-touch-target {
  display: none;
}
addiction-list .header-grid-container .header-grid .left-container .checkbox-container mat-checkbox .mdc-form-field .mdc-checkbox input {
  width: 18px;
  height: 18px;
}
addiction-list .header-grid-container .header-grid .left-container .checkbox-container mat-checkbox .mdc-form-field .mdc-checkbox input:enabled:checked ~ .mdc-checkbox__background {
  border-color: unset;
  background-color: #004BC4 !important;
}
addiction-list .header-grid-container .header-grid .left-container .checkbox-container mat-checkbox .mdc-form-field .mdc-checkbox input:enabled:indeterminate ~ .mdc-checkbox__background, addiction-list .header-grid-container .header-grid .left-container .checkbox-container mat-checkbox .mdc-form-field .mdc-checkbox input.mdc-checkbox--selected ~ .mdc-checkbox__background {
  border-color: unset;
  background-color: #004BC4 !important;
}
addiction-list .header-grid-container .header-grid .left-container .checkbox-container mat-checkbox .mdc-form-field .mdc-checkbox input:enabled:indeterminate ~ .mdc-checkbox__background .mdc-checkbox__mixedmark, addiction-list .header-grid-container .header-grid .left-container .checkbox-container mat-checkbox .mdc-form-field .mdc-checkbox input.mdc-checkbox--selected ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
  transform: scale(0.5);
  border-color: #FFFFFF !important;
}
addiction-list .header-grid-container .header-grid .left-container .checkbox-container mat-checkbox .mdc-form-field .mdc-checkbox .mdc-checkbox__ripple {
  display: none;
}
addiction-list .header-grid-container .header-grid .left-container .checkbox-container mat-checkbox .mdc-form-field .mdc-checkbox .mdc-checkbox__background {
  width: 18px;
  height: 18px;
  top: unset;
  left: unset;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.1) !important;
  border: none !important;
}
addiction-list .header-grid-container .header-grid .left-container .checkbox-container mat-checkbox .mdc-form-field .mdc-checkbox .mdc-checkbox__background svg {
  transform: scale(0.6);
}
addiction-list .header-grid-container .header-grid .left-container .checkbox-container mat-checkbox .mdc-form-field .mdc-checkbox .mdc-checkbox__background .mdc-checkbox__mixedmark {
  transform: scale(0);
}
addiction-list .header-grid-container .header-grid .left-container .checkbox-container mat-checkbox .mdc-form-field .mdc-checkbox .mat-mdc-checkbox-ripple {
  display: none;
}
addiction-list .header-grid-container .header-grid .left-container .checkbox-container mat-checkbox .mdc-form-field .mdc-label {
  padding-left: 10px;
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 150%;
  letter-spacing: 0;
  cursor: default;
}
addiction-list .header-grid-container .header-grid .left-container .title {
  width: calc(100% - 30px);
}
addiction-list .header-grid-container .header-grid .left-container .title h1 {
  font-family: "Segma", sans-serif;
  font-size: 20px;
  font-weight: 300;
  color: #FFFFFF;
  line-height: 150%;
  letter-spacing: 0;
  cursor: default;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
}
addiction-list .header-grid-container .header-grid .right-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
addiction-list .header-grid-container .header-grid .right-container .view-mode-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
addiction-list .header-grid-container .header-grid .right-container .view-mode-container .view-button {
  width: 20px;
  height: 36px;
  background: rgba(255, 255, 255, 0);
  padding: 0;
  border: none;
  border-radius: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  margin-left: 20px;
}
@media (hover: hover) and (pointer: fine) {
  addiction-list .header-grid-container .header-grid .right-container .view-mode-container .view-button:hover {
    opacity: 0.5;
  }
}
addiction-list .header-grid-container .header-grid .right-container .view-mode-container .view-button::before {
  content: "v";
  width: 16px;
  height: 16px;
  color: #FFFFFF;
  font-size: 16px;
  font-family: "DataLean Font Icon";
  position: absolute;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}
addiction-list .header-grid-container .header-grid .right-container .view-mode-container .view-button.active::before {
  color: #0061FF;
}
@media (hover: hover) and (pointer: fine) {
  addiction-list .header-grid-container .header-grid .right-container .view-mode-container .view-button.active:hover {
    opacity: 1;
  }
}
addiction-list .header-grid-container .header-grid .right-container .view-mode-container .view-button.list-view::before {
  content: "A";
}
addiction-list .header-grid-container .header-grid .right-container .view-mode-container .view-button.grid-view::before {
  content: "z";
}
addiction-list .header-grid-container .header-grid .right-container addiction-search {
  /* stile dentro addiction-components.scss */
  margin-left: 20px;
}
addiction-list .header-grid-container .header-grid .right-container button.filters-button {
  box-shadow: none;
  border-radius: 3px;
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: initial;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  width: 34px;
  height: 34px;
  background: rgba(255, 255, 255, 0);
  border: 2px solid #FFFFFF;
  margin-left: 20px;
}
addiction-list .header-grid-container .header-grid .right-container button.filters-button[aria-expanded=true] {
  border-radius: 0;
}
@media (hover: hover) and (pointer: fine) {
  addiction-list .header-grid-container .header-grid .right-container button.filters-button:hover {
    opacity: 0.5;
  }
}
addiction-list .header-grid-container .header-grid .right-container button.filters-button::before {
  content: "y";
  width: 16px;
  height: 16px;
  font-size: 16px;
  color: #FFFFFF;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 100%;
  font-family: "DataLean Font Icon";
  position: absolute;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-list .header-grid-container .header-grid .right-container button.filters-button:disabled, addiction-list .header-grid-container .header-grid .right-container button.filters-button.disabled, addiction-list .header-grid-container .header-grid .right-container button.filters-button[disabled], addiction-list .header-grid-container .header-grid .right-container button.filters-button[disabled=true] {
  opacity: 0.25;
  cursor: not-allowed;
  pointer-events: none;
}
addiction-list .header-grid-container .header-grid .right-container button.filters-button:disabled span, addiction-list .header-grid-container .header-grid .right-container button.filters-button.disabled span, addiction-list .header-grid-container .header-grid .right-container button.filters-button[disabled] span, addiction-list .header-grid-container .header-grid .right-container button.filters-button[disabled=true] span {
  cursor: not-allowed;
  pointer-events: none;
}
addiction-list .header-grid-container .header-grid .right-container button.filters-button::after {
  content: "";
  width: calc(100% + 4px);
  height: 10px;
  background: #01286C;
  position: absolute;
  bottom: -10px;
  left: -2px;
  opacity: 0;
}
addiction-list .header-grid-container .header-grid .right-container button.filters-button[aria-expanded=false] {
  box-shadow: none;
}
addiction-list .header-grid-container .header-grid .right-container button.filters-button[aria-expanded=true] {
  background: #01286C;
  border: 2px solid #01286C;
}
addiction-list .header-grid-container .header-grid .right-container button.filters-button[aria-expanded=true]::after {
  opacity: 1;
}
addiction-list .header-grid-container .header-grid .right-container button.filters-button[aria-expanded=true].mat-menu-below {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
addiction-list .header-grid-container .header-grid .right-container button.filters-button[aria-expanded=true].mat-menu-below::after {
  bottom: -10px;
}
addiction-list .header-grid-container .header-grid .right-container button.filters-button[aria-expanded=true].mat-menu-above {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
addiction-list .header-grid-container .header-grid .right-container button.filters-button[aria-expanded=true].mat-menu-above::after {
  top: -10px;
}
addiction-list .header-grid-container .header-grid .right-container button.import-export {
  box-shadow: none;
  border-radius: 3px;
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: initial;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  width: 34px;
  height: 34px;
  background: rgba(255, 255, 255, 0);
  border: 2px solid #FFFFFF;
  margin-left: 20px;
}
addiction-list .header-grid-container .header-grid .right-container button.import-export[aria-expanded=true] {
  border-radius: 0;
}
@media (hover: hover) and (pointer: fine) {
  addiction-list .header-grid-container .header-grid .right-container button.import-export:hover {
    opacity: 0.5;
  }
}
addiction-list .header-grid-container .header-grid .right-container button.import-export::before {
  content: "l";
  width: 16px;
  height: 16px;
  font-size: 16px;
  color: #FFFFFF;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 100%;
  font-family: "DataLean Font Icon";
  position: absolute;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-list .header-grid-container .header-grid .right-container button.import-export:disabled, addiction-list .header-grid-container .header-grid .right-container button.import-export.disabled, addiction-list .header-grid-container .header-grid .right-container button.import-export[disabled], addiction-list .header-grid-container .header-grid .right-container button.import-export[disabled=true] {
  opacity: 0.25;
  cursor: not-allowed;
  pointer-events: none;
}
addiction-list .header-grid-container .header-grid .right-container button.import-export:disabled span, addiction-list .header-grid-container .header-grid .right-container button.import-export.disabled span, addiction-list .header-grid-container .header-grid .right-container button.import-export[disabled] span, addiction-list .header-grid-container .header-grid .right-container button.import-export[disabled=true] span {
  cursor: not-allowed;
  pointer-events: none;
}
addiction-list .header-grid-container .header-grid .right-container button.new {
  width: auto;
  padding: 5px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 130%;
  letter-spacing: 2.5px;
  cursor: default;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  min-height: 36px;
  background: #004BC4;
  border: none;
  border-radius: 10px;
  margin-left: 20px;
}
addiction-list .header-grid-container .header-grid .right-container button.new span {
  width: 100%;
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 130%;
  letter-spacing: 2.5px;
  cursor: default;
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  margin-top: 3px;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-list .header-grid-container .header-grid .right-container button.new[aria-expanded=true] {
  border-radius: 0;
}
@media (hover: hover) and (pointer: fine) {
  addiction-list .header-grid-container .header-grid .right-container button.new:hover {
    background: #FFFFFF;
    color: #004BC4;
  }
  addiction-list .header-grid-container .header-grid .right-container button.new:hover span {
    color: #004BC4;
  }
}
addiction-list .header-grid-container .header-grid .right-container button.new:disabled, addiction-list .header-grid-container .header-grid .right-container button.new.disabled, addiction-list .header-grid-container .header-grid .right-container button.new[disabled], addiction-list .header-grid-container .header-grid .right-container button.new[disabled=true] {
  opacity: 0.25;
  cursor: not-allowed;
  pointer-events: none;
}
addiction-list .header-grid-container .header-grid .right-container button.new:disabled span, addiction-list .header-grid-container .header-grid .right-container button.new.disabled span, addiction-list .header-grid-container .header-grid .right-container button.new[disabled] span, addiction-list .header-grid-container .header-grid .right-container button.new[disabled=true] span {
  cursor: not-allowed;
  pointer-events: none;
}
addiction-list .header-grid-container .header-grid .right-container .elements {
  display: flex;
  justify-content: flex-end;
}
addiction-list .header-grid-container .header-grid .right-container .elements .number {
  font-family: "Segma", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 100%;
  letter-spacing: 1px;
  cursor: default;
  margin-right: 5px;
}
addiction-list .header-grid-container .header-grid .right-container .elements .text {
  font-family: "Segma", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 100%;
  letter-spacing: 1px;
  cursor: default;
}
addiction-list .header-grid-container addiction-bulk-action-bar {
  /* stile dentro addiction-components.scss */
}
addiction-list .single-row-header {
  height: 56px;
  min-height: 56px;
  width: calc(100% - 40px);
  display: flex;
  align-items: center;
  column-gap: 10px;
  margin: 0 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
addiction-list .single-row-header.hidden {
  display: none;
}
addiction-list .single-row-header.mat-sort .single-column span {
  cursor: pointer;
}
addiction-list .single-row-header mat-checkbox {
  width: 20px;
}
addiction-list .single-row-header mat-checkbox .mdc-form-field .mdc-checkbox {
  padding: 0;
}
addiction-list .single-row-header mat-checkbox .mdc-form-field .mdc-checkbox .mat-mdc-checkbox-touch-target {
  display: none;
}
addiction-list .single-row-header mat-checkbox .mdc-form-field .mdc-checkbox input {
  width: 18px;
  height: 18px;
}
addiction-list .single-row-header mat-checkbox .mdc-form-field .mdc-checkbox input:enabled:checked ~ .mdc-checkbox__background {
  border-color: unset;
  background-color: #004BC4 !important;
}
addiction-list .single-row-header mat-checkbox .mdc-form-field .mdc-checkbox input:enabled:indeterminate ~ .mdc-checkbox__background, addiction-list .single-row-header mat-checkbox .mdc-form-field .mdc-checkbox input.mdc-checkbox--selected ~ .mdc-checkbox__background {
  border-color: unset;
  background-color: #004BC4 !important;
}
addiction-list .single-row-header mat-checkbox .mdc-form-field .mdc-checkbox input:enabled:indeterminate ~ .mdc-checkbox__background .mdc-checkbox__mixedmark, addiction-list .single-row-header mat-checkbox .mdc-form-field .mdc-checkbox input.mdc-checkbox--selected ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
  transform: scale(0.5);
  border-color: #FFFFFF !important;
}
addiction-list .single-row-header mat-checkbox .mdc-form-field .mdc-checkbox .mdc-checkbox__ripple {
  display: none;
}
addiction-list .single-row-header mat-checkbox .mdc-form-field .mdc-checkbox .mdc-checkbox__background {
  width: 18px;
  height: 18px;
  top: unset;
  left: unset;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.1) !important;
  border: none !important;
}
addiction-list .single-row-header mat-checkbox .mdc-form-field .mdc-checkbox .mdc-checkbox__background svg {
  transform: scale(0.6);
}
addiction-list .single-row-header mat-checkbox .mdc-form-field .mdc-checkbox .mdc-checkbox__background .mdc-checkbox__mixedmark {
  transform: scale(0);
}
addiction-list .single-row-header mat-checkbox .mdc-form-field .mdc-checkbox .mat-mdc-checkbox-ripple {
  display: none;
}
addiction-list .single-row-header mat-checkbox .mdc-form-field .mdc-label {
  padding-left: 10px;
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 150%;
  letter-spacing: 0;
  cursor: default;
}
addiction-list .single-row-header .single-column {
  display: flex;
  overflow: hidden;
}
addiction-list .single-row-header .single-column.checkbox {
  width: 20px;
}
addiction-list .single-row-header .single-column.image {
  width: 60px;
}
addiction-list .single-row-header .single-column.single {
  flex: 1;
}
addiction-list .single-row-header .single-column.double {
  flex: 2;
}
addiction-list .single-row-header .single-column.triple {
  flex: 3;
}
addiction-list .single-row-header .single-column.arrow {
  width: 30px;
}
addiction-list .single-row-header .single-column.options {
  width: 30px;
  justify-content: center;
}
addiction-list .single-row-header .single-column.icon {
  width: 60px;
}
addiction-list .single-row-header .single-column.position {
  width: 30px;
}
addiction-list .single-row-header .single-column.delete {
  width: 30px;
}
addiction-list .single-row-header .single-column.reverse {
  justify-content: flex-end;
}
addiction-list .single-row-header .single-column.reverse span {
  text-align: right;
}
addiction-list .single-row-header .single-column.center {
  justify-content: center;
}
addiction-list .single-row-header .single-column.center span {
  text-align: center;
}
addiction-list .single-row-header .single-column span {
  width: 100%;
  font-family: "Segma", sans-serif;
  font-size: 10px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
  line-height: 100%;
  letter-spacing: 0.5px;
  cursor: default;
  text-transform: uppercase;
  text-align: left;
  word-break: break-all;
}
addiction-list .list-viewport {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}
addiction-list .list-viewport::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
addiction-list .list-viewport::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: none;
}
addiction-list .list-viewport::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0);
}
addiction-list .list-viewport.with-header {
  height: calc(100% - 70px);
}
addiction-list .list-viewport.with-header.with-header-template {
  height: calc(100% - 120px);
}
addiction-list .list-viewport.with-header-template {
  height: calc(100% - 50px);
}
addiction-list .list-viewport.hidden {
  height: 0;
}
addiction-list .list-viewport * {
  transition: none;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper {
  display: flex;
  flex-flow: column;
  width: 100%;
  max-width: 100%;
  padding: 0 20px;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row {
  display: flex;
  justify-content: center;
  flex-flow: column;
  width: 100%;
  height: auto;
  column-gap: 20px;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row {
  min-height: 56px;
  height: 100%;
  display: flex;
  align-items: center;
  column-gap: 10px;
  cursor: pointer;
  padding: 10px 0;
  position: relative;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
@media (hover: hover) and (pointer: fine) {
  addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row:hover .single-column.image img {
    opacity: 0.5;
  }
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row mat-checkbox {
  width: 20px;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row mat-checkbox .mdc-form-field .mdc-checkbox {
  padding: 0;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row mat-checkbox .mdc-form-field .mdc-checkbox .mat-mdc-checkbox-touch-target {
  display: none;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row mat-checkbox .mdc-form-field .mdc-checkbox input {
  width: 18px;
  height: 18px;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row mat-checkbox .mdc-form-field .mdc-checkbox input:enabled:checked ~ .mdc-checkbox__background {
  border-color: unset;
  background-color: #004BC4 !important;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row mat-checkbox .mdc-form-field .mdc-checkbox input:enabled:indeterminate ~ .mdc-checkbox__background, addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row mat-checkbox .mdc-form-field .mdc-checkbox input.mdc-checkbox--selected ~ .mdc-checkbox__background {
  border-color: unset;
  background-color: #004BC4 !important;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row mat-checkbox .mdc-form-field .mdc-checkbox input:enabled:indeterminate ~ .mdc-checkbox__background .mdc-checkbox__mixedmark, addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row mat-checkbox .mdc-form-field .mdc-checkbox input.mdc-checkbox--selected ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
  transform: scale(0.5);
  border-color: #FFFFFF !important;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row mat-checkbox .mdc-form-field .mdc-checkbox .mdc-checkbox__ripple {
  display: none;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row mat-checkbox .mdc-form-field .mdc-checkbox .mdc-checkbox__background {
  width: 18px;
  height: 18px;
  top: unset;
  left: unset;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.1) !important;
  border: none !important;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row mat-checkbox .mdc-form-field .mdc-checkbox .mdc-checkbox__background svg {
  transform: scale(0.6);
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row mat-checkbox .mdc-form-field .mdc-checkbox .mdc-checkbox__background .mdc-checkbox__mixedmark {
  transform: scale(0);
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row mat-checkbox .mdc-form-field .mdc-checkbox .mat-mdc-checkbox-ripple {
  display: none;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row mat-checkbox .mdc-form-field .mdc-label {
  padding-left: 10px;
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 150%;
  letter-spacing: 0;
  cursor: default;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6);
  line-height: 150%;
  letter-spacing: 0;
  cursor: default;
  text-align: left;
  cursor: pointer;
  overflow: hidden;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column.checkbox {
  width: 20px;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column.image {
  width: 60px;
  height: 60px;
  border: 1px solid #3d5d96;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column.image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column.icon {
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
@media (hover: hover) and (pointer: fine) {
  addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column.icon:hover {
    opacity: 0.5;
  }
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column.icon span {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column.icon span::before {
  width: 20px;
  height: 20px;
  font-size: 12px;
  font-family: "DataLean Font Icon";
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column.icon span.user-detail::before {
  content: "M";
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column.icon span.challenge-detail::before {
  content: "N";
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column.icon span.wrong::before {
  content: "d";
  color: #FF4848;
  box-shadow: 0 0 10px #FF4848;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column.icon span.right::before {
  content: "c";
  color: #42BD41;
  box-shadow: 0 0 10px #42BD41;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column.icon span.liferay, addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column.icon span.wordpress, addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column.icon span.catalean, addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column.icon span.smartCo {
  width: 34px;
  height: 34px;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column.icon span.liferay::before, addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column.icon span.wordpress::before, addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column.icon span.catalean::before, addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column.icon span.smartCo::before {
  width: 34px;
  height: 34px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column.icon span.liferay::before {
  content: "";
  background-image: url("../../../assets/images/integrations/integration-liferay.png");
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column.icon span.wordpress::before {
  content: "";
  background-image: url("../../../assets/images/integrations/integration-wordpress.png");
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column.icon span.catalean::before {
  content: "";
  background-image: url("../../../assets/images/integrations/integration-catalean.png");
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column.icon span.smartCo::before {
  content: "";
  background-image: url("../../../assets/images/integrations/integration-smartco.png");
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column.buttons-actions {
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column.buttons-actions button {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  border: none;
  background: rgba(255, 255, 255, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
@media (hover: hover) and (pointer: fine) {
  addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column.buttons-actions button:hover {
    opacity: 0.5;
  }
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column.buttons-actions button::before {
  width: 20px;
  height: 20px;
  font-family: "DataLean Font Icon";
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column.buttons-actions button.invalid::before {
  content: "d";
  font-size: 12px;
  color: #A20000;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column.buttons-actions button.valid::before {
  content: "c";
  font-size: 15px;
  color: #4F8A10;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column.features > span {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  box-orient: vertical;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 54px;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column.single {
  flex: 1;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column.double {
  flex: 2;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column.triple {
  flex: 3;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column.position {
  width: 30px;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column.position span {
  font-family: "Segma", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 100%;
  letter-spacing: 0;
  cursor: default;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column.reverse {
  justify-content: flex-end;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column.reverse span {
  text-align: right;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column.center {
  justify-content: center;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column.center span {
  text-align: center;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column span {
  width: 100%;
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6);
  line-height: 150%;
  letter-spacing: 0;
  cursor: default;
  text-align: left;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column span.highlight {
  font-family: "Segma", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 150%;
  letter-spacing: 0;
  cursor: default;
  cursor: pointer;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column span.format {
  width: auto;
  font-family: "Segma", sans-serif;
  font-size: 10px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 100%;
  letter-spacing: 0;
  cursor: default;
  cursor: pointer;
  text-transform: uppercase;
  background: #3d5d96;
  border-radius: 3px;
  padding: 4px 5px;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column span.status {
  padding-left: 16px;
  position: relative;
  display: flex;
  align-items: center;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column span.status::before {
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background: #FFFF00;
  position: absolute;
  left: 1px;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column span.status.CONFIRMED::before, addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column span.status.PUBLISHED::before, addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column span.status.ENABLED::before, addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column span.status.ACTIVE::before, addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column span.status.VALID::before {
  background: #42BD41;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column span.status.DECLINED::before, addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column span.status.ARCHIVED::before, addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column span.status.DISABLED::before, addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column span.status.INACTIVE::before, addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column span.status.NOT_VALID::before {
  background: #FF4848;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column span.status.correct, addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column span.status.right, addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column span.status.partial, addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column span.status.wrong {
  width: 30px;
  height: 30px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column span.status.correct::before, addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column span.status.right::before, addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column span.status.partial::before, addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column span.status.wrong::before {
  content: "f";
  width: 18px;
  height: 18px;
  font-size: 17px;
  font-family: "DataLean Font Icon";
  color: #FFFF00;
  text-shadow: 0 0 5px #FFFF00;
  border-radius: 0;
  background: var(--transparent);
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column span.status.correct::before, addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column span.status.right::before {
  content: "c";
  font-size: 18px;
  color: #42BD41;
  text-shadow: 0 0 5px #42BD41;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column span.status.wrong::before {
  content: "d";
  font-size: 16px;
  color: #FF4848;
  text-shadow: 0 0 5px #FF4848;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column.arrow {
  width: 30px;
  height: 10px;
  justify-content: center;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column.arrow::before {
  content: "b";
  width: 10px;
  height: 10px;
  font-family: "DataLean Font Icon";
  color: #FFFFFF;
  font-size: 6px;
  background: rgba(255, 255, 255, 0);
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  transform: rotate(-90deg);
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column.options {
  width: 30px;
  justify-content: center;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column.delete {
  width: 30px;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column.delete button.delete {
  box-shadow: none;
  border-radius: 3px;
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: initial;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  width: 34px;
  height: 34px;
  background: rgba(255, 255, 255, 0);
  border: 2px solid #FF4848;
  border: none;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column.delete button.delete[aria-expanded=true] {
  border-radius: 0;
}
@media (hover: hover) and (pointer: fine) {
  addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column.delete button.delete:hover {
    opacity: 0.5;
  }
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column.delete button.delete::before {
  content: "i";
  width: 16px;
  height: 16px;
  font-size: 16px;
  color: #FF4848;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 100%;
  font-family: "DataLean Font Icon";
  position: absolute;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column.delete button.delete:disabled, addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column.delete button.delete.disabled, addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column.delete button.delete[disabled], addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column.delete button.delete[disabled=true] {
  opacity: 0.25;
  cursor: not-allowed;
  pointer-events: none;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column.delete button.delete:disabled span, addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column.delete button.delete.disabled span, addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column.delete button.delete[disabled] span, addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row .single-column.delete button.delete[disabled=true] span {
  cursor: not-allowed;
  pointer-events: none;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row.disabled {
  opacity: 0.5;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row-skeleton {
  min-height: 56px;
  display: flex;
  align-items: center;
  column-gap: 20px;
  cursor: pointer;
  padding: 10px 0;
  position: relative;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row-skeleton .single-column {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row-skeleton .single-column.checkbox {
  width: 18px;
  height: 18px;
  background: linear-gradient(110deg, rgba(1, 40, 108, 0) 40%, rgba(1, 40, 108, 0.5) 60%, rgba(1, 40, 108, 0) 70%) rgba(0, 75, 196, 0.2);
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-in-out infinite;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row-skeleton .single-column.checkbox span {
  width: 100%;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row-skeleton .single-column.image {
  width: 60px;
  height: 60px;
  border: none;
  background: linear-gradient(110deg, rgba(1, 40, 108, 0) 40%, rgba(1, 40, 108, 0.5) 60%, rgba(1, 40, 108, 0) 70%) rgba(0, 75, 196, 0.2);
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-in-out infinite;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row-skeleton .single-column.single {
  flex: 1;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row-skeleton .single-column.double {
  flex: 2;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row-skeleton .single-column.triple {
  flex: 3;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row-skeleton .single-column.icon {
  width: 60px;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row-skeleton .single-column.position {
  width: 30px;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row-skeleton .single-column.reverse {
  justify-content: flex-end;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row-skeleton .single-column.reverse span {
  text-align: right;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row-skeleton .single-column.center {
  justify-content: center;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row-skeleton .single-column.center span {
  text-align: center;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row-skeleton .single-column span {
  width: 80%;
  height: 20px;
  border-radius: 3px;
  background: linear-gradient(110deg, rgba(1, 40, 108, 0) 40%, rgba(1, 40, 108, 0.5) 60%, rgba(1, 40, 108, 0) 70%) rgba(0, 75, 196, 0.2);
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-in-out infinite;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row-skeleton .single-column.arrow {
  width: 30px;
  height: 10px;
  justify-content: center;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row-skeleton .single-column.arrow::before {
  content: "b";
  width: 10px;
  height: 10px;
  font-family: "DataLean Font Icon";
  color: #FFFFFF;
  font-size: 6px;
  background: rgba(255, 255, 255, 0);
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  transform: rotate(-90deg);
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row .single-row-skeleton .single-column.options {
  width: 30px;
  justify-content: center;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row cdk-accordion {
  height: 100%;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row cdk-accordion cdk-accordion-item {
  height: 100%;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row cdk-accordion cdk-accordion-item.accordion-disabled .single-row.accordion-header .single-column.arrow::before {
  display: none;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row cdk-accordion cdk-accordion-item[aria-expanded=true] .single-row.accordion-header .single-column.arrow::before {
  transform: rotate(0);
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row cdk-accordion cdk-accordion-item .action-list .single-row.accordion-details {
  border-bottom: 0;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row cdk-accordion cdk-accordion-item .action-list .single-row.accordion-details:first-of-type {
  padding-top: 60px;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row cdk-accordion cdk-accordion-item .action-list .single-row.accordion-details:last-of-type {
  padding-bottom: 60px;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row cdk-accordion cdk-accordion-item .action-list .single-row.accordion-details .single-column:first-of-type span {
  padding-left: 50px;
}
addiction-list .list-viewport .cdk-virtual-scroll-content-wrapper .list-row cdk-accordion cdk-accordion-item .action-list .single-row.accordion-details .single-column.arrow::before {
  display: none;
}
addiction-list .list-viewport .cdk-virtual-scroll-spacer {
  display: none;
}
addiction-list .no-results-container {
  width: 100%;
  height: auto;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column;
}
addiction-list .no-results-container.bigger ng-lottie {
  width: 400px;
  height: 400px;
  margin-bottom: 0;
}
addiction-list .no-results-container ng-lottie {
  display: flex;
  width: 250px;
  height: 250px;
  object-fit: contain;
}
addiction-list .no-results-container ng-lottie.arrow-top {
  transform: rotate(230deg);
}
addiction-list .no-results-container ng-lottie.arrow-bottom {
  transform: rotate(50deg);
}
addiction-list .no-results-container .title {
  width: 100%;
  margin-bottom: 10px;
  font-family: "Segma", sans-serif;
  font-size: 30px;
  font-weight: 300;
  color: #FFFFFF;
  line-height: 130%;
  letter-spacing: 0;
  cursor: default;
  text-align: center;
}
addiction-list .no-results-container .text {
  width: 100%;
  font-family: "Segma", sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.65);
  line-height: 150%;
  letter-spacing: 0;
  cursor: default;
  text-align: center;
}
addiction-list .no-results-container .text span {
  color: #09152C;
}

/* --- TYPOGRAPHY --- */
/* --- COLORS --- */
/* --- ANIMATIONS --- */
/* --- SPECIFIC MIXINS --- */
/* Stile per buttons che al click aprono un sottomenù */
/* --- TYPOGRAPHY --- */
/* --- COLORS --- */
/* --- ANIMATIONS --- */
/* --- STANDARD MIXINS --- */
/* --- FLEX --- */
/* --- FONTS --- */
/* Usato ad es. per scroll dentro select o popup con sfondo $brand-color-03 */
@keyframes loading {
  to {
    background-position-x: -20%;
  }
}
/*
NOTE BUTTONS FE 3.0 - DAM
I buttons con lo sfondo colorato, senza bordo e icona in mezzo (es. +) si fanno così:
@include button-style("e", $brand-color-04, $white, $buttonIcon: true, $buttonBackground: true);

I buttons senza sfondo, con bordo e icona in mezzo (es. permessi, back, navigazione, filtri) si fanno così:
@include button-style("e", $brand-color-04, $brand-color-04, $buttonIcon: true, $buttonBackground: false);

I buttons con lo sfondo colorato, senza bordo e testo in mezzo (es. salva, nuovo) si fanno così:
@include button-style(36px, $brand-color-04, $white, $buttonIcon: false, $buttonBackground: true);

I buttons senza sfondo, con bordo e testo in mezzo (es. aggiungi a collezione, collezioni) si fanno così:
@include button-style(36px, $brand-color-04, $brand-color-04, $buttonIcon: false, $buttonBackground: false);
*/
/* ADDICTION LIST */
addiction-list-draggable {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
}
addiction-list-draggable.show-bulk-actions {
  height: calc(100% - 70px);
}
addiction-list-draggable .header-grid-container {
  /* uguale ad addiction-grid.scss */
  width: 100%;
  min-height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
  position: relative;
}
addiction-list-draggable .header-grid-container.expanded {
  margin-bottom: 70px;
}
addiction-list-draggable .header-grid-container.dam .header-grid .left-container .title h1 {
  font-family: "Segma", sans-serif;
  font-size: 40px;
  font-weight: 300;
  color: #FFFFFF;
  line-height: 100%;
  letter-spacing: 0;
  cursor: default;
}
addiction-list-draggable .header-grid-container .header-grid {
  width: 100%;
  height: 70px;
  padding: 10px 20px;
  display: flex;
}
addiction-list-draggable .header-grid-container .header-grid .left-container {
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: auto;
}
addiction-list-draggable .header-grid-container .header-grid .left-container .checkbox-container {
  width: 30px;
}
addiction-list-draggable .header-grid-container .header-grid .left-container .checkbox-container mat-checkbox {
  margin: 0 10px 0 0;
}
addiction-list-draggable .header-grid-container .header-grid .left-container .checkbox-container mat-checkbox .mdc-form-field .mdc-checkbox {
  padding: 0;
}
addiction-list-draggable .header-grid-container .header-grid .left-container .checkbox-container mat-checkbox .mdc-form-field .mdc-checkbox .mat-mdc-checkbox-touch-target {
  display: none;
}
addiction-list-draggable .header-grid-container .header-grid .left-container .checkbox-container mat-checkbox .mdc-form-field .mdc-checkbox input {
  width: 18px;
  height: 18px;
}
addiction-list-draggable .header-grid-container .header-grid .left-container .checkbox-container mat-checkbox .mdc-form-field .mdc-checkbox input:enabled:checked ~ .mdc-checkbox__background {
  border-color: unset;
  background-color: #004BC4 !important;
}
addiction-list-draggable .header-grid-container .header-grid .left-container .checkbox-container mat-checkbox .mdc-form-field .mdc-checkbox input:enabled:indeterminate ~ .mdc-checkbox__background, addiction-list-draggable .header-grid-container .header-grid .left-container .checkbox-container mat-checkbox .mdc-form-field .mdc-checkbox input.mdc-checkbox--selected ~ .mdc-checkbox__background {
  border-color: unset;
  background-color: #004BC4 !important;
}
addiction-list-draggable .header-grid-container .header-grid .left-container .checkbox-container mat-checkbox .mdc-form-field .mdc-checkbox input:enabled:indeterminate ~ .mdc-checkbox__background .mdc-checkbox__mixedmark, addiction-list-draggable .header-grid-container .header-grid .left-container .checkbox-container mat-checkbox .mdc-form-field .mdc-checkbox input.mdc-checkbox--selected ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
  transform: scale(0.5);
  border-color: #FFFFFF !important;
}
addiction-list-draggable .header-grid-container .header-grid .left-container .checkbox-container mat-checkbox .mdc-form-field .mdc-checkbox .mdc-checkbox__ripple {
  display: none;
}
addiction-list-draggable .header-grid-container .header-grid .left-container .checkbox-container mat-checkbox .mdc-form-field .mdc-checkbox .mdc-checkbox__background {
  width: 18px;
  height: 18px;
  top: unset;
  left: unset;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.1) !important;
  border: none !important;
}
addiction-list-draggable .header-grid-container .header-grid .left-container .checkbox-container mat-checkbox .mdc-form-field .mdc-checkbox .mdc-checkbox__background svg {
  transform: scale(0.6);
}
addiction-list-draggable .header-grid-container .header-grid .left-container .checkbox-container mat-checkbox .mdc-form-field .mdc-checkbox .mdc-checkbox__background .mdc-checkbox__mixedmark {
  transform: scale(0);
}
addiction-list-draggable .header-grid-container .header-grid .left-container .checkbox-container mat-checkbox .mdc-form-field .mdc-checkbox .mat-mdc-checkbox-ripple {
  display: none;
}
addiction-list-draggable .header-grid-container .header-grid .left-container .checkbox-container mat-checkbox .mdc-form-field .mdc-label {
  padding-left: 10px;
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 150%;
  letter-spacing: 0;
  cursor: default;
}
addiction-list-draggable .header-grid-container .header-grid .left-container .title {
  width: calc(100% - 30px);
}
addiction-list-draggable .header-grid-container .header-grid .left-container .title h1 {
  font-family: "Segma", sans-serif;
  font-size: 20px;
  font-weight: 300;
  color: #FFFFFF;
  line-height: 150%;
  letter-spacing: 0;
  cursor: default;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
}
addiction-list-draggable .header-grid-container .header-grid .right-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
addiction-list-draggable .header-grid-container .header-grid .right-container .view-mode-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
addiction-list-draggable .header-grid-container .header-grid .right-container .view-mode-container .view-button {
  width: 20px;
  height: 36px;
  background: rgba(255, 255, 255, 0);
  padding: 0;
  border: none;
  border-radius: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  margin-left: 20px;
}
@media (hover: hover) and (pointer: fine) {
  addiction-list-draggable .header-grid-container .header-grid .right-container .view-mode-container .view-button:hover {
    opacity: 0.5;
  }
}
addiction-list-draggable .header-grid-container .header-grid .right-container .view-mode-container .view-button::before {
  content: "v";
  width: 16px;
  height: 16px;
  color: #FFFFFF;
  font-size: 16px;
  font-family: "DataLean Font Icon";
  position: absolute;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}
addiction-list-draggable .header-grid-container .header-grid .right-container .view-mode-container .view-button.active::before {
  color: #0061FF;
}
@media (hover: hover) and (pointer: fine) {
  addiction-list-draggable .header-grid-container .header-grid .right-container .view-mode-container .view-button.active:hover {
    opacity: 1;
  }
}
addiction-list-draggable .header-grid-container .header-grid .right-container .view-mode-container .view-button.list-view::before {
  content: "A";
}
addiction-list-draggable .header-grid-container .header-grid .right-container .view-mode-container .view-button.grid-view::before {
  content: "z";
}
addiction-list-draggable .header-grid-container .header-grid .right-container addiction-search {
  /* stile dentro addiction-components.scss */
  margin-left: 20px;
}
addiction-list-draggable .header-grid-container .header-grid .right-container button.filters-button {
  box-shadow: none;
  border-radius: 3px;
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: initial;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  width: 34px;
  height: 34px;
  background: rgba(255, 255, 255, 0);
  border: 2px solid #FFFFFF;
  margin-left: 20px;
}
addiction-list-draggable .header-grid-container .header-grid .right-container button.filters-button[aria-expanded=true] {
  border-radius: 0;
}
@media (hover: hover) and (pointer: fine) {
  addiction-list-draggable .header-grid-container .header-grid .right-container button.filters-button:hover {
    opacity: 0.5;
  }
}
addiction-list-draggable .header-grid-container .header-grid .right-container button.filters-button::before {
  content: "y";
  width: 16px;
  height: 16px;
  font-size: 16px;
  color: #FFFFFF;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 100%;
  font-family: "DataLean Font Icon";
  position: absolute;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-list-draggable .header-grid-container .header-grid .right-container button.filters-button:disabled, addiction-list-draggable .header-grid-container .header-grid .right-container button.filters-button.disabled, addiction-list-draggable .header-grid-container .header-grid .right-container button.filters-button[disabled], addiction-list-draggable .header-grid-container .header-grid .right-container button.filters-button[disabled=true] {
  opacity: 0.25;
  cursor: not-allowed;
  pointer-events: none;
}
addiction-list-draggable .header-grid-container .header-grid .right-container button.filters-button:disabled span, addiction-list-draggable .header-grid-container .header-grid .right-container button.filters-button.disabled span, addiction-list-draggable .header-grid-container .header-grid .right-container button.filters-button[disabled] span, addiction-list-draggable .header-grid-container .header-grid .right-container button.filters-button[disabled=true] span {
  cursor: not-allowed;
  pointer-events: none;
}
addiction-list-draggable .header-grid-container .header-grid .right-container button.filters-button::after {
  content: "";
  width: calc(100% + 4px);
  height: 10px;
  background: #01286C;
  position: absolute;
  bottom: -10px;
  left: -2px;
  opacity: 0;
}
addiction-list-draggable .header-grid-container .header-grid .right-container button.filters-button[aria-expanded=false] {
  box-shadow: none;
}
addiction-list-draggable .header-grid-container .header-grid .right-container button.filters-button[aria-expanded=true] {
  background: #01286C;
  border: 2px solid #01286C;
}
addiction-list-draggable .header-grid-container .header-grid .right-container button.filters-button[aria-expanded=true]::after {
  opacity: 1;
}
addiction-list-draggable .header-grid-container .header-grid .right-container button.filters-button[aria-expanded=true].mat-menu-below {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
addiction-list-draggable .header-grid-container .header-grid .right-container button.filters-button[aria-expanded=true].mat-menu-below::after {
  bottom: -10px;
}
addiction-list-draggable .header-grid-container .header-grid .right-container button.filters-button[aria-expanded=true].mat-menu-above {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
addiction-list-draggable .header-grid-container .header-grid .right-container button.filters-button[aria-expanded=true].mat-menu-above::after {
  top: -10px;
}
addiction-list-draggable .header-grid-container .header-grid .right-container button.import-export {
  box-shadow: none;
  border-radius: 3px;
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: initial;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  width: 34px;
  height: 34px;
  background: rgba(255, 255, 255, 0);
  border: 2px solid #FFFFFF;
  margin-left: 20px;
}
addiction-list-draggable .header-grid-container .header-grid .right-container button.import-export[aria-expanded=true] {
  border-radius: 0;
}
@media (hover: hover) and (pointer: fine) {
  addiction-list-draggable .header-grid-container .header-grid .right-container button.import-export:hover {
    opacity: 0.5;
  }
}
addiction-list-draggable .header-grid-container .header-grid .right-container button.import-export::before {
  content: "l";
  width: 16px;
  height: 16px;
  font-size: 16px;
  color: #FFFFFF;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 100%;
  font-family: "DataLean Font Icon";
  position: absolute;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-list-draggable .header-grid-container .header-grid .right-container button.import-export:disabled, addiction-list-draggable .header-grid-container .header-grid .right-container button.import-export.disabled, addiction-list-draggable .header-grid-container .header-grid .right-container button.import-export[disabled], addiction-list-draggable .header-grid-container .header-grid .right-container button.import-export[disabled=true] {
  opacity: 0.25;
  cursor: not-allowed;
  pointer-events: none;
}
addiction-list-draggable .header-grid-container .header-grid .right-container button.import-export:disabled span, addiction-list-draggable .header-grid-container .header-grid .right-container button.import-export.disabled span, addiction-list-draggable .header-grid-container .header-grid .right-container button.import-export[disabled] span, addiction-list-draggable .header-grid-container .header-grid .right-container button.import-export[disabled=true] span {
  cursor: not-allowed;
  pointer-events: none;
}
addiction-list-draggable .header-grid-container .header-grid .right-container button.new {
  width: auto;
  padding: 5px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 130%;
  letter-spacing: 2.5px;
  cursor: default;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  min-height: 36px;
  background: #004BC4;
  border: none;
  border-radius: 10px;
  margin-left: 20px;
}
addiction-list-draggable .header-grid-container .header-grid .right-container button.new span {
  width: 100%;
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 130%;
  letter-spacing: 2.5px;
  cursor: default;
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  margin-top: 3px;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-list-draggable .header-grid-container .header-grid .right-container button.new[aria-expanded=true] {
  border-radius: 0;
}
@media (hover: hover) and (pointer: fine) {
  addiction-list-draggable .header-grid-container .header-grid .right-container button.new:hover {
    background: #FFFFFF;
    color: #004BC4;
  }
  addiction-list-draggable .header-grid-container .header-grid .right-container button.new:hover span {
    color: #004BC4;
  }
}
addiction-list-draggable .header-grid-container .header-grid .right-container button.new:disabled, addiction-list-draggable .header-grid-container .header-grid .right-container button.new.disabled, addiction-list-draggable .header-grid-container .header-grid .right-container button.new[disabled], addiction-list-draggable .header-grid-container .header-grid .right-container button.new[disabled=true] {
  opacity: 0.25;
  cursor: not-allowed;
  pointer-events: none;
}
addiction-list-draggable .header-grid-container .header-grid .right-container button.new:disabled span, addiction-list-draggable .header-grid-container .header-grid .right-container button.new.disabled span, addiction-list-draggable .header-grid-container .header-grid .right-container button.new[disabled] span, addiction-list-draggable .header-grid-container .header-grid .right-container button.new[disabled=true] span {
  cursor: not-allowed;
  pointer-events: none;
}
addiction-list-draggable .header-grid-container .header-grid .right-container .elements {
  display: flex;
  justify-content: flex-end;
}
addiction-list-draggable .header-grid-container .header-grid .right-container .elements .number {
  font-family: "Segma", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 100%;
  letter-spacing: 1px;
  cursor: default;
  margin-right: 5px;
}
addiction-list-draggable .header-grid-container .header-grid .right-container .elements .text {
  font-family: "Segma", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 100%;
  letter-spacing: 1px;
  cursor: default;
}
addiction-list-draggable .header-grid-container addiction-bulk-action-bar {
  /* stile dentro addiction-components.scss */
}
addiction-list-draggable .single-row-header {
  height: 50px;
  width: calc(100% - 40px);
  display: flex;
  align-items: center;
  column-gap: 20px;
  margin: 0 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
addiction-list-draggable .single-row-header.hidden {
  display: none;
}
addiction-list-draggable .single-row-header.mat-sort .single-column span {
  cursor: pointer;
}
addiction-list-draggable .single-row-header mat-checkbox {
  width: 20px;
}
addiction-list-draggable .single-row-header mat-checkbox .mdc-form-field .mdc-checkbox {
  padding: 0;
}
addiction-list-draggable .single-row-header mat-checkbox .mdc-form-field .mdc-checkbox .mat-mdc-checkbox-touch-target {
  display: none;
}
addiction-list-draggable .single-row-header mat-checkbox .mdc-form-field .mdc-checkbox input {
  width: 18px;
  height: 18px;
}
addiction-list-draggable .single-row-header mat-checkbox .mdc-form-field .mdc-checkbox input:enabled:checked ~ .mdc-checkbox__background {
  border-color: unset;
  background-color: #004BC4 !important;
}
addiction-list-draggable .single-row-header mat-checkbox .mdc-form-field .mdc-checkbox input:enabled:indeterminate ~ .mdc-checkbox__background, addiction-list-draggable .single-row-header mat-checkbox .mdc-form-field .mdc-checkbox input.mdc-checkbox--selected ~ .mdc-checkbox__background {
  border-color: unset;
  background-color: #004BC4 !important;
}
addiction-list-draggable .single-row-header mat-checkbox .mdc-form-field .mdc-checkbox input:enabled:indeterminate ~ .mdc-checkbox__background .mdc-checkbox__mixedmark, addiction-list-draggable .single-row-header mat-checkbox .mdc-form-field .mdc-checkbox input.mdc-checkbox--selected ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
  transform: scale(0.5);
  border-color: #FFFFFF !important;
}
addiction-list-draggable .single-row-header mat-checkbox .mdc-form-field .mdc-checkbox .mdc-checkbox__ripple {
  display: none;
}
addiction-list-draggable .single-row-header mat-checkbox .mdc-form-field .mdc-checkbox .mdc-checkbox__background {
  width: 18px;
  height: 18px;
  top: unset;
  left: unset;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.1) !important;
  border: none !important;
}
addiction-list-draggable .single-row-header mat-checkbox .mdc-form-field .mdc-checkbox .mdc-checkbox__background svg {
  transform: scale(0.6);
}
addiction-list-draggable .single-row-header mat-checkbox .mdc-form-field .mdc-checkbox .mdc-checkbox__background .mdc-checkbox__mixedmark {
  transform: scale(0);
}
addiction-list-draggable .single-row-header mat-checkbox .mdc-form-field .mdc-checkbox .mat-mdc-checkbox-ripple {
  display: none;
}
addiction-list-draggable .single-row-header mat-checkbox .mdc-form-field .mdc-label {
  padding-left: 10px;
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 150%;
  letter-spacing: 0;
  cursor: default;
}
addiction-list-draggable .single-row-header .single-column {
  display: flex;
  overflow: hidden;
}
addiction-list-draggable .single-row-header .single-column.checkbox {
  width: 20px;
}
addiction-list-draggable .single-row-header .single-column.image {
  width: 60px;
}
addiction-list-draggable .single-row-header .single-column.single {
  flex: 1;
}
addiction-list-draggable .single-row-header .single-column.double {
  flex: 2;
}
addiction-list-draggable .single-row-header .single-column.triple {
  flex: 3;
}
addiction-list-draggable .single-row-header .single-column.arrow {
  width: 30px;
}
addiction-list-draggable .single-row-header .single-column.options {
  width: 30px;
  justify-content: center;
}
addiction-list-draggable .single-row-header .single-column.icon {
  width: 60px;
}
addiction-list-draggable .single-row-header .single-column.position {
  width: 30px;
}
addiction-list-draggable .single-row-header .single-column.delete {
  width: 30px;
}
addiction-list-draggable .single-row-header .single-column.reverse {
  justify-content: flex-end;
}
addiction-list-draggable .single-row-header .single-column.reverse span {
  text-align: right;
}
addiction-list-draggable .single-row-header .single-column.center {
  justify-content: center;
}
addiction-list-draggable .single-row-header .single-column.center span {
  text-align: center;
}
addiction-list-draggable .single-row-header .single-column span {
  width: 100%;
  font-family: "Segma", sans-serif;
  font-size: 10px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
  line-height: 100%;
  letter-spacing: 0.5px;
  cursor: default;
  text-transform: uppercase;
  text-align: left;
  word-break: break-all;
}
addiction-list-draggable .list-viewport {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  /*
  &.hidden {
    Con display: none; o height: 0; non funziona il virtual scroll quindi per ora lasciamo così
  }
  */
}
addiction-list-draggable .list-viewport::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
addiction-list-draggable .list-viewport::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: none;
}
addiction-list-draggable .list-viewport::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0);
}
addiction-list-draggable .list-viewport.with-header {
  height: calc(100% - 70px);
}
addiction-list-draggable .list-viewport.with-header.with-header-template {
  height: calc(100% - 120px);
}
addiction-list-draggable .list-viewport.with-header-template {
  height: calc(100% - 50px);
}
addiction-list-draggable .list-viewport * {
  transition: none;
}
addiction-list-draggable .list-viewport .list-row {
  display: flex;
  justify-content: center;
  flex-flow: column;
  width: 100%;
  height: auto;
  column-gap: 20px;
}
addiction-list-draggable .list-viewport .list-row .single-row {
  min-height: 56px;
  height: 100%;
  display: flex;
  align-items: center;
  column-gap: 20px;
  cursor: pointer;
  padding: 10px 0;
  position: relative;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
@media (hover: hover) and (pointer: fine) {
  addiction-list-draggable .list-viewport .list-row .single-row:hover .single-column.image img {
    opacity: 0.5;
  }
}
addiction-list-draggable .list-viewport .list-row .single-row mat-checkbox {
  width: 20px;
}
addiction-list-draggable .list-viewport .list-row .single-row mat-checkbox .mdc-form-field .mdc-checkbox {
  padding: 0;
}
addiction-list-draggable .list-viewport .list-row .single-row mat-checkbox .mdc-form-field .mdc-checkbox .mat-mdc-checkbox-touch-target {
  display: none;
}
addiction-list-draggable .list-viewport .list-row .single-row mat-checkbox .mdc-form-field .mdc-checkbox input {
  width: 18px;
  height: 18px;
}
addiction-list-draggable .list-viewport .list-row .single-row mat-checkbox .mdc-form-field .mdc-checkbox input:enabled:checked ~ .mdc-checkbox__background {
  border-color: unset;
  background-color: #004BC4 !important;
}
addiction-list-draggable .list-viewport .list-row .single-row mat-checkbox .mdc-form-field .mdc-checkbox input:enabled:indeterminate ~ .mdc-checkbox__background, addiction-list-draggable .list-viewport .list-row .single-row mat-checkbox .mdc-form-field .mdc-checkbox input.mdc-checkbox--selected ~ .mdc-checkbox__background {
  border-color: unset;
  background-color: #004BC4 !important;
}
addiction-list-draggable .list-viewport .list-row .single-row mat-checkbox .mdc-form-field .mdc-checkbox input:enabled:indeterminate ~ .mdc-checkbox__background .mdc-checkbox__mixedmark, addiction-list-draggable .list-viewport .list-row .single-row mat-checkbox .mdc-form-field .mdc-checkbox input.mdc-checkbox--selected ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
  transform: scale(0.5);
  border-color: #FFFFFF !important;
}
addiction-list-draggable .list-viewport .list-row .single-row mat-checkbox .mdc-form-field .mdc-checkbox .mdc-checkbox__ripple {
  display: none;
}
addiction-list-draggable .list-viewport .list-row .single-row mat-checkbox .mdc-form-field .mdc-checkbox .mdc-checkbox__background {
  width: 18px;
  height: 18px;
  top: unset;
  left: unset;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.1) !important;
  border: none !important;
}
addiction-list-draggable .list-viewport .list-row .single-row mat-checkbox .mdc-form-field .mdc-checkbox .mdc-checkbox__background svg {
  transform: scale(0.6);
}
addiction-list-draggable .list-viewport .list-row .single-row mat-checkbox .mdc-form-field .mdc-checkbox .mdc-checkbox__background .mdc-checkbox__mixedmark {
  transform: scale(0);
}
addiction-list-draggable .list-viewport .list-row .single-row mat-checkbox .mdc-form-field .mdc-checkbox .mat-mdc-checkbox-ripple {
  display: none;
}
addiction-list-draggable .list-viewport .list-row .single-row mat-checkbox .mdc-form-field .mdc-label {
  padding-left: 10px;
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 150%;
  letter-spacing: 0;
  cursor: default;
}
addiction-list-draggable .list-viewport .list-row .single-row .single-column {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6);
  line-height: 150%;
  letter-spacing: 0;
  cursor: default;
  text-align: left;
  cursor: pointer;
  overflow: hidden;
}
addiction-list-draggable .list-viewport .list-row .single-row .single-column.checkbox {
  width: 20px;
}
addiction-list-draggable .list-viewport .list-row .single-row .single-column.image {
  width: 60px;
  height: 60px;
  border: 1px solid #3d5d96;
}
addiction-list-draggable .list-viewport .list-row .single-row .single-column.image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-list-draggable .list-viewport .list-row .single-row .single-column.icon {
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
@media (hover: hover) and (pointer: fine) {
  addiction-list-draggable .list-viewport .list-row .single-row .single-column.icon:hover {
    opacity: 0.5;
  }
}
addiction-list-draggable .list-viewport .list-row .single-row .single-column.icon span {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
addiction-list-draggable .list-viewport .list-row .single-row .single-column.icon span::before {
  width: 20px;
  height: 20px;
  font-size: 12px;
  font-family: "DataLean Font Icon";
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}
addiction-list-draggable .list-viewport .list-row .single-row .single-column.icon span.user-detail::before {
  content: "M";
}
addiction-list-draggable .list-viewport .list-row .single-row .single-column.icon span.challenge-detail::before {
  content: "N";
}
addiction-list-draggable .list-viewport .list-row .single-row .single-column.buttons-actions {
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
addiction-list-draggable .list-viewport .list-row .single-row .single-column.buttons-actions button {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  border: none;
  background: rgba(255, 255, 255, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
@media (hover: hover) and (pointer: fine) {
  addiction-list-draggable .list-viewport .list-row .single-row .single-column.buttons-actions button:hover {
    opacity: 0.5;
  }
}
addiction-list-draggable .list-viewport .list-row .single-row .single-column.buttons-actions button::before {
  width: 20px;
  height: 20px;
  font-family: "DataLean Font Icon";
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}
addiction-list-draggable .list-viewport .list-row .single-row .single-column.buttons-actions button.invalid::before {
  content: "d";
  font-size: 12px;
  color: #A20000;
}
addiction-list-draggable .list-viewport .list-row .single-row .single-column.buttons-actions button.valid::before {
  content: "c";
  font-size: 15px;
  color: #4F8A10;
}
addiction-list-draggable .list-viewport .list-row .single-row .single-column.single {
  flex: 1;
}
addiction-list-draggable .list-viewport .list-row .single-row .single-column.double {
  flex: 2;
}
addiction-list-draggable .list-viewport .list-row .single-row .single-column.triple {
  flex: 3;
}
addiction-list-draggable .list-viewport .list-row .single-row .single-column.position {
  width: 30px;
}
addiction-list-draggable .list-viewport .list-row .single-row .single-column.position span {
  font-family: "Segma", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 100%;
  letter-spacing: 0;
  cursor: default;
}
addiction-list-draggable .list-viewport .list-row .single-row .single-column.reverse {
  justify-content: flex-end;
}
addiction-list-draggable .list-viewport .list-row .single-row .single-column.reverse span {
  text-align: right;
}
addiction-list-draggable .list-viewport .list-row .single-row .single-column.center {
  justify-content: center;
}
addiction-list-draggable .list-viewport .list-row .single-row .single-column.center span {
  text-align: center;
}
addiction-list-draggable .list-viewport .list-row .single-row .single-column span {
  width: 100%;
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6);
  line-height: 150%;
  letter-spacing: 0;
  cursor: default;
  text-align: left;
  cursor: pointer;
}
addiction-list-draggable .list-viewport .list-row .single-row .single-column span.highlight {
  font-family: "Segma", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 150%;
  letter-spacing: 0;
  cursor: default;
  cursor: pointer;
}
addiction-list-draggable .list-viewport .list-row .single-row .single-column span.format {
  width: auto;
  font-family: "Segma", sans-serif;
  font-size: 10px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 100%;
  letter-spacing: 0;
  cursor: default;
  cursor: pointer;
  text-transform: uppercase;
  background: #3d5d96;
  border-radius: 3px;
  padding: 4px 5px;
}
addiction-list-draggable .list-viewport .list-row .single-row .single-column span.status {
  padding-left: 16px;
  position: relative;
  display: flex;
  align-items: center;
}
addiction-list-draggable .list-viewport .list-row .single-row .single-column span.status::before {
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background: #FFFF00;
  position: absolute;
  left: 1px;
}
addiction-list-draggable .list-viewport .list-row .single-row .single-column span.status.CONFIRMED::before, addiction-list-draggable .list-viewport .list-row .single-row .single-column span.status.PUBLISHED::before, addiction-list-draggable .list-viewport .list-row .single-row .single-column span.status.ENABLED::before, addiction-list-draggable .list-viewport .list-row .single-row .single-column span.status.ACTIVE::before, addiction-list-draggable .list-viewport .list-row .single-row .single-column span.status.VALID::before {
  background: green;
}
addiction-list-draggable .list-viewport .list-row .single-row .single-column span.status.DECLINED::before, addiction-list-draggable .list-viewport .list-row .single-row .single-column span.status.ARCHIVED::before, addiction-list-draggable .list-viewport .list-row .single-row .single-column span.status.DISABLED::before, addiction-list-draggable .list-viewport .list-row .single-row .single-column span.status.INACTIVE::before, addiction-list-draggable .list-viewport .list-row .single-row .single-column span.status.NOT_VALID::before {
  background: red;
}
addiction-list-draggable .list-viewport .list-row .single-row .single-column.arrow {
  width: 30px;
  height: 10px;
  justify-content: center;
}
addiction-list-draggable .list-viewport .list-row .single-row .single-column.arrow::before {
  content: "b";
  width: 10px;
  height: 10px;
  font-family: "DataLean Font Icon";
  color: #FFFFFF;
  font-size: 6px;
  background: rgba(255, 255, 255, 0);
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  transform: rotate(-90deg);
}
addiction-list-draggable .list-viewport .list-row .single-row .single-column.options {
  width: 30px;
  justify-content: center;
}
addiction-list-draggable .list-viewport .list-row .single-row .single-column.delete {
  width: 30px;
}
addiction-list-draggable .list-viewport .list-row .single-row .single-column.delete button.delete {
  box-shadow: none;
  border-radius: 3px;
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: initial;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  width: 34px;
  height: 34px;
  background: rgba(255, 255, 255, 0);
  border: 2px solid #FF4848;
  border: none;
}
addiction-list-draggable .list-viewport .list-row .single-row .single-column.delete button.delete[aria-expanded=true] {
  border-radius: 0;
}
@media (hover: hover) and (pointer: fine) {
  addiction-list-draggable .list-viewport .list-row .single-row .single-column.delete button.delete:hover {
    opacity: 0.5;
  }
}
addiction-list-draggable .list-viewport .list-row .single-row .single-column.delete button.delete::before {
  content: "i";
  width: 16px;
  height: 16px;
  font-size: 16px;
  color: #FF4848;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 100%;
  font-family: "DataLean Font Icon";
  position: absolute;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-list-draggable .list-viewport .list-row .single-row .single-column.delete button.delete:disabled, addiction-list-draggable .list-viewport .list-row .single-row .single-column.delete button.delete.disabled, addiction-list-draggable .list-viewport .list-row .single-row .single-column.delete button.delete[disabled], addiction-list-draggable .list-viewport .list-row .single-row .single-column.delete button.delete[disabled=true] {
  opacity: 0.25;
  cursor: not-allowed;
  pointer-events: none;
}
addiction-list-draggable .list-viewport .list-row .single-row .single-column.delete button.delete:disabled span, addiction-list-draggable .list-viewport .list-row .single-row .single-column.delete button.delete.disabled span, addiction-list-draggable .list-viewport .list-row .single-row .single-column.delete button.delete[disabled] span, addiction-list-draggable .list-viewport .list-row .single-row .single-column.delete button.delete[disabled=true] span {
  cursor: not-allowed;
  pointer-events: none;
}
addiction-list-draggable .list-viewport .list-row .single-row.disabled {
  opacity: 0.5;
}
addiction-list-draggable .list-viewport .list-row .single-row-skeleton {
  min-height: 56px;
  display: flex;
  align-items: center;
  column-gap: 20px;
  cursor: pointer;
  padding: 10px 0;
  position: relative;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
addiction-list-draggable .list-viewport .list-row .single-row-skeleton .single-column {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
}
addiction-list-draggable .list-viewport .list-row .single-row-skeleton .single-column.checkbox {
  width: 18px;
  height: 18px;
  background: linear-gradient(110deg, rgba(1, 40, 108, 0) 40%, rgba(1, 40, 108, 0.5) 60%, rgba(1, 40, 108, 0) 70%) rgba(0, 75, 196, 0.2);
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-in-out infinite;
}
addiction-list-draggable .list-viewport .list-row .single-row-skeleton .single-column.checkbox span {
  width: 100%;
}
addiction-list-draggable .list-viewport .list-row .single-row-skeleton .single-column.image {
  width: 60px;
  height: 60px;
  border: none;
  background: linear-gradient(110deg, rgba(1, 40, 108, 0) 40%, rgba(1, 40, 108, 0.5) 60%, rgba(1, 40, 108, 0) 70%) rgba(0, 75, 196, 0.2);
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-in-out infinite;
}
addiction-list-draggable .list-viewport .list-row .single-row-skeleton .single-column.single {
  flex: 1;
}
addiction-list-draggable .list-viewport .list-row .single-row-skeleton .single-column.double {
  flex: 2;
}
addiction-list-draggable .list-viewport .list-row .single-row-skeleton .single-column.triple {
  flex: 3;
}
addiction-list-draggable .list-viewport .list-row .single-row-skeleton .single-column.icon {
  width: 60px;
}
addiction-list-draggable .list-viewport .list-row .single-row-skeleton .single-column.position {
  width: 30px;
}
addiction-list-draggable .list-viewport .list-row .single-row-skeleton .single-column.reverse {
  justify-content: flex-end;
}
addiction-list-draggable .list-viewport .list-row .single-row-skeleton .single-column.reverse span {
  text-align: right;
}
addiction-list-draggable .list-viewport .list-row .single-row-skeleton .single-column.center {
  justify-content: center;
}
addiction-list-draggable .list-viewport .list-row .single-row-skeleton .single-column.center span {
  text-align: center;
}
addiction-list-draggable .list-viewport .list-row .single-row-skeleton .single-column span {
  width: 80%;
  height: 20px;
  border-radius: 3px;
  background: linear-gradient(110deg, rgba(1, 40, 108, 0) 40%, rgba(1, 40, 108, 0.5) 60%, rgba(1, 40, 108, 0) 70%) rgba(0, 75, 196, 0.2);
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-in-out infinite;
}
addiction-list-draggable .list-viewport .list-row .single-row-skeleton .single-column.arrow {
  width: 30px;
  height: 10px;
  justify-content: center;
}
addiction-list-draggable .list-viewport .list-row .single-row-skeleton .single-column.arrow::before {
  content: "b";
  width: 10px;
  height: 10px;
  font-family: "DataLean Font Icon";
  color: #FFFFFF;
  font-size: 6px;
  background: rgba(255, 255, 255, 0);
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  transform: rotate(-90deg);
}
addiction-list-draggable .list-viewport .list-row .single-row-skeleton .single-column.options {
  width: 30px;
  justify-content: center;
}
addiction-list-draggable .no-results-container {
  width: 100%;
  height: auto;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column;
}
addiction-list-draggable .no-results-container.bigger ng-lottie {
  width: 400px;
  height: 400px;
  margin-bottom: 0;
}
addiction-list-draggable .no-results-container ng-lottie {
  display: flex;
  width: 250px;
  height: 250px;
  object-fit: contain;
}
addiction-list-draggable .no-results-container ng-lottie.arrow-top {
  transform: rotate(230deg);
}
addiction-list-draggable .no-results-container ng-lottie.arrow-bottom {
  transform: rotate(50deg);
}
addiction-list-draggable .no-results-container .title {
  width: 100%;
  margin-bottom: 10px;
  font-family: "Segma", sans-serif;
  font-size: 30px;
  font-weight: 300;
  color: #FFFFFF;
  line-height: 130%;
  letter-spacing: 0;
  cursor: default;
  text-align: center;
}
addiction-list-draggable .no-results-container .text {
  width: 100%;
  font-family: "Segma", sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.65);
  line-height: 150%;
  letter-spacing: 0;
  cursor: default;
  text-align: center;
}
addiction-list-draggable .no-results-container .text span {
  color: #09152C;
}

/* --- TYPOGRAPHY --- */
/* --- COLORS --- */
/* --- ANIMATIONS --- */
/* --- STANDARD MIXINS --- */
/* --- FLEX --- */
/* --- FONTS --- */
/* Usato ad es. per scroll dentro select o popup con sfondo $brand-color-03 */
@keyframes loading {
  to {
    background-position-x: -20%;
  }
}
/*
NOTE BUTTONS FE 3.0 - DAM
I buttons con lo sfondo colorato, senza bordo e icona in mezzo (es. +) si fanno così:
@include button-style("e", $brand-color-04, $white, $buttonIcon: true, $buttonBackground: true);

I buttons senza sfondo, con bordo e icona in mezzo (es. permessi, back, navigazione, filtri) si fanno così:
@include button-style("e", $brand-color-04, $brand-color-04, $buttonIcon: true, $buttonBackground: false);

I buttons con lo sfondo colorato, senza bordo e testo in mezzo (es. salva, nuovo) si fanno così:
@include button-style(36px, $brand-color-04, $white, $buttonIcon: false, $buttonBackground: true);

I buttons senza sfondo, con bordo e testo in mezzo (es. aggiungi a collezione, collezioni) si fanno così:
@include button-style(36px, $brand-color-04, $brand-color-04, $buttonIcon: false, $buttonBackground: false);
*/
/* ADDICTION PASSWORD FORM */
addiction-password-form {
  width: 100%;
}
addiction-password-form form {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 20px;
}
addiction-password-form form .form-field {
  padding: 0;
  width: auto;
  flex: 1;
}
addiction-password-form form .form-field label {
  width: 100%;
  display: flex;
  font-family: "Segma", sans-serif;
  font-size: 10px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
  line-height: 100%;
  letter-spacing: 2.5px;
  cursor: default;
  text-transform: uppercase;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
addiction-password-form form .form-field label .tooltip {
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  position: relative;
  cursor: pointer;
}
addiction-password-form form .form-field label .tooltip::before {
  content: "F";
  font-family: "DataLean Font Icon";
  font-size: 13px;
  color: #FFFFFF;
  position: absolute;
  pointer-events: none;
}
addiction-password-form form .form-field .input-container {
  width: 100%;
  position: relative;
}
addiction-password-form form .form-field .input-container input {
  width: 100%;
  height: 36px;
  font-family: "Segma", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 100%;
  letter-spacing: 0;
  cursor: default;
  padding: 5px 40px 5px 10px;
  cursor: text;
  background: rgba(255, 255, 255, 0.05);
  border: none;
  margin: 0;
  border-radius: 5px;
}
addiction-password-form form .form-field .input-container input::placeholder {
  font-family: "Segma", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
  line-height: 100%;
  letter-spacing: 0;
  cursor: default;
}
addiction-password-form form .form-field .input-container input:read-only {
  cursor: not-allowed;
}
addiction-password-form form .form-field .input-container .icon {
  width: 40px;
  height: 36px;
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
@media (hover: hover) and (pointer: fine) {
  addiction-password-form form .form-field .input-container .icon:hover {
    opacity: 0.5;
  }
}
addiction-password-form form .form-field .input-container .icon::before {
  content: "";
  width: 20px;
  height: 20px;
  font-size: 20px;
  font-family: "DataLean Font Icon";
  color: #FFFFFF;
  position: absolute;
  pointer-events: none;
}
addiction-password-form form .form-field .input-container .icon.show::before {
  content: "O";
}
addiction-password-form form .form-field .input-container .icon.hide::before {
  content: "P";
}
addiction-password-form form .form-field .invalid-feedback {
  width: 100%;
  padding: 5px 10px;
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #FF4848;
  line-height: 150%;
  letter-spacing: 0;
  cursor: default;
}
addiction-password-form form .form-field .invalid-feedback li {
  list-style: none;
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #FF4848;
  line-height: 150%;
  letter-spacing: 0;
  cursor: default;
}
addiction-password-form form .form-field .invalid-feedback li span {
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #FF4848;
  line-height: 150%;
  letter-spacing: 0;
  cursor: default;
}

/* --- TYPOGRAPHY --- */
/* --- COLORS --- */
/* --- ANIMATIONS --- */
/* --- TYPOGRAPHY --- */
/* --- COLORS --- */
/* --- ANIMATIONS --- */
/* --- STANDARD MIXINS --- */
/* --- FLEX --- */
/* --- FONTS --- */
/* Usato ad es. per scroll dentro select o popup con sfondo $brand-color-03 */
@keyframes loading {
  to {
    background-position-x: -20%;
  }
}
/*
NOTE BUTTONS FE 3.0 - DAM
I buttons con lo sfondo colorato, senza bordo e icona in mezzo (es. +) si fanno così:
@include button-style("e", $brand-color-04, $white, $buttonIcon: true, $buttonBackground: true);

I buttons senza sfondo, con bordo e icona in mezzo (es. permessi, back, navigazione, filtri) si fanno così:
@include button-style("e", $brand-color-04, $brand-color-04, $buttonIcon: true, $buttonBackground: false);

I buttons con lo sfondo colorato, senza bordo e testo in mezzo (es. salva, nuovo) si fanno così:
@include button-style(36px, $brand-color-04, $white, $buttonIcon: false, $buttonBackground: true);

I buttons senza sfondo, con bordo e testo in mezzo (es. aggiungi a collezione, collezioni) si fanno così:
@include button-style(36px, $brand-color-04, $brand-color-04, $buttonIcon: false, $buttonBackground: false);
*/
/* ADDICTION PHONE */
addiction-phone {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
addiction-phone .form-group-container {
  width: calc(100% + 20px);
  margin-left: -10px;
  margin-right: -10px;
  display: flex;
  align-items: flex-end;
  flex-flow: row wrap;
  row-gap: 20px;
}
addiction-phone .form-group-container .divider {
  width: calc(100% - 20px);
  height: 1px;
  margin: 20px 10px 0 10px;
  background: rgba(61, 93, 150, 0.2);
}
addiction-phone .form-group-container .title-fields-container {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding: 0 10px;
  margin-top: 30px;
}
addiction-phone .form-group-container .title-fields-container.no-space {
  padding: 0;
  margin: 0;
}
addiction-phone .form-group-container .title-fields-container h2 {
  width: 100%;
  font-family: "Segma", sans-serif;
  font-size: 20px;
  font-weight: 300;
  color: #FFFFFF;
  line-height: 100%;
  letter-spacing: 0;
  cursor: default;
  margin: 0 !important;
}
addiction-phone .form-group-container .datalean-container { /* Per ora solo feature-editor.component.html */
  width: 100%;
}
addiction-phone .form-group-container .fields-container {
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-flow: row wrap;
  row-gap: 20px;
}
addiction-phone .form-group-container .fields-container.radio-dates-container {
  width: 50%;
}
@media screen and (max-width: 1900px) {
  addiction-phone .form-group-container .fields-container.radio-dates-container {
    width: 100%;
  }
}
addiction-phone .form-group-container .fields-container .skeleton {
  display: flex;
  align-items: center;
  column-gap: 20px;
  width: 100%;
  height: 36px;
  cursor: pointer;
  background: linear-gradient(110deg, rgba(1, 40, 108, 0) 40%, rgba(1, 40, 108, 0.5) 60%, rgba(1, 40, 108, 0) 70%) rgba(0, 75, 196, 0.2);
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-in-out infinite;
}
addiction-phone .form-group-container .non-repeatable-container {
  width: 100%;
}
addiction-phone .form-group-container .repeatable-container {
  width: calc(100% - 20px);
  margin: 0 10px;
  display: flex;
  flex-flow: column;
}
addiction-phone .form-group-container .repeatable-container > label {
  width: 100%;
  font-family: "Segma", sans-serif;
  font-size: 20px;
  font-weight: 300;
  color: #FFFFFF;
  line-height: 100%;
  letter-spacing: 0;
  cursor: default;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
addiction-phone .form-group-container .repeatable-container > label .tooltip {
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  position: relative;
  cursor: pointer;
}
addiction-phone .form-group-container .repeatable-container > label .tooltip::before {
  content: "F";
  font-family: "DataLean Font Icon";
  font-size: 15px;
  color: #FFFFFF;
  position: absolute;
  pointer-events: none;
}
addiction-phone .form-group-container .repeatable-container .drag-placeholder {
  width: 100%;
  height: 20px;
  background: #004BC4;
}
addiction-phone .form-group-container .repeatable-container .single-element {
  width: 100%;
  height: auto;
  border: 1px solid #1F2B43;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  transition: all 0s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-phone .form-group-container .repeatable-container .single-element.collapsed {
  background: #1F2B43;
}
addiction-phone .form-group-container .repeatable-container .single-element.closed {
  background: #1F2B43;
}
addiction-phone .form-group-container .repeatable-container .single-element.closed .repeatable-fields-container {
  height: 0;
  opacity: 0;
  padding: 0 10px;
  overflow: hidden;
  background: #1F2B43;
}
addiction-phone .form-group-container .repeatable-container .single-element.closed .buttons-container {
  padding: 0;
}
addiction-phone .form-group-container .repeatable-container .single-element .drag-container {
  width: 40px;
  border-radius: 5px;
  background: #1F2B43;
  display: flex;
  flex-flow: column;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
@media (hover: hover) and (pointer: fine) {
  addiction-phone .form-group-container .repeatable-container .single-element .drag-container:hover {
    background: rgba(0, 75, 196, 0.5);
  }
}
addiction-phone .form-group-container .repeatable-container .single-element .drag-container.disabled {
  pointer-events: none;
  cursor: default;
}
addiction-phone .form-group-container .repeatable-container .single-element .drag-container.disabled .number {
  display: none;
}
addiction-phone .form-group-container .repeatable-container .single-element .drag-container .number {
  font-family: "Segma", sans-serif;
  font-size: 10px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 100%;
  letter-spacing: 1.5px;
  cursor: default;
  text-align: center;
  cursor: grab;
}
addiction-phone .form-group-container .repeatable-container .single-element .repeatable-fields-container {
  width: calc(100% - 80px);
  display: flex;
  align-items: center;
  padding: 20px 10px;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-phone .form-group-container .repeatable-container .single-element .repeatable-fields-container.collapsed {
  height: 0;
  overflow: hidden;
}
addiction-phone .form-group-container .repeatable-container .single-element .repeatable-fields-container.collapsed > * {
  display: none;
}
addiction-phone .form-group-container .repeatable-container .single-element .repeatable-fields-container.expanded {
  height: auto;
  overflow: visible;
}
addiction-phone .form-group-container .repeatable-container .single-element .repeatable-fields-container.no-drag, addiction-phone .form-group-container .repeatable-container .single-element .repeatable-fields-container.no-buttons {
  width: calc(100% - 40px);
}
addiction-phone .form-group-container .repeatable-container .single-element .repeatable-fields-container.no-drag.no-buttons {
  width: 100%;
}
addiction-phone .form-group-container .repeatable-container .single-element .buttons-container {
  width: 40px;
  padding: 10px 0;
  border-radius: 5px;
  background: #1F2B43;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-phone .form-group-container .repeatable-container .single-element .buttons-container button.clone {
  box-shadow: none;
  border-radius: 3px;
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: initial;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  width: 34px;
  height: 34px;
  background: rgba(255, 255, 255, 0);
  border: 2px solid #FFFFFF;
  border: none;
}
addiction-phone .form-group-container .repeatable-container .single-element .buttons-container button.clone[aria-expanded=true] {
  border-radius: 0;
}
@media (hover: hover) and (pointer: fine) {
  addiction-phone .form-group-container .repeatable-container .single-element .buttons-container button.clone:hover {
    opacity: 0.5;
  }
}
addiction-phone .form-group-container .repeatable-container .single-element .buttons-container button.clone::before {
  content: "k";
  width: 16px;
  height: 16px;
  font-size: 16px;
  color: #FFFFFF;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 100%;
  font-family: "DataLean Font Icon";
  position: absolute;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-phone .form-group-container .repeatable-container .single-element .buttons-container button.clone:disabled, addiction-phone .form-group-container .repeatable-container .single-element .buttons-container button.clone.disabled, addiction-phone .form-group-container .repeatable-container .single-element .buttons-container button.clone[disabled], addiction-phone .form-group-container .repeatable-container .single-element .buttons-container button.clone[disabled=true] {
  opacity: 0.25;
  cursor: not-allowed;
  pointer-events: none;
}
addiction-phone .form-group-container .repeatable-container .single-element .buttons-container button.clone:disabled span, addiction-phone .form-group-container .repeatable-container .single-element .buttons-container button.clone.disabled span, addiction-phone .form-group-container .repeatable-container .single-element .buttons-container button.clone[disabled] span, addiction-phone .form-group-container .repeatable-container .single-element .buttons-container button.clone[disabled=true] span {
  cursor: not-allowed;
  pointer-events: none;
}
addiction-phone .form-group-container .repeatable-container .single-element .buttons-container button.accordion {
  box-shadow: none;
  border-radius: 3px;
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: initial;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  width: 34px;
  height: 34px;
  background: rgba(255, 255, 255, 0);
  border: 2px solid #FFFFFF;
  border: none;
}
addiction-phone .form-group-container .repeatable-container .single-element .buttons-container button.accordion[aria-expanded=true] {
  border-radius: 0;
}
@media (hover: hover) and (pointer: fine) {
  addiction-phone .form-group-container .repeatable-container .single-element .buttons-container button.accordion:hover {
    opacity: 0.5;
  }
}
addiction-phone .form-group-container .repeatable-container .single-element .buttons-container button.accordion::before {
  content: "a";
  width: 16px;
  height: 16px;
  font-size: 16px;
  color: #FFFFFF;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 100%;
  font-family: "DataLean Font Icon";
  position: absolute;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-phone .form-group-container .repeatable-container .single-element .buttons-container button.accordion:disabled, addiction-phone .form-group-container .repeatable-container .single-element .buttons-container button.accordion.disabled, addiction-phone .form-group-container .repeatable-container .single-element .buttons-container button.accordion[disabled], addiction-phone .form-group-container .repeatable-container .single-element .buttons-container button.accordion[disabled=true] {
  opacity: 0.25;
  cursor: not-allowed;
  pointer-events: none;
}
addiction-phone .form-group-container .repeatable-container .single-element .buttons-container button.accordion:disabled span, addiction-phone .form-group-container .repeatable-container .single-element .buttons-container button.accordion.disabled span, addiction-phone .form-group-container .repeatable-container .single-element .buttons-container button.accordion[disabled] span, addiction-phone .form-group-container .repeatable-container .single-element .buttons-container button.accordion[disabled=true] span {
  cursor: not-allowed;
  pointer-events: none;
}
addiction-phone .form-group-container .repeatable-container .single-element .buttons-container button.accordion::before {
  font-size: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2px;
  transform: rotate(90deg);
}
addiction-phone .form-group-container .repeatable-container .single-element .buttons-container button.accordion::after {
  content: "";
  width: 16px;
  height: 16px;
  border: 1px solid #FFFFFF;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}
addiction-phone .form-group-container .repeatable-container .single-element .buttons-container button.accordion.down::before {
  margin-left: -2px;
  transform: rotate(270deg);
}
addiction-phone .form-group-container .repeatable-container .single-element .buttons-container button.delete {
  box-shadow: none;
  border-radius: 3px;
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: initial;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  width: 34px;
  height: 34px;
  background: rgba(255, 255, 255, 0);
  border: 2px solid #FF4848;
  border: none;
}
addiction-phone .form-group-container .repeatable-container .single-element .buttons-container button.delete[aria-expanded=true] {
  border-radius: 0;
}
@media (hover: hover) and (pointer: fine) {
  addiction-phone .form-group-container .repeatable-container .single-element .buttons-container button.delete:hover {
    opacity: 0.5;
  }
}
addiction-phone .form-group-container .repeatable-container .single-element .buttons-container button.delete::before {
  content: "i";
  width: 16px;
  height: 16px;
  font-size: 16px;
  color: #FF4848;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 100%;
  font-family: "DataLean Font Icon";
  position: absolute;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-phone .form-group-container .repeatable-container .single-element .buttons-container button.delete:disabled, addiction-phone .form-group-container .repeatable-container .single-element .buttons-container button.delete.disabled, addiction-phone .form-group-container .repeatable-container .single-element .buttons-container button.delete[disabled], addiction-phone .form-group-container .repeatable-container .single-element .buttons-container button.delete[disabled=true] {
  opacity: 0.25;
  cursor: not-allowed;
  pointer-events: none;
}
addiction-phone .form-group-container .repeatable-container .single-element .buttons-container button.delete:disabled span, addiction-phone .form-group-container .repeatable-container .single-element .buttons-container button.delete.disabled span, addiction-phone .form-group-container .repeatable-container .single-element .buttons-container button.delete[disabled] span, addiction-phone .form-group-container .repeatable-container .single-element .buttons-container button.delete[disabled=true] span {
  cursor: not-allowed;
  pointer-events: none;
}
addiction-phone .form-group-container .repeatable-container button.add-element {
  width: auto;
  padding: 5px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 130%;
  letter-spacing: 2.5px;
  cursor: default;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  min-height: 36px;
  background: #004BC4;
  border: none;
  border-radius: 10px;
  max-width: max-content;
}
addiction-phone .form-group-container .repeatable-container button.add-element span {
  width: 100%;
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 130%;
  letter-spacing: 2.5px;
  cursor: default;
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  margin-top: 3px;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-phone .form-group-container .repeatable-container button.add-element[aria-expanded=true] {
  border-radius: 0;
}
@media (hover: hover) and (pointer: fine) {
  addiction-phone .form-group-container .repeatable-container button.add-element:hover {
    background: #FFFFFF;
    color: #004BC4;
  }
  addiction-phone .form-group-container .repeatable-container button.add-element:hover span {
    color: #004BC4;
  }
}
addiction-phone .form-group-container .repeatable-container button.add-element:disabled, addiction-phone .form-group-container .repeatable-container button.add-element.disabled, addiction-phone .form-group-container .repeatable-container button.add-element[disabled], addiction-phone .form-group-container .repeatable-container button.add-element[disabled=true] {
  opacity: 0.25;
  cursor: not-allowed;
  pointer-events: none;
}
addiction-phone .form-group-container .repeatable-container button.add-element:disabled span, addiction-phone .form-group-container .repeatable-container button.add-element.disabled span, addiction-phone .form-group-container .repeatable-container button.add-element[disabled] span, addiction-phone .form-group-container .repeatable-container button.add-element[disabled=true] span {
  cursor: not-allowed;
  pointer-events: none;
}
addiction-phone .form-group-container .accordion-container {
  width: 100%;
}
addiction-phone .form-group-container .accordion-container .accordion-item {
  width: 100%;
  display: flex;
  flex-flow: column;
  row-gap: 20px;
}
addiction-phone .form-group-container .accordion-container .accordion-item .accordion-header .title-fields-container {
  cursor: pointer;
}
addiction-phone .form-group-container .accordion-container .accordion-item .accordion-header .title-fields-container h2 {
  width: calc(100% - 40px);
  cursor: pointer;
}
addiction-phone .form-group-container .accordion-container .accordion-item .accordion-header .title-fields-container .accordion-arrow {
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0);
  border: none;
  padding: 0;
  position: relative;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}
addiction-phone .form-group-container .accordion-container .accordion-item .accordion-header .title-fields-container .accordion-arrow::before {
  content: "b";
  width: 7px;
  height: 7px;
  color: #FFFFFF;
  font-size: 7px;
  font-family: "DataLean Font Icon";
  position: absolute;
  pointer-events: none;
}
addiction-phone .form-group-container .accordion-container .accordion-item .accordion-header .title-fields-container .accordion-arrow.closed {
  transform: rotate(-90deg);
}
addiction-phone .form-group-container .accordion-container .accordion-item .accordion-content-container {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  row-gap: 20px;
}
addiction-phone .form-group-container .field-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: column;
  padding: 0 10px;
  overflow: visible;
  /* Crea in automatico le larghezze da 0 a 100 ogni 1 */
}
addiction-phone .form-group-container .field-container.ratio-0 {
  width: 0%;
}
addiction-phone .form-group-container .field-container.ratio-1 {
  width: 1%;
}
addiction-phone .form-group-container .field-container.ratio-2 {
  width: 2%;
}
addiction-phone .form-group-container .field-container.ratio-3 {
  width: 3%;
}
addiction-phone .form-group-container .field-container.ratio-4 {
  width: 4%;
}
addiction-phone .form-group-container .field-container.ratio-5 {
  width: 5%;
}
addiction-phone .form-group-container .field-container.ratio-6 {
  width: 6%;
}
addiction-phone .form-group-container .field-container.ratio-7 {
  width: 7%;
}
addiction-phone .form-group-container .field-container.ratio-8 {
  width: 8%;
}
addiction-phone .form-group-container .field-container.ratio-9 {
  width: 9%;
}
addiction-phone .form-group-container .field-container.ratio-10 {
  width: 10%;
}
addiction-phone .form-group-container .field-container.ratio-11 {
  width: 11%;
}
addiction-phone .form-group-container .field-container.ratio-12 {
  width: 12%;
}
addiction-phone .form-group-container .field-container.ratio-13 {
  width: 13%;
}
addiction-phone .form-group-container .field-container.ratio-14 {
  width: 14%;
}
addiction-phone .form-group-container .field-container.ratio-15 {
  width: 15%;
}
addiction-phone .form-group-container .field-container.ratio-16 {
  width: 16%;
}
addiction-phone .form-group-container .field-container.ratio-17 {
  width: 17%;
}
addiction-phone .form-group-container .field-container.ratio-18 {
  width: 18%;
}
addiction-phone .form-group-container .field-container.ratio-19 {
  width: 19%;
}
addiction-phone .form-group-container .field-container.ratio-20 {
  width: 20%;
}
addiction-phone .form-group-container .field-container.ratio-21 {
  width: 21%;
}
addiction-phone .form-group-container .field-container.ratio-22 {
  width: 22%;
}
addiction-phone .form-group-container .field-container.ratio-23 {
  width: 23%;
}
addiction-phone .form-group-container .field-container.ratio-24 {
  width: 24%;
}
addiction-phone .form-group-container .field-container.ratio-25 {
  width: 25%;
}
addiction-phone .form-group-container .field-container.ratio-26 {
  width: 26%;
}
addiction-phone .form-group-container .field-container.ratio-27 {
  width: 27%;
}
addiction-phone .form-group-container .field-container.ratio-28 {
  width: 28%;
}
addiction-phone .form-group-container .field-container.ratio-29 {
  width: 29%;
}
addiction-phone .form-group-container .field-container.ratio-30 {
  width: 30%;
}
addiction-phone .form-group-container .field-container.ratio-31 {
  width: 31%;
}
addiction-phone .form-group-container .field-container.ratio-32 {
  width: 32%;
}
addiction-phone .form-group-container .field-container.ratio-33 {
  width: 33%;
}
addiction-phone .form-group-container .field-container.ratio-34 {
  width: 34%;
}
addiction-phone .form-group-container .field-container.ratio-35 {
  width: 35%;
}
addiction-phone .form-group-container .field-container.ratio-36 {
  width: 36%;
}
addiction-phone .form-group-container .field-container.ratio-37 {
  width: 37%;
}
addiction-phone .form-group-container .field-container.ratio-38 {
  width: 38%;
}
addiction-phone .form-group-container .field-container.ratio-39 {
  width: 39%;
}
addiction-phone .form-group-container .field-container.ratio-40 {
  width: 40%;
}
addiction-phone .form-group-container .field-container.ratio-41 {
  width: 41%;
}
addiction-phone .form-group-container .field-container.ratio-42 {
  width: 42%;
}
addiction-phone .form-group-container .field-container.ratio-43 {
  width: 43%;
}
addiction-phone .form-group-container .field-container.ratio-44 {
  width: 44%;
}
addiction-phone .form-group-container .field-container.ratio-45 {
  width: 45%;
}
addiction-phone .form-group-container .field-container.ratio-46 {
  width: 46%;
}
addiction-phone .form-group-container .field-container.ratio-47 {
  width: 47%;
}
addiction-phone .form-group-container .field-container.ratio-48 {
  width: 48%;
}
addiction-phone .form-group-container .field-container.ratio-49 {
  width: 49%;
}
addiction-phone .form-group-container .field-container.ratio-50 {
  width: 50%;
}
addiction-phone .form-group-container .field-container.ratio-51 {
  width: 51%;
}
addiction-phone .form-group-container .field-container.ratio-52 {
  width: 52%;
}
addiction-phone .form-group-container .field-container.ratio-53 {
  width: 53%;
}
addiction-phone .form-group-container .field-container.ratio-54 {
  width: 54%;
}
addiction-phone .form-group-container .field-container.ratio-55 {
  width: 55%;
}
addiction-phone .form-group-container .field-container.ratio-56 {
  width: 56%;
}
addiction-phone .form-group-container .field-container.ratio-57 {
  width: 57%;
}
addiction-phone .form-group-container .field-container.ratio-58 {
  width: 58%;
}
addiction-phone .form-group-container .field-container.ratio-59 {
  width: 59%;
}
addiction-phone .form-group-container .field-container.ratio-60 {
  width: 60%;
}
addiction-phone .form-group-container .field-container.ratio-61 {
  width: 61%;
}
addiction-phone .form-group-container .field-container.ratio-62 {
  width: 62%;
}
addiction-phone .form-group-container .field-container.ratio-63 {
  width: 63%;
}
addiction-phone .form-group-container .field-container.ratio-64 {
  width: 64%;
}
addiction-phone .form-group-container .field-container.ratio-65 {
  width: 65%;
}
addiction-phone .form-group-container .field-container.ratio-66 {
  width: 66%;
}
addiction-phone .form-group-container .field-container.ratio-67 {
  width: 67%;
}
addiction-phone .form-group-container .field-container.ratio-68 {
  width: 68%;
}
addiction-phone .form-group-container .field-container.ratio-69 {
  width: 69%;
}
addiction-phone .form-group-container .field-container.ratio-70 {
  width: 70%;
}
addiction-phone .form-group-container .field-container.ratio-71 {
  width: 71%;
}
addiction-phone .form-group-container .field-container.ratio-72 {
  width: 72%;
}
addiction-phone .form-group-container .field-container.ratio-73 {
  width: 73%;
}
addiction-phone .form-group-container .field-container.ratio-74 {
  width: 74%;
}
addiction-phone .form-group-container .field-container.ratio-75 {
  width: 75%;
}
addiction-phone .form-group-container .field-container.ratio-76 {
  width: 76%;
}
addiction-phone .form-group-container .field-container.ratio-77 {
  width: 77%;
}
addiction-phone .form-group-container .field-container.ratio-78 {
  width: 78%;
}
addiction-phone .form-group-container .field-container.ratio-79 {
  width: 79%;
}
addiction-phone .form-group-container .field-container.ratio-80 {
  width: 80%;
}
addiction-phone .form-group-container .field-container.ratio-81 {
  width: 81%;
}
addiction-phone .form-group-container .field-container.ratio-82 {
  width: 82%;
}
addiction-phone .form-group-container .field-container.ratio-83 {
  width: 83%;
}
addiction-phone .form-group-container .field-container.ratio-84 {
  width: 84%;
}
addiction-phone .form-group-container .field-container.ratio-85 {
  width: 85%;
}
addiction-phone .form-group-container .field-container.ratio-86 {
  width: 86%;
}
addiction-phone .form-group-container .field-container.ratio-87 {
  width: 87%;
}
addiction-phone .form-group-container .field-container.ratio-88 {
  width: 88%;
}
addiction-phone .form-group-container .field-container.ratio-89 {
  width: 89%;
}
addiction-phone .form-group-container .field-container.ratio-90 {
  width: 90%;
}
addiction-phone .form-group-container .field-container.ratio-91 {
  width: 91%;
}
addiction-phone .form-group-container .field-container.ratio-92 {
  width: 92%;
}
addiction-phone .form-group-container .field-container.ratio-93 {
  width: 93%;
}
addiction-phone .form-group-container .field-container.ratio-94 {
  width: 94%;
}
addiction-phone .form-group-container .field-container.ratio-95 {
  width: 95%;
}
addiction-phone .form-group-container .field-container.ratio-96 {
  width: 96%;
}
addiction-phone .form-group-container .field-container.ratio-97 {
  width: 97%;
}
addiction-phone .form-group-container .field-container.ratio-98 {
  width: 98%;
}
addiction-phone .form-group-container .field-container.ratio-99 {
  width: 99%;
}
addiction-phone .form-group-container .field-container.ratio-100 {
  width: 100%;
}
addiction-phone .form-group-container .field-container.ratio-66 {
  width: 66.666%;
}
addiction-phone .form-group-container .field-container.ratio-33 {
  width: 33.333%;
}
addiction-phone .form-group-container .field-container.ratio-0, addiction-phone .form-group-container .field-container.radio-null, addiction-phone .form-group-container .field-container.radio-undefined {
  width: 0;
  display: none;
}
addiction-phone .form-group-container .field-container.ratio-auto {
  width: auto;
}
addiction-phone .form-group-container .field-container.ratio-auto addiction-date-picker {
  min-width: 420px;
}
addiction-phone .form-group-container .field-container.bigger > .text {
  font-size: 20px;
}
addiction-phone .form-group-container .field-container.bigger addiction-input::ng-deep .form-field .form-input-container.number::before {
  right: 15px;
  top: 15px;
}
addiction-phone .form-group-container .field-container.bigger addiction-input::ng-deep .form-field .form-input-container.number::after {
  right: 15px;
  bottom: 15px;
}
addiction-phone .form-group-container .field-container.bigger addiction-input::ng-deep .form-field .form-input-container input {
  height: 60px;
  padding: 5px 15px;
  font-family: "Segma", sans-serif;
  font-size: 20px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 100%;
  letter-spacing: 1px;
  cursor: default;
  cursor: text;
}
addiction-phone .form-group-container .field-container.bigger addiction-select::ng-deep .select-container select {
  height: 60px;
  padding: 5px 15px;
  font-family: "Segma", sans-serif;
  font-size: 20px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 100%;
  letter-spacing: 1px;
  cursor: default;
  cursor: text;
}
addiction-phone .form-group-container .field-container.bigger addiction-select::ng-deep mat-form-field {
  height: 60px;
}
addiction-phone .form-group-container .field-container.bigger addiction-select::ng-deep mat-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-select {
  padding: 5px 15px;
}
addiction-phone .form-group-container .field-container.bigger addiction-select::ng-deep mat-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-select .mat-mdc-select-trigger .mat-mdc-select-value .mat-mdc-select-min-line {
  font-family: "Segma", sans-serif;
  font-size: 20px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 100%;
  letter-spacing: 1px;
  cursor: default;
  cursor: pointer;
}
addiction-phone .form-group-container .field-container.bigger .info-container .value {
  font-size: 20px;
}
addiction-phone .form-group-container .field-container.entity-version {
  display: flex;
  flex-flow: row;
  align-items: center;
}
addiction-phone .form-group-container .field-container.entity-version label {
  width: 50%;
  margin: 0;
}
addiction-phone .form-group-container .field-container.entity-version .entity-version-container {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
}
addiction-phone .form-group-container .field-container.entity-version .entity-version-container .version {
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 100%;
  letter-spacing: 0;
  cursor: default;
  text-transform: uppercase;
}
addiction-phone .form-group-container .field-container.entity-version .entity-version-container button.versioning {
  box-shadow: none;
  border-radius: 3px;
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: initial;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  width: 34px;
  height: 34px;
  background: rgba(255, 255, 255, 0);
  border: 2px solid #004BC4;
  height: 20px;
  border: none;
}
addiction-phone .form-group-container .field-container.entity-version .entity-version-container button.versioning[aria-expanded=true] {
  border-radius: 0;
}
@media (hover: hover) and (pointer: fine) {
  addiction-phone .form-group-container .field-container.entity-version .entity-version-container button.versioning:hover {
    opacity: 0.5;
  }
}
addiction-phone .form-group-container .field-container.entity-version .entity-version-container button.versioning::before {
  content: "w";
  width: 16px;
  height: 16px;
  font-size: 16px;
  color: #FFFFFF;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 100%;
  font-family: "DataLean Font Icon";
  position: absolute;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-phone .form-group-container .field-container.entity-version .entity-version-container button.versioning:disabled, addiction-phone .form-group-container .field-container.entity-version .entity-version-container button.versioning.disabled, addiction-phone .form-group-container .field-container.entity-version .entity-version-container button.versioning[disabled], addiction-phone .form-group-container .field-container.entity-version .entity-version-container button.versioning[disabled=true] {
  opacity: 0.25;
  cursor: not-allowed;
  pointer-events: none;
}
addiction-phone .form-group-container .field-container.entity-version .entity-version-container button.versioning:disabled span, addiction-phone .form-group-container .field-container.entity-version .entity-version-container button.versioning.disabled span, addiction-phone .form-group-container .field-container.entity-version .entity-version-container button.versioning[disabled] span, addiction-phone .form-group-container .field-container.entity-version .entity-version-container button.versioning[disabled=true] span {
  cursor: not-allowed;
  pointer-events: none;
}
addiction-phone .form-group-container .field-container.entity-version .entity-version-container button.checked {
  box-shadow: none;
  border-radius: 3px;
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: initial;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  width: 34px;
  height: 34px;
  background: rgba(255, 255, 255, 0);
  border: 2px solid #09152C;
  height: 20px;
  border: none;
}
addiction-phone .form-group-container .field-container.entity-version .entity-version-container button.checked[aria-expanded=true] {
  border-radius: 0;
}
@media (hover: hover) and (pointer: fine) {
  addiction-phone .form-group-container .field-container.entity-version .entity-version-container button.checked:hover {
    opacity: 0.5;
  }
}
addiction-phone .form-group-container .field-container.entity-version .entity-version-container button.checked::before {
  content: "c";
  width: 16px;
  height: 16px;
  font-size: 16px;
  color: #4F8A10;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 100%;
  font-family: "DataLean Font Icon";
  position: absolute;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-phone .form-group-container .field-container.entity-version .entity-version-container button.checked:disabled, addiction-phone .form-group-container .field-container.entity-version .entity-version-container button.checked.disabled, addiction-phone .form-group-container .field-container.entity-version .entity-version-container button.checked[disabled], addiction-phone .form-group-container .field-container.entity-version .entity-version-container button.checked[disabled=true] {
  opacity: 0.25;
  cursor: not-allowed;
  pointer-events: none;
}
addiction-phone .form-group-container .field-container.entity-version .entity-version-container button.checked:disabled span, addiction-phone .form-group-container .field-container.entity-version .entity-version-container button.checked.disabled span, addiction-phone .form-group-container .field-container.entity-version .entity-version-container button.checked[disabled] span, addiction-phone .form-group-container .field-container.entity-version .entity-version-container button.checked[disabled=true] span {
  cursor: not-allowed;
  pointer-events: none;
}
addiction-phone .form-group-container .field-container.entity-version .entity-version-container button.refused {
  box-shadow: none;
  border-radius: 3px;
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: initial;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  width: 34px;
  height: 34px;
  background: rgba(255, 255, 255, 0);
  border: 2px solid #09152C;
  height: 20px;
  border: none;
}
addiction-phone .form-group-container .field-container.entity-version .entity-version-container button.refused[aria-expanded=true] {
  border-radius: 0;
}
@media (hover: hover) and (pointer: fine) {
  addiction-phone .form-group-container .field-container.entity-version .entity-version-container button.refused:hover {
    opacity: 0.5;
  }
}
addiction-phone .form-group-container .field-container.entity-version .entity-version-container button.refused::before {
  content: "d";
  width: 12px;
  height: 12px;
  font-size: 12px;
  color: #A20000;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 100%;
  font-family: "DataLean Font Icon";
  position: absolute;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-phone .form-group-container .field-container.entity-version .entity-version-container button.refused:disabled, addiction-phone .form-group-container .field-container.entity-version .entity-version-container button.refused.disabled, addiction-phone .form-group-container .field-container.entity-version .entity-version-container button.refused[disabled], addiction-phone .form-group-container .field-container.entity-version .entity-version-container button.refused[disabled=true] {
  opacity: 0.25;
  cursor: not-allowed;
  pointer-events: none;
}
addiction-phone .form-group-container .field-container.entity-version .entity-version-container button.refused:disabled span, addiction-phone .form-group-container .field-container.entity-version .entity-version-container button.refused.disabled span, addiction-phone .form-group-container .field-container.entity-version .entity-version-container button.refused[disabled] span, addiction-phone .form-group-container .field-container.entity-version .entity-version-container button.refused[disabled=true] span {
  cursor: not-allowed;
  pointer-events: none;
}
addiction-phone .form-group-container .field-container label,
addiction-phone .form-group-container .field-container .label {
  width: 100%;
  font-family: "Segma", sans-serif;
  font-size: 10px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
  line-height: 100%;
  letter-spacing: 2.5px;
  cursor: default;
  text-transform: uppercase;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  display: flex;
  align-items: center;
}
addiction-phone .form-group-container .field-container label .tooltip,
addiction-phone .form-group-container .field-container .label .tooltip {
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  position: relative;
  cursor: pointer;
}
addiction-phone .form-group-container .field-container label .tooltip::before,
addiction-phone .form-group-container .field-container .label .tooltip::before {
  content: "F";
  font-family: "DataLean Font Icon";
  font-size: 13px;
  color: #FFFFFF;
  position: absolute;
  pointer-events: none;
}
addiction-phone .form-group-container .field-container .required {
  width: auto;
  font-family: "Segma", sans-serif;
  font-size: 10px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
  line-height: 100%;
  letter-spacing: 2.5px;
  cursor: default;
  text-transform: uppercase;
}
addiction-phone .form-group-container .field-container .title {
  display: flex;
  align-items: center;
  display: flex;
  align-items: center;
}
addiction-phone .form-group-container .field-container .title .tooltip {
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  position: relative;
  cursor: pointer;
}
addiction-phone .form-group-container .field-container .title .tooltip::before {
  content: "F";
  font-family: "DataLean Font Icon";
  font-size: 15px;
  color: #FFFFFF;
  position: absolute;
  pointer-events: none;
}
addiction-phone .form-group-container .field-container > .text {
  width: 100%;
  font-family: "Segma", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 180%;
  letter-spacing: 1px;
  cursor: default;
}
addiction-phone .form-group-container .field-container .skeleton {
  background: linear-gradient(110deg, rgba(1, 40, 108, 0) 40%, rgba(1, 40, 108, 0.5) 60%, rgba(1, 40, 108, 0) 70%) rgba(0, 75, 196, 0.2);
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-in-out infinite;
}
addiction-phone .form-group-container .field-container .skeleton.label {
  width: 30%;
  height: 10px;
  margin-bottom: 10px;
  border-radius: 3px;
}
addiction-phone .form-group-container .field-container .skeleton.input {
  width: 100%;
  height: 36px;
  border-radius: 5px;
}
addiction-phone .form-group-container .field-container addiction-input { /* stile dentro addiction-components.scss */ }
addiction-phone .form-group-container .field-container addiction-select { /* stile dentro addiction-components.scss */ }
addiction-phone .form-group-container .field-container addiction-email { /* stile dentro addiction-components.scss */ }
addiction-phone .form-group-container .field-container mat-slide-toggle {
  appearance: none;
}
addiction-phone .form-group-container .field-container mat-slide-toggle[aria-readonly=true], addiction-phone .form-group-container .field-container mat-slide-toggle[aria-disabled=true] {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}
addiction-phone .form-group-container .field-container mat-slide-toggle::ng-deep .mdc-form-field {
  appearance: none;
}
addiction-phone .form-group-container .field-container mat-slide-toggle::ng-deep .mdc-form-field button.mdc-switch {
  --mdc-switch-handle-height: 26px;
  --mdc-switch-handle-shape: 13px;
  --mdc-switch-handle-width: 26px;
  --mdc-switch-track-height: 36px;
  --mdc-switch-track-shape: 18px;
  --mdc-switch-track-width: 70px;
  --mdc-switch-disabled-handle-opacity: 1;
  --mdc-switch-disabled-selected-icon-opacity: 1;
  --mdc-switch-disabled-track-opacity: 1;
  --mdc-switch-disabled-unselected-icon-opacity: 1;
  --mdc-switch-selected-focus-state-layer-opacity: 1;
  --mdc-switch-selected-hover-state-layer-opacity: 1;
  --mdc-switch-selected-pressed-state-layer-opacity: 1;
  --mdc-switch-unselected-focus-state-layer-opacity: 1;
  --mdc-switch-unselected-hover-state-layer-opacity: 1;
  --mdc-switch-unselected-pressed-state-layer-opacity: 1;
}
addiction-phone .form-group-container .field-container mat-slide-toggle::ng-deep .mdc-form-field button.mdc-switch.mdc-switch--checked .mdc-switch__handle-track {
  margin: 0 -5px;
}
addiction-phone .form-group-container .field-container mat-slide-toggle::ng-deep .mdc-form-field button.mdc-switch.mdc-switch--checked .mdc-switch__handle-track .mdc-switch__handle::after {
  background: #FFFFFF !important;
}
addiction-phone .form-group-container .field-container mat-slide-toggle::ng-deep .mdc-form-field button.mdc-switch.mdc-switch--checked .mdc-switch__handle-track .mdc-switch__handle .mdc-switch__icons {
  display: flex;
  justify-content: center;
  align-items: center;
}
addiction-phone .form-group-container .field-container mat-slide-toggle::ng-deep .mdc-form-field button.mdc-switch.mdc-switch--checked .mdc-switch__handle-track .mdc-switch__handle .mdc-switch__icons::before {
  content: "c";
  font-size: 9px;
  margin-top: 2px;
  color: #004BC4;
}
addiction-phone .form-group-container .field-container mat-slide-toggle::ng-deep .mdc-form-field button.mdc-switch .mdc-switch__track::before {
  background: rgba(255, 255, 255, 0.1) !important;
}
addiction-phone .form-group-container .field-container mat-slide-toggle::ng-deep .mdc-form-field button.mdc-switch .mdc-switch__track::after {
  background: #004BC4 !important;
}
addiction-phone .form-group-container .field-container mat-slide-toggle::ng-deep .mdc-form-field button.mdc-switch .mdc-switch__handle-track {
  margin: 0 5px;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-phone .form-group-container .field-container mat-slide-toggle::ng-deep .mdc-form-field button.mdc-switch .mdc-switch__handle-track .mdc-switch__handle {
  --mat-switch-with-icon-handle-size: 26px;
}
addiction-phone .form-group-container .field-container mat-slide-toggle::ng-deep .mdc-form-field button.mdc-switch .mdc-switch__handle-track .mdc-switch__handle::before {
  display: none;
}
addiction-phone .form-group-container .field-container mat-slide-toggle::ng-deep .mdc-form-field button.mdc-switch .mdc-switch__handle-track .mdc-switch__handle::after {
  background: #004BC4 !important;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-phone .form-group-container .field-container mat-slide-toggle::ng-deep .mdc-form-field button.mdc-switch .mdc-switch__handle-track .mdc-switch__handle .mdc-switch__shadow,
addiction-phone .form-group-container .field-container mat-slide-toggle::ng-deep .mdc-form-field button.mdc-switch .mdc-switch__handle-track .mdc-switch__handle .mdc-switch__ripple {
  display: none;
}
addiction-phone .form-group-container .field-container mat-slide-toggle::ng-deep .mdc-form-field button.mdc-switch .mdc-switch__handle-track .mdc-switch__handle .mdc-switch__icons {
  display: flex;
  justify-content: center;
  align-items: center;
}
addiction-phone .form-group-container .field-container mat-slide-toggle::ng-deep .mdc-form-field button.mdc-switch .mdc-switch__handle-track .mdc-switch__handle .mdc-switch__icons::before {
  content: "d";
  width: 10px;
  height: 10px;
  font-size: 7px;
  color: #FFFFFF;
  font-family: "DataLean Font Icon";
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  pointer-events: none;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-phone .form-group-container .field-container mat-slide-toggle::ng-deep .mdc-form-field button.mdc-switch .mdc-switch__handle-track .mdc-switch__handle .mdc-switch__icons svg {
  display: none;
}
addiction-phone .form-group-container .field-container mat-slide-toggle::ng-deep .mdc-form-field label {
  font-family: "Segma", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 130%;
  letter-spacing: 0;
  cursor: default;
  margin-left: 10px;
  display: flex;
  align-items: center;
}
addiction-phone .form-group-container .field-container mat-slide-toggle::ng-deep .mdc-form-field label .tooltip {
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  position: relative;
  cursor: pointer;
}
addiction-phone .form-group-container .field-container mat-slide-toggle::ng-deep .mdc-form-field label .tooltip::before {
  content: "F";
  font-family: "DataLean Font Icon";
  font-size: 13px;
  color: #FFFFFF;
  position: absolute;
  pointer-events: none;
}
addiction-phone .form-group-container .field-container .info-container { /* campo con solo informazioni es. "tipo" "formato" "data" */
  width: 100%;
}
addiction-phone .form-group-container .field-container .info-container .label {
  font-family: "Segma", sans-serif;
  font-size: 10px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
  line-height: 100%;
  letter-spacing: 2.5px;
  cursor: default;
  text-transform: uppercase;
  margin-bottom: 10px;
}
addiction-phone .form-group-container .field-container .info-container .value {
  font-family: "Segma", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 160%;
  letter-spacing: 0.5px;
  cursor: default;
  word-break: break-all;
}
addiction-phone .form-group-container .field-container .answers-container {
  width: 100%;
  display: flex;
  flex-flow: column;
}
addiction-phone .form-group-container .field-container .answers-container label {
  margin: 20px 0;
}
addiction-phone .form-group-container .field-container .answers-container .single-answer {
  width: 100%;
  display: flex;
  flex-flow: column;
  row-gap: 20px;
}
addiction-phone .form-group-container .field-container .answers-container .single-answer .accordion-header {
  min-height: 56px;
  border-bottom: 1px solid rgba(61, 93, 150, 0.15);
  display: flex;
  align-items: center;
  cursor: pointer;
}
addiction-phone .form-group-container .field-container .answers-container .single-answer .accordion-header .left-container {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
addiction-phone .form-group-container .field-container .answers-container .single-answer .accordion-header .left-container .number {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  background: #1F2B43;
  margin-right: 20px;
  display: flex;
  flex-flow: column;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  font-family: "Segma", sans-serif;
  font-size: 10px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 100%;
  letter-spacing: 1.5px;
  cursor: default;
  cursor: pointer;
  text-align: center;
}
addiction-phone .form-group-container .field-container .answers-container .single-answer .accordion-header .left-container .title {
  font-family: "Segma", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 130%;
  letter-spacing: 0;
  cursor: default;
  cursor: pointer;
}
addiction-phone .form-group-container .field-container .answers-container .single-answer .accordion-header .right-container {
  width: 200px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
addiction-phone .form-group-container .field-container .answers-container .single-answer .accordion-header .right-container .count {
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 130%;
  letter-spacing: 0;
  cursor: default;
  cursor: pointer;
}
addiction-phone .form-group-container .field-container .answers-container .single-answer .accordion-header .right-container .accordion-arrow {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  background: #004BC4;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  position: relative;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-phone .form-group-container .field-container .answers-container .single-answer .accordion-header .right-container .accordion-arrow::before {
  content: "b";
  width: 7px;
  height: 7px;
  color: #FFFFFF;
  font-size: 7px;
  font-family: "DataLean Font Icon";
  position: absolute;
  pointer-events: none;
  transform: rotate(0deg);
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-phone .form-group-container .field-container .answers-container .single-answer .accordion-header .right-container .accordion-arrow.closed {
  background: #1F2B43;
}
addiction-phone .form-group-container .field-container .answers-container .single-answer .accordion-header .right-container .accordion-arrow.closed::before {
  transform: rotate(90deg);
}
addiction-phone .form-group-container .field-container .answers-container .single-answer .accordion-content-container {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  row-gap: 20px;
  margin: 20px 0;
}
addiction-phone .form-group-container .field-container .feature-chips-selector-container {
  width: 100%;
}
addiction-phone .form-group-container .field-container .feature-chips-selector-container datalean-feature-chips-selector { /* stile dentro chip-selector.component.scss */ }
addiction-phone .form-group-container .field-container .asset-selector-container { /* stile dentro asset-selector.component.scss */ }
addiction-phone .form-group-container .field-container .single-thumbnail-container {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
}
addiction-phone .form-group-container .field-container .single-thumbnail-container .dimensions,
addiction-phone .form-group-container .field-container .single-thumbnail-container .bytes {
  flex: 1;
  font-family: "Segma", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 150%;
  letter-spacing: 1px;
  cursor: default;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
addiction-phone .form-group-container .field-container .single-thumbnail-container .bytes {
  padding: 0 10px;
}
addiction-phone .form-group-container .field-container .single-thumbnail-container button.download {
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0);
  box-shadow: none;
  border: none;
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-phone .form-group-container .field-container .single-thumbnail-container button.download::before {
  content: "K";
  width: 16px;
  height: 16px;
  font-size: 16px;
  color: #FFFFFF;
  font-family: "DataLean Font Icon";
  position: absolute;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
@media (hover: hover) and (pointer: fine) {
  addiction-phone .form-group-container .field-container .single-thumbnail-container button.download:hover {
    opacity: 0.5;
  }
}
addiction-phone .form-group-container .field-container .single-thumbnail-container button.download:disabled, addiction-phone .form-group-container .field-container .single-thumbnail-container button.download.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
addiction-phone .form-group-container .field-container .buttons-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-flow: row;
}
addiction-phone .form-group-container .field-container .buttons-container button {
  width: auto;
  padding: 5px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 130%;
  letter-spacing: 2.5px;
  cursor: default;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  min-height: 36px;
  background: #004BC4;
  border: none;
  border-radius: 10px;
  margin-left: 20px;
}
addiction-phone .form-group-container .field-container .buttons-container button span {
  width: 100%;
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 130%;
  letter-spacing: 2.5px;
  cursor: default;
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  margin-top: 3px;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-phone .form-group-container .field-container .buttons-container button[aria-expanded=true] {
  border-radius: 0;
}
@media (hover: hover) and (pointer: fine) {
  addiction-phone .form-group-container .field-container .buttons-container button:hover {
    background: #FFFFFF;
    color: #004BC4;
  }
  addiction-phone .form-group-container .field-container .buttons-container button:hover span {
    color: #004BC4;
  }
}
addiction-phone .form-group-container .field-container .buttons-container button:disabled, addiction-phone .form-group-container .field-container .buttons-container button.disabled, addiction-phone .form-group-container .field-container .buttons-container button[disabled], addiction-phone .form-group-container .field-container .buttons-container button[disabled=true] {
  opacity: 0.25;
  cursor: not-allowed;
  pointer-events: none;
}
addiction-phone .form-group-container .field-container .buttons-container button:disabled span, addiction-phone .form-group-container .field-container .buttons-container button.disabled span, addiction-phone .form-group-container .field-container .buttons-container button[disabled] span, addiction-phone .form-group-container .field-container .buttons-container button[disabled=true] span {
  cursor: not-allowed;
  pointer-events: none;
}
addiction-phone .form-group-container .field-container .simple-container-container {
  width: 100%;
  display: flex;
  flex-flow: column;
  margin: 10px 0;
  padding-left: 17px;
  border-left: 3px solid rgba(0, 75, 196, 0.8);
}
addiction-phone .form-group-container .field-container .simple-container-container.no-border {
  padding-left: 0;
  border-left: 0;
}
addiction-phone .form-group-container .field-container .simple-container-container .container-title {
  width: 100%;
  font-family: "Segma", sans-serif;
  font-size: 20px;
  font-weight: 300;
  color: #FFFFFF;
  line-height: 100%;
  letter-spacing: 0;
  cursor: default;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
addiction-phone .form-group-container .field-container .simple-container-container .container-title .title {
  font-family: "Segma", sans-serif;
  font-size: 20px;
  font-weight: 300;
  color: #FFFFFF;
  line-height: 100%;
  letter-spacing: 0;
  cursor: default;
  cursor: pointer;
}
addiction-phone .form-group-container .field-container .simple-container-container .container-title.accordion {
  justify-content: space-between;
}
addiction-phone .form-group-container .field-container .simple-container-container .container-title.accordion button.accordion {
  box-shadow: none;
  border-radius: 3px;
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: initial;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  width: 34px;
  height: 34px;
  background: rgba(255, 255, 255, 0);
  border: 2px solid #FFFFFF;
  border: none;
}
addiction-phone .form-group-container .field-container .simple-container-container .container-title.accordion button.accordion[aria-expanded=true] {
  border-radius: 0;
}
@media (hover: hover) and (pointer: fine) {
  addiction-phone .form-group-container .field-container .simple-container-container .container-title.accordion button.accordion:hover {
    opacity: 0.5;
  }
}
addiction-phone .form-group-container .field-container .simple-container-container .container-title.accordion button.accordion::before {
  content: "a";
  width: 16px;
  height: 16px;
  font-size: 16px;
  color: #FFFFFF;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 100%;
  font-family: "DataLean Font Icon";
  position: absolute;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-phone .form-group-container .field-container .simple-container-container .container-title.accordion button.accordion:disabled, addiction-phone .form-group-container .field-container .simple-container-container .container-title.accordion button.accordion.disabled, addiction-phone .form-group-container .field-container .simple-container-container .container-title.accordion button.accordion[disabled], addiction-phone .form-group-container .field-container .simple-container-container .container-title.accordion button.accordion[disabled=true] {
  opacity: 0.25;
  cursor: not-allowed;
  pointer-events: none;
}
addiction-phone .form-group-container .field-container .simple-container-container .container-title.accordion button.accordion:disabled span, addiction-phone .form-group-container .field-container .simple-container-container .container-title.accordion button.accordion.disabled span, addiction-phone .form-group-container .field-container .simple-container-container .container-title.accordion button.accordion[disabled] span, addiction-phone .form-group-container .field-container .simple-container-container .container-title.accordion button.accordion[disabled=true] span {
  cursor: not-allowed;
  pointer-events: none;
}
addiction-phone .form-group-container .field-container .simple-container-container .container-title.accordion button.accordion::before {
  font-size: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2px;
  transform: rotate(90deg);
}
addiction-phone .form-group-container .field-container .simple-container-container .container-title.accordion button.accordion::after {
  content: "";
  width: 16px;
  height: 16px;
  border: 1px solid #FFFFFF;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}
addiction-phone .form-group-container .field-container .simple-container-container .container-title.accordion button.accordion.down::before {
  margin-left: -2px;
  transform: rotate(270deg);
}
addiction-phone .form-group-container .field-container .simple-container-container .accordion-content.collapsed {
  height: 0;
  overflow: hidden;
}
addiction-phone .form-group-container .field-container .simple-container-container .accordion-content.collapsed > * {
  display: none;
}
addiction-phone .form-group-container .field-container .simple-container-container .accordion-content.expanded {
  height: auto;
  overflow: visible;
}
addiction-phone .form-group-container .field-container .simple-container-container datalean-container,
addiction-phone .form-group-container .field-container .simple-container-container .datalean-container {
  /* stile dentro container.component.scss */
  width: calc(100% + 20px);
  margin-left: -10px;
}
addiction-phone .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container,
addiction-phone .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container,
addiction-phone .form-group-container .field-container .simple-container-container .datalean-container .container form .field-container .simple-container-container,
addiction-phone .form-group-container .field-container .simple-container-container .datalean-container .container .form-group-container .field-container .simple-container-container {
  border-left: 3px solid rgba(0, 75, 196, 0.6);
}
addiction-phone .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container,
addiction-phone .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container,
addiction-phone .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container,
addiction-phone .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container,
addiction-phone .form-group-container .field-container .simple-container-container .datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container,
addiction-phone .form-group-container .field-container .simple-container-container .datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container,
addiction-phone .form-group-container .field-container .simple-container-container .datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container,
addiction-phone .form-group-container .field-container .simple-container-container .datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container {
  border-left: 3px solid rgba(0, 75, 196, 0.4);
}
addiction-phone .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container,
addiction-phone .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container,
addiction-phone .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container,
addiction-phone .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container,
addiction-phone .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container,
addiction-phone .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container,
addiction-phone .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container,
addiction-phone .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container,
addiction-phone .form-group-container .field-container .simple-container-container .datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container,
addiction-phone .form-group-container .field-container .simple-container-container .datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container,
addiction-phone .form-group-container .field-container .simple-container-container .datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container,
addiction-phone .form-group-container .field-container .simple-container-container .datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container,
addiction-phone .form-group-container .field-container .simple-container-container .datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container,
addiction-phone .form-group-container .field-container .simple-container-container .datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container,
addiction-phone .form-group-container .field-container .simple-container-container .datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container,
addiction-phone .form-group-container .field-container .simple-container-container .datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container {
  border-left: 3px solid rgba(0, 75, 196, 0.2);
}
addiction-phone .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container,
addiction-phone .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container,
addiction-phone .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container,
addiction-phone .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container,
addiction-phone .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container,
addiction-phone .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container,
addiction-phone .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container,
addiction-phone .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container,
addiction-phone .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container,
addiction-phone .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container,
addiction-phone .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container,
addiction-phone .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container,
addiction-phone .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container,
addiction-phone .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container,
addiction-phone .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container,
addiction-phone .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container,
addiction-phone .form-group-container .field-container .simple-container-container .datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container,
addiction-phone .form-group-container .field-container .simple-container-container .datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container,
addiction-phone .form-group-container .field-container .simple-container-container .datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container,
addiction-phone .form-group-container .field-container .simple-container-container .datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container,
addiction-phone .form-group-container .field-container .simple-container-container .datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container,
addiction-phone .form-group-container .field-container .simple-container-container .datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container,
addiction-phone .form-group-container .field-container .simple-container-container .datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container,
addiction-phone .form-group-container .field-container .simple-container-container .datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container,
addiction-phone .form-group-container .field-container .simple-container-container .datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container,
addiction-phone .form-group-container .field-container .simple-container-container .datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container,
addiction-phone .form-group-container .field-container .simple-container-container .datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container,
addiction-phone .form-group-container .field-container .simple-container-container .datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container,
addiction-phone .form-group-container .field-container .simple-container-container .datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container,
addiction-phone .form-group-container .field-container .simple-container-container .datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container,
addiction-phone .form-group-container .field-container .simple-container-container .datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container form .field-container .simple-container-container,
addiction-phone .form-group-container .field-container .simple-container-container .datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container datalean-container .container .form-group-container .field-container .simple-container-container {
  border-left: 3px solid rgba(0, 75, 196, 0.1);
}
addiction-phone .form-group-container .field-container .external-file-selector {
  width: 100%;
}
addiction-phone .form-group-container .field-container .external-file-selector input[type=file] {
  width: 100%;
  height: 36px;
  font-family: "Segma", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 100%;
  letter-spacing: 0;
  cursor: default;
  cursor: pointer;
  padding: 0;
  background: rgba(255, 255, 255, 0.05);
  border: none;
  margin: 0;
  border-radius: 10px 5px 5px 10px;
  position: relative;
}
addiction-phone .form-group-container .field-container .external-file-selector input[type=file]::placeholder {
  font-family: "Segma", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
  line-height: 100%;
  letter-spacing: 0;
  cursor: default;
}
addiction-phone .form-group-container .field-container .external-file-selector input[type=file]::file-selector-button {
  width: auto;
  padding: 5px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 130%;
  letter-spacing: 2.5px;
  cursor: default;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  min-height: 36px;
  background: #004BC4;
  border: none;
  border-radius: 10px;
  width: 150px;
  padding: 0;
  margin-right: 10px;
  display: inline-block;
}
addiction-phone .form-group-container .field-container .external-file-selector input[type=file]::file-selector-button span {
  width: 100%;
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 130%;
  letter-spacing: 2.5px;
  cursor: default;
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  margin-top: 3px;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-phone .form-group-container .field-container .external-file-selector input[type=file]::file-selector-button[aria-expanded=true] {
  border-radius: 0;
}
@media (hover: hover) and (pointer: fine) {
  addiction-phone .form-group-container .field-container .external-file-selector input[type=file]::file-selector-button:hover {
    background: #FFFFFF;
    color: #004BC4;
  }
  addiction-phone .form-group-container .field-container .external-file-selector input[type=file]::file-selector-button:hover span {
    color: #004BC4;
  }
}
addiction-phone .form-group-container .field-container .external-file-selector input[type=file]::file-selector-button:disabled, addiction-phone .form-group-container .field-container .external-file-selector input[type=file]::file-selector-button.disabled, addiction-phone .form-group-container .field-container .external-file-selector input[type=file]::file-selector-button[disabled], addiction-phone .form-group-container .field-container .external-file-selector input[type=file]::file-selector-button[disabled=true] {
  opacity: 0.25;
  cursor: not-allowed;
  pointer-events: none;
}
addiction-phone .form-group-container .field-container .external-file-selector input[type=file]::file-selector-button:disabled span, addiction-phone .form-group-container .field-container .external-file-selector input[type=file]::file-selector-button.disabled span, addiction-phone .form-group-container .field-container .external-file-selector input[type=file]::file-selector-button[disabled] span, addiction-phone .form-group-container .field-container .external-file-selector input[type=file]::file-selector-button[disabled=true] span {
  cursor: not-allowed;
  pointer-events: none;
}
addiction-phone .form-group-container .field-container .search-list-field {
  width: 100%;
  display: flex;
  flex-flow: column;
}
addiction-phone .form-group-container .field-container .search-list-field .search-container {
  display: flex;
  gap: 20px;
}
addiction-phone .form-group-container .field-container .search-list-field .search-container addiction-search {
  flex: 1;
}
addiction-phone .form-group-container .field-container .search-list-field .search-container button.add {
  box-shadow: none;
  border-radius: 3px;
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: initial;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  width: 36px;
  height: 36px;
  background: #004BC4;
  border: none;
}
addiction-phone .form-group-container .field-container .search-list-field .search-container button.add[aria-expanded=true] {
  border-radius: 0;
}
@media (hover: hover) and (pointer: fine) {
  addiction-phone .form-group-container .field-container .search-list-field .search-container button.add:hover {
    background: #FFFFFF;
  }
  addiction-phone .form-group-container .field-container .search-list-field .search-container button.add:hover::before {
    color: #004BC4;
  }
}
addiction-phone .form-group-container .field-container .search-list-field .search-container button.add::before {
  content: "e";
  width: 16px;
  height: 16px;
  font-size: 16px;
  color: #FFFFFF;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 100%;
  font-family: "DataLean Font Icon";
  position: absolute;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-phone .form-group-container .field-container .search-list-field .search-container button.add:disabled, addiction-phone .form-group-container .field-container .search-list-field .search-container button.add.disabled, addiction-phone .form-group-container .field-container .search-list-field .search-container button.add[disabled], addiction-phone .form-group-container .field-container .search-list-field .search-container button.add[disabled=true] {
  opacity: 0.25;
  cursor: not-allowed;
  pointer-events: none;
}
addiction-phone .form-group-container .field-container .search-list-field .search-container button.add:disabled span, addiction-phone .form-group-container .field-container .search-list-field .search-container button.add.disabled span, addiction-phone .form-group-container .field-container .search-list-field .search-container button.add[disabled] span, addiction-phone .form-group-container .field-container .search-list-field .search-container button.add[disabled=true] span {
  cursor: not-allowed;
  pointer-events: none;
}
addiction-phone .form-group-container .field-container .search-list-field .list-container {
  width: calc(100% + 40px);
  margin-left: -20px;
}
addiction-phone .form-group-container .field-container .search-list-field .list-container addiction-list {
  height: 250px !important;
}
addiction-phone .form-group-container .field-container .images-radio-container {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  row-gap: 20px;
  column-gap: 20px;
}
addiction-phone .form-group-container .field-container .images-radio-container.columns-3 .single-image-radio {
  width: calc(33.333% - 13.333px);
}
addiction-phone .form-group-container .field-container .images-radio-container.columns-4 .single-image-radio {
  width: calc(25% - 15px);
}
addiction-phone .form-group-container .field-container .images-radio-container .single-image-radio {
  width: calc(14.285% - 17.142px);
  max-height: 250px;
  height: auto;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  align-items: center;
}
addiction-phone .form-group-container .field-container .images-radio-container .single-image-radio label {
  width: 100%;
  height: calc(100% - 28px);
  margin: 0;
}
addiction-phone .form-group-container .field-container .images-radio-container .single-image-radio label img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: bottom;
  cursor: pointer;
}
addiction-phone .form-group-container .field-container .images-radio-container .single-image-radio input {
  width: 18px;
  height: 18px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 100%;
  cursor: pointer;
  border: 5px solid rgba(255, 255, 255, 0);
  margin: 10px 0 0 0;
  position: relative;
  appearance: none;
}
addiction-phone .form-group-container .field-container .images-radio-container .single-image-radio input:checked {
  background: #FFFFFF;
  border: 5px solid #004BC4;
}
addiction-phone .form-group-container .field-container .images-radio-container .single-image-radio .delete {
  box-shadow: none;
  border-radius: 3px;
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: initial;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  width: 34px;
  height: 34px;
  background: rgba(255, 255, 255, 0);
  border: 2px solid #FF4848;
  border: none;
}
addiction-phone .form-group-container .field-container .images-radio-container .single-image-radio .delete[aria-expanded=true] {
  border-radius: 0;
}
@media (hover: hover) and (pointer: fine) {
  addiction-phone .form-group-container .field-container .images-radio-container .single-image-radio .delete:hover {
    opacity: 0.5;
  }
}
addiction-phone .form-group-container .field-container .images-radio-container .single-image-radio .delete::before {
  content: "i";
  width: 16px;
  height: 16px;
  font-size: 16px;
  color: #FF4848;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 100%;
  font-family: "DataLean Font Icon";
  position: absolute;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-phone .form-group-container .field-container .images-radio-container .single-image-radio .delete:disabled, addiction-phone .form-group-container .field-container .images-radio-container .single-image-radio .delete.disabled, addiction-phone .form-group-container .field-container .images-radio-container .single-image-radio .delete[disabled], addiction-phone .form-group-container .field-container .images-radio-container .single-image-radio .delete[disabled=true] {
  opacity: 0.25;
  cursor: not-allowed;
  pointer-events: none;
}
addiction-phone .form-group-container .field-container .images-radio-container .single-image-radio .delete:disabled span, addiction-phone .form-group-container .field-container .images-radio-container .single-image-radio .delete.disabled span, addiction-phone .form-group-container .field-container .images-radio-container .single-image-radio .delete[disabled] span, addiction-phone .form-group-container .field-container .images-radio-container .single-image-radio .delete[disabled=true] span {
  cursor: not-allowed;
  pointer-events: none;
}
addiction-phone .form-group-container .field-container .hotspot-selected-image {
  width: 200px;
  height: 200px;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: rgba(255, 255, 255, 0.1);
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-phone .form-group-container .field-container .hotspot-selected-image.full {
  background: rgba(255, 255, 255, 0);
  border: 1px solid rgba(61, 93, 150, 0.3);
}
addiction-phone .form-group-container .field-container .hotspot-selected-image .buttons-container {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
  column-gap: 20px;
  position: absolute;
}
addiction-phone .form-group-container .field-container .hotspot-selected-image .buttons-container button.add {
  box-shadow: none;
  border-radius: 3px;
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: initial;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  width: 36px;
  height: 36px;
  background: #004BC4;
  border: none;
}
addiction-phone .form-group-container .field-container .hotspot-selected-image .buttons-container button.add[aria-expanded=true] {
  border-radius: 0;
}
@media (hover: hover) and (pointer: fine) {
  addiction-phone .form-group-container .field-container .hotspot-selected-image .buttons-container button.add:hover {
    background: #FFFFFF;
  }
  addiction-phone .form-group-container .field-container .hotspot-selected-image .buttons-container button.add:hover::before {
    color: #004BC4;
  }
}
addiction-phone .form-group-container .field-container .hotspot-selected-image .buttons-container button.add::before {
  content: "e";
  width: 16px;
  height: 16px;
  font-size: 16px;
  color: #FFFFFF;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 100%;
  font-family: "DataLean Font Icon";
  position: absolute;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-phone .form-group-container .field-container .hotspot-selected-image .buttons-container button.add:disabled, addiction-phone .form-group-container .field-container .hotspot-selected-image .buttons-container button.add.disabled, addiction-phone .form-group-container .field-container .hotspot-selected-image .buttons-container button.add[disabled], addiction-phone .form-group-container .field-container .hotspot-selected-image .buttons-container button.add[disabled=true] {
  opacity: 0.25;
  cursor: not-allowed;
  pointer-events: none;
}
addiction-phone .form-group-container .field-container .hotspot-selected-image .buttons-container button.add:disabled span, addiction-phone .form-group-container .field-container .hotspot-selected-image .buttons-container button.add.disabled span, addiction-phone .form-group-container .field-container .hotspot-selected-image .buttons-container button.add[disabled] span, addiction-phone .form-group-container .field-container .hotspot-selected-image .buttons-container button.add[disabled=true] span {
  cursor: not-allowed;
  pointer-events: none;
}
addiction-phone .form-group-container .field-container .hotspot-selected-image .buttons-container button.edit {
  box-shadow: none;
  border-radius: 3px;
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: initial;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  width: 36px;
  height: 36px;
  background: #004BC4;
  border: none;
}
addiction-phone .form-group-container .field-container .hotspot-selected-image .buttons-container button.edit[aria-expanded=true] {
  border-radius: 0;
}
@media (hover: hover) and (pointer: fine) {
  addiction-phone .form-group-container .field-container .hotspot-selected-image .buttons-container button.edit:hover {
    background: #FFFFFF;
  }
  addiction-phone .form-group-container .field-container .hotspot-selected-image .buttons-container button.edit:hover::before {
    color: #004BC4;
  }
}
addiction-phone .form-group-container .field-container .hotspot-selected-image .buttons-container button.edit::before {
  content: "n";
  width: 16px;
  height: 16px;
  font-size: 16px;
  color: #FFFFFF;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 100%;
  font-family: "DataLean Font Icon";
  position: absolute;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-phone .form-group-container .field-container .hotspot-selected-image .buttons-container button.edit:disabled, addiction-phone .form-group-container .field-container .hotspot-selected-image .buttons-container button.edit.disabled, addiction-phone .form-group-container .field-container .hotspot-selected-image .buttons-container button.edit[disabled], addiction-phone .form-group-container .field-container .hotspot-selected-image .buttons-container button.edit[disabled=true] {
  opacity: 0.25;
  cursor: not-allowed;
  pointer-events: none;
}
addiction-phone .form-group-container .field-container .hotspot-selected-image .buttons-container button.edit:disabled span, addiction-phone .form-group-container .field-container .hotspot-selected-image .buttons-container button.edit.disabled span, addiction-phone .form-group-container .field-container .hotspot-selected-image .buttons-container button.edit[disabled] span, addiction-phone .form-group-container .field-container .hotspot-selected-image .buttons-container button.edit[disabled=true] span {
  cursor: not-allowed;
  pointer-events: none;
}
addiction-phone .form-group-container .field-container .hotspot-selected-image .buttons-container button.delete {
  box-shadow: none;
  border-radius: 3px;
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: initial;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  width: 36px;
  height: 36px;
  background: #FF4848;
  border: none;
}
addiction-phone .form-group-container .field-container .hotspot-selected-image .buttons-container button.delete[aria-expanded=true] {
  border-radius: 0;
}
@media (hover: hover) and (pointer: fine) {
  addiction-phone .form-group-container .field-container .hotspot-selected-image .buttons-container button.delete:hover {
    background: #FFFFFF;
  }
  addiction-phone .form-group-container .field-container .hotspot-selected-image .buttons-container button.delete:hover::before {
    color: #FF4848;
  }
}
addiction-phone .form-group-container .field-container .hotspot-selected-image .buttons-container button.delete::before {
  content: "i";
  width: 16px;
  height: 16px;
  font-size: 16px;
  color: #FFFFFF;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 100%;
  font-family: "DataLean Font Icon";
  position: absolute;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-phone .form-group-container .field-container .hotspot-selected-image .buttons-container button.delete:disabled, addiction-phone .form-group-container .field-container .hotspot-selected-image .buttons-container button.delete.disabled, addiction-phone .form-group-container .field-container .hotspot-selected-image .buttons-container button.delete[disabled], addiction-phone .form-group-container .field-container .hotspot-selected-image .buttons-container button.delete[disabled=true] {
  opacity: 0.25;
  cursor: not-allowed;
  pointer-events: none;
}
addiction-phone .form-group-container .field-container .hotspot-selected-image .buttons-container button.delete:disabled span, addiction-phone .form-group-container .field-container .hotspot-selected-image .buttons-container button.delete.disabled span, addiction-phone .form-group-container .field-container .hotspot-selected-image .buttons-container button.delete[disabled] span, addiction-phone .form-group-container .field-container .hotspot-selected-image .buttons-container button.delete[disabled=true] span {
  cursor: not-allowed;
  pointer-events: none;
}
addiction-phone .form-group-container .field-container .hotspot-selected-image img {
  width: 100%;
  height: 100%;
}
addiction-phone .form-group-container .title-fields-container {
  margin-top: 0;
}
addiction-phone .form-group-container .title-fields-container h2 {
  display: flex;
  align-items: center;
}
addiction-phone .form-group-container .title-fields-container h2 .tooltip {
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  position: relative;
  cursor: pointer;
}
addiction-phone .form-group-container .title-fields-container h2 .tooltip::before {
  content: "F";
  font-family: "DataLean Font Icon";
  font-size: 15px;
  color: #FFFFFF;
  position: absolute;
  pointer-events: none;
}

/* --- TYPOGRAPHY --- */
/* --- COLORS --- */
/* --- ANIMATIONS --- */
/* --- STANDARD MIXINS --- */
/* --- FLEX --- */
/* --- FONTS --- */
/* Usato ad es. per scroll dentro select o popup con sfondo $brand-color-03 */
@keyframes loading {
  to {
    background-position-x: -20%;
  }
}
/*
NOTE BUTTONS FE 3.0 - DAM
I buttons con lo sfondo colorato, senza bordo e icona in mezzo (es. +) si fanno così:
@include button-style("e", $brand-color-04, $white, $buttonIcon: true, $buttonBackground: true);

I buttons senza sfondo, con bordo e icona in mezzo (es. permessi, back, navigazione, filtri) si fanno così:
@include button-style("e", $brand-color-04, $brand-color-04, $buttonIcon: true, $buttonBackground: false);

I buttons con lo sfondo colorato, senza bordo e testo in mezzo (es. salva, nuovo) si fanno così:
@include button-style(36px, $brand-color-04, $white, $buttonIcon: false, $buttonBackground: true);

I buttons senza sfondo, con bordo e testo in mezzo (es. aggiungi a collezione, collezioni) si fanno così:
@include button-style(36px, $brand-color-04, $brand-color-04, $buttonIcon: false, $buttonBackground: false);
*/
/* ADDICTION RADIO */
addiction-radio {
  width: 100%;
  height: auto;
  min-height: 36px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
addiction-radio .form-field {
  width: 100%;
  display: flex;
  flex-flow: column;
}
addiction-radio .form-field > label {
  width: 100%;
  font-family: "Segma", sans-serif;
  font-size: 10px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
  line-height: 100%;
  letter-spacing: 2.5px;
  cursor: default;
  text-transform: uppercase;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
addiction-radio .form-field > label .tooltip {
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  position: relative;
  cursor: pointer;
}
addiction-radio .form-field > label .tooltip::before {
  content: "F";
  font-family: "DataLean Font Icon";
  font-size: 13px;
  color: #FFFFFF;
  position: absolute;
  pointer-events: none;
}
addiction-radio .form-field mat-radio-group {
  width: 100%;
  display: flex;
  column-gap: 20px;
}
addiction-radio .form-field mat-radio-group mat-radio-button {
  --mdc-radio-state-layer-size: 18px !important;
  --mdc-radio-disabled-selected-icon-color: transparent !important;
  --mdc-radio-disabled-unselected-icon-color: transparent !important;
  --mdc-radio-unselected-hover-icon-color: transparent !important;
  --mdc-radio-unselected-icon-color: transparent !important;
  --mdc-radio-unselected-pressed-icon-color: transparent !important;
  --mdc-radio-selected-focus-icon-color: transparent !important;
  --mdc-radio-selected-hover-icon-color: transparent !important;
  --mdc-radio-selected-icon-color: transparent !important;
  --mdc-radio-selected-pressed-icon-color: transparent !important;
  --mat-radio-ripple-color: transparent !important;
  --mat-radio-checked-ripple-color: transparent !important;
  --mat-radio-disabled-label-color: inherit !important;
}
addiction-radio .form-field mat-radio-group mat-radio-button.no-label {
  margin-right: -10px;
}
addiction-radio .form-field mat-radio-group mat-radio-button.no-label .mdc-form-field .mdc-label {
  display: none;
}
addiction-radio .form-field mat-radio-group mat-radio-button.mat-mdc-radio-checked .mdc-form-field .mdc-radio .mdc-radio__background .mdc-radio__inner-circle {
  transform: scale(1);
}
addiction-radio .form-field mat-radio-group mat-radio-button .mdc-form-field {
  display: flex;
}
addiction-radio .form-field mat-radio-group mat-radio-button .mdc-form-field .mdc-radio {
  width: 18px;
  height: 18px;
  padding: 0;
}
addiction-radio .form-field mat-radio-group mat-radio-button .mdc-form-field .mdc-radio .mat-mdc-radio-touch-target {
  width: 18px;
  height: 18px;
}
addiction-radio .form-field mat-radio-group mat-radio-button .mdc-form-field .mdc-radio input {
  width: 18px;
  height: 18px;
}
addiction-radio .form-field mat-radio-group mat-radio-button .mdc-form-field .mdc-radio .mdc-radio__background {
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}
addiction-radio .form-field mat-radio-group mat-radio-button .mdc-form-field .mdc-radio .mdc-radio__background .mdc-radio__outer-circle {
  width: 18px;
  height: 18px;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  position: relative;
}
addiction-radio .form-field mat-radio-group mat-radio-button .mdc-form-field .mdc-radio .mdc-radio__background .mdc-radio__inner-circle {
  width: 8px;
  height: 8px;
  background: #FFFFFF;
  border: none;
  position: absolute;
  top: unset;
  left: unset;
  transform: scale(0);
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-radio .form-field mat-radio-group mat-radio-button .mdc-form-field .mdc-radio .mat-ripple {
  display: none;
}
addiction-radio .form-field mat-radio-group mat-radio-button .mdc-form-field .mdc-label {
  font-family: "Segma", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 130%;
  letter-spacing: 0;
  cursor: default;
  margin-left: 10px;
  padding: 0;
}

/* --- TYPOGRAPHY --- */
/* --- COLORS --- */
/* --- ANIMATIONS --- */
/* --- STANDARD MIXINS --- */
/* --- FLEX --- */
/* --- FONTS --- */
/* Usato ad es. per scroll dentro select o popup con sfondo $brand-color-03 */
@keyframes loading {
  to {
    background-position-x: -20%;
  }
}
/*
NOTE BUTTONS FE 3.0 - DAM
I buttons con lo sfondo colorato, senza bordo e icona in mezzo (es. +) si fanno così:
@include button-style("e", $brand-color-04, $white, $buttonIcon: true, $buttonBackground: true);

I buttons senza sfondo, con bordo e icona in mezzo (es. permessi, back, navigazione, filtri) si fanno così:
@include button-style("e", $brand-color-04, $brand-color-04, $buttonIcon: true, $buttonBackground: false);

I buttons con lo sfondo colorato, senza bordo e testo in mezzo (es. salva, nuovo) si fanno così:
@include button-style(36px, $brand-color-04, $white, $buttonIcon: false, $buttonBackground: true);

I buttons senza sfondo, con bordo e testo in mezzo (es. aggiungi a collezione, collezioni) si fanno così:
@include button-style(36px, $brand-color-04, $brand-color-04, $buttonIcon: false, $buttonBackground: false);
*/
/* ADDICTION SEARCH */
addiction-search {
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
addiction-search button.search {
  width: 16px;
  height: 16px;
  position: absolute;
  left: 10px;
  border: none;
  padding: 0;
  background: rgba(255, 255, 255, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
}
@media (hover: hover) and (pointer: fine) {
  addiction-search button.search:hover {
    opacity: 0.5;
  }
}
addiction-search button.search::before {
  content: "h";
  width: 16px;
  height: 16px;
  font-size: 16px;
  color: #FFFFFF;
  font-family: "DataLean Font Icon";
  position: absolute;
  cursor: pointer;
}
addiction-search addiction-input {
  width: 100%;
}
addiction-search addiction-input .form-field .form-input-container input {
  padding: 5px 30px 5px 40px;
}
addiction-search button.reset {
  width: 10px;
  height: 10px;
  position: absolute;
  right: 10px;
  border: none;
  padding: 0;
  background: rgba(255, 255, 255, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
}
@media (hover: hover) and (pointer: fine) {
  addiction-search button.reset:hover {
    opacity: 0.5;
  }
}
addiction-search button.reset::before {
  content: "d";
  width: 10px;
  height: 10px;
  font-size: 10px;
  color: #FFFFFF;
  font-family: "DataLean Font Icon";
  position: absolute;
  cursor: pointer;
}

/* --- TYPOGRAPHY --- */
/* --- COLORS --- */
/* --- ANIMATIONS --- */
/* --- STANDARD MIXINS --- */
/* --- FLEX --- */
/* --- FONTS --- */
/* Usato ad es. per scroll dentro select o popup con sfondo $brand-color-03 */
@keyframes loading {
  to {
    background-position-x: -20%;
  }
}
/*
NOTE BUTTONS FE 3.0 - DAM
I buttons con lo sfondo colorato, senza bordo e icona in mezzo (es. +) si fanno così:
@include button-style("e", $brand-color-04, $white, $buttonIcon: true, $buttonBackground: true);

I buttons senza sfondo, con bordo e icona in mezzo (es. permessi, back, navigazione, filtri) si fanno così:
@include button-style("e", $brand-color-04, $brand-color-04, $buttonIcon: true, $buttonBackground: false);

I buttons con lo sfondo colorato, senza bordo e testo in mezzo (es. salva, nuovo) si fanno così:
@include button-style(36px, $brand-color-04, $white, $buttonIcon: false, $buttonBackground: true);

I buttons senza sfondo, con bordo e testo in mezzo (es. aggiungi a collezione, collezioni) si fanno così:
@include button-style(36px, $brand-color-04, $brand-color-04, $buttonIcon: false, $buttonBackground: false);
*/
/* ADDICTION SELECT */
addiction-select {
  width: 100%;
  display: flex;
}
addiction-select.disabled, addiction-select[ng-reflect-readonly=true] {
  pointer-events: none;
}
addiction-select.disabled mat-form-field, addiction-select[ng-reflect-readonly=true] mat-form-field {
  cursor: not-allowed;
}
addiction-select.disabled .select-container, addiction-select[ng-reflect-readonly=true] .select-container {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}
addiction-select label {
  width: 100%;
  display: flex;
  align-items: center;
  font-family: "Segma", sans-serif;
  font-size: 10px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
  line-height: 150%;
  letter-spacing: 2.5px;
  cursor: default;
  text-transform: uppercase;
  margin-right: 10px;
  display: flex;
  align-items: center;
}
addiction-select label .tooltip {
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  position: relative;
  cursor: pointer;
}
addiction-select label .tooltip::before {
  content: "F";
  font-family: "DataLean Font Icon";
  font-size: 13px;
  color: #FFFFFF;
  position: absolute;
  pointer-events: none;
}
addiction-select .select-container {
  flex: 1;
  position: relative;
}
addiction-select .select-container::before {
  content: "b";
  width: 100%;
  height: 100%;
  color: #FFFFFF;
  font-size: 7px;
  font-family: "DataLean Font Icon";
  position: absolute;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  pointer-events: none;
  right: 10px;
}
addiction-select .select-container select {
  width: 100%;
  min-width: 250px;
  height: 36px;
  font-family: "Segma", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 100%;
  letter-spacing: 0;
  cursor: default;
  padding: 5px 30px 5px 10px;
  cursor: pointer;
  background: #2B374D;
  border: none;
  margin: 0;
  border-radius: 5px;
  outline: 0 !important;
  appearance: none;
}
addiction-select mat-form-field {
  flex: 1;
  height: 36px;
  background: rgba(255, 255, 255, 0);
  border: none;
  border-radius: 5px;
  padding: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-select mat-form-field .mat-mdc-text-field-wrapper {
  width: 100%;
  height: 100%;
  padding: 0;
  background: rgba(255, 255, 255, 0) !important;
}
addiction-select mat-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-focus-overlay {
  display: none;
}
addiction-select mat-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex {
  width: 100%;
  height: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0);
}
addiction-select mat-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
  width: 100%;
  height: 100%;
  min-height: 100%;
  background: rgba(255, 255, 255, 0);
  padding: 0;
  margin: 0;
  border: 0;
  display: flex;
  flex-flow: column;
  border: none;
}
addiction-select mat-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-select {
  width: 100%;
  height: 100%;
  min-height: 100%;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 5px;
  padding: 5px 10px;
}
addiction-select mat-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-select.mat-mdc-select-disabled, addiction-select mat-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-select[aria-readonly=true], addiction-select mat-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-select[aria-disabled=true] {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}
addiction-select mat-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-select[aria-expanded=true] {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
addiction-select mat-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-select .mat-mdc-select-trigger {
  min-height: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
addiction-select mat-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-select .mat-mdc-select-trigger .mat-mdc-select-value {
  padding: 3px 20px 0 0;
}
addiction-select mat-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-select .mat-mdc-select-trigger .mat-mdc-select-value .mat-mdc-select-min-line {
  font-family: "Segma", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 170%;
  letter-spacing: 1px;
  cursor: default;
  cursor: pointer;
}
addiction-select mat-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-select .mat-mdc-select-trigger .mat-mdc-select-arrow-wrapper {
  transform: unset;
  width: 10px;
  position: absolute;
  right: 0;
}
addiction-select mat-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-select .mat-mdc-select-trigger .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow {
  width: 100%;
  height: 100%;
  margin: 0;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
addiction-select mat-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-select .mat-mdc-select-trigger .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow::before {
  content: "b";
  width: 10px;
  height: 5px;
  color: #FFFFFF;
  font-size: 7px;
  font-family: "DataLean Font Icon";
  position: absolute;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  display: flex;
  justify-content: center;
  align-items: center;
}
addiction-select mat-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-select .mat-mdc-select-trigger .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow svg {
  display: none;
}
addiction-select mat-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix {
  padding: 0;
}
addiction-select mat-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix mat-datepicker-toggle button {
  width: 16px;
  height: 16px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  appearance: none;
  outline: none;
  border: none;
  background: rgba(255, 255, 255, 0);
  position: relative;
}
@media (hover: hover) and (pointer: fine) {
  addiction-select mat-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix mat-datepicker-toggle button:hover {
    opacity: 0.5;
  }
}
addiction-select mat-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix mat-datepicker-toggle button::before {
  content: "o";
  width: 100%;
  height: 100%;
  color: #FFFFFF;
  font-size: 16px;
  font-family: "DataLean Font Icon";
  position: absolute;
  pointer-events: none;
  z-index: 1;
}
addiction-select mat-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-suffix mat-datepicker-toggle button * {
  display: none;
}
addiction-select mat-form-field .mat-mdc-text-field-wrapper .mdc-line-ripple {
  display: none;
}
addiction-select mat-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-underline {
  display: none;
}
addiction-select mat-form-field .mat-mdc-form-field-subscript-wrapper {
  display: none;
}

/* DATALEAN-SELECT */
datalean-select {
  width: 100%;
  min-height: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* NON MATERIAL STYLE */
  /* MATERIAL STYLE */
  /* stile dentro select-field.component.scss */
}
datalean-select label {
  width: 100%;
  display: flex;
  font-family: "Segma", sans-serif;
  font-size: 10px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
  line-height: 150%;
  letter-spacing: 2.5px;
  cursor: default;
  text-transform: uppercase;
  margin-right: 10px;
}
datalean-select .select-container {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
datalean-select .select-container select {
  width: 180px;
  height: 36px;
  appearance: none;
  outline: none;
  border: none !important;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.05);
  padding: 5px 30px 5px 10px;
  font-family: "Segma", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 100%;
  letter-spacing: 1px;
  cursor: default;
  cursor: pointer;
}

/* --- TYPOGRAPHY --- */
/* --- COLORS --- */
/* --- ANIMATIONS --- */
/* --- SPECIFIC MIXINS --- */
/* Stile per buttons che al click aprono un sottomenù */
/* --- TYPOGRAPHY --- */
/* --- COLORS --- */
/* --- ANIMATIONS --- */
/* --- STANDARD MIXINS --- */
/* --- FLEX --- */
/* --- FONTS --- */
/* Usato ad es. per scroll dentro select o popup con sfondo $brand-color-03 */
@keyframes loading {
  to {
    background-position-x: -20%;
  }
}
/*
NOTE BUTTONS FE 3.0 - DAM
I buttons con lo sfondo colorato, senza bordo e icona in mezzo (es. +) si fanno così:
@include button-style("e", $brand-color-04, $white, $buttonIcon: true, $buttonBackground: true);

I buttons senza sfondo, con bordo e icona in mezzo (es. permessi, back, navigazione, filtri) si fanno così:
@include button-style("e", $brand-color-04, $brand-color-04, $buttonIcon: true, $buttonBackground: false);

I buttons con lo sfondo colorato, senza bordo e testo in mezzo (es. salva, nuovo) si fanno così:
@include button-style(36px, $brand-color-04, $white, $buttonIcon: false, $buttonBackground: true);

I buttons senza sfondo, con bordo e testo in mezzo (es. aggiungi a collezione, collezioni) si fanno così:
@include button-style(36px, $brand-color-04, $brand-color-04, $buttonIcon: false, $buttonBackground: false);
*/
/* ADDICTION-SIMPLE-REGISTRATION */
addiction-simple-registration .registration-container .form-container form > div {
  margin-bottom: 20px;
}
addiction-simple-registration .registration-container .form-container form .input-container {
  display: flex;
  flex-flow: column;
}
addiction-simple-registration .registration-container .form-container form .input-container label {
  width: 100%;
  font-family: "Segma", sans-serif;
  font-size: 10px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
  line-height: 100%;
  letter-spacing: 1px;
  cursor: default;
  text-transform: uppercase;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}
addiction-simple-registration .registration-container .form-container form .input-container label .tooltip {
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  position: relative;
  cursor: pointer;
}
addiction-simple-registration .registration-container .form-container form .input-container label .tooltip::before {
  content: "F";
  font-family: "DataLean Font Icon";
  font-size: 13px;
  color: #FFFFFF;
  position: absolute;
  pointer-events: none;
}
addiction-simple-registration .registration-container .form-container form .input-container input {
  width: 100%;
  height: 36px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 0;
  border: none;
  appearance: none;
  outline: none;
  caret-color: #FFFFFF;
  font-family: "Segma", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 100%;
  letter-spacing: 0;
  cursor: default;
  padding: 5px 10px;
  cursor: inherit;
}
addiction-simple-registration .registration-container .form-container form .change-password-error {
  width: 100%;
  padding: 5px 10px;
  border-radius: 0;
  background: #A20000;
  border: none;
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 150%;
  letter-spacing: 0;
  cursor: default;
  text-align: center;
}
addiction-simple-registration .registration-container .form-container form .checkbox-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0;
}
addiction-simple-registration .registration-container .form-container form .checkbox-container mat-checkbox {
  margin-right: 20px;
}
addiction-simple-registration .registration-container .form-container form .checkbox-container mat-checkbox .mdc-form-field .mdc-checkbox {
  padding: 0;
}
addiction-simple-registration .registration-container .form-container form .checkbox-container mat-checkbox .mdc-form-field .mdc-checkbox .mat-mdc-checkbox-touch-target {
  display: none;
}
addiction-simple-registration .registration-container .form-container form .checkbox-container mat-checkbox .mdc-form-field .mdc-checkbox input {
  width: 18px;
  height: 18px;
}
addiction-simple-registration .registration-container .form-container form .checkbox-container mat-checkbox .mdc-form-field .mdc-checkbox input:enabled:checked ~ .mdc-checkbox__background {
  border-color: unset;
  background-color: #004BC4 !important;
}
addiction-simple-registration .registration-container .form-container form .checkbox-container mat-checkbox .mdc-form-field .mdc-checkbox input:enabled:indeterminate ~ .mdc-checkbox__background, addiction-simple-registration .registration-container .form-container form .checkbox-container mat-checkbox .mdc-form-field .mdc-checkbox input.mdc-checkbox--selected ~ .mdc-checkbox__background {
  border-color: unset;
  background-color: #004BC4 !important;
}
addiction-simple-registration .registration-container .form-container form .checkbox-container mat-checkbox .mdc-form-field .mdc-checkbox input:enabled:indeterminate ~ .mdc-checkbox__background .mdc-checkbox__mixedmark, addiction-simple-registration .registration-container .form-container form .checkbox-container mat-checkbox .mdc-form-field .mdc-checkbox input.mdc-checkbox--selected ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
  transform: scale(0.5);
  border-color: #FFFFFF !important;
}
addiction-simple-registration .registration-container .form-container form .checkbox-container mat-checkbox .mdc-form-field .mdc-checkbox .mdc-checkbox__ripple {
  display: none;
}
addiction-simple-registration .registration-container .form-container form .checkbox-container mat-checkbox .mdc-form-field .mdc-checkbox .mdc-checkbox__background {
  width: 18px;
  height: 18px;
  top: unset;
  left: unset;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.1) !important;
  border: none !important;
}
addiction-simple-registration .registration-container .form-container form .checkbox-container mat-checkbox .mdc-form-field .mdc-checkbox .mdc-checkbox__background svg {
  transform: scale(0.6);
}
addiction-simple-registration .registration-container .form-container form .checkbox-container mat-checkbox .mdc-form-field .mdc-checkbox .mdc-checkbox__background .mdc-checkbox__mixedmark {
  transform: scale(0);
}
addiction-simple-registration .registration-container .form-container form .checkbox-container mat-checkbox .mdc-form-field .mdc-checkbox .mat-mdc-checkbox-ripple {
  display: none;
}
addiction-simple-registration .registration-container .form-container form .checkbox-container mat-checkbox .mdc-form-field .mdc-label {
  padding-left: 10px;
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 150%;
  letter-spacing: 0;
  cursor: default;
}
addiction-simple-registration .registration-container .form-container form .checkbox-container .privacy-text {
  font-family: "Segma", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 130%;
  letter-spacing: 0;
  cursor: default;
}
addiction-simple-registration .registration-container .form-container form .checkbox-container .privacy-text .link {
  color: #004BC4;
}
addiction-simple-registration .registration-container .form-container form .checkbox-container .privacy-text .link u {
  text-decoration: none;
}
addiction-simple-registration .registration-container .form-container form .buttons-container {
  width: 100%;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
addiction-simple-registration .registration-container .form-container form .buttons-container button {
  width: auto;
  padding: 5px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 130%;
  letter-spacing: 2.5px;
  cursor: default;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  min-height: 36px;
  background: #004BC4;
  border: none;
  border-radius: 10px;
  width: 100%;
}
addiction-simple-registration .registration-container .form-container form .buttons-container button span {
  width: 100%;
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 130%;
  letter-spacing: 2.5px;
  cursor: default;
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  margin-top: 3px;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-simple-registration .registration-container .form-container form .buttons-container button[aria-expanded=true] {
  border-radius: 0;
}
@media (hover: hover) and (pointer: fine) {
  addiction-simple-registration .registration-container .form-container form .buttons-container button:hover {
    background: #FFFFFF;
    color: #004BC4;
  }
  addiction-simple-registration .registration-container .form-container form .buttons-container button:hover span {
    color: #004BC4;
  }
}
addiction-simple-registration .registration-container .form-container form .buttons-container button:disabled, addiction-simple-registration .registration-container .form-container form .buttons-container button.disabled, addiction-simple-registration .registration-container .form-container form .buttons-container button[disabled], addiction-simple-registration .registration-container .form-container form .buttons-container button[disabled=true] {
  opacity: 0.25;
  cursor: not-allowed;
  pointer-events: none;
}
addiction-simple-registration .registration-container .form-container form .buttons-container button:disabled span, addiction-simple-registration .registration-container .form-container form .buttons-container button.disabled span, addiction-simple-registration .registration-container .form-container form .buttons-container button[disabled] span, addiction-simple-registration .registration-container .form-container form .buttons-container button[disabled=true] span {
  cursor: not-allowed;
  pointer-events: none;
}
addiction-simple-registration .registration-container .registration-result-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}
addiction-simple-registration .registration-container .registration-result-page .results-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  margin-bottom: 50px;
}
addiction-simple-registration .registration-container .registration-result-page .results-container ng-lottie {
  display: flex;
  width: 250px;
  height: 250px;
  margin-bottom: 30px;
  object-fit: contain;
}
addiction-simple-registration .registration-container .registration-result-page .results-container .title {
  width: 100%;
  margin-bottom: 10px;
  font-family: "Segma", sans-serif;
  font-size: 24px;
  font-weight: 500;
  color: #004BC4;
  line-height: 150%;
  letter-spacing: 0;
  cursor: default;
  text-align: center;
}
addiction-simple-registration .registration-container .registration-result-page .results-container .text {
  width: 100%;
  font-family: "Segma", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 150%;
  letter-spacing: 0;
  cursor: default;
  text-align: center;
}
addiction-simple-registration .registration-container .registration-result-page .results-container .text span {
  color: #004BC4;
}
addiction-simple-registration .registration-container .registration-result-page .result-button-container {
  width: 100%;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
addiction-simple-registration .registration-container .registration-result-page .result-button-container button {
  width: auto;
  padding: 5px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 130%;
  letter-spacing: 2.5px;
  cursor: default;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  min-height: 36px;
  background: #004BC4;
  border: none;
  border-radius: 10px;
  width: 100%;
}
addiction-simple-registration .registration-container .registration-result-page .result-button-container button span {
  width: 100%;
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 130%;
  letter-spacing: 2.5px;
  cursor: default;
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  margin-top: 3px;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-simple-registration .registration-container .registration-result-page .result-button-container button[aria-expanded=true] {
  border-radius: 0;
}
@media (hover: hover) and (pointer: fine) {
  addiction-simple-registration .registration-container .registration-result-page .result-button-container button:hover {
    background: #FFFFFF;
    color: #004BC4;
  }
  addiction-simple-registration .registration-container .registration-result-page .result-button-container button:hover span {
    color: #004BC4;
  }
}
addiction-simple-registration .registration-container .registration-result-page .result-button-container button:disabled, addiction-simple-registration .registration-container .registration-result-page .result-button-container button.disabled, addiction-simple-registration .registration-container .registration-result-page .result-button-container button[disabled], addiction-simple-registration .registration-container .registration-result-page .result-button-container button[disabled=true] {
  opacity: 0.25;
  cursor: not-allowed;
  pointer-events: none;
}
addiction-simple-registration .registration-container .registration-result-page .result-button-container button:disabled span, addiction-simple-registration .registration-container .registration-result-page .result-button-container button.disabled span, addiction-simple-registration .registration-container .registration-result-page .result-button-container button[disabled] span, addiction-simple-registration .registration-container .registration-result-page .result-button-container button[disabled=true] span {
  cursor: not-allowed;
  pointer-events: none;
}

/* --- TYPOGRAPHY --- */
/* --- COLORS --- */
/* --- ANIMATIONS --- */
/* --- STANDARD MIXINS --- */
/* --- FLEX --- */
/* --- FONTS --- */
/* Usato ad es. per scroll dentro select o popup con sfondo $brand-color-03 */
@keyframes loading {
  to {
    background-position-x: -20%;
  }
}
/*
NOTE BUTTONS FE 3.0 - DAM
I buttons con lo sfondo colorato, senza bordo e icona in mezzo (es. +) si fanno così:
@include button-style("e", $brand-color-04, $white, $buttonIcon: true, $buttonBackground: true);

I buttons senza sfondo, con bordo e icona in mezzo (es. permessi, back, navigazione, filtri) si fanno così:
@include button-style("e", $brand-color-04, $brand-color-04, $buttonIcon: true, $buttonBackground: false);

I buttons con lo sfondo colorato, senza bordo e testo in mezzo (es. salva, nuovo) si fanno così:
@include button-style(36px, $brand-color-04, $white, $buttonIcon: false, $buttonBackground: true);

I buttons senza sfondo, con bordo e testo in mezzo (es. aggiungi a collezione, collezioni) si fanno così:
@include button-style(36px, $brand-color-04, $brand-color-04, $buttonIcon: false, $buttonBackground: false);
*/
addiction-textarea {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
addiction-textarea .form-field {
  width: 100%;
}
addiction-textarea .form-field label {
  width: 100%;
  display: flex;
  font-family: "Segma", sans-serif;
  font-size: 10px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
  line-height: 100%;
  letter-spacing: 2.5px;
  cursor: default;
  text-transform: uppercase;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
addiction-textarea .form-field label .tooltip {
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  position: relative;
  cursor: pointer;
}
addiction-textarea .form-field label .tooltip::before {
  content: "F";
  font-family: "DataLean Font Icon";
  font-size: 13px;
  color: #FFFFFF;
  position: absolute;
  pointer-events: none;
}
addiction-textarea .form-field .form-input-container {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}
addiction-textarea .form-field .form-input-container textarea {
  width: 100%;
  height: 180px;
  font-family: "Segma", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 160%;
  letter-spacing: 0;
  cursor: default;
  cursor: text;
  padding: 10px;
  background: #1F2B43;
  border: 0;
  margin: 0;
  border-radius: 5px;
  appearance: none;
  transform: none;
}
addiction-textarea .form-field .form-input-container textarea:disabled, addiction-textarea .form-field .form-input-container textarea:read-only {
  cursor: not-allowed;
  opacity: 0.5;
}
addiction-textarea .form-field .form-input-container textarea:focus-visible {
  outline: none;
}

/* --- TYPOGRAPHY --- */
/* --- COLORS --- */
/* --- ANIMATIONS --- */
/* --- SPECIFIC MIXINS --- */
/* Stile per buttons che al click aprono un sottomenù */
/* --- TYPOGRAPHY --- */
/* --- COLORS --- */
/* --- ANIMATIONS --- */
/* --- STANDARD MIXINS --- */
/* --- FLEX --- */
/* --- FONTS --- */
/* Usato ad es. per scroll dentro select o popup con sfondo $brand-color-03 */
@keyframes loading {
  to {
    background-position-x: -20%;
  }
}
/*
NOTE BUTTONS FE 3.0 - DAM
I buttons con lo sfondo colorato, senza bordo e icona in mezzo (es. +) si fanno così:
@include button-style("e", $brand-color-04, $white, $buttonIcon: true, $buttonBackground: true);

I buttons senza sfondo, con bordo e icona in mezzo (es. permessi, back, navigazione, filtri) si fanno così:
@include button-style("e", $brand-color-04, $brand-color-04, $buttonIcon: true, $buttonBackground: false);

I buttons con lo sfondo colorato, senza bordo e testo in mezzo (es. salva, nuovo) si fanno così:
@include button-style(36px, $brand-color-04, $white, $buttonIcon: false, $buttonBackground: true);

I buttons senza sfondo, con bordo e testo in mezzo (es. aggiungi a collezione, collezioni) si fanno così:
@include button-style(36px, $brand-color-04, $brand-color-04, $buttonIcon: false, $buttonBackground: false);
*/
/* ADDICTION-TREE-BROWSER */
addiction-tree-browser,
datalean-tree-browser {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
}
addiction-tree-browser.with-search-bar .top-bar,
datalean-tree-browser.with-search-bar .top-bar {
  display: flex;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(61, 93, 150, 0.3);
}
addiction-tree-browser.with-search-bar .top-bar addiction-search,
datalean-tree-browser.with-search-bar .top-bar addiction-search {
  width: calc(100% - 76px);
  display: flex;
}
addiction-tree-browser.with-search-bar .top-bar addiction-search datalean-input .form-field,
datalean-tree-browser.with-search-bar .top-bar addiction-search datalean-input .form-field {
  margin: 0;
}
addiction-tree-browser.with-search-bar .top-bar addiction-search datalean-input .form-field .form-input-container,
datalean-tree-browser.with-search-bar .top-bar addiction-search datalean-input .form-field .form-input-container {
  width: 100%;
}
addiction-tree-browser.with-search-bar .top-bar .buttons,
datalean-tree-browser.with-search-bar .top-bar .buttons {
  width: auto;
  padding: 0 20px;
  align-items: center;
  justify-content: flex-end;
  margin: 0;
}
addiction-tree-browser .top-bar,
datalean-tree-browser .top-bar {
  width: 100%;
}
addiction-tree-browser .top-bar .page-title,
datalean-tree-browser .top-bar .page-title {
  font-family: "Segma", sans-serif;
  font-size: 20px;
  font-weight: 300;
  color: #FFFFFF;
  line-height: 150%;
  letter-spacing: 0;
  cursor: default;
  margin-bottom: 30px;
}
addiction-tree-browser .top-bar addiction-search,
datalean-tree-browser .top-bar addiction-search {
  width: 100%;
}
addiction-tree-browser .top-bar .buttons,
datalean-tree-browser .top-bar .buttons {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-right: 20px;
}
addiction-tree-browser .top-bar .buttons button.add,
datalean-tree-browser .top-bar .buttons button.add {
  box-shadow: none;
  border-radius: 3px;
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: initial;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  width: 36px;
  height: 36px;
  background: #004BC4;
  border: none;
}
addiction-tree-browser .top-bar .buttons button.add[aria-expanded=true],
datalean-tree-browser .top-bar .buttons button.add[aria-expanded=true] {
  border-radius: 0;
}
@media (hover: hover) and (pointer: fine) {
  addiction-tree-browser .top-bar .buttons button.add:hover,
  datalean-tree-browser .top-bar .buttons button.add:hover {
    background: #FFFFFF;
  }
  addiction-tree-browser .top-bar .buttons button.add:hover::before,
  datalean-tree-browser .top-bar .buttons button.add:hover::before {
    color: #004BC4;
  }
}
addiction-tree-browser .top-bar .buttons button.add::before,
datalean-tree-browser .top-bar .buttons button.add::before {
  content: "e";
  width: 16px;
  height: 16px;
  font-size: 16px;
  color: #FFFFFF;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 100%;
  font-family: "DataLean Font Icon";
  position: absolute;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-tree-browser .top-bar .buttons button.add:disabled, addiction-tree-browser .top-bar .buttons button.add.disabled, addiction-tree-browser .top-bar .buttons button.add[disabled], addiction-tree-browser .top-bar .buttons button.add[disabled=true],
datalean-tree-browser .top-bar .buttons button.add:disabled,
datalean-tree-browser .top-bar .buttons button.add.disabled,
datalean-tree-browser .top-bar .buttons button.add[disabled],
datalean-tree-browser .top-bar .buttons button.add[disabled=true] {
  opacity: 0.25;
  cursor: not-allowed;
  pointer-events: none;
}
addiction-tree-browser .top-bar .buttons button.add:disabled span, addiction-tree-browser .top-bar .buttons button.add.disabled span, addiction-tree-browser .top-bar .buttons button.add[disabled] span, addiction-tree-browser .top-bar .buttons button.add[disabled=true] span,
datalean-tree-browser .top-bar .buttons button.add:disabled span,
datalean-tree-browser .top-bar .buttons button.add.disabled span,
datalean-tree-browser .top-bar .buttons button.add[disabled] span,
datalean-tree-browser .top-bar .buttons button.add[disabled=true] span {
  cursor: not-allowed;
  pointer-events: none;
}
addiction-tree-browser .tree-browser,
datalean-tree-browser .tree-browser {
  flex: 1;
  height: auto;
  max-height: calc(100% - 36px);
}
addiction-tree-browser .tree-browser .current-node,
datalean-tree-browser .tree-browser .current-node {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 20px;
  border-bottom: 1px solid rgba(61, 93, 150, 0.3);
}
addiction-tree-browser .tree-browser .current-node .cdk-drag-placeholder,
datalean-tree-browser .tree-browser .current-node .cdk-drag-placeholder {
  /* Questa regola è importante, serve da evitare che si visualizzi il placeholder (che non sarebbe stilato) durante il drag sulla cartella corrente (in alto) */
  display: none !important;
}
addiction-tree-browser .tree-browser .current-node.cdk-drop-list-dragging,
datalean-tree-browser .tree-browser .current-node.cdk-drop-list-dragging {
  /* Stato di quando una cartella sta passando (in dragging) sul nodo in alto della cartella corrente, stilare di conseguenza */
  font-weight: bold;
  text-decoration: underline;
}
addiction-tree-browser .tree-browser .current-node button.back,
datalean-tree-browser .tree-browser .current-node button.back {
  width: 10px;
  height: 10px;
  appearance: none;
  border: none;
  padding: 0;
  background: rgba(255, 255, 255, 0);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  cursor: pointer;
  margin-right: 10px;
}
@media (hover: hover) and (pointer: fine) {
  addiction-tree-browser .tree-browser .current-node button.back:hover,
  datalean-tree-browser .tree-browser .current-node button.back:hover {
    opacity: 0.5;
  }
}
addiction-tree-browser .tree-browser .current-node button.back::before,
datalean-tree-browser .tree-browser .current-node button.back::before {
  content: "b";
  width: 10px;
  height: 10px;
  font-size: 7px;
  color: #FFFFFF;
  font-family: "DataLean Font Icon";
  position: absolute;
  transform: rotate(90deg);
}
addiction-tree-browser .tree-browser .current-node .current-node-title,
datalean-tree-browser .tree-browser .current-node .current-node-title {
  flex: 1;
  overflow: hidden;
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 100%;
  letter-spacing: 1.2px;
  cursor: default;
  cursor: pointer;
  text-overflow: ellipsis;
}
addiction-tree-browser .tree-browser .cdk-drop-list.children,
datalean-tree-browser .tree-browser .cdk-drop-list.children {
  height: calc(100% - 50px);
  overflow-y: auto;
}
addiction-tree-browser .tree-browser .cdk-drop-list.children::-webkit-scrollbar,
datalean-tree-browser .tree-browser .cdk-drop-list.children::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
addiction-tree-browser .tree-browser .cdk-drop-list.children::-webkit-scrollbar-thumb,
datalean-tree-browser .tree-browser .cdk-drop-list.children::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: none;
}
addiction-tree-browser .tree-browser .cdk-drop-list.children::-webkit-scrollbar-track,
datalean-tree-browser .tree-browser .cdk-drop-list.children::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0);
}
addiction-tree-browser .tree-browser .cdk-drop-list.children .drag-placeholder,
datalean-tree-browser .tree-browser .cdk-drop-list.children .drag-placeholder {
  width: 100%;
  height: 2px;
  background: #004BC4;
}
addiction-tree-browser .tree-browser .cdk-drop-list.children .child,
datalean-tree-browser .tree-browser .cdk-drop-list.children .child {
  width: 100%;
  min-height: 50px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  border-radius: 5px;
  margin: 0;
  padding: 0 20px;
  cursor: pointer;
  transition: all 0s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-tree-browser .tree-browser .cdk-drop-list.children .child .single-child-container,
datalean-tree-browser .tree-browser .cdk-drop-list.children .child .single-child-container {
  width: auto;
  height: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  cursor: pointer;
}
addiction-tree-browser .tree-browser .cdk-drop-list.children .child .single-child-container input,
datalean-tree-browser .tree-browser .cdk-drop-list.children .child .single-child-container input {
  width: 18px;
  height: 18px;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  appearance: none;
  cursor: pointer;
  position: relative;
  background: rgba(255, 255, 255, 0);
  margin-right: 10px;
}
addiction-tree-browser .tree-browser .cdk-drop-list.children .child .single-child-container input:disabled,
datalean-tree-browser .tree-browser .cdk-drop-list.children .child .single-child-container input:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
addiction-tree-browser .tree-browser .cdk-drop-list.children .child .single-child-container input:disabled::before,
datalean-tree-browser .tree-browser .cdk-drop-list.children .child .single-child-container input:disabled::before {
  cursor: not-allowed;
}
addiction-tree-browser .tree-browser .cdk-drop-list.children .child .single-child-container input:disabled::after,
datalean-tree-browser .tree-browser .cdk-drop-list.children .child .single-child-container input:disabled::after {
  cursor: not-allowed;
}
addiction-tree-browser .tree-browser .cdk-drop-list.children .child .single-child-container input:indeterminate::after,
datalean-tree-browser .tree-browser .cdk-drop-list.children .child .single-child-container input:indeterminate::after {
  content: "";
  width: 1px;
  height: 4px;
  background: #FFFFFF;
  position: absolute;
  margin-bottom: 2px;
  transform: rotate(0);
  opacity: 1;
  z-index: 1;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-tree-browser .tree-browser .cdk-drop-list.children .child .single-child-container input::before,
datalean-tree-browser .tree-browser .cdk-drop-list.children .child .single-child-container input::before {
  content: "";
  width: 18px;
  height: 18px;
  position: absolute;
  background: rgb(44, 53, 70);
  border-radius: 5px;
  z-index: 0;
  cursor: pointer;
}
addiction-tree-browser .tree-browser .cdk-drop-list.children .child .single-child-container input::after,
datalean-tree-browser .tree-browser .cdk-drop-list.children .child .single-child-container input::after {
  content: "";
  width: 7px;
  height: 4px;
  position: absolute;
  border-bottom: 2px solid #FFFFFF;
  border-left: 2px solid #FFFFFF;
  margin-bottom: 2px;
  transform: rotate(-45deg);
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
addiction-tree-browser .tree-browser .cdk-drop-list.children .child .single-child-container input:checked::before,
datalean-tree-browser .tree-browser .cdk-drop-list.children .child .single-child-container input:checked::before {
  background: #004BC4;
}
addiction-tree-browser .tree-browser .cdk-drop-list.children .child .single-child-container input:checked::after,
datalean-tree-browser .tree-browser .cdk-drop-list.children .child .single-child-container input:checked::after {
  opacity: 1;
  background: rgba(255, 255, 255, 0);
}
addiction-tree-browser .tree-browser .cdk-drop-list.children .child .single-child-container .title,
datalean-tree-browser .tree-browser .cdk-drop-list.children .child .single-child-container .title {
  width: 100%;
  min-height: 50px;
  position: relative;
  overflow: hidden;
  background: rgba(255, 255, 255, 0);
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (hover: hover) and (pointer: fine) {
  addiction-tree-browser .tree-browser .cdk-drop-list.children .child .single-child-container .title:hover .label,
  datalean-tree-browser .tree-browser .cdk-drop-list.children .child .single-child-container .title:hover .label {
    opacity: 0.5;
  }
}
addiction-tree-browser .tree-browser .cdk-drop-list.children .child .single-child-container .title .folder,
datalean-tree-browser .tree-browser .cdk-drop-list.children .child .single-child-container .title .folder {
  display: none;
}
addiction-tree-browser .tree-browser .cdk-drop-list.children .child .single-child-container .title .label,
datalean-tree-browser .tree-browser .cdk-drop-list.children .child .single-child-container .title .label {
  width: 100%;
  display: flex;
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 130%;
  letter-spacing: 1.2px;
  cursor: default;
  text-align: left;
  cursor: pointer;
}
addiction-tree-browser .tree-browser .cdk-drop-list.children .child .controls,
datalean-tree-browser .tree-browser .cdk-drop-list.children .child .controls {
  width: auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
addiction-tree-browser .tree-browser .cdk-drop-list.children .child .controls button.menu,
datalean-tree-browser .tree-browser .cdk-drop-list.children .child .controls button.menu {
  width: 25px;
  height: 35px;
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: none;
  padding: 0;
  outline: 0;
  border: none;
  position: relative;
}
addiction-tree-browser .tree-browser .cdk-drop-list.children .child .controls button.menu::before,
datalean-tree-browser .tree-browser .cdk-drop-list.children .child .controls button.menu::before {
  content: "";
  width: 100%;
  height: 10px;
  background: #01286C;
  position: absolute;
  bottom: -10px;
  opacity: 0;
}
addiction-tree-browser .tree-browser .cdk-drop-list.children .child .controls button.menu::after,
datalean-tree-browser .tree-browser .cdk-drop-list.children .child .controls button.menu::after {
  content: "H";
  width: 100%;
  height: 100%;
  font-family: "DataLean Font Icon";
  font-size: 14px;
  position: absolute;
  color: #004BC4;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
addiction-tree-browser .tree-browser .cdk-drop-list.children .child .controls button.menu.mat-menu-below,
datalean-tree-browser .tree-browser .cdk-drop-list.children .child .controls button.menu.mat-menu-below {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
addiction-tree-browser .tree-browser .cdk-drop-list.children .child .controls button.menu.mat-menu-below::before,
datalean-tree-browser .tree-browser .cdk-drop-list.children .child .controls button.menu.mat-menu-below::before {
  bottom: -10px;
}
addiction-tree-browser .tree-browser .cdk-drop-list.children .child .controls button.menu.mat-menu-above,
datalean-tree-browser .tree-browser .cdk-drop-list.children .child .controls button.menu.mat-menu-above {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
addiction-tree-browser .tree-browser .cdk-drop-list.children .child .controls button.menu.mat-menu-above::before,
datalean-tree-browser .tree-browser .cdk-drop-list.children .child .controls button.menu.mat-menu-above::before {
  top: -10px;
}
addiction-tree-browser .tree-browser .cdk-drop-list.children .child .controls button.menu[aria-expanded=false],
datalean-tree-browser .tree-browser .cdk-drop-list.children .child .controls button.menu[aria-expanded=false] {
  box-shadow: none;
}
addiction-tree-browser .tree-browser .cdk-drop-list.children .child .controls button.menu[aria-expanded=true],
datalean-tree-browser .tree-browser .cdk-drop-list.children .child .controls button.menu[aria-expanded=true] {
  background: #01286C;
}
addiction-tree-browser .tree-browser .cdk-drop-list.children .child .controls button.menu[aria-expanded=true]::before,
datalean-tree-browser .tree-browser .cdk-drop-list.children .child .controls button.menu[aria-expanded=true]::before {
  opacity: 1;
}
addiction-tree-browser .tree-browser .cdk-drop-list.children .child .controls button.menu[aria-expanded=true]::after,
datalean-tree-browser .tree-browser .cdk-drop-list.children .child .controls button.menu[aria-expanded=true]::after {
  color: #FFFFFF;
}
@media (hover: hover) and (pointer: fine) {
  addiction-tree-browser .tree-browser .cdk-drop-list.children .child .controls button.menu:hover,
  datalean-tree-browser .tree-browser .cdk-drop-list.children .child .controls button.menu:hover {
    opacity: 0.5;
  }
}
addiction-tree-browser .tree-browser .cdk-drop-list.children .child .controls button.menu mat-icon,
datalean-tree-browser .tree-browser .cdk-drop-list.children .child .controls button.menu mat-icon {
  display: none;
}
addiction-tree-browser .tree-browser .cdk-drop-list.children .child .controls button.menu.more-space,
datalean-tree-browser .tree-browser .cdk-drop-list.children .child .controls button.menu.more-space {
  margin-right: 12px;
}
addiction-tree-browser .tree-browser .cdk-drop-list.children .child .controls button.navigate,
datalean-tree-browser .tree-browser .cdk-drop-list.children .child .controls button.navigate {
  width: 12px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 1;
  pointer-events: all;
  background: none;
  padding: 0;
  outline: 0;
  border: none;
  position: relative;
}
addiction-tree-browser .tree-browser .cdk-drop-list.children .child .controls button.navigate::before,
datalean-tree-browser .tree-browser .cdk-drop-list.children .child .controls button.navigate::before {
  content: "a";
  width: 100%;
  height: 100%;
  font-size: 7px;
  font-family: "DataLean Font Icon";
  position: absolute;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(180deg);
}
addiction-tree-browser .tree-browser .cdk-drop-list.children .placeholder,
datalean-tree-browser .tree-browser .cdk-drop-list.children .placeholder {
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 100%;
  letter-spacing: 1.2px;
  cursor: default;
  padding: 10px;
}
addiction-tree-browser .checked-nodes,
datalean-tree-browser .checked-nodes {
  display: none;
}

/* IMPORT FILE SCSS CHE ANDRANNO RINOMINATI DA DATALEAN AD ADDICTION */
/* --- TYPOGRAPHY --- */
/* --- COLORS --- */
/* --- ANIMATIONS --- */
/* --- STANDARD MIXINS --- */
/* --- FLEX --- */
/* --- FONTS --- */
/* Usato ad es. per scroll dentro select o popup con sfondo $brand-color-03 */
@keyframes loading {
  to {
    background-position-x: -20%;
  }
}
/*
NOTE BUTTONS FE 3.0 - DAM
I buttons con lo sfondo colorato, senza bordo e icona in mezzo (es. +) si fanno così:
@include button-style("e", $brand-color-04, $white, $buttonIcon: true, $buttonBackground: true);

I buttons senza sfondo, con bordo e icona in mezzo (es. permessi, back, navigazione, filtri) si fanno così:
@include button-style("e", $brand-color-04, $brand-color-04, $buttonIcon: true, $buttonBackground: false);

I buttons con lo sfondo colorato, senza bordo e testo in mezzo (es. salva, nuovo) si fanno così:
@include button-style(36px, $brand-color-04, $white, $buttonIcon: false, $buttonBackground: true);

I buttons senza sfondo, con bordo e testo in mezzo (es. aggiungi a collezione, collezioni) si fanno così:
@include button-style(36px, $brand-color-04, $brand-color-04, $buttonIcon: false, $buttonBackground: false);
*/
/* TODO - Rimuovere datalean e lasciare solo addiction */
datalean-input-dialog datalean-input,
addiction-input-dialog datalean-input {
  margin-bottom: 20px;
}
datalean-input-dialog .button-container,
addiction-input-dialog .button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
datalean-input-dialog .button-container button,
addiction-input-dialog .button-container button {
  padding: 10px 20px;
  background: #004BC4;
  border: none;
  border-radius: 5px;
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 100%;
  letter-spacing: 2.5px;
  cursor: default;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
@media (hover: hover) and (pointer: fine) {
  datalean-input-dialog .button-container button:hover,
  addiction-input-dialog .button-container button:hover {
    background: #FFFFFF;
    color: #004BC4;
  }
}

/* --- TYPOGRAPHY --- */
/* --- COLORS --- */
/* --- ANIMATIONS --- */
/* --- STANDARD MIXINS --- */
/* --- FLEX --- */
/* --- FONTS --- */
/* Usato ad es. per scroll dentro select o popup con sfondo $brand-color-03 */
@keyframes loading {
  to {
    background-position-x: -20%;
  }
}
/*
NOTE BUTTONS FE 3.0 - DAM
I buttons con lo sfondo colorato, senza bordo e icona in mezzo (es. +) si fanno così:
@include button-style("e", $brand-color-04, $white, $buttonIcon: true, $buttonBackground: true);

I buttons senza sfondo, con bordo e icona in mezzo (es. permessi, back, navigazione, filtri) si fanno così:
@include button-style("e", $brand-color-04, $brand-color-04, $buttonIcon: true, $buttonBackground: false);

I buttons con lo sfondo colorato, senza bordo e testo in mezzo (es. salva, nuovo) si fanno così:
@include button-style(36px, $brand-color-04, $white, $buttonIcon: false, $buttonBackground: true);

I buttons senza sfondo, con bordo e testo in mezzo (es. aggiungi a collezione, collezioni) si fanno così:
@include button-style(36px, $brand-color-04, $brand-color-04, $buttonIcon: false, $buttonBackground: false);
*/
/* Stile di base dentro modal-header.component.scss */
datalean-modal-header .modal-title .left .title {
  font-family: "Segma", sans-serif;
  font-size: 20px;
  font-weight: 300;
  color: #FFFFFF;
  line-height: 100%;
  letter-spacing: 0;
  cursor: default;
  word-break: break-all;
}
datalean-modal-header .modal-title .right button.select,
datalean-modal-header .modal-title .right button.save-btn {
  height: 36px;
  padding: 5px 40px;
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 100%;
  letter-spacing: 2.5px;
  cursor: default;
  cursor: pointer;
  text-transform: uppercase;
  border-radius: 10px;
  background: #004BC4;
  border: none;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
@media (hover: hover) and (pointer: fine) {
  datalean-modal-header .modal-title .right button.select:hover,
  datalean-modal-header .modal-title .right button.save-btn:hover {
    background: #FFFFFF;
    color: #004BC4;
  }
}
datalean-modal-header .modal-title .right button.cancel-btn {
  display: none;
}
datalean-modal-header .modal-title .right button.close-icon {
  box-shadow: none;
  border-radius: 3px;
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: initial;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  width: 34px;
  height: 34px;
  background: rgba(255, 255, 255, 0);
  border: 2px solid #FFFFFF;
}
datalean-modal-header .modal-title .right button.close-icon[aria-expanded=true] {
  border-radius: 0;
}
@media (hover: hover) and (pointer: fine) {
  datalean-modal-header .modal-title .right button.close-icon:hover {
    opacity: 0.5;
  }
}
datalean-modal-header .modal-title .right button.close-icon::before {
  content: "d";
  width: 12px;
  height: 12px;
  font-size: 12px;
  color: #FFFFFF;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 100%;
  font-family: "DataLean Font Icon";
  position: absolute;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
datalean-modal-header .modal-title .right button.close-icon:disabled, datalean-modal-header .modal-title .right button.close-icon.disabled, datalean-modal-header .modal-title .right button.close-icon[disabled], datalean-modal-header .modal-title .right button.close-icon[disabled=true] {
  opacity: 0.25;
  cursor: not-allowed;
  pointer-events: none;
}
datalean-modal-header .modal-title .right button.close-icon:disabled span, datalean-modal-header .modal-title .right button.close-icon.disabled span, datalean-modal-header .modal-title .right button.close-icon[disabled] span, datalean-modal-header .modal-title .right button.close-icon[disabled=true] span {
  cursor: not-allowed;
  pointer-events: none;
}

/* --- TYPOGRAPHY --- */
/* --- COLORS --- */
/* --- ANIMATIONS --- */
/* --- STANDARD MIXINS --- */
/* --- FLEX --- */
/* --- FONTS --- */
/* Usato ad es. per scroll dentro select o popup con sfondo $brand-color-03 */
@keyframes loading {
  to {
    background-position-x: -20%;
  }
}
/*
NOTE BUTTONS FE 3.0 - DAM
I buttons con lo sfondo colorato, senza bordo e icona in mezzo (es. +) si fanno così:
@include button-style("e", $brand-color-04, $white, $buttonIcon: true, $buttonBackground: true);

I buttons senza sfondo, con bordo e icona in mezzo (es. permessi, back, navigazione, filtri) si fanno così:
@include button-style("e", $brand-color-04, $brand-color-04, $buttonIcon: true, $buttonBackground: false);

I buttons con lo sfondo colorato, senza bordo e testo in mezzo (es. salva, nuovo) si fanno così:
@include button-style(36px, $brand-color-04, $white, $buttonIcon: false, $buttonBackground: true);

I buttons senza sfondo, con bordo e testo in mezzo (es. aggiungi a collezione, collezioni) si fanno così:
@include button-style(36px, $brand-color-04, $brand-color-04, $buttonIcon: false, $buttonBackground: false);
*/
/* TODO - Rimuovere datalean e lasciare solo addiction + fare stile */
datalean-list,
addiction-simple-list {
  /* TODO - Togliere tutto il commento quando non ci sarà più list.component.scss e il componente sarà in addiction-components */
}
datalean-list .page-title,
addiction-simple-list .page-title {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  padding: 0 0 10px 0;
}
datalean-list .page-title .title,
addiction-simple-list .page-title .title {
  font-family: "Segma", sans-serif;
  font-size: 10px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
  line-height: 150%;
  letter-spacing: 2.5px;
  cursor: default;
  text-transform: uppercase;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Segma", sans-serif;
  background: #09152C;
  overflow: hidden;
}

* {
  box-sizing: border-box;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  color: #FFFFFF;
}

a,
input,
button,
form,
textarea {
  outline: none;
  text-decoration: none;
}
a:focus-visible,
input:focus-visible,
button:focus-visible,
form:focus-visible,
textarea:focus-visible {
  outline: none;
}

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid rgba(31, 43, 67, 0.7);
  -webkit-text-fill-color: rgb(255, 255, 255);
  -webkit-box-shadow: 0 0 0 1000px rgba(31, 43, 67, 0.7) inset;
  transition: background-color 5000s ease-in-out 0s;
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.editor-alert-container {
  width: 100%;
  display: flex;
  flex-flow: column;
  padding: 0 10px;
  gap: 20px;
  margin-bottom: 20px;
}
.editor-alert-container .alert {
  width: 100%;
  color: #FFFFFF;
  padding: 10px 15px;
  border-radius: 5px;
  background-color: #A20000;
  text-align: center;
}

.cdk-overlay-backdrop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(9, 21, 44, 0.5);
  backdrop-filter: blur(10px) brightness(0.85);
  -webkit-backdrop-filter: blur(10px) brightness(0.85);
}

.cdk-overlay-container {
  /* ES. POPUP PER NUOVA IMMAGINE + POPUP PER NUOVA CARTELLA NELLA MEDIA LIBRARY*/
}
.cdk-overlay-container * {
  transition: none;
}
.cdk-overlay-container .cdk-global-overlay-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cdk-overlay-container .cdk-global-overlay-wrapper .cdk-overlay-pane {
  max-height: calc(100% - 80px);
  max-width: calc(100% - 80px);
}
.cdk-overlay-container .cdk-global-overlay-wrapper .cdk-overlay-pane.fullscreen, .cdk-overlay-container .cdk-global-overlay-wrapper .cdk-overlay-pane.versioning-popup {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
}
.cdk-overlay-container .cdk-global-overlay-wrapper .cdk-overlay-pane.fullscreen .mat-mdc-dialog-container, .cdk-overlay-container .cdk-global-overlay-wrapper .cdk-overlay-pane.versioning-popup .mat-mdc-dialog-container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  padding: 0;
}
.cdk-overlay-container .cdk-global-overlay-wrapper .cdk-overlay-pane.fullscreen .mat-mdc-dialog-container .mdc-dialog__container .mdc-dialog__surface modal-dialog .modal-dialog datalean-modal-header, .cdk-overlay-container .cdk-global-overlay-wrapper .cdk-overlay-pane.versioning-popup .mat-mdc-dialog-container .mdc-dialog__container .mdc-dialog__surface modal-dialog .modal-dialog datalean-modal-header {
  padding: 30px 20px 0 20px;
}
.cdk-overlay-container .cdk-global-overlay-wrapper .cdk-overlay-pane.fullscreen .mat-mdc-dialog-container .mdc-dialog__container .mdc-dialog__surface modal-dialog .modal-dialog datalean-modal-header .modal-title, .cdk-overlay-container .cdk-global-overlay-wrapper .cdk-overlay-pane.versioning-popup .mat-mdc-dialog-container .mdc-dialog__container .mdc-dialog__surface modal-dialog .modal-dialog datalean-modal-header .modal-title {
  margin: 0;
}
.cdk-overlay-container .cdk-global-overlay-wrapper .cdk-overlay-pane.fullscreen .mat-mdc-dialog-container .mdc-dialog__container .mdc-dialog__surface modal-dialog .modal-dialog .modal-content, .cdk-overlay-container .cdk-global-overlay-wrapper .cdk-overlay-pane.versioning-popup .mat-mdc-dialog-container .mdc-dialog__container .mdc-dialog__surface modal-dialog .modal-dialog .modal-content {
  padding: 0 0 20px 0;
}
.cdk-overlay-container .cdk-global-overlay-wrapper .cdk-overlay-pane .mat-mdc-dialog-container {
  height: auto;
  min-height: unset;
  max-height: 800px;
  max-width: 100%;
}
.cdk-overlay-container .cdk-global-overlay-wrapper .cdk-overlay-pane .mat-mdc-dialog-container .mdc-dialog__container .mat-mdc-dialog-surface modal-dialog .modal-dialog .modal-content datalean-tree-selector-dialog datalean-tree-browser {
  height: calc(100% - 60px);
}
.cdk-overlay-container .cdk-global-overlay-wrapper .cdk-overlay-pane .mat-mdc-dialog-container .mdc-dialog__container .mat-mdc-dialog-surface modal-dialog .modal-dialog .modal-content datalean-tree-selector-dialog datalean-tree-browser .top-bar {
  height: 60px;
}
.cdk-overlay-container .cdk-global-overlay-wrapper .cdk-overlay-pane .mat-mdc-dialog-container .mdc-dialog__container .mat-mdc-dialog-surface modal-dialog .modal-dialog .modal-content datalean-tree-selector-dialog datalean-tree-browser .top-bar addiction-search addiction-input .form-field .form-input-container {
  width: 100%;
}
.cdk-overlay-container .cdk-global-overlay-wrapper .cdk-overlay-pane .mat-mdc-dialog-container .mdc-dialog__container .mat-mdc-dialog-surface modal-dialog .modal-dialog .modal-content datalean-tree-selector-dialog datalean-tree-browser .top-bar .buttons {
  display: none;
}
.cdk-overlay-container .cdk-global-overlay-wrapper .cdk-overlay-pane .mat-mdc-dialog-container .mdc-dialog__container .mat-mdc-dialog-surface modal-dialog .modal-dialog .modal-content datalean-tree-selector-dialog datalean-tree-browser .tree-browser {
  height: calc(100% - 60px);
}
.cdk-overlay-container .cdk-global-overlay-wrapper .cdk-overlay-pane .mat-mdc-dialog-container .mdc-dialog__container .mat-mdc-dialog-surface modal-dialog .modal-dialog .modal-content datalean-tree-selector-dialog > div {
  width: 100%;
  height: 60px;
  padding-top: 20px;
  display: flex;
  justify-content: center;
}
.cdk-overlay-container .cdk-global-overlay-wrapper .cdk-overlay-pane .mat-mdc-dialog-container .mdc-dialog__container .mat-mdc-dialog-surface modal-dialog .modal-dialog .modal-content datalean-tree-selector-dialog > div button {
  height: 40px;
  min-width: 150px;
  border: none;
  background: #004BC4;
  border-radius: 10px;
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 100%;
  letter-spacing: 2.5px;
  cursor: default;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
@media (hover: hover) and (pointer: fine) {
  .cdk-overlay-container .cdk-global-overlay-wrapper .cdk-overlay-pane .mat-mdc-dialog-container .mdc-dialog__container .mat-mdc-dialog-surface modal-dialog .modal-dialog .modal-content datalean-tree-selector-dialog > div button:hover {
    background: #FFFFFF;
    color: #004BC4;
  }
}
.cdk-overlay-container mat-dialog-container {
  background-color: #132039;
  padding: 20px;
  width: 600px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.cdk-overlay-container mat-dialog-container .mdc-dialog__container {
  width: 100%;
  height: 100%;
}
.cdk-overlay-container mat-dialog-container .mdc-dialog__container .mat-mdc-dialog-surface {
  background: rgba(255, 255, 255, 0);
  box-shadow: none;
  overflow: hidden;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box {
  /* STILE TOOLTIP */
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane {
  transform: translateX(0) !important;
  /* POPUP PER SELECT */
  /* POPUP PER MENU */
  /* es. user-menu */
  /* POPUP COMPONENTE <addiction-date-picker> */
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-select-panel {
  min-width: calc(100% - 15px) !important;
  background: rgb(35, 46, 70);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: none;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-select-panel::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-select-panel::-webkit-scrollbar-thumb {
  background: #132039;
  box-shadow: none;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-select-panel::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0);
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-select-panel .mat-mdc-option {
  padding: 0 10px;
  height: 40px;
  background: rgba(255, 255, 255, 0) !important;
}
@media (hover: hover) and (pointer: fine) {
  .cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-select-panel .mat-mdc-option:hover .mdc-list-item__primary-text {
    color: #FFFFFF;
  }
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-select-panel .mat-mdc-option.mdc-list-item--selected .mdc-list-item__primary-text {
  color: #FFFFFF;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-select-panel .mat-mdc-option .mdc-list-item__primary-text {
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
  line-height: 170%;
  letter-spacing: 1.2px;
  cursor: default;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-select-panel .mat-mdc-option mat-pseudo-checkbox {
  width: 18px;
  height: 18px;
  border: none;
  background: rgb(44, 53, 70);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-select-panel .mat-mdc-option mat-pseudo-checkbox.mat-pseudo-checkbox-checked {
  background: #004BC4;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-select-panel .mat-mdc-option mat-pseudo-checkbox.mat-pseudo-checkbox-checked::before {
  opacity: 1;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-select-panel .mat-mdc-option mat-pseudo-checkbox::before {
  content: "";
  width: 7px;
  height: 4px;
  position: absolute;
  border-bottom: 2px solid #FFFFFF;
  border-left: 2px solid #FFFFFF;
  margin-bottom: 2px;
  transform: rotate(-45deg);
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-select-panel .mat-mdc-option mat-pseudo-checkbox::after {
  display: none;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-select-panel .mat-mdc-option .mat-ripple {
  display: none;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel {
  width: 100%;
  min-width: 150px;
  max-height: 600px;
  background: #01286C;
  border-radius: 5px;
  box-shadow: none;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: none;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0);
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content {
  padding: 5px 0;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item {
  padding: 10px 20px;
  min-height: 40px;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  cursor: pointer;
}
@media (hover: hover) and (pointer: fine) {
  .cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item:hover {
    background: rgba(255, 255, 255, 0) !important;
  }
  .cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item:hover.with-icon::before {
    opacity: 1;
  }
  .cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item:hover.DELETE .mat-mdc-menu-item-text, .cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item:hover.INVALID .mat-mdc-menu-item-text, .cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item:hover.RESET .mat-mdc-menu-item-text {
    color: #FF4848;
  }
  .cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item:hover.DELETE .mat-mdc-menu-item-text label.date-picker,
  .cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item:hover.DELETE .mat-mdc-menu-item-text span, .cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item:hover.INVALID .mat-mdc-menu-item-text label.date-picker,
  .cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item:hover.INVALID .mat-mdc-menu-item-text span, .cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item:hover.RESET .mat-mdc-menu-item-text label.date-picker,
  .cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item:hover.RESET .mat-mdc-menu-item-text span {
    color: #FF4848;
  }
  .cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item:hover .mat-mdc-menu-item-text {
    color: #FFFFFF;
  }
  .cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item:hover .mat-mdc-menu-item-text label.date-picker,
  .cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item:hover .mat-mdc-menu-item-text span {
    color: #FFFFFF;
  }
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item.with-icon {
  padding: 0 20px 0 50px;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item.with-icon::before {
  width: 16px;
  height: 16px;
  font-size: 16px;
  color: #FFFFFF;
  font-family: "DataLean Font Icon";
  position: absolute;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 20px;
  top: unset;
  bottom: unset;
  right: unset;
  margin: 0;
  border: none;
  border-radius: 0;
  opacity: 0.4;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item.with-icon.ar-SA::before {
  content: "";
  background: url("/assets/images/languages/ar-SA.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item.with-icon.cs-CZ::before {
  content: "";
  background: url("/assets/images/languages/cs-CZ.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item.with-icon.de-DE::before {
  content: "";
  background: url("/assets/images/languages/de-DE.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item.with-icon.en-AU::before {
  content: "";
  background: url("/assets/images/languages/en-AU.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item.with-icon.en-UK::before {
  content: "";
  background: url("/assets/images/languages/en-UK.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item.with-icon.en-US::before {
  content: "";
  background: url("/assets/images/languages/en-US.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item.with-icon.en-CA::before {
  content: "";
  background: url("/assets/images/languages/en-CA.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item.with-icon.en-IE::before {
  content: "";
  background: url("/assets/images/languages/en-IE.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item.with-icon.es-ES::before {
  content: "";
  background: url("/assets/images/languages/es-ES.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item.with-icon.fr-CA::before {
  content: "";
  background: url("/assets/images/languages/fr-CA.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item.with-icon.fr-FR::before {
  content: "";
  background: url("/assets/images/languages/fr-FR.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item.with-icon.it-IT::before {
  content: "";
  background: url("/assets/images/languages/it-IT.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item.with-icon.ja-JP::before {
  content: "";
  background: url("/assets/images/languages/ja-JP.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item.with-icon.ko-KR::before {
  content: "";
  background: url("/assets/images/languages/ko-KR.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item.with-icon.pl-PL::before {
  content: "";
  background: url("/assets/images/languages/pl-PL.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item.with-icon.pt-PT::before {
  content: "";
  background: url("/assets/images/languages/pt-PT.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item.with-icon.ru-RU::before {
  content: "";
  background: url("/assets/images/languages/ru-RU.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item.with-icon.tr-TR::before {
  content: "";
  background: url("/assets/images/languages/tr-TR.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item.with-icon.zh-CN::before {
  content: "";
  background: url("/assets/images/languages/zh-CN.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item.with-icon.nl-NL::before {
  content: "";
  background: url("/assets/images/languages/nl-NL.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item.DELETE .mat-mdc-menu-item-text, .cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item.INVALID .mat-mdc-menu-item-text, .cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item.RESET .mat-mdc-menu-item-text {
  color: rgba(255, 72, 72, 0.5);
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item.DELETE .mat-mdc-menu-item-text label.date-picker,
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item.DELETE .mat-mdc-menu-item-text span, .cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item.INVALID .mat-mdc-menu-item-text label.date-picker,
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item.INVALID .mat-mdc-menu-item-text span, .cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item.RESET .mat-mdc-menu-item-text label.date-picker,
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item.RESET .mat-mdc-menu-item-text span {
  color: rgba(255, 72, 72, 0.5);
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row wrap;
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
  line-height: 170%;
  letter-spacing: 1.2px;
  cursor: default;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text label.date-picker {
  /* quando c'è date-picker */
  font-family: "Segma", sans-serif;
  font-size: 10px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
  line-height: 100%;
  letter-spacing: 2.5px;
  cursor: default;
  text-transform: uppercase;
  margin-bottom: 5px;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text mat-checkbox {
  width: auto;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text mat-checkbox .mdc-form-field .mdc-checkbox {
  padding: 0;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text mat-checkbox .mdc-form-field .mdc-checkbox .mat-mdc-checkbox-touch-target {
  display: none;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text mat-checkbox .mdc-form-field .mdc-checkbox input {
  width: 18px;
  height: 18px;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text mat-checkbox .mdc-form-field .mdc-checkbox input:enabled:checked ~ .mdc-checkbox__background {
  border-color: unset;
  background-color: #004BC4 !important;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text mat-checkbox .mdc-form-field .mdc-checkbox input:enabled:indeterminate ~ .mdc-checkbox__background, .cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text mat-checkbox .mdc-form-field .mdc-checkbox input.mdc-checkbox--selected ~ .mdc-checkbox__background {
  border-color: unset;
  background-color: #004BC4 !important;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text mat-checkbox .mdc-form-field .mdc-checkbox input:enabled:indeterminate ~ .mdc-checkbox__background .mdc-checkbox__mixedmark, .cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text mat-checkbox .mdc-form-field .mdc-checkbox input.mdc-checkbox--selected ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
  transform: scale(0.5);
  border-color: #FFFFFF !important;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text mat-checkbox .mdc-form-field .mdc-checkbox .mdc-checkbox__ripple {
  display: none;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text mat-checkbox .mdc-form-field .mdc-checkbox .mdc-checkbox__background {
  width: 18px;
  height: 18px;
  top: unset;
  left: unset;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.1) !important;
  border: none !important;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text mat-checkbox .mdc-form-field .mdc-checkbox .mdc-checkbox__background svg {
  transform: scale(0.6);
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text mat-checkbox .mdc-form-field .mdc-checkbox .mdc-checkbox__background .mdc-checkbox__mixedmark {
  transform: scale(0);
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text mat-checkbox .mdc-form-field .mdc-checkbox .mat-mdc-checkbox-ripple {
  display: none;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text mat-checkbox .mdc-form-field .mdc-label {
  padding-left: 10px;
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 150%;
  letter-spacing: 0;
  cursor: default;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text mat-checkbox .mdc-form-field .mdc-label {
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 170%;
  letter-spacing: 1.2px;
  cursor: default;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text span {
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
  line-height: 170%;
  letter-spacing: 1.2px;
  cursor: default;
  margin-right: auto;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text addiction-radio {
  height: auto;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text addiction-radio .form-field.radio mat-radio-group {
  flex-flow: column;
  row-gap: 20px;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text addiction-radio .form-field.radio mat-radio-group mat-radio-button .mdc-form-field .mdc-label {
  font-family: "Segma", sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
  line-height: 170%;
  letter-spacing: 1.2px;
  cursor: default;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item .mat-ripple {
  display: none;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-submenu-icon {
  display: none;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item.mat-mdc-menu-item-submenu-trigger .mat-mdc-menu-item-text {
  padding-right: 10px;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item.mat-mdc-menu-item-submenu-trigger .mat-mdc-menu-item-text::before {
  content: "a";
  width: 10px;
  height: 10px;
  font-size: 7px;
  font-family: "DataLean Font Icon";
  position: absolute;
  right: -10px;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(180deg);
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane.mat-datepicker-popup * {
  color: #FFFFFF;
  fill: #FFFFFF;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane.mat-datepicker-popup .mat-datepicker-content {
  background: #01286C;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane.mat-datepicker-popup .mat-datepicker-content .mat-datepicker-content-container mat-calendar mat-calendar-header .mat-calendar-header .mat-calendar-controls {
  margin: 0;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane.mat-datepicker-popup .mat-datepicker-content .mat-datepicker-content-container mat-calendar .mat-calendar-content mat-month-view .mat-calendar-table .mat-calendar-table-header {
  display: none;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane.mat-datepicker-popup .mat-datepicker-content .mat-datepicker-content-container mat-calendar .mat-calendar-content mat-month-view .mat-calendar-table .mat-calendar-body .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.4);
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .mat-mdc-tooltip-panel mat-tooltip-component .mdc-tooltip .mdc-tooltip__surface {
  font-family: "Segma", sans-serif;
  font-size: 10px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 150%;
  letter-spacing: 0;
  cursor: default;
  background: #004BC4;
  border-radius: 5px;
  position: relative;
  padding: 5px 10px;
  margin-left: 10px;
  overflow: visible;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .mat-mdc-tooltip-panel mat-tooltip-component .mdc-tooltip .mdc-tooltip__surface::before {
  content: "";
  width: 10px;
  height: 10px;
  transform: rotate(-90deg);
  border-width: 6px;
  border-style: solid;
  border-color: transparent transparent #004BC4 transparent;
  position: absolute;
  top: unset;
  left: -11px;
  margin-top: 1px;
}

.overlay-container #toast-container > div {
  width: calc(100% - 40px);
  margin: 10px 20px 0 20px;
  padding: 10px 25px;
  border-radius: 0;
}
.overlay-container #toast-container > div.toast-error {
  background: #A20000;
  box-shadow: none;
}
.overlay-container #toast-container > div.toast-success {
  background: #4F8A10;
  box-shadow: none;
}
.overlay-container #toast-container > div .toast-message {
  font-family: "Segma", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 170%;
  letter-spacing: 1px;
  cursor: default;
  cursor: pointer;
  position: relative;
}
.overlay-container #toast-container > div .toast-message::before {
  content: "d";
  width: 12px;
  height: 12px;
  font-size: 12px;
  color: #FFFFFF;
  font-family: "DataLean Font Icon";
  position: absolute;
  right: 0;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.09, 0.955, 0.45, 0.985);
}

.page-loader-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}
.page-loader-container ng-lottie {
  width: 300px;
  height: 300px;
}